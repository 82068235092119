import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    FormText,
    Spinner,
    Alert
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import Api from '../../api/index';

import { FiImage } from 'react-icons/fi';

import Switch from "react-switch";

import Select from 'react-select';

import { CDN_URL } from "constants/urls";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import withRouter from "utilities/withRouter";

class GalleryEdit extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            error: null,
            imageId: null,
            imageUrl: null,
            caption: null,
            staffTags: [],
            serviceTags: [],
            productTags: [],
            appHomeScreen: false,
            staffOptions: [],
            serviceOptions: [],
            productOptions: []
        };
    }

    componentDidMount() {
        let staffOptions = [], serviceOptions = [], productOptions = [];
        this.loadImage();
        // Populate staff options
        this.props.staff.forEach((staffObj, staffIndex) => {
            if(staffObj.enabled === 1) {
                staffOptions.push({
                    id: staffObj.id,
                    label: staffObj.firstname + ' ' + staffObj.lastname,
                    value: staffObj.id
                });
            }
        });
        // Populate service options
        this.props.serviceDetail.forEach((serviceDetailObj, serviceDetailIndex) => {
            if(serviceDetailObj.service_business_detail_enabled === 1) {
                let foundServiceObj = this.props.service.find(x => x.service_id === serviceDetailObj.service_business_id && x.enabled === 1);
                if(foundServiceObj) {
                    serviceOptions.push({
                        id: serviceDetailObj.service_business_detail_id,
                        label: foundServiceObj.service_name + ' (' + serviceDetailObj.service_business_detail_name + ')',
                        value: serviceDetailObj.service_business_detail_id
                    });
                }
            }
        });
        // Populate product options
        this.props.product.forEach((productObj, productIndex) => {
            productOptions.push({
                id: productObj.id,
                label: productObj.product_name,
                value: productObj.id
            });
        });
        this.setState({ staffOptions, serviceOptions, productOptions });
    }

    async loadImage() {
        // Get url params
        const query = new URLSearchParams(this.props.location.search);
        let id = query.get("id");
        try {
            id = parseInt(id);
            if(!id || !Number.isInteger(id)) {
                this.props.navigate("/admin/gallery");
                return;
            }
        } catch(e) {
            this.props.navigate("/admin/gallery");
            return;
        }
        try {
            this.props.actions.loadLoadingSpinner(true);
            let response = await Api.getGalleryImage({ gallery_image_id: id });
            let imageData = response.data.imageData;
            let tagData = response.data.tagData;
            let staffTags = [], serviceTags = [], productTags = [];
            let existingStaffTags = tagData.filter(x => x.business_gallery_map_type_id === 3);
            existingStaffTags.forEach((tagObj, tagIndex) => {
                staffTags.push({
                    id: tagObj.business_gallery_map_item_id,
                    state: 0,
                    mapId: tagObj.business_gallery_map_id
                });
            });
            let existingServiceTags = tagData.filter(x => x.business_gallery_map_type_id === 2);
            existingServiceTags.forEach((tagObj, tagIndex) => {
                serviceTags.push({
                    id: tagObj.business_gallery_map_item_id,
                    state: 0,
                    mapId: tagObj.business_gallery_map_id
                });
            });
            let existingProductTags = tagData.filter(x => x.business_gallery_map_type_id === 4);
            existingProductTags.forEach((tagObj, tagIndex) => {
                productTags.push({
                    id: tagObj.business_gallery_map_item_id,
                    state: 0,
                    mapId: tagObj.business_gallery_map_id
                });
            });
            this.setState({
                imageId: id,
                caption: imageData.business_gallery_caption,
                imageUrl: imageData.business_gallery_img,
                appHomeScreen: imageData.business_gallery_app_home_screen === 1 ? true : false,
                staffTags,
                serviceTags,
                productTags
            });
            this.props.actions.loadLoadingSpinner(false);
        } catch(e) {
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Unable to load gallery image. Please contact Support if the problem persists.", "danger", "bc", 6);
            this.props.navigate("/admin/gallery");
        }
    }

    handleChange(e, name) {
        if(name === 'caption') {
            this.setState({ caption: e.target.value });
        } else if(name === 'staff_tag') {
            let staffTags = [...this.state.staffTags];
            if(!e) {
                e = [];
            }
            // Add new tags
            e.forEach((tagObj, tagIndex) => {
                let foundObj = staffTags.find(x => x.id === tagObj.id);
                if(foundObj) {
                    if(foundObj.state === 3 && foundObj.mapId > 0) {
                        let foundIndex = staffTags.findIndex(x => x.id === tagObj.id);
                        staffTags[foundIndex].state = 0;
                    }
                } else {
                    staffTags.push({ id: tagObj.id, state: 1, mapId: 0 });
                }
            });
            // Remove existing tags
            staffTags.forEach((staffTagObj, staffTagIndex) => {
                let foundObj = e.find(x => x.id === staffTagObj.id);
                if(!foundObj) {
                    let existingStaffTagIndex = staffTags.findIndex(x => x.id === staffTagObj.id);
                    if(staffTags[existingStaffTagIndex].mapId > 0) {
                        staffTags[existingStaffTagIndex].state = 3;
                    } else {
                        staffTags = staffTags.splice(existingStaffTagIndex, 1);
                    }
                }
            });
            this.setState({ staffTags });
        } else if(name === 'service_tag') {
            let serviceTags = [...this.state.serviceTags];
            if(!e) {
                e = [];
            }
            // Add new tags
            e.forEach((tagObj, tagIndex) => {
                let foundObj = serviceTags.find(x => x.id === tagObj.id);
                if(foundObj) {
                    if(foundObj.state === 3 && foundObj.mapId > 0) {
                        let foundIndex = serviceTags.findIndex(x => x.id === tagObj.id);
                        serviceTags[foundIndex].state = 0;
                    }
                } else {
                    serviceTags.push({ id: tagObj.id, state: 1, mapId: 0 });
                }
            });
            // Remove existing tags
            serviceTags.forEach((serviceTagObj, serviceTagIndex) => {
                let foundObj = e.find(x => x.id === serviceTagObj.id);
                if(!foundObj) {
                    let existingServiceTagIndex = serviceTags.findIndex(x => x.id === serviceTagObj.id);
                    if(serviceTags[existingServiceTagIndex].mapId > 0) {
                        serviceTags[existingServiceTagIndex].state = 3;
                    } else {
                        serviceTags = serviceTags.splice(existingServiceTagIndex, 1);
                    }
                }
            });
            this.setState({ serviceTags });
        } else if(name === 'product_tag') {
            let productTags = [...this.state.productTags];
            if(!e) {
                e = [];
            }
            // Add new tags
            e.forEach((tagObj, tagIndex) => {
                let foundObj = productTags.find(x => x.id === tagObj.id);
                if(foundObj) {
                    if(foundObj.state === 3 && foundObj.mapId > 0) {
                        let foundIndex = productTags.findIndex(x => x.id === tagObj.id);
                        productTags[foundIndex].state = 0;
                    }
                } else {
                    productTags.push({ id: tagObj.id, state: 1, mapId: 0 });
                }
            });
            // Remove existing tags
            productTags.forEach((productTagObj, productTagIndex) => {
                let foundObj = e.find(x => x.id === productTagObj.id);
                if(!foundObj) {
                    let existingProductTagIndex = productTags.findIndex(x => x.id === productTagObj.id);
                    if(productTags[existingProductTagIndex].mapId > 0) {
                        productTags[existingProductTagIndex].state = 3;
                    } else {
                        productTags = productTags.splice(existingProductTagIndex, 1);
                    }
                }
            });
            this.setState({ productTags });
        } else if(name === 'app_home_screen') {
            this.setState({ appHomeScreen: e });
        }
    }

    async submitImage() {
        if(this.state.loading) {
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let data = {
                gallery_image_id: this.state.imageId,
                gallery_image_caption: this.state.caption,
                gallery_image_staff_tags: this.state.staffTags,
                gallery_image_service_tags: this.state.serviceTags,
                gallery_image_product_tags: this.state.productTags,
                gallery_image_app_home_screen: this.state.appHomeScreen
            };
            await Api.updateGalleryImage(data);
            this.props.triggerNotification("Gallery image updated successfully.", "success", "bc", 4);
            this.props.navigate('/admin/gallery');
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    render() {
        let selectedStaffIds = this.state.staffTags.filter(x => x.state !== 3).map(x => x.id);
        let selectedServiceIds = this.state.serviceTags.filter(x => x.state !== 3).map(x => x.id);
        let selectedProductIds = this.state.productTags.filter(x => x.state !== 3).map(x => x.id);
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Edit Image</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={8} md={7} lg={6} xl={4} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <div style={{ marginBottom: 15 }}>
                                    <div className="gallery-img-container">
                                        {this.state.imageUrl ?
                                            <div>
                                                <img alt="GalleryImg" src={`${CDN_URL}/` + this.state.imageUrl} style={{ width: '100%' }}/>
                                            </div>
                                        :
                                            <div className="gallery-img-icon-container">
                                                <FiImage size={30}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Caption</h6>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                style={{ padding: 10 }}
                                                value={this.state.caption}
                                                onChange={(e) => this.handleChange(e, 'caption')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Tags</h6>
                                        <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                            You can tag services, staff and products in your images. Tags will determine where the image is displayed on your widget and/or app.
                                        </FormText>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <label>Staff</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isMulti
                                                options={this.state.staffOptions}
                                                placeholder={'Tag staff...'}
                                                value={this.state.staffOptions.filter(x => selectedStaffIds.includes(x.id))}
                                                onChange={(e) => this.handleChange(e, 'staff_tag')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <label>Services</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isMulti
                                                options={this.state.serviceOptions}
                                                placeholder={'Tag services...'}
                                                value={this.state.serviceOptions.filter(x => selectedServiceIds.includes(x.id))}
                                                onChange={(e) => this.handleChange(e, 'service_tag')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <label>Products</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isMulti
                                                options={[]}
                                                placeholder={'Tag products...'}
                                                value={this.state.productOptions.filter(x => selectedProductIds.includes(x.id))}
                                                onChange={(e) => this.handleChange(e, 'product_tag')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Visibility</h6>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} className="mt-2">
                                        <FormGroup>
                                            <p className="react-switch-label">App Home Screen</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'app_home_screen') }}
                                                disabled={this.props.business.business_account_type_id === 2}
                                                checked={this.state.appHomeScreen}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                            <FormText color="muted" style={{ marginTop: '1rem' }}>
                                                Should this image be visible on your business app home screen?
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <Button color="primary" onClick={() => this.submitImage()}>
                                    {this.state.loading ? <Spinner color="success" size="sm" /> : 'Save Changes'}
                                </Button>
                            </Col>
                        </Row>
                        <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                            <span>
                                {this.state.error}
                            </span>
                        </Alert>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        staff: state.staff,
        service: state.service,
        serviceDetail: state.serviceDetail,
        product: state.product
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GalleryEdit));