/*eslint-disable*/
import React from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalHeader,
    Input,
    FormGroup,
    Row,
    Col,
    Label,
    Button,
    Alert,
    Spinner
} from "reactstrap";
import Select from "react-select";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import customerStatus from '../../constants/customerStatus';
import Api from 'api/index';
import ModalFooter from "reactstrap/lib/ModalFooter";

class CustomerEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            firstname: null,
            lastname: null,
            email: null,
            contact_number: null,
            contact_number_iso: 'gb',
            marketing_email: true,
            marketing_sms: true,
            notification_email: true,
            notification_sms: true,
            status: null,
            notes: null,
            procedure_id: null,
            error: null,
            loading: false,
        };
    }

    componentDidMount() {
        console.log('Component mounted');
        if(this.props.customerData) {
            this.setState({
                id: this.props.customerData.user_id,
                firstname: this.props.customerData.user_firstname,
                lastname: this.props.customerData.user_lastname,
                email: this.props.customerData.user_email,
                contact_number: this.props.customerData.user_phone,
                marketing_email: (this.props.customerData.user_marketing_email && this.props.customerData.user_marketing_email === 1 ? true : false),
                marketing_sms: (this.props.customerData.user_marketing_sms && this.props.customerData.user_marketing_sms === 1 ? true : false),
                notification_email: (this.props.customerData.user_notification_email && this.props.customerData.user_notification_email === 1 ? true : false),
                notification_sms: (this.props.customerData.user_notification_sms && this.props.customerData.user_notification_sms === 1 ? true : false),
                status: this.props.customerData.user_block_id ? 1 : 0,
                notes: this.props.customerData.user_note,
                procedure_id: this.props.customerData.sign_up_procedure_id
            });
        } else {
            this.props.toggleVisible();
        }
    }

    handleChange(e, name) {
        if(name === 'firstname') {
            this.setState({ firstname: e.target.value });
        } else if(name === 'lastname') {
            this.setState({ lastname: e.target.value });
        } else if(name === 'email') {
            this.setState({ email: e.target.value });
        } else if(name === 'contact_number') {
            this.setState({ contact_number: e[0], contact_number_iso: e[1].countryCode });
        } else if(name === 'marketing_email') {
            this.setState({ marketing_email: e.target.checked });
        } else if(name === 'marketing_sms') {
            this.setState({ marketing_sms: e.target.checked });
        } else if(name === 'notification_email') {
            this.setState({ notification_email: e.target.checked });
        } else if(name === 'notification_sms') {
            this.setState({ notification_sms: e.target.checked });
        } else if(name === 'status') {
            this.setState({ status: e.value });
        } else if(name === 'notes') {
            this.setState({ notes: e.target.value });
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async submitEditCustomer() {
        if(this.state.loading) {
            return;
        }
        if(!this.state.firstname || this.state.firstname.length === 0) {
            this.setState({ error: 'A first name is required.' });
            return;
        }
        if(!this.state.lastname || this.state.lastname.length === 0) {
            this.setState({ error: 'A last name is required.' });
            return;
        }
        if(this.state.email && this.state.email.length > 0 && !this.validateEmail(this.state.email) && this.state.procedure_id !== 5){
            this.setState({ error: 'Invalid email address.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let contact_number = null;
            if(this.state.contact_number && this.state.contact_number.length > 0) {
                if(this.state.contact_number.charAt(0) !== '+') {
                    contact_number = '+' + this.state.contact_number;
                } else {
                    contact_number = this.state.contact_number;
                }
            }
            let data = {
                user_id: this.state.id,
                user_firstname: this.state.firstname,
                user_lastname: this.state.lastname,
                user_email: this.state.email,
                user_phone: contact_number,
                user_phone_iso: this.state.contact_number_iso,
                user_notification_email: this.state.notification_email,
                user_notification_sms: this.state.notification_sms,
                user_marketing_email: this.state.marketing_email,
                user_marketing_sms: this.state.marketing_sms,
                user_block: (this.state.status === 1),
                user_note: this.state.notes
            };
            console.log(data);
            let response = await Api.editCustomer(data);
            this.props.updateCustomerState(data);
            this.setState({ loading: false, error: null });
            this.props.triggerNotification("Customer updated successfully.", "success", "bc", 4);
            this.props.toggleVisible();
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    render() {
        return (
        <>
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Edit Customer</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={6} sm={12}>
                            <label>First name <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Input
                                    type="text"
                                    value={this.state.firstname}
                                    placeholder="First name"
                                    disabled={this.state.procedure_id !== 5}
                                    onChange={(e) => this.handleChange(e, 'firstname')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} sm={12}>
                            <label>Last name <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Input
                                    type="text"
                                    value={this.state.lastname}
                                    placeholder="Last name"
                                    disabled={this.state.procedure_id !== 5}
                                    onChange={(e) => this.handleChange(e, 'lastname')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Email</label>
                            <FormGroup>
                                <Input
                                    type="email"
                                    value={this.state.email}
                                    placeholder="Email"
                                    disabled={this.state.procedure_id !== 5}
                                    onChange={(e) => this.handleChange(e, 'email')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Contact Number</label>
                            <FormGroup>
                                <PhoneInput
                                    className="contact-input"
                                    value={this.state.contact_number}
                                    placeholder="Contact Number"
                                    inputClass="form-control"
                                    disabled={this.state.procedure_id !== 5}
                                    onChange={(value, country, e, formattedValue) => this.handleChange([value, country, e, formattedValue], 'contact_number')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className="mb-2">
                            <label>Marketing Preferences</label>
                            <FormGroup check style={{ paddingLeft: 0 }}>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        defaultChecked={(this.props.customerData.user_marketing_email && this.props.customerData.user_marketing_email === 1)}
                                        onChange={(e) => this.handleChange(e, 'marketing_email')}
                                    />
                                    <span className="form-check-sign" />
                                    Email
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        defaultChecked={(this.props.customerData.user_marketing_sms && this.props.customerData.user_marketing_sms === 1)}
                                        onChange={(e) => this.handleChange(e, 'marketing_sms')}
                                    />
                                    <span className="form-check-sign" />
                                    SMS
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md={6} className="mb-2">
                            <label>Notification Preferences</label>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        defaultChecked={(this.props.customerData.user_notification_email && this.props.customerData.user_notification_email === 1)}
                                        onChange={(e) => this.handleChange(e, 'notification_email')}
                                    />
                                    <span className="form-check-sign" />
                                    Email
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        defaultChecked={(this.props.customerData.user_notification_sms && this.props.customerData.user_notification_sms === 1)}
                                        onChange={(e) => this.handleChange(e, 'notification_sms')}
                                    />
                                    <span className="form-check-sign" />
                                    SMS
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                            <label>Status</label>
                            <FormGroup>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={customerStatus}
                                    defaultValue={customerStatus.find(x => x.value === (this.props.customerData.user_block_id ? 1 : 0))}
                                    onChange={(e) => this.handleChange(e, 'status')}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                            <label>Notes</label>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    value={this.state.notes}
                                    placeholder="Notes"
                                    style={{ padding: 10 }}
                                    onChange={(e) => this.handleChange(e, 'notes')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        style={{ width: '100%' }}
                        onClick={() => this.submitEditCustomer()}
                        disabled={this.state.success}
                    >
                        {this.state.loading ? <Spinner color="success" size="sm" /> : "Edit Customer"}
                    </Button>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        </>
        );
    }
}

export default CustomerEdit;