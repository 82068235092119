import React from "react";
import { ColorPicker, ColorService } from "react-color-palette";
import "react-color-palette/css";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import ModalFooter from "reactstrap/lib/ModalFooter";

class ColourPicker extends React.Component {

    constructor(props) {
        super(props);
        this.pickerContainerRef = React.createRef();
        this.state = {
            color: null,
            pickerWidth: 200
        }
    }

    handleChange(e) {
        this.setState({ color: e });
    }
    
    handleRef(e) {
        if(e && e.clientWidth && e.clientWidth !== this.state.pickerWidth) {
            this.setState({ pickerWidth: e.clientWidth });
        }
    }

    submitColor() {
        if(this.props.onSubmit && this.state.color) {
            if(this.props.alpha) {
                let rgbaString = `rgba(${this.state.color.rgb.r},${this.state.color.rgb.g},${this.state.color.rgb.b},${this.state.color.rgb.a ? this.state.color.rgb.a.toFixed(1) : 1})`
                this.props.onSubmit(rgbaString);
            } else {
                this.props.onSubmit(this.state.color.hex);
            }
        }
    }

    parseRgbaString(rgbaString) {
        let cache = /rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/.exec(rgbaString);
        if (cache) {
            cache = { r: +cache[1], g: +cache[2], b: +cache[3], a: +cache[4] };
            return cache;
        } else {
            return { r: 0, g: 0, b: 0, a: 0.6 };
        }
    }

    getColour() {
        if(this.state.color) {
            return this.state.color;
        }
        if(this.props.alpha) {
            return ColorService.convert(
                "rgb",
                this.parseRgbaString(this.props.initialColor)
            );
        } else {
            return ColorService.convert(
                "hex",
                this.props.initialColor ? this.props.initialColor : '#000000'
            );
        }
    }

    render() {
        const initialColor = this.getColour();
        return (
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Select Colour</ModalHeader>
                <ModalBody style={{ paddingBottom: 0 }} >
                    <Row>
                        <Col md={12}>
                            <div style={{ width: '100%' }} ref={ (ref) => this.handleRef(ref) }>
                                <ColorPicker
                                    height={200}
                                    color={initialColor}
                                    onChange={(e) => this.handleChange(e)}
                                    hideHSV
                                    hideAlpha={!this.props.alpha}
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        style={{ width: '100%' }}
                        onClick={() => this.submitColor()}
                    >
                        Select
                    </Button>
                    {this.props.remove ?
                        <Button
                            color="primary"
                            className="mt-3"
                            outline
                            style={{ width: '100%' }}
                            onClick={() => this.props.onSubmit(null)}
                        >
                            Remove
                        </Button>
                    : null}
                </ModalFooter>
            </Modal>
        );
    }

}

export default ColourPicker;