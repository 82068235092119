import * as types from "../constants/actionTypes";

export default function staffReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_STAFF_SUCCESS:
            return action.staff;
        case types.CLEAR_STAFF_SUCCESS:
            return [];
        default:
            return state;
    };
    
};