import React from "react";
import moment from "moment";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    FormGroup,
    Button,
    Spinner,
    Alert
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "react-switch";
import Datetime from "react-datetime";

import Api from "api/index";

import { FiX, FiTrash } from 'react-icons/fi';

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

class BusinessScheduleEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deleteLoading: false,
            error: null,
            sweetAlert: null,
            scheduleId: null,
            startDate: null,
            endDate: null,
            mondayEnabled: false,
            tuesdayEnabled: false,
            wednesdayEnabled: false,
            thursdayEnabled: false,
            fridayEnabled: false,
            saturdayEnabled: false,
            sundayEnabled: false
        };
    }

    componentDidMount() {
        if(this.props.data) {
            let scheduleObj = this.props.data;
            this.setState({
                scheduleId: scheduleObj.business_schedule_id,
                startDate: moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD'),
                endDate: scheduleObj.business_schedule_end ? moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD') : null,
                mondayEnabled: scheduleObj.business_schedule_monday,
                tuesdayEnabled: scheduleObj.business_schedule_tuesday,
                wednesdayEnabled: scheduleObj.business_schedule_wednesday,
                thursdayEnabled: scheduleObj.business_schedule_thursday,
                fridayEnabled: scheduleObj.business_schedule_friday,
                saturdayEnabled: scheduleObj.business_schedule_saturday,
                sundayEnabled: scheduleObj.business_schedule_sunday
            });
        }
    }

    async submitSchedule() {
        if(this.state.loading || this.state.deleteLoading) {
            return;
        }
        // Validate date range
        if(!this.state.startDate || !moment.isMoment(this.state.startDate)) {
            this.setState({ error: 'A start date is required.' });
            return;
        }
        if(this.state.endDate && !moment.isMoment(this.state.endDate)) {
            this.setState({ error: 'An end date is required.' });
            return;
        }
        if(this.state.endDate && this.state.startDate.isSameOrAfter(this.state.endDate, 'day')) {
            this.setState({ error: 'The start date must be before the end date.' });
            return;
        }
        this.setState({ loading: true, error: null });
        let data = {
            schedule_id: this.state.scheduleId,
            schedule_start: this.state.startDate.format('YYYY-MM-DD'),
            schedule_end: this.state.endDate ? this.state.endDate.format('YYYY-MM-DD') : null,
            monday: this.state.mondayEnabled,
            tuesday: this.state.tuesdayEnabled,
            wednesday: this.state.wednesdayEnabled,
            thursday: this.state.thursdayEnabled,
            friday: this.state.fridayEnabled,
            saturday: this.state.saturdayEnabled,
            sunday: this.state.sundayEnabled
        };
        try {
            await Api.updateBusinessSchedule(data);
            this.props.triggerNotification("Business schedule updated successfully.", "success", "bc", 4);
            if(this.props.onSubmit) {
                this.props.onSubmit();
            }
        } catch(e) {
            if(e.response && e.response.data && e.response.data.message) {
                this.setState({ loading: false, error: e.response.data.message + "."});
            } else {
                this.setState({ loading: false, error: "Unable to update business schedule. If the problem persists, please contact us at support@styler.digital." });
            }
        }
    }

    deleteSchedule() {
        this.setState({
            sweetAlert: (
              <SweetAlert
                warning
                title="Are you sure?"
                onConfirm={() => this.confirmDeleteSchedule()}
                onCancel={() => this.setState({ sweetAlert: null })}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="muted"
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
              >
                Are you sure that you want to delete this schedule? This action cannot be undone.
              </SweetAlert>
            ),
        });
    }

    async confirmDeleteSchedule() {
        if(this.state.loading || this.state.deleteLoading) {
            return;
        }
        this.setState({ deleteLoading: true, sweetAlert: null });
        try {
            await Api.deleteBusinessSchedule({ schedule_id: this.state.scheduleId });
            this.props.triggerNotification("Business schedule deleted successfully.", "success", "bc", 4);
            if(this.props.onSubmit) {
                this.props.onSubmit();
            }
        } catch(e) {
            this.setState({ deleteLoading: false, error: "Unable to delete business schedule. If the problem persists, please contact us at support@styler.digital." });
        }
    }

    renderEndDateInput(props,) {
        return (
            <input {...props} value={this.state.endDate ? props.value : "Ongoing"} />
        );
    }

    render() {
        return (
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Edit Business Schedule</ModalHeader>
                <ModalBody>
                    {this.state.sweetAlert}
                    <Row>
                        <Col md={12} className="mb-3">
                            <FormGroup className="date-group">
                                <Datetime
                                    className="date-input date-input-left"
                                    dateFormat={"ddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "Start Date", readonly: "readonly" }}
                                    value={this.state.startDate ? this.state.startDate : null}
                                    onChange={(e) => this.setState({ startDate: e })}
                                />
                                <Datetime
                                    className="date-input date-input-right"
                                    dateFormat={"ddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "End Date", readonly: "readonly" }}
                                    value={this.state.endDate ? this.state.endDate : moment()}
                                    renderInput={(e) => this.renderEndDateInput(e)}
                                    onChange={(e) => this.setState({ endDate: e })}
                                />
                                {this.state.endDate ?
                                    <div style={{ position: 'absolute', right: 25, padding: 6, cursor: 'pointer' }} onClick={() => this.setState({ endDate: null })}>
                                        <FiX size={18} color={'#ed5565'}/>
                                    </div>
                                : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Monday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ mondayEnabled: e }) }}
                                        checked={this.state.mondayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Tuesday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ tuesdayEnabled: e }) }}
                                        checked={this.state.tuesdayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Wednesday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ wednesdayEnabled: e }) }}
                                        checked={this.state.wednesdayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Thursday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ thursdayEnabled: e }) }}
                                        checked={this.state.thursdayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Friday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ fridayEnabled: e }) }}
                                        checked={this.state.fridayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Saturday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ saturdayEnabled: e }) }}
                                        checked={this.state.saturdayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Sunday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ sundayEnabled: e }) }}
                                        checked={this.state.sundayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="modal-split-footer">
                        <Button
                            color="primary"
                            style={{ width: '100%' }}
                            onClick={() => this.submitSchedule()}
                        >
                            {this.state.loading ? <Spinner color="success" size="sm" /> : "Save Changes"}
                        </Button>
                        <Button
                            color="danger"
                            className="modal-split-footer-right-button"
                            onClick={() => this.deleteSchedule()}
                            disabled={this.state.success}
                        >
                            {this.state.deleteLoading ? <Spinner color="light" size="sm" /> : <FiTrash size={15}/>}
                        </Button>
                    </div>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error ? true : false} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        );
    }

}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessScheduleEdit);