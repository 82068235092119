import * as types from "../constants/actionTypes";

export default function loadingReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_LOADING_SUCCESS:
            return action
        default:
            return state;
    };
    
};