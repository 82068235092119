import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup
} from "reactstrap";
import Switch from "react-switch";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";
// API
import Api from 'api/index';
// Utilities
import withRouter from "utilities/withRouter";

class HubNotifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            notifyNewBooking: this.props.user.notify_new_booking === 1,
            notifyCancellation: this.props.user.notify_cancellation === 1
        };
    }

    handleChange(e, name) {
        switch(name) {
            case "notify_booking":
                this.setState({ notifyNewBooking: e });
                break;
            case "notify_cancellation":
                this.setState({ notifyCancellation: e });
                break;
            default:
                return;
        }
    }

    async submitNotificationPreferences() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            await Api.updateNotificationPreferences({ notify_new_booking: this.state.notifyNewBooking, notify_cancellation: this.state.notifyCancellation });
            let existingUser = Object.assign({}, this.props.user);
            existingUser.notify_new_booking = this.state.notifyNewBooking ? 1 : 0;
            existingUser.notify_cancellation = this.state.notifyCancellation ? 1 : 0;
            this.props.actions.updateUser(existingUser);
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Hub notification preferences updated successfully.", "success", "bc", 4);
            this.props.navigate('/admin/settings');
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while updating your business settings.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Notifications</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} lg={6} xl={4} className="ms-auto me-auto">
                        <Card style={{ marginBottom: 0 }}>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-2">
                                            <p className="text-muted">The Styler Hub can update you with real-time booking notifications. Choose the booking notifications which you would like to receive.</p>
                                        </div>
                                        <hr/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} className="mt-2">
                                        <FormGroup>
                                            <p className="react-switch-label text-primary">New Bookings</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'notify_booking') }}
                                                checked={this.state.notifyNewBooking}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12} className="mt-2">
                                        <FormGroup>
                                            <p className="react-switch-label text-primary">Cancellations</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'notify_cancellation') }}
                                                checked={this.state.notifyCancellation}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <div className="action-button-row">
                                    <Button color="primary" onClick={() => this.submitNotificationPreferences()}>
                                        Save Changes
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HubNotifications));