import React from "react";
import moment from "moment";
// reactstrap components
import {
    Row,
    Col,
    FormGroup,
    Button,
    Alert,
    Spinner,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

import Select from "react-select";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import Api from "api/index";

import time from '../../constants/time';

class StaffHours extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startTime: null,
            endTime: null,
            loading: false,
            error: null
        };
    }

    componentDidMount() {
        if(!this.props.data || !this.props.data.start || !this.props.data.end) {
            return;
        }
        this.setState({ startTime: moment(this.props.data.start, 'HH:mm:ss'), endTime: moment(this.props.data.end, 'HH:mm:ss') });
    }

    handleChange(e, name) {
        if(name === 'startTime') {
            this.setState({ startTime: e });
        } else if(name === 'endTime') {
            this.setState({ endTime: e });
        }
    }

    async submitHours() {
        if(this.state.loading) {
            return;
        }
        if(!this.props.locationId) {
            this.setState({ error: 'No business location selected.' });
            return;
        }
        if(!this.state.startTime || !moment.isMoment(this.state.startTime)) {
            this.setState({ error: 'A start time is required.' });
            return;
        }
        if(!this.state.endTime || !moment.isMoment(this.state.endTime)) {
            this.setState({ error: 'An end time is required.' });
            return;
        }
        if(moment(this.state.startTime).isSameOrAfter(this.state.endTime, 'minute')) {
            this.setState({ error: 'The start time must be before the end time.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let data = {
                date: moment(this.props.data.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
                start_time: this.state.startTime.format('HH:mm'),
                end_time: this.state.endTime.format('HH:mm'),
                staff_id: this.props.data.staff_id,
                business_location_id: this.props.locationId,
                working: true
            };
            await Api.updateStaffHours(data);
            if(this.props.onSubmit) {
                this.props.onSubmit(data);
            }
            this.setState({ loading: false });
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    render() {
        let staffObj = this.props.staff.find(x => x.id === this.props.data.staff_id);
        let businessLocationObj = this.props.businessLocation.find(x => x.business_location_id === this.props.locationId);
        return (
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Amend {staffObj ? staffObj.firstname + "'s" : null} Hours</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <label>Location</label>
                            <FormGroup>
                                <Input
                                    type="text"
                                    value={businessLocationObj ? businessLocationObj.business_location_name : null}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <label>Date</label>
                            <FormGroup>
                                <Input
                                    type="text"
                                    value={this.props.data ? moment(this.props.data.date, 'YYYY-MM-DD HH:mm:ss').format('dddd, Do MMM YYYY') : null}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <label>Start & End <span className="text-danger">*</span></label>
                            <FormGroup className="time-group">
                                <Select
                                    className="react-select primary time-input time-input-left"
                                    classNamePrefix="react-select"
                                    options={time}
                                    value={this.state.startTime ? time.find(x => x.value === this.state.startTime.format('HH:mm')) : null}
                                    onChange={(e) => this.handleChange(moment(e.value, 'HH:mm'), 'startTime')}
                                    placeholder={"--:--"}
                                    isDisabled={this.state.loading}
                                />
                                <Select
                                    className="react-select primary time-input time-input-right"
                                    classNamePrefix="react-select"
                                    options={time}
                                    value={this.state.endTime ? time.find(x => x.value === this.state.endTime.format('HH:mm')) : null}
                                    onChange={(e) => this.handleChange(moment(e.value, 'HH:mm'), 'endTime')}
                                    placeholder={"--:--"}
                                    isDisabled={this.state.loading}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        style={{ width: '100%' }}
                        onClick={() => this.submitHours()}
                    >
                        {this.state.loading ? <Spinner color="success" size="sm" /> : "Save"}
                    </Button>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error ? true : false} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        staff: state.staff,
        businessLocation: state.businessLocation
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffHours);