import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    FormText,
    InputGroup,
    InputGroupText,
    Alert
} from "reactstrap";
import Switch from "react-switch";
import CurrencyInput from 'react-currency-input-field';
// Components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ColourPicker from "components/ColourPicker/ColourPicker";
// Decorators
import withRouter from "utilities/withRouter";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import Api from 'api/index';

class RevenueProtection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lateCancellationFeeEnabled: false,
            lateCancellationFeePercentage: 0,
            noShowFeeEnabled: false,
            noShowFeePercentage: 0,
        };
    }

    componentDidMount() {
        this.props.actions.loadBusinessAuto();
        this.setState({
            lateCancellationFeeEnabled: this.props.business.cancellation_fee_enabled === 1,
            lateCancellationFeePercentage: this.props.business.cancellation_fee_percentage,
            noShowFeeEnabled: this.props.business.no_show_fee_enabled === 1,
            noShowFeePercentage: this.props.business.no_show_fee_percentage
        });
    }

    handleChange(e, name) {
        if(name === 'lateCancellationFeeEnabled') {
            this.setState({ lateCancellationFeeEnabled: e });
        } else if(name === 'lateCancellationFeePercentage') {
            let feePercentage  = parseInt(e);
            if(feePercentage > 100) {
                feePercentage = 100;
            } else if(feePercentage < 0) {
                feePercentage = 0;
            }
            if(!Number.isInteger(feePercentage)) {
                feePercentage = 0;
            }
            this.setState({ lateCancellationFeePercentage: feePercentage });
        } else if(name === 'noShowFeeEnabled') {
            this.setState({ noShowFeeEnabled: e });
        } else if(name === 'noShowFeePercentage') {
            let feePercentage  = parseInt(e);
            if(feePercentage > 100) {
                feePercentage = 100;
            } else if(feePercentage < 0) {
                feePercentage = 0;
            }
            if(!Number.isInteger(feePercentage)) {
                feePercentage = 0;
            }
            this.setState({ noShowFeePercentage: feePercentage });
        }
    }

    async submitOptions() {
        if(this.state.lateCancellationFeeEnabled === true) {
            if (!Number.isInteger(this.state.lateCancellationFeePercentage)) {
                this.props.triggerNotification("A valid percentage is required for late cancellation fees", "danger", "bc", 4);
                return;
            }
            if (this.state.lateCancellationFeePercentage < 1 || this.state.lateCancellationFeePercentage > 100) {
                this.props.triggerNotification("The late cancellation fee percentage must be between 1 and 100", "danger", "bc", 4);
                return;
            }
        }
        if(this.state.noShowFeeEnabled === true) {
            if (!Number.isInteger(this.state.noShowFeePercentage)) {
                this.props.triggerNotification("A valid percentage is required for no-show fees", "danger", "bc", 4);
                return;
            }
            if (this.state.noShowFeePercentage < 1 || this.state.noShowFeePercentage > 100) {
                this.props.triggerNotification("The no-show fee percentage must be between 1 and 100", "danger", "bc", 4);
                return;
            }
        }
        try {
            this.props.actions.loadLoadingSpinner(true);
            let data = {
                late_cancellation_fee_enabled: this.state.lateCancellationFeeEnabled,
                late_cancellation_fee_percentage: this.state.lateCancellationFeePercentage,
                no_show_fee_enabled: this.state.noShowFeeEnabled,
                no_show_fee_percentage: this.state.noShowFeePercentage
            };
            await Api.updateRevenueProtectionOptions(data);
            let businessObj = Object.assign({}, this.props.business);
            businessObj.cancellation_fee_enabled = this.state.lateCancellationFeeEnabled ? 1 : 0;
            businessObj.cancellation_fee_percentage = this.state.lateCancellationFeePercentage;
            businessObj.no_show_fee_enabled = this.state.noShowFeeEnabled ? 1 : 0;
            businessObj.no_show_fee_percentage = this.state.noShowFeePercentage;
            this.props.actions.updateBusiness(businessObj);
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Revenue protection options updated successfully", "success", "bc", 4);
            this.props.navigate("/admin/settings");
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while trying to update your revenue protection options", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Revenue Protection</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12} className="mb-2">
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>Late Cancellation Fees</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'lateCancellationFeeEnabled') }}
                                                checked={this.state.lateCancellationFeeEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                disabled={this.props.business.stripe_account ? false : true}
                                            />
                                        </FormGroup>
                                        <FormText color="muted" style={{ marginTop: '1rem' }}>
                                            Enabling late cancellation fees will allow you to set a percentage of the total booking amount as a fee if a client cancels within your cancellation policy period.
                                            For example, suppose you have a cancellation policy of 1 hour, if a client tries to cancel with less than 1 hours notice, they will be required to pay a
                                            cancellation fee in order to cancel. If this feature is enabled, you will be asked whether you would like to charge the late cancellation fee whenever a booking
                                            status is changed to 'Cancelled' within the cancellation policy period.
                                        </FormText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <label>Percentage of booking amount to charge</label>
                                        <InputGroup>
                                            <CurrencyInput
                                                className="form-control currency-input-control"
                                                placeholder="--"
                                                decimalsLimit={0}
                                                decimalScale={0}
                                                allowNegativeValue={false}
                                                allowDecimals={false}
                                                step={1}
                                                value={this.state.lateCancellationFeePercentage}
                                                onValueChange={(value, name) => this.handleChange(value, 'lateCancellationFeePercentage')}
                                                disabled={this.props.business.stripe_account ? false : true}
                                            />
                                            <InputGroupText className="currency-input-symbol">%</InputGroupText>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card style={{ marginBottom: 0 }}>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12} className="mb-2">
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>No-show Fees</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'noShowFeeEnabled') }}
                                                checked={this.state.noShowFeeEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                disabled={this.props.business.stripe_account ? false : true}
                                            />
                                        </FormGroup>
                                        <FormText color="muted" style={{ marginTop: '1rem' }}>
                                            Enabling no-show fees will allow you to set a percentage of the total booking amount as a fee if a client doesn't show up for their
                                            booking (no-show). If this feature is enabled, you will be asked whether you would like to charge the no-show fee whenever a booking
                                            status is changed to 'No-Show'.
                                        </FormText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <label>Percentage of booking amount to charge</label>
                                        <InputGroup>
                                            <CurrencyInput
                                                className="form-control currency-input-control"
                                                placeholder="--"
                                                decimalsLimit={0}
                                                decimalScale={0}
                                                allowNegativeValue={false}
                                                allowDecimals={false}
                                                step={1}
                                                value={this.state.noShowFeePercentage}
                                                onValueChange={(value, name) => this.handleChange(value, 'noShowFeePercentage')}
                                                disabled={this.props.business.stripe_account ? false : true}
                                            />
                                            <InputGroupText className="currency-input-symbol">%</InputGroupText>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                {this.props.business.stripe_account === true ?
                                    <div className="action-button-row">
                                        <Button color="primary" onClick={() => this.submitOptions()}>
                                            Save Changes
                                        </Button>
                                    </div>
                                :
                                    <Alert color="warning" className="alert-fullwidth" isOpen={true}>
                                        <span>
                                            A connected Stripe account is required to enable these features. If you're unsure how to setup your connected Stripe account,
                                            please contact your Styler Consultant.
                                        </span>
                                    </Alert>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {this.state.colorPickerVisible ?
                <ColourPicker
                    visible={this.state.colorPickerVisible}
                    toggleVisible={this.toggleColorPickerVisible}
                    initialColor={this.state.selectedPicker === 'picker1Color' ? (this.state.picker1Color ? this.state.picker1Color : this.props.business.hub_header_gradient_start) : (this.state.picker2Color ? this.state.picker2Color : this.props.business.hub_header_gradient_end)}
                    onSubmit={this.handleColourChange}
                />
            : null}
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RevenueProtection));