import React from "react";
import lottie from 'lottie-web';
import { motion, AnimatePresence } from "framer-motion";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import * as spinnerData from '../../assets/animations/spinner/loadingSuccessFail.json';
import './LoadingSpinner.css';

class LoadingSpinner extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    this.loadAnimation();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.loading && prevProps.loading.isLoading === false && this.props.loading && this.props.loading.isLoading === true){
      this.loadAnimation();
    }
  }

  loadAnimation() {
    this.options = {
      container: this.el,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData: spinnerData.default,
      rendererSettings : {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    this.anim = lottie.loadAnimation(this.options);
    this.anim.setSpeed(1.3);
    this.anim.playSegments([0, 239], true);
  }

  render() {
    return (
      <AnimatePresence>
        {this.props.loading && this.props.loading.isLoading && (
        <motion.div className="spinner-overlay" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
          <motion.div className="spinner-container">
            <div
              ref={x => {
                this.el = x;
              }}
              className="spinner"
            />
          </motion.div>
        </motion.div>
        )}
      </AnimatePresence>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: state.loading
  }
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingSpinner);