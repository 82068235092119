import React from "react";
// Cookies
import { withCookies, Cookies } from 'react-cookie';
// MUI components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { MuiOtpInput } from 'mui-one-time-password-input';
// Icons
import { Eye, EyeSlash } from "@phosphor-icons/react";
// Firebase
import { firebaseApp } from 'firebaseProvider/config';
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  inMemoryPersistence,
  signOut,
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
} from 'firebase/auth';
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
// API
import Api from '../../api/index';
// Components
import ForgotPassword from 'components/Login/ForgotPassword';
import LoginBox from 'components/Login/LoginBox';
// Decorators
import withRouter from "utilities/withRouter";
// Images
import stylerLogo from "assets/img/styler_logo_secondary.svg";

const cookies = new Cookies();

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      emailValid: true,
      passwordValid: true,
      error: null,
      loading: false,
      forgotPasswordVisible: false,
      authPage: 'login',
      mfaResolver: null,
      mfaOtp: ''
    };
  }
  componentDidMount() {
    document.body.classList.add("login-page");
    this.props.actions.loadLoadingSpinner(false);
  }
  componentWillUnmount() {
    document.body.classList.remove("login-page");
  }

  toggleForgotPasswordVisible = () => {
    this.setState({
      forgotPasswordVisible: !this.state.forgotPasswordVisible
    });
  };

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getCsrfToken() {
    let existingCsrfToken = cookies.get('csrfToken');
    if(existingCsrfToken) {
      return existingCsrfToken;
    } else {
      const isProduction = process.env.NODE_ENV === "production";
      cookies.set('csrfToken', (Math.random()* 100000000000000000).toString(), { path: '/', secure: isProduction, domain: isProduction ? '.whatstyle.com' : "192.168.1.76", httpOnly: false });
      let newCsrfToken = cookies.get('csrfToken');
      return newCsrfToken;
    }
  }

  async handleSignInSuccess(userCredential) {
    const auth = getAuth(firebaseApp);
    const { navigate, actions } = this.props;
    actions.loadLoadingSpinner(true);
    const idToken = await userCredential.user.getIdToken();
    const csrfToken = this.getCsrfToken();
    const { data: responseData } = await Api.signIn({ idToken, csrfToken });
    signOut(auth);
    actions.loadBusiness(responseData.business);
    actions.loadUser(responseData.user);
    actions.loadUserRole(responseData.userRole);
    let redirectUrl = '/admin/home';
    if(window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const requestedUrl = urlParams.get('requested_url');
      if(requestedUrl) {
        redirectUrl = decodeURI(requestedUrl);
      }
    }
    navigate(redirectUrl);
  }

  async submitSignIn() {
    if(this.state.loading) {
      return;
    }
    // Validate inputs
    const emailValid = this.validateEmail(this.state.email);
    const passwordValid = this.state.password?.length > 0;
    this.setState({ emailValid, passwordValid });
    if(!emailValid || !passwordValid) {
      return;
    }
    this.setState({ loading: true });
    // As httpOnly cookies are to be used, do not persist any state client side
    const auth = getAuth(firebaseApp);
    try { 
      setPersistence(auth, inMemoryPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, this.state.email, this.state.password);
      await this.handleSignInSuccess(userCredential);
    } catch(error) {
      let errorMsg;
      switch(error.code) {
        case "auth/invalid-email":
          errorMsg = 'Invalid email address.';
          break;
        case "auth/user-disabled":
          errorMsg = 'Account disabled.';
          break;
        case "auth/user-not-found":
          errorMsg = 'Account does not exist.';
          break;
        case "auth/wrong-password":
          errorMsg = 'Incorrect password.'
          break;
        case "auth/too-many-requests":
          errorMsg = 'Too many unsuccessful login attempts. Please try again later.';
          break;
        case "auth/multi-factor-auth-required":
          const mfaResolver = getMultiFactorResolver(auth, error);
          this.setState({ authPage: 'mfa', mfaResolver, error: null  });
          return;
      }
      if(!errorMsg) {
        errorMsg = 'An unexpected error occured - please contact support at support@styler.digital if the problem persists.';
      }
      this.setState({ error: errorMsg });
    } finally {
      this.setState({ loading: false });
    }
  }

  async submitMfa(e) {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      this.state.mfaResolver.hints[0].uid,
      e
    );
    try {
      const userCredential = await this.state.mfaResolver.resolveSignIn(
          multiFactorAssertion
      );
      await this.handleSignInSuccess(userCredential);
    } catch(err) {
      this.setState({ error: 'Invalid code.' });
    }
  }

  handleInputChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  renderLoginForm() {
    return (
      <>
        <TextField
          id="email"
          label="Email"
          type="email"
          value={this.state.email}
          error={!this.state.emailValid}
          helperText={!this.state.emailValid ? 'Invalid email address' : undefined}
          disabled={this.state.loading}
          onChange={(e) => this.handleInputChange(e)}
          fullWidth
          sx={{ mb: 3 }}
        />
        <TextField
          id="password"
          label="Password"
          type={this.state.showPassword ? "text" : "password"}
          value={this.state.password}
          error={!this.state.passwordValid}
          helperText={!this.state.passwordValid ? 'Password is required' : undefined}
          disabled={this.state.loading}
          onChange={(e) => this.handleInputChange(e)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                >
                  {this.state.showPassword ? <Eye /> : <EyeSlash />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button sx={{ mb: 2, fontWeight: '500', textTransform: 'none' }} onClick={this.toggleForgotPasswordVisible}>Forgot Password</Button>
        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          loading={this.state.loading}
          sx={{ padding: '10px 16px', fontSize: 15, mb: 2 }}
          onClick={() => this.submitSignIn()}
        >
          Login
        </LoadingButton>
        {this.state.error && <Alert severity="error" sx={{ mb: 1 }}>{this.state.error}</Alert>}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
          <hr style={{  flexGrow: 1, color: '#9e9e9e'  }} />
          <Typography sx={{ mx: 2, lineHeight: 1.8, color: '#9e9e9e' }}>OR</Typography>
          <hr style={{  flexGrow: 1, color: '#9e9e9e' }} />
        </Box>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          sx={{ padding: '10px 16px', fontSize: 15 }}
          href="https://hello.styler.digital/signup"
        >
          Sign Up
        </Button>
      </>
    );
  }

  renderMfaForm() {
    return (
      <>
        <Typography variant="h4" sx={{ fontSize: 24, mb: 3 }} align="center">Multi-factor authentication</Typography>
        <Typography variant="body1" sx={{ mb: 3 }} align="center">Enter the 6-digit code generated by the Google Authenticator app.</Typography>
        <Box display="flex" justifyContent="center">
          <MuiOtpInput
            length={6}
            value={this.state.mfaOtp}
            onChange={(e) => this.setState({ mfaOtp: e })}
            onComplete={(e) => this.submitMfa(e)}
            style={{ maxWidth: 450, minWidth: 340 }}
          />
        </Box>
        {this.state.error && <Alert severity="error" sx={{ mt: 3 }}>{this.state.error}</Alert>}
      </>
    );
  }

  renderForm() {
    switch(this.state.authPage) {
      case 'mfa':
        return this.renderMfaForm();
      default:
        return this.renderLoginForm();
    }
  }

  renderFormContainer() {
    return (
      <Grid container justifyContent="center">  
        <Grid item xs={11} sm={8} md={9} lg={6} xl={5}>
          <Box sx={{ width: '100%', mb: 3, textAlign: 'center' }}>
            <img src={stylerLogo} alt="Styler" style={{ width: 200 }} />
          </Box>
          {this.renderForm()}
          </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            {this.renderFormContainer()}
          </Grid>
          {window.innerWidth >= 900 && (
            <Grid item xs={0} sm={0} md={6} lg={6} xl={7} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
              <LoginBox/>
            </Grid>
          )}
        </Grid>
        <ForgotPassword isVisible={this.state.forgotPasswordVisible} setVisible={this.toggleForgotPasswordVisible}/>
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default withCookies(connect(null, mapDispatchToProps)(withRouter(LoginPage)));
