/*eslint-disable*/
import React from "react";

// reactstrap components
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Alert, Input, FormGroup, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from 'api/index';

class ToDoAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            task: null
        };
    }

    submitToDo() {
        if(!this.state.task || this.state.task.length === 0){
            this.setState({ error: 'A To Do Task is required.' });
            return;
        }
        this.setState({ loading: true, error: null });
        let that = this;
        Api.addToDo(that.state.task).then(f => {
            return f.data;
        }).then((result) => {
            let newToDoObj = {
                id: result.toDoId,
                task: that.state.task,
                done: 0
            };
            that.props.actions.addToDo(newToDoObj);
            that.props.toggleVisible();
            that.setState({ loading: false, task: null });
        }).catch(error => {
            console.log(error);
            this.setState({ error: 'An unexpected error has occured.', loading: false });
        });
    }

    handleInputChange(e) {
        const inputValue = e.target.value;
        this.setState({ task: inputValue });
    }

    render() {
        return (
        <>
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggleVisible} className="text-center">
            <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">New To Do</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Input
                        placeholder="To Do Task"
                        onChange={(e) => this.handleInputChange(e)}
                        disabled={this.state.loading}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={() => this.submitToDo()}
                    disabled={this.state.success}
                >
                    {this.state.loading ? <Spinner color="success" size="sm" /> : "Add To Do"}
                </Button>
                <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                    <span>
                        {this.state.error}
                    </span>
                </Alert>
            </ModalFooter>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ToDoAdd);