/*eslint-disable*/
import React from "react";

// reactstrap components
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";

import BusinessLocation from "./BusinessLocation";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

class BusinessLocationPicker extends React.Component {

    constructor(props){
        super(props);
    }

    handleLocationChange(business_location_id) {
        if(this.props.settings.selected_business_location_id !== business_location_id) {
            let settings = Object.assign({}, this.props.settings);
            settings.selected_business_location_id = business_location_id;
            this.props.actions.loadSettings(settings, this.props.user);
        }
        if(this.props.onSubmit) {
            this.props.onSubmit(business_location_id);
        }
    }

    render() {
        return (
        <>
            <Modal
                isOpen={this.props.visible}
                toggle={() => this.props.toggleVisible()}
                className="text-center"
            >
                <ModalHeader
                    className="justify-content-center uppercase title"
                    toggle={() => this.props.toggleVisible()}
                    tag="h4"
                >
                    Select a location
                </ModalHeader>
                <ModalBody className="text-start">
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {this.props.businessLocation && this.props.businessLocation.filter(x => x.enabled === 1).map((businessLocationObj,) => {
                                return (
                                    <div
                                        key={`businessLocation${businessLocationObj.business_location_id}`}
                                        onClick={() => this.handleLocationChange(businessLocationObj.business_location_id)}
                                    >
                                        <BusinessLocation
                                            data={businessLocationObj}
                                            selected={businessLocationObj.business_location_id === this.props.settings.selected_business_location_id}
                                        />
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        businessLocation: state.businessLocation,
        settings: state.settings,
        user: state.user
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessLocationPicker);