import React from "react";
import moment from "moment";
// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col,
    Button
} from "reactstrap";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../../actions/index';
import { connect } from "react-redux";

import { FiPlus } from 'react-icons/fi';

// Components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import DatePicker from "components/DatePicker/DatePicker";
import WaitListModal from "components/WaitList/WaitListModal";
// API
import Api from 'api/index';
// Decorators
import withRouter from "utilities/withRouter";

class WaitList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            datePickerModalVisible: false,
            waitListModalVisible: false,
            loading: false,
            waitListData: [],
            date: moment(),
            userWaitListData: null
        };
    }

    componentDidMount() {
        let dateStr = this.state.date.format('YYYY-MM-DD');
        this.loadWaitList(dateStr);
    }

    async loadWaitList(dateStr) {
        try {
            this.setState({ loading: true, waitListData: [] });
            const { data: responseData } = await Api.getWaitList(dateStr);
            this.setState({ waitListData: responseData.waitListData });
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured whilst trying to load your wait list. Please contact Support if this problem persists.", "danger", "bc", 6);
        } finally {
            this.setState({ loading: false });
        }
    }

    handleNewBookingClick = (e, tableData) => {
        e.stopPropagation();
        const rowData = tableData?.row?.original;
        this.props.navigate(`/admin/add_booking?user_id=${rowData?.user_id}&date=${this.state.date.format('YYYY-MM-DD')}&time=${rowData?.start_time.substring(0, 5)}`)
    }

    getWaitListTableColumns() {
        let breakLayoutWidth = 540;
        let tableColumns = [
            {
                Header: window.innerWidth > breakLayoutWidth ? <span>Customer</span> : <div/>,
                accessor: "user_name",
                disableFilters: true,
                disableSortBy: window.innerWidth <= breakLayoutWidth
            },
        ];
        if(window.innerWidth > breakLayoutWidth) {
            tableColumns.push(
                {
                    Header: "From",
                    accessor: "start_time",
                    Cell: props => <span>{props.value.substring(0,5)}</span>,
                    disableFilters: true,
                    colStyle: { minWidth: 75, flex: 0.25 }
                },
                {
                    Header: () => <span>To</span>,
                    accessor: "end_time",
                    Cell: props => <span>{props.value.substring(0,5)}</span>,
                    disableFilters: true,
                    colStyle: { minWidth: 75, flex: 0.25 }
                }
            );
        }
        tableColumns.push({
            Header: <div/>,
            accessor: "action_btn",
            disableFilters: true,
            disableSortBy: true,
            colStyle: { flex: 0.2, minWidth: 124 },
            Cell: props => this.props.userRole.booking?.create ? (
                <Button
                    color="primary"
                    size="sm"
                    style={{ marginLeft: 3 }}
                    onClick={(e) => this.handleNewBookingClick(e, props)}
                >
                    New Booking
                </Button>
            ) : <></>,
        });
        return tableColumns;
    }

    handleRowClick = (rowData) => {
        if(this.props.userRole.waitlist?.update === false) {
            this.props.triggerNotification("You don't have permission to update the wait list.", "danger");
            return;
        }
        this.toggleWaitListModal(rowData);
    }

    handlePreviousDate = () => {
        let dateObj = moment(this.state.date).subtract(1, 'day');
        this.setState({ date: dateObj });
        this.loadWaitList(dateObj.format('YYYY-MM-DD'));
    }
    
    handleNextDate = () => {
        let dateObj = moment(this.state.date).add(1, 'day');
        this.setState({ date: dateObj });
        this.loadWaitList(dateObj.format('YYYY-MM-DD'));
    }

    renderHeader() {
        return (
            <div className="mb-3 calendar-header-container" style={{ padding: 0 }}>
                <div className="calendar-header-item calendar-header-item-2">
                    <div className="calendar-header-title-container" style={{ margin: 0 }}>
                        <Button
                            color="primary"
                            className="calendar-header-title-button"
                            onClick={this.handlePreviousDate}
                        >
                            <span className="btn-label">
                            <i className="now-ui-icons arrows-1_minimal-left" />
                            </span>
                        </Button>
                        <div className="calendar-header-title" onClick={() => this.setState({ datePickerModalVisible: true })}>
                            {this.state.date.format('ddd Do MMM YYYY')}
                        </div>
                        <Button
                            color="primary"
                            className="calendar-header-title-button"
                            onClick={this.handleNextDate}
                        >
                            <span className="btn-label">
                            <i className="now-ui-icons arrows-1_minimal-right" />
                            </span>
                        </Button>
                    </div>
                </div>
                {this.props.userRole.waitlist?.update === true && (
                    <div className="calendar-header-item calendar-header-item-3">
                        <Button
                            color="primary"
                            className="calendar-header-button pull-right"
                            style={{ marginLeft: 3 }}
                            onClick={() => this.toggleWaitListModal()}
                        >
                            <FiPlus size={16} color={'#1ab394'}/> Add
                        </Button>
                    </div>
                )}
                <div className="calendar-header-item calendar-header-item-4">
                    <Button
                        color="primary"
                        className="calendar-header-button"
                        style={{ marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                        onClick={this.toggleBusinessClosedDateAddModal}
                    >
                        <FiPlus size={16} color={'#1ab394'}/>
                    </Button>
                </div>
            </div>
        );
    }

    submitDatePickerChange = (e) => {
        this.setState({ date: e, datePickerModalVisible: false });
        this.loadWaitList(e.format('YYYY-MM-DD'));
    }

    renderDatePickerModal() {
        return (
            <DatePicker
                visible={this.state.datePickerModalVisible}
                curDate={this.state.date}
                submitDate={this.submitDatePickerChange}
                toggleVisible={() => this.setState({ datePickerModalVisible: !this.state.datePickerModalVisible })}
                timezoneName={this.props.business.timezone_name}
            />
        );
    }

    toggleWaitListModal = (userWaitListData = null) => {
        this.setState({
            waitListModalVisible: !this.state.waitListModalVisible,
            userWaitListData
        });
    }

    renderWaitListModal() {
        if(this.state.waitListModalVisible) {
            return (
                <WaitListModal
                    visible={this.state.waitListModalVisible}
                    date={this.state.date}
                    userWaitListData={this.state.userWaitListData}
                    toggleVisible={() => this.toggleWaitListModal()}
                    onSubmit={(dateStr) => {
                        this.toggleWaitListModal();
                        this.loadWaitList(dateStr);
                    }}
                    triggerNotification={this.props.triggerNotification}
                />
            );
        }
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Wait List</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={12} md={10} lg={10} xl={7} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        {this.renderHeader()}
                                    </Col>
                                </Row>
                                <hr style={{ marginTop: 0 }}/>
                                <ReactTable
                                    data={this.state.waitListData}
                                    pagination={this.state.waitListData?.length > 5}
                                    masterClassName="products-table"
                                    striped={false}
                                    columns={this.getWaitListTableColumns()}
                                    rowClick={this.handleRowClick}
                                    searchPlaceholder={"Filter wait list..."}
                                    noDataPlaceholder={'Wait list empty'}
                                    loading={this.state.loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.renderDatePickerModal()}
                {this.renderWaitListModal()}
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        userRole: state.userRole
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WaitList));