import React from "react";
// // react components used to create a google map
import GoogleMapReact from 'google-map-react';

import {
    Row,
    Col,
    InputGroup
} from "reactstrap";

import { FiMapPin } from "react-icons/fi";

import BusinessMapPin from "./BusinessMapPin";

import { GOOGLE_MAPS_PROD_KEY, GOOGLE_MAPS_DEV_KEY } from "constants/urls";

class CardMap extends React.Component {

    state={
        mapInstance: null,
        mapApi: null,
        placeData: null,
        lat: null,
        lng: null,
        draggable: true
    }

    componentDidMount() {
        this.setState({
            lat: this.props.lat,
            lng: this.props.lng
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
            this.setState({
                lat: this.props.lat,
                lng: this.props.lng
            });
        }
    }

    componentWillUnmount() {
        if(this.state.mapApi) {
            this.state.mapApi.event.clearInstanceListeners(this.searchInput);
        }
    }

    googleApiHasLoaded(map, maps) {
        this.setState({
            mapInstance: map,
            mapApi: maps,
        }, () => {
            if(this.props.lat && this.props.lng){
                map.setCenter({lat: Number(this.props.lat), lng: Number(this.props.lng)});
                map.setZoom(16);
            }
            this.initialiseAutocomplete();
        });
    }

    initialiseAutocomplete() {
        const options = {
            types: ['address']
        };
        const mapApi = this.state.mapApi;
        const mapInstance = this.state.mapInstance;
        this.autoComplete = new mapApi.places.Autocomplete(
            this.searchInput,
            options,
        );
        this.autoComplete.addListener('place_changed', this.onPlaceChanged);
        this.autoComplete.bindTo('bounds', mapInstance);
    }

    onPlaceChanged = ({ mapInstance} = this.state) => {
        const place = this.autoComplete.getPlace();
        console.log(place);
        if (!place.geometry) return;
        const map = mapInstance;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }
        this.setState({ placeData: place, lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        this.searchInput.blur();
        if(this.props.submitAddress) {
            this.props.submitAddress(place);
        }
    }

    handleMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }

    handleMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        if(this.props.submitLatLng) {
            this.props.submitLatLng(mouse.lat, mouse.lng);
        }
    }

    renderBusinessMapPin() {
        if(this.state.lat && this.state.lng) {
            return (
                <BusinessMapPin lat={this.state.lat} lng={this.state.lng}/>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>
                <div style={{ height: '280px', width: '100%', borderRadius: '6px' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: (process.env.NODE_ENV === "production" ? GOOGLE_MAPS_PROD_KEY : GOOGLE_MAPS_DEV_KEY), libraries: ['places', 'geometry'] }}
                        defaultCenter={{ lat: 54.4190221, lng: -4.7505935 }}
                        defaultZoom={4.7}
                        draggable={this.state.draggable}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.googleApiHasLoaded(map, maps)}
                        onChildMouseDown={this.handleMarkerInteraction}
                        onChildMouseMove={this.handleMarkerInteraction}
                        onChildMouseUp={this.handleMarkerInteractionMouseUp}
                    >
                        {this.renderBusinessMapPin()}
                    </GoogleMapReact>
                </div>
                <Row>
                    <Col md={12}>
                        <InputGroup className="mt-3">
                            <div style={{ lineHeight: '43px', padding: '0px 14px', border: '1px solid #e3e3e3', borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                                <FiMapPin size={22} color={'#0a122b'}/>
                            </div>
                            <input
                                className="form-control"
                                type="text"
                                ref={(ref) => this.searchInput = ref}
                                placeholder="Search for your address..."
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CardMap;