import { Fragment } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// QR Code library
import QRCode from "react-qr-code";

function MFAStepEnroll(props) {
    return (
        <Fragment>
            <Typography variant="body1">
                Scan the QR code below with your Google Authenticator app - you will see "Styler Hub" appear on the authenticator app.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
                {props.totpUri && <QRCode value={props.totpUri} fgColor={'#0a122b'} />}
                <Typography variant="caption" sx={{ mt: 3 }}>
                    TOTP Secret
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    {props.totpSecret}
                </Typography>
            </Box>
        </Fragment>
    );
};

export default MFAStepEnroll;