import React from "react";
import moment from "moment";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    FormGroup,
    FormText,
    Button,
    Spinner,
    Alert,
    Input
} from "reactstrap";

import Select from "react-select";
import Datetime from "react-datetime";

import Api from "api/index";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

class BusinessClosedDateAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            name: null,
            startDate: moment(),
            endDate: moment(),
            locationOptions: [],
            locationTags: []
        };
    }

    componentDidMount() {
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj, businessLocationIndex) => {
            locationOptions.push({
                id: businessLocationObj.business_location_id,
                label: businessLocationObj.business_location_name,
                value: businessLocationObj.business_location_id
            });
        });
        this.setState({ locationOptions });
    }

    handleChange(e, name) {
        if(name === 'name') {
            this.setState({ name: e.target.value });
        } else if(name === 'startDate') {
            this.setState({ startDate: e });
            if(e > this.state.endDate) {
                this.setState({ endDate: e });
            }
        } else if(name === 'endDate') {
            this.setState({ endDate: e });
        } else if(name === 'location') {
            let locationTags = [...this.state.locationTags];
            if(e && e.length > 0) {
                // Add new tags
                e.forEach((tagObj, tagIndex) => {
                    let foundObj = locationTags.find(x => x.id === tagObj.id);
                    if(!foundObj) {
                        locationTags.push({ id: tagObj.id });
                    }
                });
                // Remove existing tags
                locationTags.forEach((locationTagObj, locationTagIndex) => {
                    let foundObj = e.find(x => x.id === locationTagObj.id);
                    if(!foundObj) {
                        locationTags = locationTags.filter(x => x.id !== locationTagObj.id);
                    }
                });
            } else {
                // All tags removed
                locationTags = [];
            }
            this.setState({ locationTags });
        }
    }

    async submitClosedDate() {
        if(this.state.loading) {
            return;
        }
        // Validate name
        if(!this.state.name || this.state.name.length === 0) {
            this.setState({ error: 'A name is required.' });
            return;
        }
        // Validate date range
        if(!this.state.startDate || !moment.isMoment(this.state.startDate)) {
            this.setState({ error: 'A start date is required.' });
            return;
        }
        if(this.state.endDate && !moment.isMoment(this.state.endDate)) {
            this.setState({ error: 'An end date is required.' });
            return;
        }
        if(this.state.endDate && this.state.startDate.isAfter(this.state.endDate, 'day')) {
            this.setState({ error: 'The start date must be on or before the end date.' });
            return;
        }
        let enabledBusinessLocations = this.props.businessLocation.filter(x => x.enabled === 1);
        // Validate locations
        if(enabledBusinessLocations.length !== 1 && (!this.state.locationTags || this.state.locationTags.length === 0)) {
            this.setState({ error: 'At least one business location must be selected.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let locationTags = [...this.state.locationTags];
            if(enabledBusinessLocations && enabledBusinessLocations.length === 1 && locationTags.length === 0) {
                locationTags.push({ id: enabledBusinessLocations[0].business_location_id });
            }
            let data = {
                name: this.state.name,
                start_date: this.state.startDate.format('YYYY-MM-DD'),
                end_date: this.state.endDate.format('YYYY-MM-DD'),
                locations: locationTags
            };
            await Api.addBusinessClosedDate(data);
            this.props.triggerNotification("Closed date added successfully.", "success", "bc", 4);
            if(this.props.onSubmit) {
                this.props.onSubmit();
            }
        } catch(e) {
            console.log(e);
            if(e.response && e.response.data && e.response.data.message) {
                this.setState({ loading: false, error: e.response.data.message + "."});
            } else {
                this.setState({ loading: false, error: "Unable to add closed date. If the problem persists, please contact us at support@styler.digital." });
            }
        }
    }

    renderEndDateInput(props,) {
        return (
            <input {...props} value={this.state.endDate ? props.value : "Ongoing"} />
        );
    }

    render() {
        return (
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Add Closed Date</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} sm={12}>
                            <label>Name <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Input
                                    type="text"
                                    placeholder="e.g. Public holiday"
                                    onChange={(e) => this.handleChange(e, 'name')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <label>Dates <span className="text-danger">*</span></label>
                            <FormGroup className="date-group">
                                <Datetime
                                    className="date-input date-input-left"
                                    dateFormat={"ddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "Start Date", readonly: "readonly" }}
                                    value={this.state.startDate ? this.state.startDate : null}
                                    onChange={(e) => this.handleChange(e, 'startDate')}
                                />
                                <Datetime
                                    className="date-input date-input-right"
                                    dateFormat={"ddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "End Date", readonly: "readonly" }}
                                    value={this.state.endDate ? this.state.endDate : moment()}
                                    renderInput={(e) => this.renderEndDateInput(e)}
                                    onChange={(e) => this.handleChange(e, 'endDate')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.props.businessLocation && this.props.businessLocation.filter(x => x.enabled === 1).length > 1 ?
                        <div>
                            <hr/>
                            <Row>
                                <Col md={12}>
                                    <h6>Locations</h6>
                                    <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                        Choose the locations which are affected by this closed date.
                                    </FormText>
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            isMulti
                                            options={this.state.locationOptions}
                                            placeholder={'Choose closed locations...'}
                                            onChange={(e) => this.handleChange(e, 'location')}
                                            captureMenuScroll={true}
                                            controlShouldRenderValue={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    : null}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        style={{ width: '100%' }}
                        onClick={() => this.submitClosedDate()}
                    >
                        {this.state.loading ? <Spinner color="success" size="sm" /> : "Add Closed Date"}
                    </Button>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error ? true : false} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        businessLocation: state.businessLocation
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessClosedDateAdd);