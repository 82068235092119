const businessUserRoleType = [
    {
        id: 1,
        label: "Admin"
    },
    {
        id: 2,
        label: "High"
    },
    {
        id: 3,
        label: "Medium"
    },
    {
        id: 4,
        label: "Low"
    },
    {
        id: 5,
        label: "Basic"
    }
];

export default businessUserRoleType;