import { Component } from "react";
import moment from "moment";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from "reactstrap";
import Datetime from "react-datetime";

class DatePicker extends Component {

    render() {
        return (
            <Modal
                isOpen={this.props.visible}
                toggle={() => this.props.toggleVisible()}
                className="text-center"
            >
                <ModalHeader
                    className="justify-content-center uppercase title"
                    toggle={() => this.props.toggleVisible()}
                    tag="h4"
                >
                    Select Date
                </ModalHeader>
                <ModalBody>
                    <Datetime
                        initialViewMode={this.props.initialView ? this.props.initialView : 'days'}
                        updateOnView={this.props.initialView ? this.props.initialView : 'days'}
                        className="calendar-datepicker-modal"
                        dateFormat="YYYY-MM-DD"
                        value={moment(this.props.curDate)}
                        onChange={(e) => this.props.submitDate(e)}
                        open
                        timeFormat={false}
                        locale={moment.locale()}
                        inputProps={{ placeholder: "Datetime Picker Here" }}
                    />
                    <Button color="primary" block className="btn-block" onClick={() => this.props.submitDate(moment().tz(this.props.timezoneName))}>
                        Today
                    </Button>
                </ModalBody>
            </Modal>
        );
    }

}

export default DatePicker;