/*eslint-disable*/
import React from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalHeader,
    Input,
    FormGroup,
    Row,
    Col,
    Label,
    Button,
    Alert,
    Spinner
} from "reactstrap";

// react plugin used to create datetimepicker
import Datetime from "react-datetime";
import Select from "react-select";

import moment from 'moment';

import Api from 'api/index';
import ModalFooter from "reactstrap/lib/ModalFooter";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import frequency from '../../constants/frequency';
import ends from '../../constants/ends';
import finite from '../../constants/finite';
import time from '../../constants/time';
import { CDN_URL } from "constants/urls";

import { FiTrash } from 'react-icons/fi';

class BreakEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            staffOptions: [],
            locationOptions: [],
            selectedLocationId: null,
            breakLoaded: false,
            breakId: null,
            title: null,
            date: null,
            start: null,
            end: null,
            staffId: null,
            recurring: false,
            recurringConversion: false,
            frequencyId: null,
            endId: null,
            finiteId: null,
            endDate: null,
            loading: false,
            deleteLoading: true,
            success: false,
            error: null,
            editPermission: true
        };
    }

    componentDidMount() {
        console.log(this.props.locationId);
        if(!this.props.data) {
            this.props.toggleVisible();
        }
        // Populate staff options  
        let staffOptions = [];
        this.props.staff.forEach((staffObj, staffIndex) => {
            staffOptions.push({
                id: staffIndex,
                name: staffObj.firstname + ' ' + staffObj.lastname,
                label: <div><img className="staff-select-img" src={`${CDN_URL}/` + staffObj.staff_img} alt="StaffImg"/> {staffObj.firstname + ' ' + staffObj.lastname}</div>,
                value: staffObj.id
            });
        });
        // Populate location options
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj,) => {
            locationOptions.push({
                id: businessLocationObj.business_location_id,
                label: businessLocationObj.business_location_name,
                value: businessLocationObj.business_location_id
            });
        });
        // Access permissions
        /*const editBooking = this.props.userRole?.booking?.update;
        if(!editBooking && e.event.title === 'Booking') {
        this.props.triggerNotification("You don't have permission to edit this booking.", "danger", "bc", 4);
        return;
        }*/
        const editPermission = this.props.userRole?.break?.update === true;
        // Set initial state
        this.setState({
            staffOptions,
            locationOptions,
            error: editPermission ? null : "You don't have permission to update this break.",
            editPermission
        });
        this.populateBreakData();
    }

    async populateBreakData() {
        try {
            this.setState({ loading: true, deleteLoading: true });
            if(this.props.conversion && this.props.conversion === true) {
                this.setState({
                    breakId: parseInt(this.props.data.id),
                    title: this.props.data.extendedProps.eventNotes,
                    date: moment(this.props.data.start, 'YYYY-MM-DD HH:mm:ss'),
                    start: moment(moment(this.props.data.start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'), 'HH:mm'),
                    end: moment(moment(this.props.data.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'), 'HH:mm'),
                    staffId: this.props.data.extendedProps.staffId,
                    recurring: true,
                    frequencyId: null,
                    endId: null,
                    endDate: null,
                    recurringConversion: true
                });
            } else {
                let response = await Api.getBreak({ break_id: parseInt(this.props.data.id), break_recurring: this.props.data.extendedProps.recurring });
                let breakData = response.data.breakData;
                let breakRecurring = this.props.data.extendedProps.recurring;
                this.setState({
                    breakId: parseInt(this.props.data.id),
                    title: breakData.event_notes,
                    date: breakRecurring ? moment(breakData.start_date, 'YYYY-MM-DD') : moment(breakData.start, 'YYYY-MM-DD HH:mm:ss'),
                    start: breakRecurring ? moment(moment(breakData.start_time, 'HH:mm:ss').format('HH:mm'), 'HH:mm') : moment(moment(breakData.start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'), 'HH:mm'),
                    end: breakRecurring ? moment(moment(breakData.end_time, 'HH:mm:ss').format('HH:mm'), 'HH:mm') : moment(moment(breakData.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'), 'HH:mm'),
                    staffId: breakData.staff_id,
                    recurring: this.props.data.extendedProps.recurring,
                    frequencyId: breakRecurring ? breakData.business_events_recurring_frequency_id : null,
                    endId: breakRecurring ? (breakData.end_date ? 3 : 1) : null,
                    endDate: breakRecurring ? moment(breakData.end_date, 'YYYY-MM-DD HH:mm:ss') : null,
                    recurringConversion: false
                });
            }
            this.setState({ breakLoaded: true, loading: false, deleteLoading: false });
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, deleteLoading: false, error: 'An unexpected error occured whilst loading this break. Please contact support if the problem persists.' });
        }
    }

    handleChange(e, name) {
        if(name === 'title') {
            this.setState({ title: e.target.value });
        } else if(name === 'date') {
            this.setState({ date: e });
        } else if(name === 'start') {
            this.setState({ start: e });
        } else if(name === 'end') {
            this.setState({ end: e });
        } else if(name === 'staff') {
            this.setState({ staffId: e.value });
        } else if(name === 'recurring') {
            this.setState({ recurring: e.target.checked });
        } else if(name === 'frequency') {
            this.setState({ frequencyId: e.value });
        } else if(name === 'endType') {
            this.setState({ endId: e.value });
        } else if(name === 'finite') {
            this.setState({ finiteId: e.value });
        } else if(name === 'endDate') {
            this.setState({ endDate: e });
        }
    }

    async submitBreak() {
        if(this.state.loading || this.state.deleteLoading) {
            return;
        }
        if(!this.state.title || this.state.title.length === 0) {
            this.setState({ error: 'A title is required.' });
            return;
        }
        if(!this.state.date || !moment.isMoment(this.state.date)) {
            this.setState({ error: 'A date is required.' });
            return;
        }
        if(!this.state.start || !moment.isMoment(this.state.start)) {
            this.setState({ error: 'A start time is required.' });
            return;
        }
        if(!this.state.end || !moment.isMoment(this.state.end)) {
            this.setState({ error: 'An end time is required.' });
            return;
        }
        if(moment(this.state.start).isSameOrAfter(this.state.end, 'minute')) {
            this.setState({ error: 'The start time must be before the end time.' });
            return;
        }
        if(!this.state.staffId) {
            this.setState({ error: 'A staff member is required.' });
            return;
        }
        if(this.state.recurring && !this.state.recurringConversion) {
            if(!this.state.frequencyId) {
                this.setState({ error: 'A frequency is required.' });
                return;
            }
            if(!this.state.endId) {
                this.setState({ error: 'An end type is required.' });
                return;
            }
            if(this.state.endId === 2 && !this.state.finiteId) {
                this.setState({ error: 'No finite count selected' });
                return;
            }
            if(this.state.endId === 3 && (!this.state.endDate || !moment.isMoment(this.state.endDate))) {
                this.setState({ error: 'No end date selected' });
                return;
            }
            if(this.state.endId === 3 && moment(this.state.endDate).isSameOrBefore(this.state.date, 'day')) {
                this.setState({ error: 'The end date must be after the start date.' });
                return;
            } 
        }
        // If recurring finite option is selected calculate end date
        let recurringEndDate;
        if(this.state.recurring && !this.state.recurringConversion && this.state.endId === 3) {
            recurringEndDate = this.state.endDate.format('YYYY-MM-DD')
        } else {
            recurringEndDate = null;
        }
        this.setState({ loading: true, error: null });
        try {
            let data = {
                break_id: this.state.breakId,
                title: this.state.title,
                date: this.state.date.format('YYYY-MM-DD'),
                start_time: this.state.start.format('HH:mm'),
                end_time: this.state.end.format('HH:mm'),
                staff_id: this.state.staffId,
                recurring: this.state.recurring,
                recurring_conversion: this.state.recurringConversion,
                recurring_frequency_id: this.state.frequencyId,
                recurring_end_id: this.state.endId,
                recurring_end_date: recurringEndDate
            };
            await Api.updateBreak(data);
            this.props.triggerNotification("Break updated successfully.", "success", "bc", 4);
            this.props.toggleVisible();
            if(this.props.onSubmit) {
                this.props.onSubmit(this.state, false);
            }
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    async deleteBreak() {
        if(this.state.loading || this.state.deleteLoading) {
            return;
        }
        this.setState({ deleteLoading: true });
        try {
            let data = {
                break_id: this.state.breakId,
                recurring: this.state.recurring
            };
            await Api.deleteBreak(data);
            this.props.triggerNotification("Break deleted successfully.", "success", "bc", 4);
            this.props.toggleVisible();
            if(this.props.onSubmit) {
                this.props.onSubmit(this.state, true);
            }
        } catch(err) {
            console.log(err);
            this.setState({ deleteLoading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    customStaffOptionFilter(option, searchText) {
        if(option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
        <>
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">{this.state.recurring === true && this.state.recurringConversion === false ? 'Edit Recurring Break': 'Edit Break'}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} sm={12}>
                            <label>Title <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Input
                                    type="text"
                                    value={this.state.title}
                                    placeholder="e.g. Lunch"
                                    onChange={(e) => this.handleChange(e, 'title')}
                                    disabled={!this.state.editPermission}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Date <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Datetime
                                    dateFormat={"dddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "Select Date", disabled: !this.state.editPermission }}
                                    value={this.state.date ? this.state.date : this.props.date}
                                    onChange={(e) => this.handleChange(e, 'date')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Start & End <span className="text-danger">*</span></label>
                            <FormGroup className="time-group">
                                <Select
                                    className="react-select primary time-input time-input-left"
                                    classNamePrefix="react-select"
                                    options={time}
                                    value={this.state.start ? time.find(x => x.value === this.state.start.format('HH:mm')) : null}
                                    onChange={(e) => this.handleChange(moment(e.value, 'HH:mm'), 'start')}
                                    placeholder={"--:--"}
                                    isDisabled={!this.state.editPermission}
                                />
                                <Select
                                    className="react-select primary time-input time-input-right"
                                    classNamePrefix="react-select"
                                    options={time}
                                    value={this.state.end ? time.find(x => x.value === this.state.end.format('HH:mm')) : null}
                                    onChange={(e) => this.handleChange(moment(e.value, 'HH:mm'), 'end')}
                                    placeholder={"--:--"}
                                    isDisabled={!this.state.editPermission}
                                />
                                {/*<Datetime
                                    className="time-input"
                                    dateFormat={false}
                                    timeFormat={"HH:mm"}
                                    inputProps={{ placeholder: "--:--", className: "time-input-left form-control" }}
                                    value={this.state.start}
                                    onChange={(e) => this.handleChange(e, 'start')}
                                />
                                <Datetime
                                    className="time-input"
                                    dateFormat={false}
                                    timeFormat={"HH:mm"}
                                    inputProps={{ placeholder: "--:--", className: "time-input-right form-control" }}
                                    value={this.state.end}
                                    onChange={(e) => this.handleChange(e, 'end')}
                                />*/}
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Staff <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={this.state.staffOptions}
                                    value={this.state.staffOptions.find(x => x.value === this.state.staffId)}
                                    filterOption={this.customStaffOptionFilter}
                                    onChange={(e) => this.handleChange(e, 'staff')}
                                    isDisabled={!this.state.editPermission}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={12} md={12}>
                            <label>Location</label>
                            <FormGroup>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={this.state.locationOptions}
                                    value={this.state.locationOptions ? this.state.locationOptions.find(x => x.id === this.props.locationId) : null}
                                    placeholder={'Choose location...'}
                                    captureMenuScroll={true}
                                    isDisabled
                                    controlShouldRenderValue={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.state.recurring && !this.state.recurringConversion ?
                        <Row>
                            <Col md={12}>
                                <hr style={{ marginLeft: 0, marginRight: 0 }} />
                                <FormGroup check style={{ marginTop: 0, marginBottom: 10 }}>
                                    <Label check>
                                        <Input type="checkbox" defaultChecked={this.state.recurring} disabled={true} />
                                        <span className="form-check-sign" />
                                        Recurring Event
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    : null}
                    {this.state.recurring && !this.state.recurringConversion ?
                        <Row>
                            <Col md={12}>
                                <label>Frequency <span className="text-danger">*</span></label>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={frequency}
                                        isDisabled={true}
                                        defaultValue={frequency.find(x => x.value === this.state.frequencyId)}
                                        onChange={(e) => this.handleChange(e, 'frequency')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <label>End Type <span className="text-danger">*</span></label>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={ends.filter(x => x.value !== 2)}
                                        value={ends.find(x => x.value === this.state.endId)}
                                        onChange={(e) => this.handleChange(e, 'endType')}
                                        isDisabled={!this.state.editPermission}
                                    />
                                </FormGroup>
                            </Col>
                            {this.state.endId === 2 ?
                                <Col md={12}>
                                    <label>Ends <span className="text-danger">*</span></label>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            options={finite}
                                            defaultValue={finite.find(x => x.value === this.state.finiteId)}
                                            onChange={(e) => this.handleChange(e, 'finite')}
                                            isDisabled={!this.state.editPermission}
                                        />
                                    </FormGroup>
                                </Col>
                            : null}
                            {this.state.endId === 3 ?
                                <Col md={12}>
                                    <label>Ends <span className="text-danger">*</span></label>
                                    <FormGroup>
                                        <Datetime
                                            dateFormat={"dddd, Do MMM YYYY"}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: "Select End Date", disabled: !this.state.editPermission }}
                                            value={this.state.endDate}
                                            onChange={(e) => this.handleChange(e, 'endDate')}
                                        />
                                    </FormGroup>
                                </Col>
                            : null}
                        </Row>
                    : null}
                </ModalBody>
                <ModalFooter>
                    <div className="modal-split-footer">
                        {this.state.editPermission && (
                            <Button
                                color="primary"
                                className="modal-split-footer-left-button"
                                onClick={() => this.submitBreak()}
                                disabled={this.state.success}
                                style={this.state.recurring === true || this.state.recurringConversion === true ? { flex: 1, margin: 0 } : null}
                            >
                                {this.state.loading ? <Spinner color="success" size="sm" /> : "Save Changes"}
                            </Button>
                        )}
                        {this.state.recurring === false && this.state.recurringConversion === false && this.props.userRole?.break?.delete === true && ( 
                            <Button
                                color="danger"
                                className="modal-split-footer-right-button"
                                onClick={() => this.deleteBreak()}
                                disabled={this.state.success}
                            >
                                {this.state.deleteLoading ? <Spinner color="light" size="sm" /> : <FiTrash size={15}/>}
                            </Button>
                        )}
                    </div>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        userRole: state.userRole,
        staff: state.staff,
        businessLocation: state.businessLocation,
        businessLocationStaffMap: state.businessLocationStaffMap
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakEdit);