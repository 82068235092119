/*eslint-disable*/
import React from "react";
import moment from "moment";
// reactstrap components
import {
    Modal,
    ModalBody,
    ModalHeader,
    Badge
} from "reactstrap";
import { motion } from "framer-motion";
import { FiPlus, FiBell, FiBook } from "react-icons/fi";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

class BookingTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sweetAlert: null,
            paymentsData: null,
            error: null,
            loading: false,
        };
    }

    renderBookedTimelineItem() {
        let methodMessage;
        switch(this.props.userOrderData.user_order_method_id) {
            case 1:
                methodMessage = 'Booked via your booking widget.';
                break;
            case 2:
                methodMessage = 'Booked via your business app.';
                break;
            case 3:
                methodMessage = 'Booked by you on the Styler Hub.';
                break;
            case 4:
                methodMessage = 'Booked by the Styler Team.';
                break;
        }
        return (
            <motion.li className="timeline-inverted" initial={{ scale: 0.8, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: .2, delay: .2 }}>
                <div className="timeline-badge primary">
                    <FiPlus size={25} style={{ marginBottom: 5 }}/>
                </div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        Booked
                    </div>
                    <div className="timeline-body">
                        <p>
                           {methodMessage} Booking reference: {this.props.userOrderData.user_order_uid}.
                        </p>
                    </div>
                    <h6>
                        ON {moment.utc(this.props.userOrderData.user_order_created, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, false).format('ddd D MMM YYYY, HH:mm')}
                    </h6>
                </div>
            </motion.li>
        );
    }

    get24HourReminderScheduledTime() {
        if(this.props.bookingServices && this.props.bookingServices.length > 0 && this.props.bookingServices[0].start_time
            && moment.isMoment(this.props.bookingServices[0].start_time) && this.props.bookingServices[0].start_time.tz(this.props.business.timezone_name, true).isAfter(moment().tz(this.props.business.timezone_name, false), 'day')) {
            return 'Scheduled at ' + moment(this.props.bookingServices[0].start_time).tz(this.props.business.timezone_name, true).subtract(1, 'd').format('ddd D MMM YYYY, HH:mm');
        } else {
            return '--:--:--';
        }
    }

    render24HourReminderTimelineItem() {
        if(this.props.bookingType === 'walkin') {
            return null;
        }
        return (
            <motion.li initial={{ scale: 0.8, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: .2, delay: .4 }}>
                <div className="timeline-badge info">
                    <FiBell size={20} style={{ marginBottom: 5 }}/>
                </div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        24 hour reminder
                    </div>
                    <div className="timeline-body">
                        <p>
                            Reminder email and push notification {this.props.userOrderData.user_order_reminder_one_sent ? 'sent' : 'scheduled'}.
                        </p>
                    </div>
                    {this.props.userOrderData.user_order_reminder_one_sent ? (
                        <h6>
                            Sent at {moment.utc(this.props.userOrderData.user_order_reminder_one_sent, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, false).format('ddd D MMM YYYY, HH:mm')}
                        </h6>
                    ) : (
                        <h6>
                            {this.get24HourReminderScheduledTime()}
                        </h6>
                    )}
                </div>
            </motion.li>
        );
    }

    get1HourReminderScheduledTime() {
        if(this.props.bookingServices && this.props.bookingServices.length > 0 && this.props.bookingServices[0].start_time
            && moment.isMoment(this.props.bookingServices[0].start_time) && this.props.bookingServices[0].start_time.tz(this.props.business.timezone_name, true).isAfter(moment().tz(this.props.business.timezone_name, false), 'day')) {
            return 'Scheduled at ' + moment(this.props.bookingServices[0].start_time).tz(this.props.business.timezone_name, true).subtract(1, 'h').format('ddd D MMM YYYY, HH:mm');
        } else {
            return '--:--:--';
        }
    }

    render1HourReminderTimelineItem() {
        if(this.props.bookingType === 'walkin') {
            return null;
        }
        return (
            <motion.li className="timeline-inverted" initial={{ scale: 0.8, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: .2, delay: .6 }}>
                <div className="timeline-badge info">
                    <FiBell size={20} style={{ marginBottom: 5 }}/>
                </div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        1 hour reminder
                    </div>
                    <div className="timeline-body">
                        <p>
                            Reminder email and push notification {this.props.userOrderData.user_order_reminder_two_sent ? 'sent' : 'scheduled'}.
                        </p>
                    </div>
                    {this.props.userOrderData.user_order_reminder_two_sent ? (
                        <h6>
                            Sent at {moment.utc(this.props.userOrderData.user_order_reminder_two_sent, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, false).format('ddd D MMM YYYY, HH:mm')}
                        </h6>
                    ) : (
                        <h6>
                            {this.get1HourReminderScheduledTime()}
                        </h6>
                    )}
                </div>
            </motion.li>
        );
    }

    getBookingStartTime() {
        if(this.props.bookingServices && this.props.bookingServices.length > 0 && this.props.bookingServices[0].start_time && moment.isMoment(this.props.bookingServices[0].start_time)) {
            return moment(this.props.bookingServices[0].start_time).tz(this.props.business.timezone_name, true).format('ddd D MMM YYYY, HH:mm');
        } else {
            return '--:--';
        }
    }

    renderBookingTimelineItem() {
        let type;
        switch(this.props.status) {
            case "Booked":
                type = "success";
                break;
            case "Cancelled":
            case "No-show":
                type = "danger";
                break;
            case "In-progress":
                type = "warning";
                break;
            case "Complete":
                type = "default";
                break;
        }
        return (
            <motion.li initial={{ scale: 0.8, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: .2, delay: .8 }}>
                <div className={`timeline-badge ${type}`}>
                    <FiBook size={20} style={{ marginBottom: 5 }}/>
                </div>
                <div className="timeline-panel">
                    <div className="timeline-heading">
                        Booking
                    </div>
                    <div className="timeline-body">
                        <p>
                            Status: <b className={`text-${type}`}>{this.props.status}</b>
                        </p>
                    </div>
                    <h6>
                        Starting at {this.getBookingStartTime()}
                    </h6>
                </div>
            </motion.li>
        );
    }

    render() {
        return (
        <>
            {this.state.sweetAlert}
            <Modal size={"lg"} isOpen={this.props.visible} toggle={this.props.toggleVisible} className="booking-timeline-modal">
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Booking Timeline</ModalHeader>
                <ModalBody className="card-timeline">
                    <ul className="timeline" style={{ marginBottom: 0 }}>
                        {this.renderBookedTimelineItem()}
                        {this.render24HourReminderTimelineItem()}
                        {this.render1HourReminderTimelineItem()}
                        {this.renderBookingTimelineItem()}
                    </ul>
                </ModalBody>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingTimeline);