import React from "react";
// reactstrap components
import {
  Button,
  Row,
  Col,
} from "reactstrap";
// MUI components
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import MuiButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import ButtonGroup from '@mui/material/ButtonGroup';
// Icons
import { Plus, X } from "@phosphor-icons/react";
// Sentry
import * as Sentry from "@sentry/react";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import { CDN_URL } from "constants/urls";

import Api from '../../api/index';
// core components
import MuiCard from "components/Material/Card";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ImageCropper from "components/ImageCropper/ImageCropper";
import MFAWizardEnable from "components/Account/MFA/MFAWizardEnable";
import MFAWizardDisable from "components/Account/MFA/MFAWizardDisable";

// Compressor
import Compressor from 'compressorjs';

class Account extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      resetPasswordLoading: false,
      resetPasswordSuccess: false,
      detailsLoading: false,
      detailsError: false,
      user_firstname: this.props.user ? this.props.user.user_firstname : null,
      user_lastname: this.props.user ? this.props.user.user_lastname : null,
      user_img: this.props.user ? `${CDN_URL}/` + this.props.user.user_img : null,
      imageCropperVisible: false,
      imageFile: null,
      imageFileCropped: null,
      imageState: 0,
      mfaWizardVisible: false,
      mfaWizardDisableVisible: false,
      mfaData: null
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.submitCroppedImage = this.submitCroppedImage.bind(this);
    this.handleRemoveUserImg = this.handleRemoveUserImg.bind(this);
  }

  componentDidMount() {
    this.getMfaStatus();
  }

  async getMfaStatus() {
    try {
      const { data } = await Api.getMfa();
      const { mfaData } = data;
      this.setState({ mfaData });
    } catch(err) {
      Sentry.captureException(err);
    }
  }

  async sendResetPasswordEmail() {
    if(!this.state.resetPasswordSuccess && !this.state.resetPasswordLoading){
      this.setState({ resetPasswordLoading: true });
      await Api.resetPassword();
      this.setState({ resetPasswordLoading: false, resetPasswordSuccess: true });
    }
  }

  async handleChange(e, fieldName) {
    let val = e.target.value;
    if(fieldName === 'firstname') {
      this.setState({ user_firstname: val });
    } else if(fieldName === 'lastname') {
      this.setState({ user_lastname: val });
    }
  }

  async updateAccountDetails() {
    if(this.state.detailsLoading){
      return;
    }
    if(this.state.user_firstname === this.props.user.user_firstname && this.state.user_lastname === this.props.user.user_lastname) {
      this.setState({ detailsError: 'No changes detected.' });
      return;
    }
    if(!this.state.user_firstname?.length > 0 || !this.state.user_lastname?.length > 0) {
      this.setState({ detailsError: 'A first and last name are required.' });
      return
    }
    this.setState({ detailsLoading: true });
    try {
      await Api.updateAccountDetails(this.state.user_firstname, this.state.user_lastname);
      let existingUser = Object.assign({}, this.props.user);
      existingUser.user_firstname = this.state.user_firstname;
      existingUser.user_lastname = this.state.user_lastname;
      this.props.actions.updateUser(existingUser);
      this.props.triggerNotification("Name updated successfully.", "success", "bc", 4);
    } catch(err) {
      Sentry.captureException(err);
      this.setState({ detailsError: 'An unexpected error occurred.' });
    } finally {
      this.setState({ detailsLoading: false });
    }
  }

  toggleImageCropper = () => {
    this.setState({ imageCropperVisible: !this.state.imageCropperVisible });
  }

  userImgClick = (e) => {
    if(e.defaultPrevented) return;
    if(!Number.isInteger(this.props.user.staff_id)) {
      this.userImgInput.click();
    }
  }

  async onFileChange(e) {
    if(e.target && e.target.files && e.target.files.length === 1) {
        const imgFile = e.target.files[0];
        let imageDataUrl = await this.readFile(imgFile);
        this.setState({ imageFile: imageDataUrl, imageCropperVisible: true });
    }
  }

  readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        this.getNormalizedFile(file).then(normalizedFile => reader.readAsDataURL(normalizedFile)).catch(error => reject(error));
    });
  }

  getNormalizedFile(file) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            maxWidth: 1000,
            maxHeight: 1000,
            success(normalizedFile) {
                resolve(normalizedFile);
            },
            error(error) {
                reject(error);
            },
        });
    });
  }

  submitCroppedImage(file) {
    let imageState = this.state.imageState;
    if(imageState === 0 || imageState === 3) {
        imageState = 2;
    }
    this.setState({ user_img: file, imageCropperVisible: false, imageState }, () => this.submitUserImage());
  }

  async submitUserImage() {
    try {
      if(this.state.imageState === 2 && !Number.isInteger(this.props.user.staff_id)) {
        let response = await Api.updateAccountImage(this.state.user_img, false);
        let existingUser = Object.assign({}, this.props.user);
        existingUser.user_img = response.data.image_path;
        this.props.actions.updateUser(existingUser);
        this.props.triggerNotification("Image updated successfully.", "success", "bc", 4);
      }
    } catch(e) {
      this.props.triggerNotification("Unable to update image. Please contact support if the problem persists.", "danger", "bc", 4);
    }
  }

  async handleRemoveUserImg(e) {
    e.preventDefault();
    try {
      let response = await Api.updateAccountImage(null, true);
      let existingUser = Object.assign({}, this.props.user);
      existingUser.user_img = response.data.image_path;
      this.props.actions.updateUser(existingUser);
      this.setState({ user_img: `${CDN_URL}/` + response.data.image_path });
      this.props.triggerNotification("Image updated successfully.", "success", "bc", 4);
    } catch(e) {
      this.props.triggerNotification("Unable to update image. Please contact support if the problem persists.", "danger", "bc", 4);
    }
  }

  getStaffPosition(staffId) {
    const staffObj = this.props.staff.find(x => x.id === staffId);
    return staffObj ? staffObj.position : "Staff Member";
  }

  renderProfileCard() {
    return (
      <MuiCard style={{ padding: 0 }}>
        <Box
          sx={{
            height: 120,
            background:`linear-gradient(to top, rgba(0,0,0,.2), rgba(0,0,0,.2)), url(${CDN_URL}/${this.props.business.business_img}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        />
        <Grid container mt={-8}>
          <Grid item xs={12} sm={12} md={12} mb={2}>
            <input type="file" ref={fileInput => this.userImgInput = fileInput} accept="image/png, image/gif, image/jpeg, .jpg, .jpeg, .png" onChange={this.onFileChange} style={{ display: 'none' }} />
            <div className="staff-img-container" onClick={this.userImgClick} style={{ height: 124, width: 124, marginBottom: 15, backgroundColor: '#b1b1b1' }}>
              <img alt="UserProfile" src={this.state.user_img} className="staff-img-placeholder"/>
              {this.props.user && !Number.isInteger(this.props.user.staff_id) ?
                (this.props.user && this.props.user.user_img === "partnerImg/partner_default.png" ? (
                  <Button
                    color="success"
                    size="sm"
                    className="btn-round btn-icon"
                    style={{ float: 'right', marginBottom: 0, marginTop: -30, borderRadius: '50%' }}
                  >
                    <Plus size={14} weight="bold"/>
                  </Button>
                ): (
                  <Button
                      color="danger"
                      size="sm"
                      className="btn-round btn-icon"
                      style={{ float: 'right', marginBottom: 0, marginTop: -30, borderRadius: '50%' }}
                      onClick={this.handleRemoveUserImg}
                  >
                    <X size={14} weight="bold"/>
                  </Button>
                )
              ) : null}
            </div>
            <Typography variant="h5" align="center">{this.props.user?.user_firstname + ' ' + this.props.user?.user_lastname}</Typography>
            <Typography variant="subtitle2" align="center" sx={{ fontSize: 16 }}>{this.props.user.staff_id ? this.getStaffPosition(this.props.user.staff_id) : "Admin"}</Typography>
          </Grid>
        </Grid>
      </MuiCard>
    )
  }

  renderUserCard() {
    const isStaffAccount = Number.isInteger(this.props.user.staff_id);
    return (
      <MuiCard>
        <Typography variant="h5" mb={3}>
          Your Details
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="first-name"
              fullWidth
              label="First Name"
              variant="outlined"
              value={this.state.user_firstname}
              disabled={isStaffAccount}
              onChange={(e) => this.handleChange(e, 'firstname')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField 
              id="last-name"
              fullWidth
              label="Last Name"
              variant="outlined"
              value={this.state.user_lastname}
              disabled={isStaffAccount}
              onChange={(e) => this.handleChange(e, 'lastname')}
            />
          </Grid>
        </Grid>
        {!isStaffAccount ? (
          <Box>
            <LoadingButton
              variant="contained"
              loading={this.state.detailsLoading}
              color="primary"
              disabled={
                this.props.user.firstname === this.state.user_firstname &&
                this.props.user.lastname === this.state.user_lastname
              }
              onClick={() => this.updateAccountDetails()}
            >
              Save
            </LoadingButton>
          </Box>
        ) : (
          <Alert severity="info">This information is managed by your system admin.</Alert>
        )}
        {this.state.detailsError && (
          <Alert severity="error" sx={{ mt: 2 }}>{this.state.detailsError}</Alert>
        )}
      </MuiCard>
    );
  }

  renderConsultantCard() {
    const consultantData = {
      consultantName: "Ashleigh Charlton",
      consultantEmail: "ashleigh@styler.digital",
      consultantNumber: "+447305214688"
    }
    return (
      <MuiCard>
        <Typography variant="h5" mb={2}>
          Your Consultant
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <p>Your consultant is on hand to help you with any account-related matters.</p>
            <hr/>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex'}}>
                <img
                  alt="Consultant"
                  src={require("assets/img/ashleigh_consultant.png")}
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', borderRadius: '50%', maxHeight: 50 }}
                />
                <Box ml={2}>
                  <Typography variant="subtitle1" >
                    {consultantData.consultantName}
                  </Typography>
                  <Typography variant="subtitle2">
                    {consultantData.consultantEmail}
                  </Typography>
                </Box>
              </Box>
              <ButtonGroup variant="outlined">
                <MuiButton onClick={() => window.open(`tel:${consultantData.consultantNumber}`, '_self')}>Call</MuiButton>
                <MuiButton onClick={() => window.open(`mailto:${consultantData.consultantEmail}`, '_self')}>Email</MuiButton>
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
      </MuiCard>
    )
  }

  renderChangePasswordCard() {
    return (
      <MuiCard>
        <Typography variant="h5" mb={2}>
          Change Password
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <p>Click the link below and we'll send you an email to reset your password.</p>
            <hr/>
            <LoadingButton
              variant="contained"
              color={this.state.resetPasswordSuccess ? 'success' : 'primary'}
              onClick={() => this.sendResetPasswordEmail()}
              loading={this.state.resetPasswordLoading}
            >
              {this.state.resetPasswordSuccess ? 'Sent' : 'Send Reset Password Email'}
            </LoadingButton>
          </Grid>
        </Grid>
      </MuiCard>
    )
  }

  renderTwoStepVerificationCard() {
    return (
      <>
        <MuiCard>
          <Typography variant="h5" mb={2}>
            Two-factor Authentication
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <p>With two-factor authentication, you can add an extra layer of security to your account in case your password is stolen. We strongly recommend enabling two-factor authentication to protect your account and keep it safe from cyberattacks or unauthorised access.</p>
              {this.state.mfaData !== null && (
                <>
                <hr/>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ height: 15, width: 15, borderRadius: '50%', backgroundColor: this.state.mfaData?.enabled ? '#1ab394' : '#ed5565' }} />
                      <Typography variant="body1" sx={{ lineHeight: 1, ml: 1 }}>{this.state.mfaData?.enabled ? 'Enabled' : 'Disabled'}</Typography>
                    </Box>
                    <Typography variant="subtitle2" sx={{ fontSize: 12, mt: 1.5, lineHeight: 0.5 }}>{this.state.mfaData?.enrollmentTime ? this.state.mfaData.enrollmentTime : 'Not configured'}</Typography>
                  </Box>
                  <MuiButton variant="contained" color={this.state.mfaData?.enabled ? "error" : "primary"} onClick={() => this.setState(this.state.mfaData?.enabled ? { mfaWizardDisableVisible: true } : { mfaWizardVisible: true})}>
                    {this.state.mfaData?.enabled ? 'Disable' : 'Enable'}
                  </MuiButton>
                </Box>
                </>
              )}
            </Grid>
          </Grid>
        </MuiCard>
        <MFAWizardEnable
          isVisible={this.state.mfaWizardVisible}
          setVisible={() => { this.setState({ mfaWizardVisible: !this.state.mfaWizardVisible }); this.getMfaStatus() }}
          user={this.props.user}
        />
        <MFAWizardDisable
          isVisible={this.state.mfaWizardDisableVisible}
          setVisible={() => { this.setState({ mfaWizardDisableVisible: !this.state.mfaWizardDisableVisible }); this.getMfaStatus() }}
          user={this.props.user}
        />
      </>
    );
  }

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} sm={10} md={8} lg={12} xl={10} xxl={8} className="ms-auto me-auto">
              <Row>
                <Col lg="4" md="12">
                  {this.renderProfileCard()}
                </Col>
                <Col md={12} lg={8} xl={7}>
                  {this.renderUserCard()}
                  {this.renderConsultantCard()}
                  {this.renderChangePasswordCard()}
                  {this.renderTwoStepVerificationCard()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <ImageCropper
          visible={this.state.imageCropperVisible}
          toggleVisible={this.toggleImageCropper}
          imageFile={this.state.imageFile}
          submitCroppedImage={this.submitCroppedImage}
          xDim={300}
          yDim={300}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    business: state.business,
    user: state.user,
    staff: state.staff
  }
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
