import * as types from "../constants/actionTypes";

export default function userReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_USER_SUCCESS:
            return action.user;
        case types.UPDATE_USER_SUCCESS:
            return action.user;
        case types.CLEAR_USER_SUCCESS:
            return [];
        default:
            return state;
    };
    
};