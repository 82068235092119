const bool = [
    {
        id: 1,
        label: "Yes",
        value: true
    },
    {
        id: 2,
        label: "No",
        value: false
    }
];

export default bool;