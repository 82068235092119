import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "@firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyC90cQ6W2uzjRS-flwqBnolP49LKcF-VSM",
    projectId: "whatstylebusinessusers",
    authDomain: "whatstylebusinessusers.firebaseapp.com",
    appId: "1:594099617492:web:50262b679d0fc97debc541",
    measurementId: "G-GVPW3M5FYH"
};
let firebaseApp, firebaseAnalytics;
let firebaseApps = getApps();
if(firebaseApps.length === 0) {
    firebaseApp = initializeApp(firebaseConfig);
    if(process.env.NODE_ENV === "production") {
        firebaseAnalytics = getAnalytics(firebaseApp);
    }
}

export { firebaseApp, firebaseAnalytics };