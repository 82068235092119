import React from 'react';

import { FiCheck } from 'react-icons/fi';

const getFormattedBusinessLocationAddress = (businessLocationObj) => {
  let formatted_address = '';
  let unit_name = businessLocationObj.address_unit_name;
  if(unit_name && unit_name !== '') {
    formatted_address = unit_name + ', ';
  }
  let street_number = businessLocationObj.address_street_number;
  if(street_number && street_number !== '') {
    formatted_address = formatted_address + street_number + ' ';
  }
  let street_name = businessLocationObj.address_street_name;
  if(street_name && street_name !== '') {
    formatted_address = formatted_address + street_name + ', ';
  }
  let city = businessLocationObj.address_city;
  if(city && city !== '') {
    formatted_address = formatted_address + city + ', ';
  }
  let state = businessLocationObj.address_state;
  if(state && state !== '') {
    formatted_address = formatted_address + state + ', ';
  }
  let country = businessLocationObj.address_country;
  if(country && country !== '') {
    formatted_address = formatted_address + country + ', ';
  }
  let postal_code = businessLocationObj.address_postal_code;
  if(postal_code && postal_code !== '') {
    formatted_address = formatted_address + postal_code;
  }
  return formatted_address;
}

function BusinessLocation(props) {
  let businessLocationData = props.data;
  return (
      <div className="business-location-container">
        <div className="business-location-title">
          <div>
            {businessLocationData.business_location_name}
          </div>
          {props.selected ? (
            <div>
              <FiCheck size={16} />
            </div>
          ) : null}
        </div>
        <div className="business-location-description text-muted">
          {getFormattedBusinessLocationAddress(businessLocationData)}
        </div>
      </div>
  );
}

export default BusinessLocation;