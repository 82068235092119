const bookingAdvanceMonths = [
    {
        id: 1,
        label: "Immediately before the start time",
        value: 0
    },
    {
        id: 2,
        label: "5 minutes before the start time",
        value: 5
    },
    {
        id: 3,
        label: "10 minutes before the start time",
        value: 10
    },
    {
        id: 4,
        label: "15 minutes before the start time",
        value: 15
    },
    {
        id: 5,
        label: "20 minutes before the start time",
        value: 20
    },
    {
        id: 6,
        label: "25 minutes before the start time",
        value: 25
    },
    {
        id: 7,
        label: "30 minutes before the start time",
        value: 30
    },
    {
        id: 8,
        label: "35 minutes before the start time",
        value: 35
    },
    {
        id: 9,
        label: "40 minutes before the start time",
        value: 40
    },
    {
        id: 10,
        label: "45 minutes before the start time",
        value: 45
    },
    {
        id: 11,
        label: "50 minutes before the start time",
        value: 50
    },
    {
        id: 12,
        label: "55 minutes before the start time",
        value: 55
    },
    {
        id: 13,
        label: "1 hr before the start time",
        value: 60
    },
    {
        id: 14,
        label: "2 hrs before the start time",
        value: 120
    },
    {
        id: 15,
        label: "3 hrs before the start time",
        value: 180
    },
    {
        id: 16,
        label: "4 hrs before the start time",
        value: 240
    },
    {
        id: 17,
        label: "5 hrs before the start time",
        value: 300
    },
    {
        id: 18,
        label: "6 hrs before the start time",
        value: 360
    },
    {
        id: 19,
        label: "7 hrs before the start time",
        value: 420
    },
    {
        id: 20,
        label: "8 hrs before the start time",
        value: 480
    },
    {
        id: 21,
        label: "9 hrs before the start time",
        value: 540
    },
    {
        id: 22,
        label: "10 hr before the start time",
        value: 600
    },
    {
        id: 23,
        label: "11 hrs before the start time",
        value: 660
    },
    {
        id: 24,
        label: "12 hrs before the start time",
        value: 720
    },
    {
        id: 25,
        label: "13 hrs before the start time",
        value: 780
    },
    {
        id: 26,
        label: "14 hrs before the start time",
        value: 840
    },
    {
        id: 27,
        label: "15 hrs before the start time",
        value: 900
    },
    {
        id: 28,
        label: "16 hrs before the start time",
        value: 960
    },
    {
        id: 29,
        label: "17 hrs before the start time",
        value: 1020
    },
    {
        id: 30,
        label: "18 hrs before the start time",
        value: 1080
    },
    {
        id: 31,
        label: "19 hrs before the start time",
        value: 1140
    },
    {
        id: 32,
        label: "20 hrs before the start time",
        value: 1200
    },
    {
        id: 33,
        label: "21 hrs before the start time",
        value: 1260
    },
    {
        id: 33,
        label: "22 hrs before the start time",
        value: 1320
    },
    {
        id: 34,
        label: "23 hrs before the start time",
        value: 1380
    },
    {
        id: 35,
        label: "1 day before the start time",
        value: 1440
    },
    {
        id: 36,
        label: "2 days before the start time",
        value: 2880
    },
    {
        id: 37,
        label: "3 days before the start time",
        value: 4320
    },
    {
        id: 38,
        label: "4 days before the start time",
        value: 5760
    },
    {
        id: 39,
        label: "5 days before the start time",
        value: 7200
    },
    {
        id: 40,
        label: "6 days before the start time",
        value: 8640
    },
    {
        id: 41,
        label: "7 days before the start time",
        value: 10080
    },
];

export default bookingAdvanceMonths;