import React, { useState, useEffect } from "react";
// MUI components
import Box from "@mui/material/Box";
// Lottie
import Lottie from 'react-lottie';

import * as WebAnim from '../../assets/animations/logIn/rings.json';

// Login box

function LoginBox() {
    
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    const renderLottie = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: WebAnim,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            },
            width: '100%',
            height: '100%'
        };
        return (
            <Lottie
                speed={loaded ? 0.2 : 1}
                options={defaultOptions}
                isClickToPauseDisabled={true}
            />
        );
    }

    return (
        <Box
            sx={{
            height: 'calc(100vh - 149px)',
            background: '#0a122b',
            mr: 10,
            borderRadius: 16,
            overflow: 'hidden'
            }}
        >
            <div style={{ width: '100%', height: '170%', marginTop: '10%' }}>
                {renderLottie()}
            </div>
        </Box>
    );
}

export default LoginBox;