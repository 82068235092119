const status = [
    {
        id: 1,
        label: "Booked",
        value: 1,
        color: '#1ab394'
    },
    {
        id: 2,
        label: "In-progress",
        value: 2,
        color: '#f8ac59'
    },
    {
        id: 3,
        label: "Completed",
        value: 3,
        color: '#aeaeae'
    },
    {
        id: 4,
        label: "No-show",
        value: 4,
        color: '#ed5565'
    },
    {
        id: 5,
        label: "Cancelled",
        value: 5,
        color: '#ed5565'
    }
];

export default status;