/*eslint-disable*/
import React from "react";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
// reactstrap components
import { Modal, ModalBody, ModalHeader, Button, Tooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from "reactstrap";
import { FiPhone, FiMessageSquare, FiMail, FiRepeat } from 'react-icons/fi';
import { FaCircle } from 'react-icons/fa';
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
// Constants
import status from "../../constants/status";
import frequency from "../../constants/frequency";
import currency from "../../constants/currency";
import { CDN_URL } from "constants/urls";
// API
import Api from '../../api/index';

class CalendarBookingOptions extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            user_order_id: null,
            user_order_recurring: null,
            user_order_uid: null,
            status: null,
            bookingDate: null,
            bookingServices: [],
            paid: null,
            bookingLoading: false,
            statusLoading: false,
            notes: null,
            frequency_id: null,
            end_id: null,
            end_date: null,
            callTooltipVisible: false,
            smsTooltipVisible: false,
            emailTooltipVisible: false,
            sweetAlert: null,
            editPermission: false
        };
    }

    componentDidMount() {
        if(!this.props.bookingData || !this.props.bookingData.extendedProps || !Number.isInteger(this.props.bookingData.extendedProps.userOrderId)) {
            this.props.toggleVisible();
        }
        let user_order_id = this.props.bookingData.extendedProps.userOrderId;
        let user_order_recurring = this.props.bookingData.extendedProps.recurring;
        this.setState({
            user_order_id,
            user_order_recurring,
            editPermission: this.props.userRole?.booking?.update
        });
        this.getBookingData(user_order_id, user_order_recurring);
    }

    async getBookingData(user_order_id, user_order_recurring) {
        try {
            if(!user_order_id || typeof user_order_recurring !== "boolean") {
                return;
            }
            this.setState({ bookingLoading: true });
            let response = await Api.getBooking({ user_order_id, user_order_recurring, paid_amount: true });
            let orderData = response.data.orderData;
            let orderEventData = response.data.orderEventData;
            let bookingServices = [];
            orderEventData.forEach((orderEventObj, orderEventIndex) => {
                // Find duration id
                let bookingServiceDuration = null, duration = null;
                if(user_order_recurring) {
                    bookingServiceDuration = moment.duration(moment(orderEventObj.end_time, 'HH:mm:ss').diff(moment(orderEventObj.start_time, 'HH:mm:ss')));
                    duration = bookingServiceDuration.asMinutes();
                } else {
                    bookingServiceDuration = moment.duration(moment(orderEventObj.end, 'YYYY-MM-DD HH:mm:ss').diff(moment(orderEventObj.start, 'YYYY-MM-DD HH:mm:ss')));
                    duration = bookingServiceDuration.asMinutes();
                }
                bookingServices.push({
                    service_detail_id: orderEventObj.service_detail_id,
                    staff_id: orderEventObj.staff_id,
                    start_time: user_order_recurring ? moment(orderEventObj.start_time, 'HH:mm:ss') : moment(orderEventObj.start, 'YYYY-MM-DD HH:mm:ss'),
                    duration: duration ? duration : null,
                });
            });
            this.setState({
                user_order_uid: user_order_recurring ? orderData.user_order_recurring_uid : orderData.user_order_uid,
                status: orderEventData[0].status,
                bookingDate: this.state.recurring_conversion ? this.state.bookingDate : (user_order_recurring ? moment(orderEventData[0].start_date, 'YYYY-MM-DD') : moment(orderEventData[0].start, 'YYYY-MM-DD HH:mm:ss')),
                bookingServices,
                paid: response.data.paid ? response.data.paid : null,
                notes: orderEventData[0].event_notes,
                frequency_id: orderEventData[0].business_events_recurring_frequency_id,
                end_id: user_order_recurring ? (orderEventData[0].end_date ? 3 : 1)  : null,
                end_date: user_order_recurring ? moment(orderEventData[0].end_date, 'YYYY-MM-DD') : null,
            });
            this.setState({ bookingLoading: false });
        } catch(err) {
            console.log(err);
            this.setState({ bookingLoading: false });
        }
    }

    renderStatusDropdown() {
        let statusObj = status.find(x => x.label === this.state.status);
        let statusData = [...status];
        const cancelPermission = this.props.userRole?.booking?.cancel === true;
        if(!cancelPermission) {
            statusData = statusData.filter(x => x.label !== "Cancelled");
        }
        const updatePermission = this.props.userRole?.booking?.update === true;
        if(!statusObj) {
            return null;
        } else {
            return (
                <div>
                    <UncontrolledDropdown disabled={!updatePermission}>
                        <DropdownToggle caret={this.state.user_order_recurring === false} disabled={this.state.user_order_recurring === true} color="link" style={{ margin: 0, fontSize: 14 }}>
                            {this.state.statusLoading ?<Spinner color="success" size="sm" style={{ marginRight: 5 }}/> : null}<FaCircle size={10} color={statusObj.color} style={{ marginRight: 5 }}/>{statusObj.label}
                        </DropdownToggle>
                        <DropdownMenu end>
                            {statusData.filter(x => x.label !== this.state.status).map((statusObj) => {
                                return (
                                    <DropdownItem onClick={() => this.submitStatusChange(statusObj.label)}><FaCircle size={10} color={statusObj.color} style={{ marginRight: 5 }}/>{statusObj.label}</DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            );
        }
    }

    handleCancellationFee(newStatus) {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        let total = 0;
        {this.state.bookingServices.map((bookingServiceObj, bookingServiceIndex) => {
            let serviceDetailObj = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingServiceObj.service_detail_id);
            if(!serviceDetailObj) {
                return null;
            }
            total = total + serviceDetailObj.service_business_detail_price;
        })}
        let feeAmount = Math.round(total * (this.props.business.cancellation_fee_percentage / 100));
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    title="Late Cancellation Fee"
                    onConfirm={() => this.confirmStatusChange(newStatus, true)}
                    onCancel={() => this.confirmStatusChange(newStatus, false)}
                    confirmBtnBsStyle="warning"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Would you like to charge the customer a {this.props.business.cancellation_fee_percentage}% ({currencyObj ? currencyObj.symbol : '£'}{Number(feeAmount / 100).toFixed(2)}) late cancellation fee?
                </SweetAlert>
            ),
        });
    }

    handleNoShowFee(newStatus) {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        let total = 0;
        {this.state.bookingServices.map((bookingServiceObj, bookingServiceIndex) => {
            let serviceDetailObj = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingServiceObj.service_detail_id);
            if(!serviceDetailObj) {
                return null;
            }
            total = total + serviceDetailObj.service_business_detail_price;
        })}
        let feeAmount = Math.round(total * (this.props.business.no_show_fee_percentage / 100));
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    title="No-show Fee"
                    onConfirm={() => this.confirmStatusChange(newStatus, true)}
                    onCancel={() => this.confirmStatusChange(newStatus, false)}
                    confirmBtnBsStyle="warning"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Would you like to charge the customer a {this.props.business.no_show_fee_percentage}% ({currencyObj ? currencyObj.symbol : '£'}{Number(feeAmount / 100).toFixed(2)}) no-show fee?
                </SweetAlert>
            ),
        });
    }

    async submitStatusChange(newStatus) {
        if(!this.state.user_order_id || this.state.user_order_recurring === true || this.state.status === newStatus) {
            return;
        }
        try {
            this.setState({ statusLoading: true });
            // Handle late cancellation fees
            if(newStatus === 'Cancelled' && this.props.business.cancellation_fee_enabled === 1 && this.props.business.cancellation_fee_percentage > 0) {
                this.handleCancellationFee(newStatus);
                return;
            }
            // Handle no-show fees
            if(newStatus === 'No-show' && this.props.business.no_show_fee_enabled === 1 && this.props.business.no_show_fee_percentage > 0) {
                this.handleNoShowFee(newStatus);
                return;
            }
            this.confirmStatusChange(newStatus, false);
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured whilst trying to update the booking status. Please contact Support if this problem persists.", "danger", "bc", 6);
            this.setState({ statusLoading: false });
        }
    }

    async confirmStatusChange(newStatus, bookingFee) {
        try {
            this.setState({ sweetAlert: null });
            let response = await Api.updateBookingStatus({ user_order_id: this.state.user_order_id, status: newStatus, booking_fee: bookingFee });
            this.props.triggerNotification("Booking status updated successfully.", "success", "bc", 3);
            if(bookingFee === true) {
                if(response.data && response.data.feeData && response.data.feeData.feeCharged === true) {
                    this.props.triggerNotification("Booking fee processed successfully.", "success", "bc", 4);
                } else {
                    if(response.data.feeData.message) {
                        this.props.triggerNotification("Booking fee failed. " + response.data.feeData.message, "danger", "bc", 8);
                    }
                }
            }
            if(this.props.submitStatusChange) {
                this.props.submitStatusChange(newStatus);
            }
            this.setState({ statusLoading: false, status: newStatus });
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured whilst trying to update the booking status. Please contact Support if this problem persists.", "danger", "bc", 6);
            this.setState({ statusLoading: false });
        }
    }

    renderBookingServices() {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        let total = 0;
        return (
            <div>
                {this.state.bookingServices.map((bookingServiceObj, bookingServiceIndex) => {
                    let serviceDetailObj = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingServiceObj.service_detail_id);
                    if(!serviceDetailObj) {
                        return null;
                    }
                    let staffObj = this.props.staff.find(x => x.id === bookingServiceObj.staff_id);
                    let serviceObj = this.props.service.find(x => x.service_id === serviceDetailObj.service_business_id);
                    total = total + serviceDetailObj.service_business_detail_price;
                    return (
                        <div>
                            <hr/>
                            <div className="booking-options-modal-flex-row">
                                <div>{serviceObj ? serviceObj.service_name : null} ({serviceDetailObj ? serviceDetailObj.service_business_detail_name : null})</div>
                                <div>{currencyObj ? currencyObj.symbol : '£'}{serviceDetailObj ? Number(serviceDetailObj.service_business_detail_price / 100).toFixed(2) : '--.--'}</div>
                            </div>
                            <div className="text-muted booking-options-modal-service-description">{bookingServiceObj.duration} mins with {staffObj ? staffObj.firstname + ' ' + staffObj.lastname : null} at {bookingServiceObj.start_time.format('HH:mm')}</div>
                        </div>
                    );
                })}
                <hr style={{ borderTopWidth: 2 }}/>
                <div className="booking-options-modal-flex-row">
                    <div className="text-bold">Total</div>
                    <div className="text-bold">{currencyObj ? currencyObj.symbol : '£'}{Number(total / 100).toFixed(2)}</div>
                </div>
                {this.state.paid > 0 && (
                    <>
                        <div className="booking-options-modal-flex-row">
                            <div className="text-bold">Paid</div>
                            <div className="text-bold">{currencyObj ? currencyObj.symbol : '£'}{Number(this.state.paid / 100).toFixed(2)}</div>
                        </div>
                        <hr style={{ borderTopWidth: 2 }}/>
                        <div className="booking-options-modal-flex-row">
                            <div className="text-bold">Due</div>
                            <div className="text-bold">{currencyObj ? currencyObj.symbol : '£'}{Number((total - this.state.paid) / 100).toFixed(2)}</div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    submitEdit(recurring_conversion) {
        const { navigate } = this.props;
        let user_order_id = this.state.user_order_id;
        let user_order_recurring = this.state.user_order_recurring;
        if(user_order_recurring) {
            if(recurring_conversion) {
                navigate('/admin/edit_booking?user_order_id=' + user_order_id + '&user_order_recurring=true&recurring_conversion=true&recurring_conversion_date=' + moment(this.props.bookingData.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));
            } else {
                navigate('/admin/edit_booking?user_order_id=' + user_order_id + '&user_order_recurring=true');
            }
        } else {
            navigate('/admin/edit_booking?user_order_id=' + user_order_id);
        }
    }

    renderRecurringDescription() {
        let user_order_recurring = this.props.bookingData.extendedProps.recurring;
        if(user_order_recurring === false) {
            return null;
        }
        let frequencyObj = frequency.find(x => x.id === this.state.frequency_id);
        return (
            <div>
                <p className="text-muted booking-options-modal-service-description" style={{ margin: 0 }}><FiRepeat style={{ marginRight: 5 }}/>Repeats {frequencyObj ? frequencyObj.label : null}</p>
                <p className="text-muted booking-options-modal-service-description" style={{ margin: 0 }}><span className="text-bold">Start Date:</span> {this.state.bookingDate ? this.state.bookingDate.format('ddd Do MMM YYYY') : '----'}</p>
                <p className="text-muted booking-options-modal-service-description" style={{ margin: 0 }}><span className="text-bold">End Date:</span> {this.state.end_id === 3 && this.state.end_date ? this.state.end_date.format('ddd Do MMM YYYY') : '----'}</p>
            </div>
        );
    }

    render() {
        return (
        <>
            <Modal
                isOpen={this.props.visible}
                toggle={() => this.props.toggleVisible()}
                className="text-center"
            >
                <ModalHeader
                    className="justify-content-center uppercase booking-options-modal-header"
                    toggle={() => this.props.toggleVisible()}
                >
                    <div className="image">
                        <img alt="BusinessProfileImg" src={`${CDN_URL}/` + this.props.business.business_img} />
                    </div>
                    <div className="author">
                        <div className="customer-profile-avatar">
                            <div className="customer-profile-avatar-initials">{this.props.bookingData && this.props.bookingData.extendedProps ? this.props.bookingData.extendedProps.customerFirstname.charAt(0) + this.props.bookingData.extendedProps.customerLastname.charAt(0) : '--'}</div>
                        </div>
                        <h5 className="title booking-options-modal-header-title">{this.props.bookingData && this.props.bookingData.extendedProps ? this.props.bookingData.extendedProps.customerFirstname + ' ' + this.props.bookingData.extendedProps.customerLastname : '----'}</h5>
                        <p className="description booking-options-modal-header-description">Customer</p>
                    </div>
                </ModalHeader>
                <ModalBody style={{ paddingTop: 0, textAlign: 'left' }}>
                    <div className="customer-profile-buttons">
                        <Button id="callButton" color="muted" className="customer-profile-button btn-icon" onClick={() => this.props.bookingData && this.props.bookingData.extendedProps ? window.open(`tel:${this.props.bookingData.extendedProps.customerPhone}`, '_self') : null}><FiPhone style={{ marginBottom: 3 }} /></Button>
                        <Tooltip placement="bottom" isOpen={this.state.callTooltipVisible} target="callButton" toggle={() => this.setState({ callTooltipVisible: !this.state.callTooltipVisible })}>
                            Call: {this.props.bookingData && this.props.bookingData.extendedProps ? this.props.bookingData.extendedProps.customerPhone : 'N/A'}
                        </Tooltip>
                        <Button id="smsButton" color="muted" className="customer-profile-button btn-icon" onClick={() => this.props.bookingData && this.props.bookingData.extendedProps ? window.open(`sms:${this.props.bookingData.extendedProps.customerPhone}`, '_self') : null}><FiMessageSquare style={{ marginBottom: 3 }} /></Button>
                        <Tooltip placement="bottom" isOpen={this.state.smsTooltipVisible} target="smsButton" toggle={() => this.setState({ smsTooltipVisible: !this.state.smsTooltipVisible })}>
                            SMS: {this.props.bookingData && this.props.bookingData.extendedProps ? this.props.bookingData.extendedProps.customerPhone : 'N/A'}
                        </Tooltip>
                        <Button id="emailButton" color="muted" className="customer-profile-button btn-icon" onClick={() => this.props.bookingData && this.props.bookingData.extendedProps ? window.open(`mailto:${this.props.bookingData.extendedProps.customerEmail}`, '_self') : null}><FiMail style={{ marginBottom: 3 }} /></Button>
                        <Tooltip placement="bottom" isOpen={this.state.emailTooltipVisible} target="emailButton" toggle={() => this.setState({ emailTooltipVisible: !this.state.emailTooltipVisible })}>
                            Email: {this.props.bookingData && this.props.bookingData.extendedProps ? this.props.bookingData.extendedProps.customerEmail : 'N/A'}
                        </Tooltip>
                    </div>
                    {this.state.bookingLoading === false ?
                        <div>
                            <hr style={{ marginTop: 5 }}/>
                            <div className="booking-options-modal-flex-row">
                                <div>
                                    <p className="text-bold" style={{ margin: 0 }}>{this.props.bookingData ? moment(this.props.bookingData.start, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY') : null}</p>
                                    <p className="text-muted booking-options-modal-service-description" style={{ margin: 0 }}>Booking Ref: {this.state.user_order_uid}</p>
                                    {this.renderRecurringDescription()}
                                </div>
                                {this.renderStatusDropdown()}
                            </div>
                            {this.renderBookingServices()}
                            {this.state.editPermission && (
                                <div className="booking-options-modal-flex-row">
                                    {this.state.user_order_recurring && <Button block className="btn-block" color="primary" style={{ marginTop: '1rem', padding: 11 }} onClick={() => this.submitEdit(true)}>Edit Only This Event</Button>}
                                    <Button block className="btn-block" color="primary" style={{ marginTop: '1rem', padding: 11 }} onClick={() => this.submitEdit(false)}>{this.state.user_order_recurring ? 'Edit Recurring Booking' : 'Edit Booking'}</Button>
                                </div>
                            )}
                        </div>
                    :
                        <div className="text-center" style={{ marginTop: '1rem' }}>
                            <Spinner color="success" size="sm" style={{ marginRight: 5 }}/> Loading Booking...
                        </div>
                    }
                </ModalBody>
            </Modal>
            {this.state.sweetAlert}
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        userRole: state.userRole,
        business: state.business,
        staff: state.staff,
        service: state.service,
        serviceDetail: state.serviceDetail
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarBookingOptions);