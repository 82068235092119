import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Button
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ColourPicker from "components/ColourPicker/ColourPicker";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";
// API
import Api from 'api/index';
// Constants
import { CDN_URL } from "constants/urls";
// Utilities
import withRouter from "utilities/withRouter";

class HubAppearance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            colorPickerVisible: false,
            selectedPicker: null,
            picker1Color: null,
            picker2Color: null,
            picker3Color: null,
            picker4Color: null,
            picker5Color: null,
            picker6Color: null,
            picker7Color: null
        };
        this.toggleColorPickerVisible = this.toggleColorPickerVisible.bind(this);
        this.handleColourChange = this.handleColourChange.bind(this);
    }

    handleToggle() {
        this.setState({ picker1Visible: !this.state.picker1Visible });
    }

    toggleColorPickerVisible() {
        this.setState({ colorPickerVisible: !this.state.colorPickerVisible });
    }

    handleSwatchClick(controlName) {
        this.setState({ colorPickerVisible: true, selectedPicker: controlName });
    }

    handleColourChange(colorObj) {
        let businessObj = Object.assign({}, this.props.business);
        let formattedColorStr = colorObj ? colorObj : null;
        if(this.state.selectedPicker === 'picker1Color') {
            businessObj.hub_header_gradient_start = formattedColorStr;
            this.setState({ picker1Color: colorObj, colorPickerVisible: false });
        } else if(this.state.selectedPicker === 'picker2Color') {
            businessObj.hub_header_gradient_end = formattedColorStr;
            this.setState({ picker2Color: colorObj, colorPickerVisible: false });
        } else if(this.state.selectedPicker === 'picker3Color') {
            businessObj.hub_header_gradient_start_text = formattedColorStr;
            this.setState({ picker3Color: colorObj, colorPickerVisible: false });
        } else if(this.state.selectedPicker === 'picker4Color') {
            businessObj.hub_header_gradient_end_text = formattedColorStr;
            this.setState({ picker4Color: colorObj, colorPickerVisible: false });
        } else if(this.state.selectedPicker === 'picker5Color') {
            businessObj.hub_header_title = formattedColorStr;
            this.setState({ picker5Color: colorObj, colorPickerVisible: false });
        } else if(this.state.selectedPicker === 'picker6Color') {
            businessObj.hub_cover_image_overlay_gradient_start = formattedColorStr;
            this.setState({ picker6Color: colorObj, colorPickerVisible: false });
        } else if(this.state.selectedPicker === 'picker7Color') {
            businessObj.hub_cover_image_overlay_gradient_end = formattedColorStr;
            this.setState({ picker7Color: colorObj, colorPickerVisible: false });
        } else if(this.state.selectedPicker === 'picker8Color') {
            businessObj.hub_cover_image_address = formattedColorStr;
            this.setState({ picker8Color: colorObj, colorPickerVisible: false });
        }
        this.props.actions.loadBusiness(businessObj);
    }

    async submitAppearance() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            await Api.updateHubAppearance({
                header_gradient_start: this.state.picker1Color ? this.state.picker1Color : this.props.business.hub_header_gradient_start,
                header_gradient_end: this.state.picker2Color ? this.state.picker2Color : this.props.business.hub_header_gradient_end,
                header_gradient_start_text: this.state.picker3Color ? this.state.picker3Color : this.props.business.hub_header_gradient_start_text,
                header_gradient_end_text: this.state.picker4Color ? this.state.picker4Color : this.props.business.hub_header_gradient_end_text,
                header_title: this.state.picker5Color ? this.state.picker5Color : this.props.business.hub_header_title,
                cover_image_overlay_gradient_start: this.state.picker6Color ? this.state.picker6Color : this.props.business.hub_cover_image_overlay_gradient_start,
                cover_image_overlay_gradient_end: this.state.picker7Color ? this.state.picker7Color : this.props.business.hub_cover_image_overlay_gradient_end,
                cover_image_address: this.state.picker8Color ? this.state.picker8Color : this.props.business.hub_cover_image_address
            });
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Hub appearance preferences updated successfully.", "success", "bc", 4);
            this.props.navigate('/admin/settings');
        } catch(e) {
            console.log(e);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    async resetAppearance() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            this.setState({ picker1Color: null, picker2Color: null });
            let businessObj = Object.assign({}, this.props.business);
            businessObj.hub_header_gradient_start = null;
            businessObj.hub_header_gradient_end = null;
            businessObj.hub_header_gradient_start_text = null;
            businessObj.hub_header_gradient_end_text = null;
            businessObj.hub_header_title = null;
            businessObj.hub_header_title = null;
            businessObj.hub_cover_image_overlay_gradient_start = null;
            businessObj.hub_cover_image_overlay_gradient_end = null;
            businessObj.hub_cover_image_address = null;
            this.props.actions.loadBusiness(businessObj);
            await Api.updateHubAppearance({
                header_gradient_start: null,
                header_gradient_end: null,
                header_gradient_start_text: null,
                header_gradient_end_text: null,
                header_title: null,
                cover_image_overlay_gradient_start: null,
                cover_image_overlay_gradient_end: null,
                cover_image_address: null
            });
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Hub appearance preferences updated successfully.", "success", "bc", 4);
            this.props.navigate('/admin/settings');
        } catch(e) {
            console.log(e);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    getInitialColour() {
        if(this.state.selectedPicker === 'picker1Color') {
            if(this.state.picker1Color) {
                return this.state.picker1Color;
            } else {
                return this.props.business.hub_header_gradient_start;
            }
        } else if(this.state.selectedPicker === 'picker2Color') {
            if(this.state.picker2Color) {
                return this.state.picker2Color;
            } else {
                return this.props.business.hub_header_gradient_end;
            }
        } else if(this.state.selectedPicker === 'picker3Color') {
            if(this.state.picker3Color) {
                return this.state.picker3Color;
            } else {
                return this.props.business.hub_header_gradient_start_text;
            }
        } else if(this.state.selectedPicker === 'picker4Color') {
            if(this.state.picker4Color) {
                return this.state.picker4Color;
            } else {
                return this.props.business.hub_header_gradient_end_text;
            }
        } else if(this.state.selectedPicker === 'picker5Color') {
            if(this.state.picker5Color) {
                return this.state.picker5Color;
            } else {
                return this.props.business.hub_header_title;
            }
        } else if(this.state.selectedPicker === 'picker6Color') {
            if(this.state.picker6Color) {
                return this.state.picker6Color;
            } else {
                return this.props.business.hub_cover_image_overlay_gradient_start;
            }
        } else if(this.state.selectedPicker === 'picker7Color') {
            if(this.state.picker7Color) {
                return this.state.picker7Color;
            } else {
                return this.props.business.hub_cover_image_overlay_gradient_end;
            }
        } else if(this.state.selectedPicker === 'picker8Color') {
            if(this.state.picker8Color) {
                return this.state.picker8Color;
            } else {
                return this.props.business.hub_cover_image_address;
            }
        }
    }

    getFormattedBusinessAddress() {
        let formatted_address = '';
        let unit_name = this.props.business.address_unit_name;
        if(unit_name && unit_name !== '') {
          formatted_address = unit_name + ', ';
        }
        let street_number = this.props.business.address_street_number;
        if(street_number && street_number !== '') {
          formatted_address = formatted_address + street_number + ' ';
        }
        let street_name = this.props.business.address_street_name;
        if(street_name && street_name !== '') {
          formatted_address = formatted_address + street_name + ', ';
        }
        let city = this.props.business.address_city;
        if(city && city !== '') {
          formatted_address = formatted_address + city + ', ';
        }
        let state = this.props.business.address_state;
        if(state && state !== '') {
          formatted_address = formatted_address + state + ', ';
        }
        let country = this.props.business.address_country;
        if(country && country !== '') {
          formatted_address = formatted_address + country + ', ';
        }
        let postal_code = this.props.business.address_postal_code;
        if(postal_code && postal_code !== '') {
          formatted_address = formatted_address + postal_code;
        }
        return formatted_address;
    }

    renderBusinessCard() {
        return (
            <Card className="card-business card-business-sm" style={this.props.business.business_img ? { backgroundImage: `url("${CDN_URL}/` + this.props.business.business_img + '")' } : null}>
                {this.props.business.business_img ?
                    <div
                        className="card-overlay"
                        style={this.props.business.hub_cover_image_overlay_gradient_start && this.props.business.hub_cover_image_overlay_gradient_end ? (
                            { background: `linear-gradient(160deg, ${this.props.business.hub_cover_image_overlay_gradient_start} 0%, ${this.props.business.hub_cover_image_overlay_gradient_end} 100%)` }
                        ) : (
                            { background: `rgba(0, 0, 0, 0.6)` }
                        )}
                    />
                : null}
                <CardBody className="text-center">
                    {this.props.business && this.props.business.business_logo ?
                        <img className="card-logo" src={`${CDN_URL}/` + this.props.business.business_logo} alt="BusinessLogo"/>
                    :
                        <CardTitle style={!this.props.business.business_img && !this.props.business.business_img ?  { color: '#000000' } : null}>{this.props.business ? this.props.business.business_name : 'Loading...'}</CardTitle>
                    }
                    <p className="card-description" style={this.props.business.hub_cover_image_address ? { color: this.props.business.hub_cover_image_address } : null}>
                        {this.getFormattedBusinessAddress()}
                    </p>
                </CardBody>
            </Card>
        );
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Appearance</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <Card>
                            <CardHeader>
                                <h5 style={{ marginBottom: 0 }}>Header Colours</h5>
                                <hr style={{ marginBottom: 0 }} />
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={6} sm={12} className="mb-3">
                                        <div className="color-row">
                                            <div className="color-title">
                                                Gradient Start
                                            </div>
                                            <div className="color-picker-swatch-container" onClick={() => this.handleSwatchClick('picker1Color')}>
                                                <div className="color-picker-swatch" style={this.state.picker1Color ? { backgroundColor: this.state.picker1Color } : { backgroundColor: this.props.business.hub_header_gradient_start}} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12} className="mb-3">
                                        <div className="color-row">
                                            <div className="color-title">
                                                Gradient End
                                            </div>
                                            <div className="color-picker-swatch-container" onClick={() => this.handleSwatchClick('picker2Color')}>
                                                <div className="color-picker-swatch" style={this.state.picker2Color ? { backgroundColor: this.state.picker2Color } : { backgroundColor: this.props.business.hub_header_gradient_end }} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12} className="mb-3">
                                        <div className="color-row">
                                            <div className="color-title">
                                                Gradient Start Text
                                            </div>
                                            <div className="color-picker-swatch-container" onClick={() => this.handleSwatchClick('picker3Color')}>
                                                <div className="color-picker-swatch" style={this.state.picker3Color ? { backgroundColor: this.state.picker3Color } : { backgroundColor: this.props.business.hub_header_gradient_start_text }} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12} className="mb-3">
                                        <div className="color-row">
                                            <div className="color-title">
                                                Gradient End Text
                                            </div>
                                            <div className="color-picker-swatch-container" onClick={() => this.handleSwatchClick('picker4Color')}>
                                                <div className="color-picker-swatch" style={this.state.picker4Color ? { backgroundColor: this.state.picker4Color } : { backgroundColor: this.props.business.hub_header_gradient_end_text }} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <div className="color-row">
                                            <div className="color-title">
                                                Title
                                            </div>
                                            <div className="color-picker-swatch-container" onClick={() => this.handleSwatchClick('picker5Color')}>
                                                <div className="color-picker-swatch" style={this.state.picker5Color ? { backgroundColor: this.state.picker5Color } : { backgroundColor: this.props.business.hub_header_title }} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card style={{ marginBottom: 0 }}>
                            <CardHeader>
                                <h5 style={{ marginBottom: 0 }}>Cover Image Overlay</h5>
                                <hr style={{ marginBottom: 0 }} />
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        {this.renderBusinessCard()}
                                    </Col>
                                    <Col md={6} sm={12} className="mb-3">
                                        <div className="color-row">
                                            <div className="color-title">
                                                Gradient Start
                                            </div>
                                            <div className="color-picker-swatch-container" onClick={() => this.handleSwatchClick('picker6Color')}>
                                                <div className="color-picker-swatch" style={this.state.picker6Color ? { backgroundColor: this.state.picker6Color } : { backgroundColor: this.props.business.hub_cover_image_overlay_gradient_start }} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12} className="mb-3">
                                        <div className="color-row">
                                            <div className="color-title">
                                                Gradient End
                                            </div>
                                            <div className="color-picker-swatch-container" onClick={() => this.handleSwatchClick('picker7Color')}>
                                                <div className="color-picker-swatch" style={this.state.picker7Color ? { backgroundColor: this.state.picker7Color } : { backgroundColor: this.props.business.hub_cover_image_overlay_gradient_end }} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12} className="mb-3">
                                        <div className="color-row">
                                            <div className="color-title">
                                                Address
                                            </div>
                                            <div className="color-picker-swatch-container" onClick={() => this.handleSwatchClick('picker8Color')}>
                                                <div className="color-picker-swatch" style={this.state.picker8Color ? { backgroundColor: this.state.picker8Color } : { backgroundColor: this.props.business.hub_cover_image_address }} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <div className="action-button-row">
                                    <Button color="primary" onClick={() => this.submitAppearance()}>
                                        Save Changes
                                    </Button>
                                    <Button color="primary" outline onClick={() => this.resetAppearance()}>
                                        Reset to default colours
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {this.state.colorPickerVisible ?
                <ColourPicker
                    visible={this.state.colorPickerVisible}
                    toggleVisible={this.toggleColorPickerVisible}
                    initialColor={this.getInitialColour()}
                    onSubmit={this.handleColourChange}
                    alpha={(this.state.selectedPicker === "picker6Color" || this.state.selectedPicker === "picker7Color")}
                />
            : null}
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HubAppearance));