import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  FormGroup,
  Label,
  Input,
  Badge
} from "reactstrap";
import { FiX } from 'react-icons/fi';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { connect } from "react-redux";
import moment from 'moment';
import 'moment-timezone';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from '../../api/index';
import stripe from '../../utilities/stripe';

import mastercard from '../../assets/img/paymentIcons/mastercard.svg';
import visa from '../../assets/img/paymentIcons/visa.svg';
import americanExpress from '../../assets/img/paymentIcons/american-express.svg';

import currency from 'constants/currency';
import timezone from "constants/timezone";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";

class Billing extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            paymentMethodData: [],
            paymentMethodDefault: null,
            subscriptionData: null,
            invoiceData: null,
            addPaymentMethodVisible: false,
            addPaymentMethodError: null,
            addPaymentMethodName: null,
            addPaymentMethodTerms: false,
            timezoneObj: null
        };
    }

    componentDidMount() {
        this.getPaymentMethods();
        this.getSubscription();
        this.getInvoices();
        if(this.props.business.timezone_id) {
            let timezoneObj = timezone.find(x => x.id === this.props.business.timezone_id);
            if(timezoneObj) {
                this.setState({ timezoneObj });
            }
        }
    }

    async getSubscription() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let subscriptionData = await Api.getSubscription();
            this.setState({ subscriptionData: subscriptionData.data.subscriptionData });
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst trying to retrieve your payment methods.", "danger", "bc", 4);
        }
        this.props.actions.loadLoadingSpinner(false);
    }

    async getInvoices() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let invoiceData = await Api.getInvoices();
            let invoiceTableData = [];
            invoiceData.data.invoiceData.forEach((invoiceObj, invoiceIndex) => {
                let invoiceCurrencyObj = currency.find(x => x.iso === invoiceObj.currency.toUpperCase());
                invoiceTableData.push({
                    id: invoiceIndex,
                    invoice_date: invoiceObj.created,
                    invoice_id: invoiceObj.id,
                    status: invoiceObj.paid,
                    total: invoiceObj.total,
                    currency_symbol: invoiceCurrencyObj ? invoiceCurrencyObj.symbol : '£'
                    /*actions: (
                        <Button
                            onClick={() => {
                                this.sendInvoice(invoiceObj.id)
                            }}
                            className="pull-right"
                            color="primary"
                            size="sm"
                        >
                            <FiMail style={{ marginBottom: 3 }}/> Email
                        </Button>
                    )*/
                });
            });
            this.setState({ invoiceData: invoiceTableData });
        } catch(err) {
            console.log(err);
            this.props.triggerNotification("An unexpected error occured whilst trying to retrieve your invoices.", "danger", "bc", 4);
        }
        this.props.actions.loadLoadingSpinner(false);
    }

    async sendInvoice(invoice_id) {
        this.props.actions.loadLoadingSpinner(true);
        try {
            await Api.sendInvoice(invoice_id);
            this.props.triggerNotification("The invoice has been sent to your email inbox.", "success", "bc", 4);
        } catch(err) {
            console.log(err);
            this.props.triggerNotification("An unexpected error occured whilst trying to send the invoice.", "danger", "bc", 4);
        }
        this.props.actions.loadLoadingSpinner(false);
    }
    
    async getPaymentMethods() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let paymentMethodData = await Api.getPaymentMethods();
            this.setState({ paymentMethodData: paymentMethodData.data.paymentMethodsData, paymentMethodDefault: paymentMethodData.data.paymentMethodDefault });
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst trying to retrieve your payment methods.", "danger", "bc", 4);
        }
        this.props.actions.loadLoadingSpinner(false);
    }

    async setDefaultPaymentMethod(paymentMethodId) {
        if(this.state.paymentMethodDefault !== paymentMethodId){
            this.props.actions.loadLoadingSpinner(true);
            try {
                await Api.setDefaultPaymentMethod(paymentMethodId);
                this.setState({ paymentMethodDefault: paymentMethodId });
            } catch(err) {
                this.props.triggerNotification("An unexpected error occured whilst trying to update your default payment method.", "danger", "bc", 4);
            }
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    async deletePaymentMethod(paymentMethodId) {
        if(this.state.paymentMethodDefault !== paymentMethodId){
            this.props.actions.loadLoadingSpinner(true);
            try {
                await Api.deletePaymentMethod(paymentMethodId);
                this.setState({ paymentMethodData: this.state.paymentMethodData.filter(x => x.id !== paymentMethodId) });
                this.props.triggerNotification("Payment method deleted.", "success", "bc", 4);
            } catch(err) {
                this.props.triggerNotification("An unexpected error occured whilst trying to delete your payment method.", "danger", "bc", 4);
            }
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    renderDefaultCard() {
        let defaultCardObj = this.state.paymentMethodData.find(x => x.id === this.state.paymentMethodDefault);
        let cardBrand = null;
        if(defaultCardObj){
            if(defaultCardObj.brand === 'Visa' || defaultCardObj.brand === 'visa'){
                cardBrand = visa;
            } else if(defaultCardObj.brand === 'American Express' || defaultCardObj.brand === 'amex') {
                cardBrand = americanExpress;
            } else if(defaultCardObj.brand === 'MasterCard' || defaultCardObj.brand === 'mastercard'){
                cardBrand = mastercard;
            }
        }
        return(
            <div className="info">
                <div className="icon icon-primary">
                    <i className="now-ui-icons shopping_credit-card" />
                </div>
                <h6 className="stats-title billing-metric-title">Default Card</h6>
                <h3 className="billing-metric-info billing-metric-card-info">
                    {defaultCardObj ?
                        <div>
                            {cardBrand ? <img alt="default-card-brand-logo" src={cardBrand} height={40} style={{ marginRight: 10 }}/> : null}**** {defaultCardObj.last4}
                        </div>
                    :  
                        <div>
                            ----
                        </div>
                    }
                </h3>
            </div>
        );
    }

    async addNewPaymentMethod(elements, stripe) {
        if (!stripe || !elements) {
            return;
        }
        if(!this.state.addPaymentMethodName || this.state.addPaymentMethodName === ''){
            this.setState({ addPaymentMethodError: "The name on the card is required." });
            return;
        }
        if(this.state.addPaymentMethodTerms === false){
            this.setState({ addPaymentMethodError: "Please agree to the terms." });
            return;
        }
        this.props.actions.loadLoadingSpinner(true);
        const cardElement = elements.getElement(CardElement);
        let setupIntentData = await Api.createSetupIntent();
        let result = await stripe.confirmCardSetup(setupIntentData.data.client_secret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: this.state.addPaymentMethodName,
                },
            }
        });
        if (result.error) {
            this.setState({ addPaymentMethodError: result.error.message });
            this.props.actions.loadLoadingSpinner(false);
        } else {
            try {
                this.props.triggerNotification("Payment method added.", "success", "bc", 4);
                this.setState({ addPaymentMethodVisible: false });
                this.getPaymentMethods();
            } catch(err) {
                this.props.triggerNotification("An unexpected error occured whilst trying to add your payment method.", "danger", "bc", 4);
                this.props.actions.loadLoadingSpinner(false);
            }
        }
    }

    renderNewPaymentMethodModal(e) {
        return (
            <Elements stripe={stripe}>
                <ElementsConsumer>
                    {({elements, stripe}) => 
                        <Modal
                            isOpen={this.state.addPaymentMethodVisible}
                            toggle={() => this.setState({ addPaymentMethodVisible: false })}
                            className="text-center"
                        >
                        <ModalHeader
                            className="justify-content-center uppercase title"
                            toggle={() => this.setState({ addPaymentMethodVisible: false })}
                            tag="h4"
                        >
                            New Payment Method
                        </ModalHeader>
                        <ModalBody style={{ marginTop: 10, marginBottom: 10 }}>
                            <FormGroup style={{ marginBottom: 25 }}>
                                <Input placeholder="Name On Card" type="text" style={{ fontSize: 14 }} value={this.state.addPaymentMethodName} onChange={(e) => this.setState({ addPaymentMethodName: e.target.value })} />
                            </FormGroup>
                            <FormGroup className="payment-method-input-container">
                            <CardElement
                                options={{
                                    style: {
                                    base: {
                                        fontSize: '14px',
                                        color: '#2c2c2c',
                                        fontFamily: 'Public Sans',
                                        '::placeholder': {
                                        color: '#9a9a9a',
                                        },
                                    },
                                    invalid: {
                                        color: '#ed5565',
                                    },
                                    },
                                }}
                            />
                            </FormGroup>
                            <FormGroup check style={{ marginTop: 25, paddingLeft: 0 }}>
                                <Label check>
                                    <Input type="checkbox" value={this.state.addPaymentMethodTerms ? this.state.addPaymentMethodTerms : false} onChange={(e) => this.setState({ addPaymentMethodTerms: e.target.checked }) }/>
                                    <span className="form-check-sign" />
                                    <p className="payment-method-terms">I authorise Styler to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.</p>
                                </Label>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.addNewPaymentMethod(elements, stripe)}
                            >
                                Add
                            </Button>
                            <Alert color="danger" className="alert-fullwidth" isOpen={this.state.addPaymentMethodError !== null} toggle={() => this.setState({ addPaymentMethodError: null })}>
                                <span>
                                    {this.state.addPaymentMethodError}
                                </span>
                            </Alert>
                        </ModalFooter>
                        </Modal>
                    }
                </ElementsConsumer>
            </Elements>
        );
    }

    renderSubscriptionFrequency() {
        let frequencyStr = null;
        if(this.state.subscriptionData && this.state.subscriptionData.subscription_interval === 'year'){
            frequencyStr = 'Yearly';
        } else if(this.state.subscriptionData && this.state.subscriptionData.subscription_interval === 'month'){
            frequencyStr = 'Monthly';
        } else if(this.state.subscriptionData && this.state.subscriptionData.subscription_interval === 'week'){
            frequencyStr = 'Weekly';
        } else if(this.state.subscriptionData && this.state.subscriptionData.subscription_interval === 'day'){
            frequencyStr = 'Daily';
        }
        return (
            <div className="info">
                <div className="icon icon-primary">
                    <i className="now-ui-icons loader_refresh" />
                </div>
                <h6 className="stats-title billing-metric-title">Frequency</h6>
                <h3 className="billing-metric-info">
                    {frequencyStr ? frequencyStr : '----'}
                </h3>
            </div>
        );
    }

    renderSubscriptionStatus() {
        let statusStr = null;
        if(this.state.subscriptionData && this.state.subscriptionData.subscription_status === 'active'){
            statusStr = 'Active';
        } else if(this.state.subscriptionData && this.state.subscriptionData.subscription_status === 'past_due'){
            statusStr = 'Past Due';
        } else if(this.state.subscriptionData && this.state.subscriptionData.subscription_status === 'canceled'){
            statusStr = 'Cancelled';
        } else if(this.state.subscriptionData && this.state.subscriptionData.subscription_status === 'unpaid'){
            statusStr = 'Unpaid';
        } else if((this.state.subscriptionData && this.state.subscriptionData.subscription_status === 'incomplete_expired') || (this.state.subscriptionData && this.state.subscriptionData.subscription_status === 'incomplete')){
            statusStr = 'Incomplete';
        }
        return (
            <div className="info">
                <div className="icon icon-primary">
                    <i className="now-ui-icons media-2_sound-wave" />
                </div>
                <h6 className="stats-title billing-metric-title">Plan Status</h6>
                <h3 className="billing-metric-info">
                    {statusStr ? statusStr : '--'}
                </h3>
            </div>
        );
    }

    renderSubscriptionAmount() {
        let currencyObj = this.state.subscriptionData ? currency.find(x => x.iso === this.state.subscriptionData.subscription_currency.toUpperCase()) : null;
        return (
            <div className="info">
                <div className="icon icon-primary">
                    <i className="now-ui-icons business_money-coins" />
                </div>
                <h6 className="stats-title billing-metric-title">Plan</h6>
                {this.state.subscriptionData && this.state.subscriptionData.subscription_discount_name ?
                    <h3 className="billing-metric-info">
                        <small>{currencyObj ? currencyObj.symbol : '£'}</small>{this.state.subscriptionData && this.state.subscriptionData.subscription_amount ? Number((this.state.subscriptionData.subscription_amount - this.state.subscriptionData.subscription_discount_amount) / 100) : '--'}
                    </h3>
                :
                    <h3 className="billing-metric-info">
                        <small>{currencyObj ? currencyObj.symbol : '£'}</small>{this.state.subscriptionData && this.state.subscriptionData.subscription_amount ? Number(this.state.subscriptionData.subscription_amount / 100) : '--'}
                    </h3>
                }
            </div>
        );
    }

    render() {
        let currencyObj = this.state.subscriptionData ? currency.find(x => x.iso === this.state.subscriptionData.subscription_currency.toUpperCase()) : null;
        const editPermission = this.props.userRole.billing.update === true;
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col xs={12} md={12}>
                        <Card className="card-stats card-raised">
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <div className="statistics">
                                            {this.renderSubscriptionAmount()}
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className="statistics">
                                            {this.renderSubscriptionStatus()}
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className="statistics">
                                            {this.renderSubscriptionFrequency()}
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className="statistics">
                                            {this.renderDefaultCard()}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {this.state.subscriptionData && this.state.subscriptionData.subscription_discount_name ?
                            <Alert color="primary" className="alert-fullwidth discount-alert" isOpen={true}>
                                <span>
                                    <span className="text-success">{this.state.subscriptionData.subscription_discount_name}</span>  discount applied. <span>{currencyObj ? currencyObj.symbol : '£'}{Number(this.state.subscriptionData.subscription_discount_amount / 100).toFixed(2)} off every {this.state.subscriptionData.subscription_interval}.</span>
                                </span>
                            </Alert>
                        : null}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <h3 className="payment-methods-header">Payment Methods</h3>
                    </Col>
                    <Col xs={12} md={12}>
                        <div className="payment-methods-container">
                            {this.state.paymentMethodData.map((paymentMethodObj, paymentMethodIndex) => {
                                let cardExpiry = null;
                                if(paymentMethodObj.exp_month < 10){
                                    cardExpiry = '0' + paymentMethodObj.exp_month + paymentMethodObj.exp_year
                                } else {
                                    cardExpiry = '' + paymentMethodObj.exp_month + paymentMethodObj.exp_year
                                }
                                let cardBrand = null;
                                if(paymentMethodObj.brand === 'Visa' || paymentMethodObj.brand === 'visa') {
                                    cardBrand = 'visa';
                                } else if(paymentMethodObj.brand === 'American Express' || paymentMethodObj.brand === 'amex') {
                                    cardBrand = 'amex';
                                } else if(paymentMethodObj.brand === 'MasterCard' || paymentMethodObj.brand === 'mastercard') {
                                    cardBrand = 'mastercard';
                                } else {
                                    cardBrand = 'unknown';
                                }
                                return (
                                    <div className="payment-method-container" key={'paymentMethod' + paymentMethodIndex}>
                                        <Cards
                                            preview={true}
                                            issuer={cardBrand}
                                            cvc={''}
                                            expiry={cardExpiry}
                                            focused={''}
                                            name={paymentMethodObj.name ? paymentMethodObj.name : '********'}
                                            number={(cardBrand === 'amex' ? '●●●●●●●●●●●' : '●●●●●●●●●●●●') + paymentMethodObj.last4}
                                        />
                                        {editPermission && (
                                            <Button color={this.state.paymentMethodDefault === paymentMethodObj.id ? 'success' : 'primary'} size="sm" onClick={() => this.setDefaultPaymentMethod(paymentMethodObj.id)}>
                                                {this.state.paymentMethodDefault === paymentMethodObj.id ? 'Default' : 'Set Default'}
                                            </Button>
                                        )}
                                        {this.state.paymentMethodDefault !== paymentMethodObj.id && editPermission ? 
                                            <Button color="danger" size="sm" onClick={() => this.deletePaymentMethod(paymentMethodObj.id)}>
                                                <FiX/>
                                            </Button>
                                        : null}
                                    </div>
                                );
                            })}
                            {editPermission && (
                                <div className="payment-method-container">
                                    <div className="payment-card-mockup" style={this.state.paymentMethodData && this.state.paymentMethodData.length > 0 ? { marginBottom: 28 } : null}>
                                        <Button color="primary" className="btn-round btn-icon payment-card-mockup-button" outline onClick={() => this.setState({ addPaymentMethodVisible: true })}>
                                            <i className="now-ui-icons ui-1_simple-add" />
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <h3 className="invoices-header">Invoices</h3>
                    </Col>
                    <Col xs={12} md={12}>
                        <Card className="card-stats card-raised">
                            <CardBody>
                            <ReactTable
                                data={this.state.invoiceData ? this.state.invoiceData : []}
                                pagination={false}
                                columns={[
                                {
                                    Header: "Invoice Date",
                                    accessor: "invoice_date",
                                    disableFilters: true,
                                    Cell: props => <span><strong>{this.state.timezoneObj ? moment.unix(props.value).tz(this.state.timezoneObj.value).format('Do MMM YY') : moment.unix(props.value).format('Do MMM YY')}</strong></span>
                                },
                                {
                                    Header: "Invoice Id",
                                    accessor: "invoice_id",
                                    disableFilters: true,
                                },
                                {
                                    Header: "Status",
                                    accessor: "status",
                                    disableFilters: true,
                                    Cell: props => <span>{props.value ? <Badge color="success">Paid</Badge> : <Badge color="danger">Unpaid</Badge>}</span>
                                },
                                {
                                    Header: <span className="pull-right">Total</span>,
                                    accessor: "total",
                                    disableFilters: true,
                                    disableSortBy: true,
                                    Cell: props => <span className="pull-right">{props.row.original.currency_symbol + Number(props.value / 100).toFixed(2)}</span>
                                },
                                /*{
                                    Header: "Actions",
                                    accessor: "actions",
                                    disableFilters: true,
                                },*/
                                ]}
                            />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.renderNewPaymentMethodModal()}
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        userRole: state.userRole,
        business: state.business
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing);