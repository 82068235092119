// Unauthenticated pages
import LoginPage from "views/Pages/LoginPage.js";
  
const routes = [
    {
        path: "login-page",
        name: "Login Page",
        component: LoginPage
    }
];
  
export default routes;