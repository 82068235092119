import React from 'react';
import { BsShop } from 'react-icons/bs';
 
function BusinessMapPin(props) {
 
    return (
        <div>
            <div className={`business-map-pin ${props.hovered ? 'hover' : ''}`}>
                <BsShop size={16} color={props.hovered ? '#0a122b' : '#ffffff'}/>
            </div>
        </div>
    );
}
 
export default BusinessMapPin;