/*eslint-disable*/
import React from "react";
import { Button } from "reactstrap";

import { FiPlus } from 'react-icons/fi';

class BookingServiceAdd extends React.Component {

    render() {
        return (
            <li className="timeline-inverted timeline-add">
                <div className="timeline-badge">
                    <FiPlus size={26} color={'#0a122b'}/>
                </div>
                <div className="timeline-panel">
                    <div className="timeline-body">
                        <Button
                            outline
                            color="primary"
                            style={{ width: '100%', margin: 0 }}
                            onClick={() => this.props.addBookingService()}
                        >
                            Add Service
                        </Button>
                    </div>
                </div>
            </li>
        );
    }
}

export default BookingServiceAdd;