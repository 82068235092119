import React from "react";

// reactstrap components
import {
    Row,
    Col
} from "reactstrap";
import {
    Grid,
    CircularProgress,
    Stack,
    IconButton
} from "@mui/material";
// Icons
import { Plus } from "@phosphor-icons/react";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Card from "components/Material/Card";
import StaffAccountsGrid from "components/Staff/StaffAccountsGrid";
import StaffAccountCreate from "components/Staff/StaffAccountCreate";
// Redux
import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";
// Utilities
import withRouter from "utilities/withRouter";

import Api from '../../api/index';

import businessUserRoleType from "constants/businessUserRoleType";

class StaffAccounts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            userData: [],
            staffAccountCreateOpen: false
        };
    }

    componentDidMount() {
        this.getBusinessUserData();
    }

    async getBusinessUserData() {
        try {
            const { data } = await Api.getBusinessUsers();
            this.setState({ userData: data.businessUserData, loading: false });
        } catch(e) {
            this.props.triggerNotification("Unable to load account data - please contact Support if the problem persists.", "danger");
        }
    }

    handleChange = async (businessUserId, dataKey, dataVal) => {
        const businessUserObj = this.state.userData.find(x => x.business_user_id === businessUserId);
        if(businessUserObj[dataKey] === dataVal) {
            return;
        }
        try {
            await Api.putBusinessUser({
                business_user_id: businessUserId,
                [dataKey]: dataVal
            });
        } catch(e) {
            this.props.triggerNotification("Unable to update account - please contact Support if the problem persists.", "danger");
            return
        }
        // Update local state
        const userData = [...this.state.userData];
        const userIndex = userData.findIndex(x => x.business_user_id === businessUserId);
        if(userIndex > -1) {
            userData[userIndex][dataKey] = dataVal;
            this.setState({ userData });
        }
        // Trigger notification
        let notificationMessage;
        switch(dataKey) {
            case "enabled":
                notificationMessage = `User ${dataVal ? "enabled" : "disabled"}.`;
                break;
            case "business_user_role_type_id":
                let permissionLevel = businessUserRoleType.find(x => x.id === dataVal);
                notificationMessage = `User permission level changed to ${permissionLevel.label}.`;
                break;
            default:
                notificationMessage = "User updated";
        }
        this.props.triggerNotification(notificationMessage, "success");
    }

    toggleStaffAccountCreate = () => {
        this.setState({
            staffAccountCreateOpen: !this.state.staffAccountCreateOpen
        });
    }

    handleStaffAccountCreate = (businessUserId, businessUserRoleTypeId, staffId) => {
        const userData = [...this.state.userData]
        userData.push({
            business_user_id: businessUserId,
            business_user_role_type_id: businessUserRoleTypeId,
            enabled: true,
            staff_id: staffId
        });
        this.setState({ userData });
        this.toggleStaffAccountCreate();
        this.props.triggerNotification("Staff account created successfully.", "success");
    }

    render() {
        if(this.props.userRole.permission.update === false) {
            return null;
        }
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={8} xl={6} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Accounts</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Grid container>
                    <Grid item xl={6} lg={8} md={10} sm={12} xs={12} sx={{ mx: 'auto' }}>
                        <Card>
                            {!this.state.loading && (
                                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                                    <div></div>
                                    <IconButton aria-label="create" onClick={this.toggleStaffAccountCreate}>
                                        <Plus weight="bold" />
                                    </IconButton>
                                </Stack>
                            )}
                            <StaffAccountsGrid
                                staffData={this.props.staff}
                                userData={this.state.userData}
                                handleChange={this.handleChange}
                            />
                            {this.state.loading && <div style={{ textAlign: 'center', marginTop: 20 }}><CircularProgress color="secondary"/></div>}
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <StaffAccountCreate
                open={this.state.staffAccountCreateOpen}
                toggleOpen={this.toggleStaffAccountCreate}
                staffData={this.props.staff}
                userData={this.state.userData}
                onCreate={this.handleStaffAccountCreate}
            />
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        userRole: state.userRole,
        staff: state.staff
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StaffAccounts));