import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import α from 'color-alpha';
import classNames from "classnames";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input,
} from "reactstrap";
import {
  Breadcrumbs, Link
} from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { FiMapPin } from 'react-icons/fi';

import BusinessLocationPicker from "components/Business/BusinessLocationPicker.js";

import withRouter from "utilities/withRouter";

class AdminNavbar extends React.Component {
  state = {
    isOpen: false,
    dropdownOpen: false,
    locationPickerOpen: false,
    color: "transparent",
    searchInput: null
  };
  sidebarToggle = React.createRef();
  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "white",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  dropdownToggle = (e) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "white",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }

  getBreadcrumbs = () => {
    const { routes, navigate } = this.props;
    const pathname = window.location.pathname;
    const routeObj = routes.find(x => pathname === `/${x.layout}/${x.path}`);
    if(!routeObj) {
      return;
    }
    const startTextColor = this.props.business.hub_header_gradient_start_text ? (
      this.props.business.hub_header_gradient_start_text
    ) : '#ffffff';
    return (
      <Breadcrumbs separator={<div style={{ color: this.props.business.hub_header_gradient_start_text }}>•</div>}>
        {routeObj.path.split("/").slice(0, -1).map((routeParent) => {
          return (
            <Link
              underline="none"
              component="button"
              sx={{ textTransform: 'uppercase', color: α(startTextColor,.75) }}
              onClick={(e) => { e.preventDefault(); navigate(routeParent); } }
            >
              {routeParent}
            </Link>
          );
        })}
        <Link
          underline="none"
          component="button"
          onClick={(e) => e.preventDefault()}
          sx={{ textTransform: 'uppercase', color: this.props.business.hub_header_gradient_start_text }}
        >
          {routeObj.name}
        </Link>
      </Breadcrumbs>
    );
  }

  handleSearchInputChange(e) {
    this.setState({ searchInput: e.target.value });
  }
  submitSearchInput(e) {
    if (e && e.key === 'Enter') {
      e.preventDefault();
      const { navigate } = this.props;
      let searchPath = "search";
      if(this.state.searchInput?.length > 0) {
        searchPath += `?query=${this.state.searchInput}`; 
      }
      navigate(searchPath);
      this.setState({ searchInput: null });
    }
  }

  renderLocationSelector() {
    if(window.location.href.indexOf("home") !== -1
      && this.props.businessLocation
      && this.props.businessLocation.filter(x => x.enabled === 1).length > 1
      && this.props.settings.selected_business_location_id) {
      let businessLocationObj = this.props.businessLocation.find(x => x.business_location_id === this.props.settings.selected_business_location_id);
      return (
        <div className="navbar-location-selector" onClick={() => this.setState({ locationPickerOpen: true })}>
          <FiMapPin size={13} style={{ marginRight: 3 }} /> {businessLocationObj ? businessLocationObj.business_location_name : '----'}
        </div>
      );
    }
  }

  render() {
    const { navigate } = this.props;
    const isFullScreen = this.props.isFullScreen();
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={isFullScreen ? this.state.color : "white"}
        expand="lg"
        className={classNames("navbar-absolute", !isFullScreen && this.state.color === "transparent" ? "navbar-transparent" : null)}
        style={
          !this.state.isOpen && isFullScreen ? (
            { background: 'linear-gradient(160deg, ' + this.props.business.hub_header_gradient_start + ' 0%, ' + this.props.business.hub_header_gradient_end + ' 100%)' }
          ) : null}
      >
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span
                  className="navbar-toggler-bar bar1"
                  style={this.props.business.hub_header_gradient_start_text && !this.state.isOpen ? {
                    backgroundColor: this.props.business.hub_header_gradient_start_text
                  } : null}
                />
                <span
                  className="navbar-toggler-bar bar2"
                  style={this.props.business.hub_header_gradient_start_text && !this.state.isOpen ? {
                    backgroundColor: this.props.business.hub_header_gradient_start_text
                  } : null}
                />
                <span
                  className="navbar-toggler-bar bar3"
                  style={this.props.business.hub_header_gradient_start_text && !this.state.isOpen ? {
                    backgroundColor: this.props.business.hub_header_gradient_start_text
                  } : null}
                />
              </button>
            </div>
            <NavbarBrand
              style={this.props.business.hub_header_gradient_start_text && !this.state.isOpen ? {
                color: this.props.business.hub_header_gradient_start_text
              } : null}
            >
              <div style={{ display: 'flex' }}>
                <div>{this.getBreadcrumbs()}</div>
                <div>{this.renderLocationSelector()}</div>
              </div>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span
              className="navbar-toggler-bar navbar-kebab"
              style={this.props.business.hub_header_gradient_end_text && !this.state.isOpen ? {
                backgroundColor: this.props.business.hub_header_gradient_end_text
              } : null}
            />
            <span
              className="navbar-toggler-bar navbar-kebab"
              style={this.props.business.hub_header_gradient_end_text && !this.state.isOpen ? {
                backgroundColor: this.props.business.hub_header_gradient_end_text
              } : null}
            />
            <span
              className="navbar-toggler-bar navbar-kebab"
              style={this.props.business.hub_header_gradient_end_text && !this.state.isOpen ? {
                backgroundColor: this.props.business.hub_header_gradient_end_text
              } : null}
            />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            <form>
              <InputGroup className="no-border">
                <Input
                  placeholder="Search..."
                  value={this.state.searchInput ? this.state.searchInput : ''}
                  onChange={(e) => this.handleSearchInputChange(e)}
                  onKeyDown={(e) => this.submitSearchInput(e)}
                  style={!this.state.isOpen && this.props.business.hub_header_gradient_end_text ? {
                    color: this.props.business.hub_header_gradient_end_text,
                    backgroundColor: α(this.props.business.hub_header_gradient_end_text, .3)
                  } : null}
                />
              </InputGroup>
            </form>
            <Nav navbar>
              <NavItem>
                <NavLink href="https://whatstyle.statuspage.io" target="_blank" className="nav-link">
                  <i
                    className="now-ui-icons media-2_sound-wave"
                    style={!this.state.isOpen && this.props.business.hub_header_gradient_end_text ? {
                      color: this.props.business.hub_header_gradient_end_text,
                    } : null}
                  />
                  <p>
                    <span className="d-lg-none d-md-block">System Status</span>
                  </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://help.styler.digital" target="_blank" className="nav-link">
                  <i
                    className="now-ui-icons travel_info"
                    style={!this.state.isOpen && this.props.business.hub_header_gradient_end_text ? {
                      color: this.props.business.hub_header_gradient_end_text,
                    } : null}
                  />
                  <p>
                    <span className="d-lg-none d-md-block">Help Centre</span>
                  </p>
                </NavLink>
              </NavItem>
              <Dropdown
                nav
                isOpen={this.state.dropdownOpen}
                toggle={(e) => this.dropdownToggle(e)}
              >
                <DropdownToggle
                  caret
                  nav
                  style={!this.state.isOpen && this.props.business.hub_header_gradient_end_text ? {
                    color: this.props.business.hub_header_gradient_end_text,
                  } : null}
                >
                  <i className="now-ui-icons users_single-02" />
                  <p>
                    <span className="d-lg-none d-md-block">Account Options</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu end className="dropdown-menu-right">
                  <DropdownItem onClick={() => navigate("account")}>Account</DropdownItem>
                  {this.props.userRole?.billing?.read === true && (
                    <DropdownItem onClick={() => navigate("billing")}>Billing</DropdownItem>
                  )}
                  <DropdownItem onClick={() => navigate("referrals")}>Referrals</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        <BusinessLocationPicker
          visible={this.state.locationPickerOpen}
          toggleVisible={() => this.setState({ locationPickerOpen: !this.state.locationPickerOpen })}
          onSubmit={() => this.setState({ locationPickerOpen: false })}
        />
      </Navbar>
    );
  }
}

AdminNavbar.defaultProps = {
  brandText: "Default Brand Text",
};

AdminNavbar.propTypes = {
  // string for the page name
  brandText: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
      business: state.business,
      businessLocation: state.businessLocation,
      settings: state.settings,
      userRole: state.userRole
  };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminNavbar));