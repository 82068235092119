/*eslint-disable*/
import React from "react";

// reactstrap components
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Alert, Input, FormGroup, Spinner } from "reactstrap";
import Api from "api";

class CustomerPushNotification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            success: false,
            error: null,
            loading: false,
            title: null,
            body: null,
            tokenCount: null
        };
    }

    componentDidMount() {
        this.getCustomerPushTokens();
    }

    async getCustomerPushTokens() {
        try {
            this.setState({ loading: true });
            let customerPushTokenResponse = await Api.getCustomerPushTokens({ customer_id: this.props.customerId });
            this.setState({ loading: false, tokenCount: customerPushTokenResponse.data.customerTokenData });
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst loading the customer push tokens.", "danger", "bc", 4);
            this.setState({ loading: false });
        }
    }

    handleInputChange(e, name) {
        const inputValue = e.target.value;
        if(name === 'title'){
            this.setState({ title: inputValue });
        } else if(name === 'body'){
            this.setState({ body: inputValue });
        }
    }

    async sendPushNotification() {
        if(this.state.loading || this.state.success){
            return;
        }
        if(!this.state.title) {
            this.setState({ error: 'A title is required.' });
            return;
        }
        if(!this.state.body) {
            this.setState({ error: 'A message is required.' });
            return;
        }
        if(this.state.title && this.state.title.length > 40) {
            this.setState({ error: 'The character limit for the title is 40 characters.' });
            return;
        }
        if(this.state.body && this.state.body.length > 150) {
            this.setState({ error: 'The character limit for the message is 150 characters.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            await Api.sendCustomerPushNotification({ customer_id: this.props.customerId, title: this.state.title, body: this.state.body });
            this.setState({ loading: false, success: true });
        } catch(err) {
            this.setState({ loading: false, error: 'Unable to send push notification. If the problem persists, please contact Support.' });
        }
    }

    render() {
        return (
        <>
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggleVisible}>
            <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Send Push Notification</ModalHeader>
            {this.state.tokenCount && this.state.tokenCount > 0 ?
                <ModalBody>
                    <p className="text-center text-muted" style={{ fontSize: 13 }}>
                        The character limit is 40 characters for the title and 150 characters for the message.
                        This limit ensures that the full push notification is displayed on most devices and operating systems.
                    </p>
                    <hr/>
                    <Alert color="success" className="alert-fullwidth" isOpen={true} style={{ fontSize: 13 }}>
                        <span>
                            This customer is registered to receive push notifications on {this.state.tokenCount} device/s.
                        </span>
                    </Alert>
                    <label>Title</label>
                    <FormGroup>
                        <Input
                            type="text"
                            placeholder="Title"
                            onChange={(e) => this.handleInputChange(e, 'title')}
                            disabled={this.state.loading || this.state.success}
                        />
                        <div className={"pull-right " + (this.state.title && this.state.title.length > 40 ? "text-danger" : "text-muted")}>{this.state.title ? 40 - this.state.title.length : 40}</div>
                    </FormGroup>
                    <label>Message</label>
                    <FormGroup>
                        <Input
                            type="textarea"
                            placeholder="Message"
                            onChange={(e) => this.handleInputChange(e, 'body')}
                            disabled={this.state.loading || this.state.success}
                        />
                        <div className={"pull-right " + (this.state.body && this.state.body.length > 150 ? "text-danger" : "text-muted")}>{this.state.body ? 150 - this.state.body.length : 150}</div>
                    </FormGroup>
                </ModalBody>
            :
                <ModalBody>
                    <Alert color="danger" className="alert-fullwidth" isOpen={true} style={{ fontSize: 13 }}>
                        <span>
                            This customer is not registered to receive push notifications on any devices.
                        </span>
                    </Alert>
                </ModalBody>
            }
            {this.state.tokenCount && this.state.tokenCount > 0 ?
                <ModalFooter>
                    <Button
                        color={this.state.success ? "success" : "primary"}
                        style={{ width: '100%' }}
                        onClick={() => this.sendPushNotification()}
                    >
                        {this.state.loading ? <Spinner color="success" size="sm" /> : this.state.success ? "Push Notification Sent" : "Send Push Notification"}
                    </Button>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })} style={{ fontSize: 13 }}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            : null}
            </Modal>
        </>
        );
    }
}

export default CustomerPushNotification;