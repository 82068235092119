import React from 'react';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';
import './Clock.css';

function ClockComponent(props) {
  return (
    <div>
      <Clock
        value={new Date(props.curTime)}
        size={65}
        secondHandWidth={2}
        hourHandWidth={3}
        renderMinuteMarks={false}
        hourMarksWidth={2}
        hourMarksLength={8}
        secondHandLength={85}
      />
    </div>
  )
}

export default ClockComponent;