/*eslint-disable*/
import React from "react";

// reactstrap components
import { Modal, ModalBody, ModalHeader, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { FiPlus, FiUsers, FiSettings } from 'react-icons/fi';

class CalendarMinifiedOptions extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        // Access permissions
        const createBooking = this.props.userRole?.booking?.create === true;
        const createBreak = this.props.userRole?.break?.create === true;
        return (
        <>
            <Modal
                isOpen={this.props.visible}
                toggle={() => this.props.toggleVisible()}
                className="text-center"
            >
                <ModalHeader
                    className="justify-content-center uppercase title"
                    toggle={() => this.props.toggleVisible()}
                    tag="h4"
                >
                    Options
                </ModalHeader>
                <ModalBody>
                    {(createBooking || createBreak) && (
                        <>
                            <UncontrolledDropdown>
                                <DropdownToggle block className="btn-block" color="primary">
                                    <FiPlus size={16} color={'#1ab394'}/> Add
                                </DropdownToggle>
                                <DropdownMenu end>
                                    {createBooking && <DropdownItem onClick={() => this.props.handleOption('add', 'booking')}>Booking</DropdownItem>}
                                    {createBooking && <DropdownItem onClick={() => this.props.handleOption('add', 'walk-in')}>Walk-in</DropdownItem>}
                                    {createBreak && <DropdownItem onClick={() => this.props.handleOption('add', 'break')}>Break</DropdownItem>}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <hr/>
                        </>
                    )}
                    <UncontrolledDropdown>
                        <DropdownToggle block className="btn-block btn-outline-primary" caret>
                            Calendar View
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>Calendar</DropdownItem>
                            <DropdownItem onClick={() => this.props.handleOption('view', 'resourceTimeGridDay')}>Day</DropdownItem>
                            <DropdownItem onClick={() => this.props.handleOption('view', 'resourceTimeGridThreeDay')}>3 Day</DropdownItem>
                            <DropdownItem onClick={() => this.props.handleOption('view', 'resourceTimeGridWeek')}>Week</DropdownItem>
                            <DropdownItem onClick={() => this.props.handleOption('view', 'dayGridMonth')}>Month</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem header>List</DropdownItem>
                            <DropdownItem onClick={() => this.props.handleOption('view', 'listDay')}>List Day</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <Button block className="btn-block btn-outline-primary" color="primary" onClick={() => this.props.handleOption('staff', null)}><FiUsers size={16} style={{ marginRight: 5 }}/>Staff</Button>
                    <Button block className="btn-block btn-outline-primary" color="primary" onClick={() => this.props.handleOption('settings', null)}><FiSettings size={16} style={{ marginRight: 5 }}/>Settings</Button>
                </ModalBody>
            </Modal>
        </>
        );
    }
}

export default CalendarMinifiedOptions;