import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";

class StaffScheduleKey extends React.Component {

    render() {
        return (
            <Modal size={"sm"} isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Guide</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <div className="schedule-key-row mb-2">
                                <div>
                                    <div>Regular Hours</div>
                                </div>
                                <div className="schedule-key-box"></div>
                            </div>
                            <div className="schedule-key-row mb-2">
                                <div>
                                    <div>Amended Hours</div>
                                </div>
                                <div className="schedule-key-box amended-cell"></div>
                            </div>
                            <div className="schedule-key-row mb-2">
                                <div>
                                    <div>Staff Holiday</div>
                                </div>
                                <div className="schedule-key-box holiday-cell"></div>
                            </div>
                            <div className="schedule-key-row mb-2">
                                <div>
                                    <div>Staff Not Working</div>
                                </div>
                                <div className="schedule-key-box not-working-cell"></div>
                            </div>
                            <div className="schedule-key-row">
                                <div>
                                    <div>Business Closed</div>
                                </div>
                                <div className="schedule-key-box closed-cell"></div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }

}

export default StaffScheduleKey;