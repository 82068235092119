import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Button,
    Input,
    FormGroup,
    FormText,
    InputGroup
} from "reactstrap";
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import Compressor from 'compressorjs';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ImageCropper from "components/ImageCropper/ImageCropper";
import CardMap from "components/Maps/CardMap.js";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import { FaInstagram } from "react-icons/fa";
import { FiGlobe, FiImage } from "react-icons/fi";
// API
import Api from 'api/index';
// Constants
import currency from '../../../constants/currency';
import timezone from '../../../constants/timezone';
import country from '../../../constants/country';
import { CDN_URL } from "constants/urls";
// Utilities
import withRouter from "utilities/withRouter";
import customValidation from 'utilities/customValidation';

class BusinessSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            businessName: null,
            businessDescription: null,
            businessUnit: null,
            businessStreetNumber: null,
            businessStreetName: null,
            businessCity: null,
            businessState: null,
            businessPostcode: null,
            businessCountry: null,
            businessLat: this.props.business.address_latitude,
            businessLng: this.props.business.address_longitude,
            businessCurrency: null,
            businessTimezone: null,
            businessEmail: null,
            businessContactNumber: null,
            businessContactNumberIso: null,
            imageCropperVisible: false,
            imageCropperType: null,
            logoFile: null,
            logoFileCropped: null,
            logoState: 0,
            coverImageFile: null,
            coverImageFileCropped: null,
            coverImageState: 0,
        };
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleLatLngChange = this.handleLatLngChange.bind(this);
        this.submitCroppedImage = this.submitCroppedImage.bind(this);
    };

    componentDidMount() {
        this.props.actions.loadBusinessAuto();
        this.setState({
            businessName: this.props.business.business_name,
            businessDescription: this.props.business.description,
            businessUnit: this.props.business.address_unit_name,
            businessStreetNumber: this.props.business.address_street_number,
            businessStreetName: this.props.business.address_street_name,
            businessCity: this.props.business.address_city,
            businessState: this.props.business.address_state,
            businessPostcode: this.props.business.address_postal_code,
            businessCountry: this.props.business.address_country,
            businessCurrency: this.props.business.currency_id,
            businessTimezone: this.props.business.timezone_id,
            businessEmail: this.props.business.business_email,
            businessContactNumber: this.props.business.business_phone_no,
            businessContactNumberIso: this.props.business.business_phone_no_country_iso,
            businessWebsite: this.props.business.business_website,
            businessInstagram: this.props.business.business_instagram,
            logoFileCropped: this.props.business.business_logo ? `${CDN_URL}/` + this.props.business.business_logo : null,
            coverImageFileCropped: this.props.business.business_img ? `${CDN_URL}/` + this.props.business.business_img : null,
        });
    }

    handleChange(e, name) {
        switch(name) {
            case "name":
                this.setState({ businessName: e.target.value });
                break;
            case "description":
                this.setState({ businessDescription: e.target.value });
                break;
            case "unit":
                this.setState({ businessUnit: e.target.value });
                break;
            case "streetNumber":
                this.setState({ businessStreetNumber: e.target.value });
                break;
            case "streetName":
                this.setState({ businessStreetName: e.target.value });
                break;
            case "city":
                this.setState({ businessCity: e.target.value });
                break;
            case "state":
                this.setState({ businessState: e.target.value });
                break;
            case "postCode":
                this.setState({ businessPostcode: e.target.value });
                break;
            case "country":
                this.setState({ businessCountry: e.label });
                break;
            case "currency":
                this.setState({ businessCurrency: e.id });
                break;
            case "timezone":
                this.setState({ businessTimezone: e.id });
                break;
            case "email":
                this.setState({ businessEmail: e.target.value });
                break;
            case "contactNumber":
                this.setState({ businessContactNumber: e[0], businessContactNumberIso: e[1].countryCode });
                break;
            case "website":
                this.setState({ businessWebsite: e.target.value });
                break;
            case "instagram":
                this.setState({ businessInstagram: e.target.value });
                break;
            default:
                return;
        }
    }

    handleAddressChange(placeObj) {
        if(!placeObj.address_components) {
            return;
        }
        let businessStreetNumber = placeObj.address_components.find(x => x.types.includes('street_number'));
        let businessStreetName = placeObj.address_components.find(x => x.types.includes('route'));
        let businessCity = placeObj.address_components.find(x => x.types.includes('postal_town'));
        let businessState = placeObj.address_components.find(x => x.types.includes('administrative_area_level_2'));
        let businessPostcode = placeObj.address_components.find(x => x.types.includes('postal_code'));
        let businessCountry = placeObj.address_components.find(x => x.types.includes('country'));
        this.setState({
            businessUnit: null,
            businessStreetNumber: businessStreetNumber ? businessStreetNumber.long_name : null,
            businessStreetName: businessStreetName ? businessStreetName.long_name : null,
            businessCity: businessCity ? businessCity.long_name : null,
            businessState: businessState ? businessState.long_name : null,
            businessPostcode: businessPostcode ? businessPostcode.long_name : null,
            businessCountry: businessCountry ? businessCountry.long_name : null,
            businessLat: placeObj.geometry.location.lat(),
            businessLng: placeObj.geometry.location.lng()
        });
    }

    handleLatLngChange(lat, lng) {
        this.setState({
            businessLat: lat,
            businessLng: lng
        });
    }

    async submitBusinessSettings() {
        if(!this.state.businessName || this.state.businessName.length === 0) {
            this.props.triggerNotification("A business name is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessStreetName || this.state.businessStreetName.length === 0) {
            this.props.triggerNotification("Address incomplete - a street name is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessCity || this.state.businessCity.length === 0) {
            this.props.triggerNotification("Address incomplete - a city is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessState || this.state.businessState.length === 0) {
            this.props.triggerNotification("Address incomplete - a state/county is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessPostcode || this.state.businessPostcode.length === 0) {
            this.props.triggerNotification("Address incomplete - a postcode is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessCountry || this.state.businessCountry.length === 0) {
            this.props.triggerNotification("Address incomplete - a country is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessCurrency || !Number.isInteger(this.state.businessCurrency)) {
            this.props.triggerNotification("A currency is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessTimezone || !Number.isInteger(this.state.businessTimezone)) {
            this.props.triggerNotification("A timezone is required.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessEmail || this.state.businessEmail.length === 0) {
            this.props.triggerNotification("An email address is required.", "danger", "bc", 4);
            return;
        }
        if(!customValidation.validateEmail(this.state.businessEmail)) {
            this.props.triggerNotification("Invalid email address.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessContactNumber || this.state.businessContactNumber.length === 0) {
            this.props.triggerNotification("A contact number is required.", "danger", "bc", 4);
            return;
        }
        if(this.state.businessWebsite && this.state.businessWebsite.length > 0 && !customValidation.validateUrl(this.state.businessWebsite)) {
            this.props.triggerNotification("Invalid website URL.", "danger", "bc", 4);
            return;
        }
        if(!this.state.businessLat || !this.state.businessLng) {
            this.props.triggerNotification("Unable to determine business address. Please search for your address and ensure that your location is displayed correctly on the map.", "danger", "bc", 10);
            return;
        }
        this.props.actions.loadLoadingSpinner(true);
        try {
            let contact_number = null;
            if(this.state.businessContactNumber && this.state.businessContactNumber.length > 0) {
                if(this.state.businessContactNumber.charAt(0) !== '+') {
                    contact_number = '+' + this.state.businessContactNumber;
                } else {
                    contact_number = this.state.businessContactNumber;
                }
            }
            let business_description = this.state.businessDescription;
            if(business_description === "") {
                business_description = null;
            }
            let business_website = this.state.businessWebsite;
            if(business_website === "") {
                business_website = null;
            }
            let business_instagram = this.state.businessInstagram;
            if(business_instagram === "") {
                business_instagram = null;
            }
            await Api.updateBusinessSettings({
                business_name: this.state.businessName,
                business_description,
                business_unit: this.state.businessUnit,
                business_street_number: this.state.businessStreetNumber,
                business_street_name: this.state.businessStreetName,
                business_city: this.state.businessCity,
                business_state: this.state.businessState,
                business_postcode: this.state.businessPostcode,
                business_country: this.state.businessCountry,
                business_latitude: Number(parseFloat(this.state.businessLat).toFixed(7)),
                business_longitude: Number(parseFloat(this.state.businessLng).toFixed(7)),
                business_currency_id: this.state.businessCurrency,
                business_timezone_id: this.state.businessTimezone,
                business_email: this.state.businessEmail,
                business_contact_number: contact_number,
                business_contact_number_iso: this.state.businessContactNumberIso,
                business_website,
                business_instagram,
                business_img: this.state.coverImageFileCropped,
                business_img_state: this.state.coverImageState,
                business_logo: this.state.logoFileCropped,
                business_logo_state: this.state.logoState
            });
            if(this.state.coverImageState !== 0 || this.state.logoState !== 0) {
                this.props.actions.loadBusinessAuto();
            } else {
                // Update Redux state
                let businessObj = Object.assign({}, this.props.business);
                businessObj.business_name = this.state.businessName;
                businessObj.description = this.state.businessDescription;
                businessObj.address_unit_name = this.state.businessUnit;
                businessObj.address_street_number = this.state.businessStreetNumber;
                businessObj.address_street_name = this.state.businessStreetName;
                businessObj.address_city = this.state.businessCity;
                businessObj.address_state = this.state.businessState;
                businessObj.address_postal_code = this.state.businessPostcode;
                businessObj.address_country = this.state.businessCountry;
                businessObj.address_latitude = Number(parseFloat(this.state.businessLat).toFixed(7));
                businessObj.address_longitude = Number(parseFloat(this.state.businessLng).toFixed(7));
                businessObj.currency_id = this.state.businessCurrency;
                businessObj.timezone_id = this.state.businessTimezone;
                businessObj.business_email = this.state.businessEmail;
                businessObj.business_phone_no = contact_number;
                businessObj.business_phone_no_country_iso = this.state.businessContactNumberIso;
                businessObj.business_website = this.state.businessWebsite;
                businessObj.business_instagram = this.state.businessInstagram;
                this.props.actions.updateBusiness(businessObj);
            }
            this.props.triggerNotification("Business settings updated successfully.", "success", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
            this.props.navigate('/admin/settings');
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while updating your business settings.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    getFormattedBusinessAddress() {
        let formatted_address = '';
        let unit_name = this.props.business.address_unit_name;
        if(unit_name && unit_name !== '') {
          formatted_address = unit_name + ', ';
        }
        let street_number = this.props.business.address_street_number;
        if(street_number && street_number !== '') {
          formatted_address = formatted_address + street_number + ' ';
        }
        let street_name = this.props.business.address_street_name;
        if(street_name && street_name !== '') {
          formatted_address = formatted_address + street_name + ', ';
        }
        let city = this.props.business.address_city;
        if(city && city !== '') {
          formatted_address = formatted_address + city + ', ';
        }
        let state = this.props.business.address_state;
        if(state && state !== '') {
          formatted_address = formatted_address + state + ', ';
        }
        let country = this.props.business.address_country;
        if(country && country !== '') {
          formatted_address = formatted_address + country + ', ';
        }
        let postal_code = this.props.business.address_postal_code;
        if(postal_code && postal_code !== '') {
          formatted_address = formatted_address + postal_code;
        }
        return formatted_address;
    }

    logoImgClick = (e) => {
        if(e.defaultPrevented) return
        this.logoImgInput.click();
    }

    coverImgClick = (e) => {
        if(e.defaultPrevented) return
        this.coverImgInput.click();
    }

    onLogoFileChange = async (e) => {
        if(e.target && e.target.files && e.target.files.length === 1) {
            const imgFile = e.target.files[0];
            let imageDataUrl = await this.readFile(imgFile);
            this.setState({ logoFile: imageDataUrl, imageCropperVisible: true, imageCropperType: 'logo' });
        }
    }

    onCoverImageFileChange = async (e) => {
        if(e.target && e.target.files && e.target.files.length === 1) {
            const imgFile = e.target.files[0];
            let imageDataUrl = await this.readFile(imgFile);
            this.setState({ coverImageFile: imageDataUrl, imageCropperVisible: true, imageCropperType: 'cover' });
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            this.getNormalizedFile(file).then(normalizedFile => reader.readAsDataURL(normalizedFile)).catch(error => reject(error));
        });
    }

    getNormalizedFile(file) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 1000,
                maxHeight: 1000,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    }

    toggleImageCropper = () => {
        this.setState({ imageCropperVisible: !this.state.imageCropperVisible });
    }

    submitCroppedImage(file) {
        if(this.state.imageCropperType === 'logo') {
            let logoState = this.state.logoState;
            if(logoState === 0 || logoState === 3) {
                logoState = 2;
            }
            this.setState({ logoFileCropped: file, imageCropperVisible: false, logoState });
        } else {
            let coverImageState = this.state.coverImageState;
            if(coverImageState === 0 || coverImageState === 3) {
                coverImageState = 2;
            }
            this.setState({ coverImageFileCropped: file, imageCropperVisible: false, coverImageState });
        }
    }

    render() {
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col xs={12} sm={11} md={10} lg={9} xl={6} className="ms-auto me-auto">
                        <Card className="card-business card-business-sm" style={this.props.business.business_img ? { backgroundImage: `url("${CDN_URL}/` + this.props.business.business_img + '")' } : null}>
                            {this.props.business.business_img ?
                            <div
                                className="card-overlay"
                                style={this.props.business.hub_cover_image_overlay_gradient_start && this.props.business.hub_cover_image_overlay_gradient_end ? (
                                    { background: `linear-gradient(160deg, ${this.props.business.hub_cover_image_overlay_gradient_start} 0%, ${this.props.business.hub_cover_image_overlay_gradient_end} 100%)` }
                                ) : (
                                    { background: `rgba(0, 0, 0, 0.6)` }
                                )}
                            />
                            : null}
                            <CardBody className="text-center">
                            {this.props.business && this.props.business.business_logo ?
                                <img className="card-logo" src={`${CDN_URL}/` + this.props.business.business_logo} alt="BusinessLogo"/>
                            :
                                <CardTitle style={!this.props.business.business_img && !this.props.business.business_img ?  { color: '#000000' } : null}>{this.props.business ? this.props.business.business_name : 'Loading...'}</CardTitle>
                            }
                            <p className="card-description" style={this.props.business.hub_cover_image_address ? { color: this.props.business.hub_cover_image_address } : null}>
                                {this.getFormattedBusinessAddress()}
                            </p>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Details</h6>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <label>Business name <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessName}
                                                onChange={(e) => this.handleChange(e, 'name')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Business Bio</label>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                value={this.state.businessDescription}
                                                style={{ resize: 'vertical', maxHeight: 275, minHeight: 120, padding: 10, lineHeight: 1.8 }}
                                                onChange={(e) => this.handleChange(e, 'description')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Billing Address</h6>
                            </CardHeader>
                            <CardBody>
                                <CardMap
                                    lat={this.state.businessLat}
                                    lng={this.state.businessLng}
                                    submitAddress={this.handleAddressChange}
                                    submitLatLng={this.handleLatLngChange}
                                />
                                <Row>
                                    <Col md={3}>
                                        <label>Unit Name</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessUnit ? this.state.businessUnit : ''}
                                                onChange={(e) => this.handleChange(e, 'unit')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <label>Street Number</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessStreetNumber ? this.state.businessStreetNumber : ''}
                                                onChange={(e) => this.handleChange(e, 'streetNumber')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <label>Street Name <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessStreetName ? this.state.businessStreetName : ''}
                                                onChange={(e) => this.handleChange(e, 'streetName')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label>City <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessCity ? this.state.businessCity : ''}
                                                onChange={(e) => this.handleChange(e, 'city')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <label>State/County <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessState ? this.state.businessState : ''}
                                                onChange={(e) => this.handleChange(e, 'state')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label>Postcode <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.businessPostcode ? this.state.businessPostcode : ''}
                                                onChange={(e) => this.handleChange(e, 'postCode')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <label>Country <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                options={country}
                                                value={this.state.businessCountry ? country.find(x => x.label === this.state.businessCountry) : null}
                                                isOptionSelected={(option, selectValue) => option.label === this.state.businessCountry}
                                                onChange={(e) => this.handleChange(e, 'country')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Branding</h6>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <h6 className="text-muted">Logo</h6>
                                        <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                            <b>For the best result</b>, upload a high definition image with a transparent background (should be a PNG file) where you crop to the edges of your logo to remove any unnecessary spacing. Please contact your consultant if you need any help with this.
                                        </FormText>
                                        <input type="file" ref={fileInput => this.logoImgInput = fileInput} accept="image/png, image/jpeg, .jpg, .jpeg, .png" onChange={this.onLogoFileChange} style={{ display: 'none' }} />
                                        <div className="business-brand-area" onClick={this.logoImgClick}>
                                            {this.state.logoFileCropped ?
                                                <img className="business-brand-logo" src={this.state.logoFileCropped} alt="BusinessLogo"/>
                                            :
                                                <FiImage size={40}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} className="mt-2">
                                        <h6 className="text-muted">Cover Image</h6>
                                        <input type="file" ref={fileInput => this.coverImgInput = fileInput} accept="image/png, image/jpeg, .jpg, .jpeg, .png" onChange={this.onCoverImageFileChange} style={{ display: 'none' }} />
                                        <div className="business-brand-area" onClick={this.coverImgClick}>
                                            {this.state.coverImageFileCropped ?
                                                <img className="business-brand-logo" src={this.state.coverImageFileCropped} alt="BusinessCoverImage"/>
                                            :
                                                <FiImage size={40}/>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Localisation</h6>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <label>Currency <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                options={currency}
                                                isOptionSelected={(option, selectValue) => option.id === this.state.businessCurrency}
                                                value={this.state.businessCurrency ? currency.find(x => x.id === this.state.businessCurrency) : null}
                                                onChange={(e) => this.handleChange(e, 'currency')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <label>Language <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                value={"English"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <label>Timezone <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                options={timezone}
                                                value={this.state.businessTimezone ? timezone.find(x => x.id === this.state.businessTimezone) : null}
                                                onChange={(e) => this.handleChange(e, 'timezone')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h6 className="title mb-0">Contact Details</h6>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12}>
                                        <label>Email <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                                value={this.state.businessEmail}
                                                onChange={(e) => this.handleChange(e, 'email')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <label>Contact Number <span className="text-danger">*</span></label>
                                        <FormGroup>
                                            <PhoneInput
                                                className="contact-input"
                                                value={this.state.businessContactNumber}
                                                country={'gb'}
                                                placeholder="Contact Number"
                                                inputClass="form-control"
                                                onChange={(value, country, e, formattedValue) => this.handleChange([value, country, e, formattedValue], 'contactNumber')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card style={{ marginBottom: 0 }}>
                            <CardHeader>
                                <h6 className="title mb-0">Online Presence</h6>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12}>
                                        <label>Website</label>
                                        <InputGroup>
                                            <div style={{ lineHeight: '43px', padding: '0px 14px', border: '1px solid #e3e3e3', borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                                                <FiGlobe size={22} color={'#b1b1b1'}/>
                                            </div>
                                            <Input
                                                type="text"
                                                onChange={(e) => this.handleChange(e, 'website')}
                                                value={this.state.businessWebsite}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={12} sm={12}>
                                        <label>Instagram</label>
                                        <InputGroup>
                                            <div style={{ lineHeight: '43px', padding: '0px 5px', border: '1px solid #e3e3e3', borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                                                <FaInstagram size={22} color={'#C13584'}/> @
                                            </div>
                                            <Input
                                                type="text"
                                                value={this.state.businessInstagram}
                                                onChange={(e) => this.handleChange(e, 'instagram')}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <div className="action-button-row">
                                    <Button color="primary" onClick={() => this.submitBusinessSettings()}>
                                        Save Changes
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ImageCropper
                    visible={this.state.imageCropperVisible}
                    toggleVisible={this.toggleImageCropper}
                    imageFile={this.state.imageCropperType === 'logo' ? this.state.logoFile : this.state.coverImageFile}
                    submitCroppedImage={this.submitCroppedImage}
                    xDim={1000000}
                    yDim={1000000}
                    aspect={this.state.imageCropperType === 'logo' ? 1 : 16/9}
                    adjustCropSize={this.state.imageCropperType === 'logo'}
                />
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessSettings));