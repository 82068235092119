import * as React from 'react';
// Material components
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TableContainer, Table, TableRow, TableCell, Stack, Radio, Divider, Typography, Select, MenuItem, Alert } from '@mui/material';
// Custom components
import StaffDisplay from './StaffDisplay';
// API
import Api from "api/index";

export default function StaffAccountCreate(props) {
    const [selectedStaffId, setSelectedStaffId] = React.useState(null);
    const [userRoleTypeId, setUserRoleTypeId] = React.useState(3);
    const [loading, setLoading] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const { open, toggleOpen, staffData, userData } = props;

    const staffIdsToExclude = userData.map(x => x.staff_id);
    const filteredStaffData = staffData.filter(x => !staffIdsToExclude.includes(x.id));

    const createBusinessUser = async () => {
        if(loading) {
            return;
        }
        try {
            setLoading(true);
            const { data } = await Api.postBusinessUser({
                staff_id: selectedStaffId,
                business_user_role_type_id: userRoleTypeId
            });
            if(props.onCreate) {
                props.onCreate(
                    data.businessUserId,
                    userRoleTypeId,
                    selectedStaffId
                );
            }
            setSelectedStaffId(null);
            setUserRoleTypeId(3);
        } catch(e) {
            console.log(e);
            if(e.response && e.response.data && e.response.data.message) {
                setAlertMsg(e.response.data.message);
            } else {
                setAlertMsg("Unable to create business user. If the problem persists, please contact us at support@styler.digital.");
            }
        } finally {
            setLoading(false);
        }
    }

    const renderStaffOptions = () => {
        if(filteredStaffData.length === 0) {
            return (
                <Box mt={3} mb={2} textAlign="center">
                    <Typography variant="body2">No staff available</Typography>
                </Box>
            );
        }
        return (
            <TableContainer>
                <Table size="small">
                    {filteredStaffData.map(x => (
                        <TableRow hover onClick={() => x.staff_email && setSelectedStaffId(x.id) } sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
                            <TableCell sx={{ paddingLeft: 0, paddingRight: 0, cursor: 'pointer' }} component="th" scope="row">
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <StaffDisplay
                                        name={`${x.firstname} ${x.lastname}`}
                                        subtitle={x.staff_email ? x.staff_email : <Box sx={{ color: 'red' }}>Email not provided</Box>}
                                    />
                                    <Box>
                                        <Radio
                                            checked={x.id === selectedStaffId}
                                            disabled={!x.staff_email}
                                        />
                                    </Box>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>
            </TableContainer>
        )
    }

    return (
        <Dialog
            maxWidth="md"
            open={open}
            onClose={toggleOpen}
        >
            <DialogTitle>Create Account</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" mb={1}>
                        Steps
                    </Typography>
                    <Typography mb={1}>
                        1. Select a staff member to add
                    </Typography>
                    <Divider/>
                    {renderStaffOptions()}
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Typography mb={1}>
                        2. Set their permission level
                    </Typography>
                    <Select
                        sx={{ width: '100%', border: 'none' }}
                        value={userRoleTypeId}
                        size="medium"
                        onChange={(event) => setUserRoleTypeId(event.target.value)}
                    >
                        <MenuItem value={1} sx={{ display: 'none' }} disabled>Admin</MenuItem>
                        <MenuItem value={2}>High</MenuItem>
                        <MenuItem value={3}>Medium</MenuItem>
                        <MenuItem value={4}>Low</MenuItem>
                        <MenuItem value={5}>Basic</MenuItem>
                    </Select>
                </Box>
                <Box>
                    <Typography mb={1}>
                        3. Click the Create button
                    </Typography>
                    <Typography variant="body2" mb={2}>
                        We'll send them an email with a
                        link to set their password.
                    </Typography>
                    <LoadingButton
                        fullWidth
                        loading={loading}
                        variant="contained"
                        disabled={selectedStaffId ? false : true}
                        onClick={createBusinessUser}
                    >
                        Create
                    </LoadingButton>
                </Box>
                {alertMsg && (
                    <Box mt={3}>
                        <Alert severity="error" sx={{ maxWidth: 373 }}>{alertMsg}</Alert>
                    </Box>
                )}
                
            </DialogContent>
        </Dialog>
    );
}