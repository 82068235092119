const bookingTypes = [
    {
        id: 1,
        label: "Booking",
        value: "booking"
    },
    {
        id: 2,
        label: "Walk-in",
        value: "walkin"
    }
];

export default bookingTypes;