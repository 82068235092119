import React from "react";
// MUI components
import {
    Grid,
    TextField,
    InputAdornment,
    CircularProgress,
    ButtonGroup,
    Button,
    IconButton,
    Alert,
    Box,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
// Core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Card from "components/Material/Card";
import UserAvatar from "components/Material/UserAvatar";
import CustomerAdd from "components/Customers/CustomerAdd";
import CustomerMerger from "components/Customers/CustomerMerger";
// Phospor icons
import { Users, User, Envelope, Phone, ArrowRight } from "@phosphor-icons/react";
// Redux
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";
// API
import Api from '../../api/index';
// Decorators
import withRouter from "utilities/withRouter";

class Customers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customerResultsPage: 0,
            customerResults: [],
            customerResultsLoading: false,
            customerResultsLoadMore: true,
            customerSearchString: null,
            customerSearchResults: null,
            customerSearchLoading: false,
            customerAddVisible: false,
            customerMergerVisible: false,
            customerMergeCount: null
        };
        this.toggleCustomerAdd = this.toggleCustomerAdd.bind(this);
        this.handleCustomerAddSubmit = this.handleCustomerAddSubmit.bind(this);
    }

    async componentDidMount() {
        this.getCustomerResults();
        this.getUserMergeCount();
    }

    toggleCustomerAdd() {
        this.setState({ customerAddVisible: !this.state.customerAddVisible });
    }

    handleCustomerAddSubmit(customerId) {
        this.props.navigate('/admin/customer_profile?id=' + customerId);
    }

    async getUserMergeCount() {
        try {
            const { data } = await Api.getUserMerge(0, true);
            this.setState({ customerMergeCount: data.userMergeCount });
        } catch(err) {
            console.log(err);
        }
    }

    async getCustomerResults() {
        if(this.state.customerResultsLoading) {
            return;
        }
        let that = this;
        try {
            that.setState({ customerResultsLoading: true });
            let existingCustomerData = [...that.state.customerResults];
            let customerData = await Api.getCustomers({ page: that.state.customerResultsPage });
            existingCustomerData.push(...customerData.data.customerData);
            that.setState({ customerResultsLoading: false, customerResults: existingCustomerData, customerResultsPage: this.state.customerResultsPage + 1, customerResultsLoadMore: (customerData.data.customerData.length === 20) });
        } catch(err) {
            console.log(err);
            that.setState({ customerResultsLoading: false });
            that.props.triggerNotification("An unexpected error occured. Please contact Support if the problem persists.", "danger", "bc", 8);
            return;
        }
    }

    handleSearchChange = async (e) => {
        const searchString = e.target.value;
        if(searchString === ''){
            this.setState({ customerSearchResults: null, customerSearchString: null, customerResultsLoadMore: true });
        } else {
            this.setState({ customerSearchString: searchString, customerResultsLoadMore: false });
        }
        if(searchString && searchString.length > 2) {
            try {
                this.setState({ customerSearchLoading: true })
                let searchResults = await Api.searchCustomers({ search_string: searchString });
                this.setState({ customerSearchResults: searchResults.data.customerData, customerSearchLoading: false });
            } catch(err) {
                console.log(err);
                this.setState({ customerSearchLoading: false });
                this.props.triggerNotification("An unexpected error while fetching customer search results. Please contact Support if the problem persists.", "danger", "bc", 8);
                return;
            }
        }
    }

    renderDuplicateAlert() {
        const updatePermission = this.props.userRole?.customer?.update === true;
        const { customerMergeCount } = this.state;
        if(!updatePermission || !customerMergeCount || customerMergeCount === 0) return;
        return (
            <Alert
                sx={{ mb: 2, alignItems: 'center' }}
                severity="warning"
                action={
                    <Button variant="contained" size="small" sx={{ fontWeight: '600' }} onClick={() => this.setState({ customerMergerVisible: true })} >
                        Review
                    </Button>
                }
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        We found {customerMergeCount} duplicate customer profile{customerMergeCount > 1 && 's'}. Use our merge tool to merge duplicate customer profiles.
                    </Box>
                </Box>
            </Alert>
        )
    }

    renderCardHeader() {
        const createPermission = this.props.userRole?.customer?.create === true;
        return (
            <Box sx={{ display: 'flex' }}>
                <TextField
                    placeholder="Search for a customer..."
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={this.handleSearchChange}
                    InputProps={{
                        endAdornment: this.state.customerSearchLoading ? (
                            <InputAdornment position="end">
                                <CircularProgress color="secondary" size={20} />
                            </InputAdornment>
                        ) : undefined,
                        sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                    }}
                />
                {createPermission && (
                    <ButtonGroup variant="outlined">
                        {createPermission && (
                            <Button variant="contained" onClick={() => this.toggleCustomerAdd()} sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                                Add
                            </Button>
                        )}
                    </ButtonGroup>
                )}
            </Box>
        );
    }

    renderCustomerContactDetail(contactDetail, contactType, size="md") {
        // Size
        let iconSize = 18;
        let textVariant = "body2";
        if(size === "sm") {
            iconSize = 16;
            textVariant = "caption";
        }
        // Icon
        let contactIcon;
        switch(contactType) {
            case "email":
                contactIcon = <Envelope size={iconSize}/>;
                break;
            case "phone":
                contactIcon = <Phone size={iconSize}/>;
                break;
            default:
                contactIcon = <User size={iconSize}/>;
        }
        return (
            <Box display="flex" gap={1} alignItems="center">
                {contactIcon}
                <Typography variant={textVariant}>{contactDetail ? contactDetail : '----------------'}</Typography>
            </Box>
        );
    }

    renderCardBody() {
        const displayLargeSx = { display: { xs: 'none', sm: 'none', md: 'table-cell' }};
        const displaySmallSx = { display: { sm: 'table-cell', md: 'none' } };
        const customerData = this.state.customerSearchResults ? this.state.customerSearchResults : this.state.customerResults;
        return (
            <TableContainer sx={{ pt: 1 }}>
                <Table responsive style={{ marginBottom: 0 }}>
                    <TableBody>
                        {customerData.map((customerObj,) => (
                            <TableRow
                                key={`customerRow${customerObj.user_id}`}
                                hover
                                sx={{ cursor: 'pointer' }}
                                onClick={() => this.props.navigate('/admin/customer_profile?id=' + customerObj.user_id)}
                            >
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        <Box sx={{ mr: 2 }}>
                                            <UserAvatar name={`${customerObj.user_firstname} ${customerObj.user_lastname}`}/>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle1">{`${customerObj.user_firstname} ${customerObj.user_lastname}`}</Typography>
                                            <Box sx={displaySmallSx}>
                                                {this.renderCustomerContactDetail(customerObj.user_email, 'email', 'sm')}
                                                {this.renderCustomerContactDetail(customerObj.user_phone, 'phone', 'sm')}
                                            </Box>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell sx={displayLargeSx}>
                                    {this.renderCustomerContactDetail(customerObj.user_email, 'email')}
                                </TableCell>
                                <TableCell sx={displayLargeSx}>
                                    {this.renderCustomerContactDetail(customerObj.user_phone, 'phone')}
                                </TableCell>
                                <TableCell sx={{ width: 32 }}>
                                    <IconButton
                                        onClick={() => this.props.navigate('/admin/customer_profile?id=' + customerObj.user_id)}
                                    >
                                        <ArrowRight size={16}/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    renderLoadMore() {
        if(!this.state.customerResultsLoadMore) {
            return;
        }
        return (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
                {this.state.customerResultsLoading === false ? (
                    <Button color="primary" variant="outlined" fullWidth onClick={() => this.getCustomerResults()}>
                        Load more
                    </Button>
                ) : (
                    <CircularProgress color="secondary" size={30}/>
                )}
            </Box>
        )
    }

    renderNoResults() {
        if(this.state.customerSearchString && this.state.customerSearchString.length > 2 && !this.state.customerSearchLoading && this.state.customerSearchResults.length === 0) {
            return (
                <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
                    <Users size={40}/>
                    <Typography variant="h6">No customers found</Typography>
                    <Typography variant="subtitle2" sx={{ mt: 1 }}>No results matching "{this.state.customerSearchString}".</Typography>
                    <Button variant="contained" size="small" sx={{ mt: 2 }} onClick={() => this.toggleCustomerAdd()}>Add new customer</Button>
                </Box>
            )
        }
    }

    render() {
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Grid container>
                    <Grid xs={12} lg={10} xl={8} sx={{ mx: 'auto' }}>
                        {this.renderDuplicateAlert()}
                        <Card>
                            <Box>
                                {this.renderCardHeader()}
                                {this.renderCardBody()}
                            </Box>
                            {this.renderLoadMore()}
                            {this.renderNoResults()}
                        </Card>
                    </Grid>
                </Grid>
                <CustomerAdd
                    visible={this.state.customerAddVisible}
                    toggleVisible={this.toggleCustomerAdd}
                    onSubmit={this.handleCustomerAddSubmit}
                />
                {this.state.customerMergerVisible && (
                    <CustomerMerger
                        triggerNotification={this.props.triggerNotification}
                        onClose={() => this.setState({ customerMergerVisible: false })}
                    />
                )}
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        userRole: state.userRole,
        business: state.business,
        serviceCategory: state.serviceCategory,
        service: state.service,
        serviceDetail: state.serviceDetail
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Customers));