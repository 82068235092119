import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";

import { FiLogOut } from 'react-icons/fi';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from '../../api/index';

class SignOutModal extends React.Component {

    async handleSignOut() {
        this.props.actions.loadLoadingSpinner(true);
        await Api.signOut();
        // Clear down local storage
        this.props.actions.clearBusiness();
        this.props.actions.clearBusinessLocation();
        this.props.actions.loadCalendarSettings({});
        this.props.actions.clearServiceCategory();
        this.props.actions.clearService();
        this.props.actions.clearStaff();
        this.props.actions.clearToDo();
        this.props.actions.clearUser();
        this.props.actions.clearUserRole();
        this.props.actions.loadProduct([]);
        this.props.actions.loadSettings({});
        this.props.actions.loadLoadingSpinner(false);
        setTimeout(() => this.props.navigate('/auth/login-page'), 500);
    }

    render() {
        return (
            <Modal
                isOpen={this.props.open}
                toggle={this.props.toggle}
                size="mini"
                modalClassName="modal-primary"
            >
                <div className="modal-header justify-content-center">
                    <div className="modal-profile">
                        <FiLogOut size={30} color={'#0a122b'} />
                    </div>
                </div>
                <ModalBody>
                    <p>Are you sure?</p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        size="large"
                        color="link"
                        className="btn-neutral"
                        onClick={this.props.toggle}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        color="link"
                        className="btn-danger"
                        onClick={() => this.handleSignOut()}
                    >
                        Sign Out
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {}
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOutModal);