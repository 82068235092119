import React from "react";
import moment from "moment";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

class StaffScheduleOptions extends React.Component {

    renderStatus() {
        if(this.props.data && this.props.data.time_status === 0) {
            if(this.props.data.working === 0) {
                return 'Staff Not Working';
            } else {
                return 'Regular Hours';
            }
        } else if(this.props.data && this.props.data.time_status === 1) {
            return 'Amended Hours';
        } else if(this.props.data && this.props.data.time_status === 2) {
            return 'Business Closed';
        } else if(this.props.data && this.props.data.time_status === 3) {
            return 'Staff Holiday';
        }
    }

    render() {
        if(!this.props.data) {
            return null;
        }
        let staffObj = this.props.staff.find(x => x.id === this.props.data.staff_id);
        const updateHoursPermission = this.props.userRole.hours.update_all === true || (this.props.userRole.hours.update === true && this.props.user.staff_id === staffObj.id);
        const updateDatePermission = this.props.userRole.date.update === true;
        return (
            <Modal size="sm" isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Options</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <div className="schedule-option-info-row">
                                <div className="schedule-option-info-title">Staff</div>
                                <div>{staffObj ? staffObj.firstname + ' ' + staffObj.lastname : null}</div>
                            </div>
                            <div className="schedule-option-info-row">
                                <div className="schedule-option-info-title">Date</div>
                                <div>{this.props.data ? moment(this.props.data.date, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY') : null}</div>
                            </div>
                            {this.props.data && ((this.props.data.time_status === 0 && this.props.data.working === 1) || this.props.data.time_status === 1) ?
                                <div className="schedule-option-info-row">
                                    <div className="schedule-option-info-title">Hours</div>
                                    <div>{this.props.data.start.substring(0, 5) + ' - ' + this.props.data.end.substring(0, 5)}</div>
                                </div>
                            : null}
                            <div className="schedule-option-info-row" style={{ marginBottom: 0 }}>
                                <div className="schedule-option-info-title">Status</div>
                                <div>{this.renderStatus()}</div>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    {this.props.data && this.props.data.time_status === 0 && this.props.editSchedule === true && (
                        <Button block className="btn-block w-100" color="primary" onClick={() => this.props.submitOption('editSchedule')}>Edit schedule</Button>
                    )}
                    {this.props.data && this.props.data.time_status === 0 && this.props.data.working === 0 && updateHoursPermission && (
                        <Button block className="btn-block w-100" color="success" onClick={() => this.props.submitOption('editHours')}>Staff working</Button>
                    )}
                    {this.props.data && (this.props.data.time_status === 1 || (this.props.data.time_status === 0 && this.props.data.working === 1)) && updateHoursPermission && (
                        <Button block className="btn-block w-100" color="primary" onClick={() => this.props.submitOption('editHours')}>Edit hours</Button>
                    )}
                    {this.props.data && this.props.data.time_status === 1 && updateHoursPermission && (
                        <Button block className="btn-block w-100" color="danger" outline onClick={() => this.props.submitOption('deleteHours')}>Delete amended hours</Button>
                    )}
                    {this.props.data && (this.props.data.time_status === 1 || (this.props.data.time_status === 0 && this.props.data.working === 1)) && updateHoursPermission && (
                        <Button block className="btn-block w-100" color="danger" outline onClick={() => this.props.submitOption('notWorking')}>Staff not working</Button>
                    )}
                    {this.props.data && this.props.data.time_status === 3 && (
                        <Button block className="btn-block w-100" color="info">View Holiday</Button>
                    )}
                    {this.props.data && this.props.data.time_status === 2 && updateDatePermission && (
                        <Button block className="btn-block w-100" color="success" onClick={() => this.props.submitOption('openBusiness')}>Open business</Button>
                    )}
                    {this.props.data && this.props.data.time_status !== 2 && updateDatePermission &&(
                        <Button block className="btn-block w-100" color="danger" outline onClick={() => this.props.submitOption('closeBusiness')}>Close business</Button>
                    )}
                </ModalBody>
            </Modal>
        );
    }

}

function mapStateToProps(state, ownProps) {
    return {
        staff: state.staff,
        user: state.user,
        userRole: state.userRole
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffScheduleOptions);