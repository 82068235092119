import { Fragment, useEffect } from 'react';
// Lottie
import Lottie from 'react-lottie';
// MUI components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import * as ShieldAnim from 'assets/animations/adhoc/shield.json';

function MFAStepApp(props) {

    useEffect(() => {
        if(props.unenrollment) {
            refresh();
        }
    }, []);

    const refresh = async () => {
        await new Promise(r => setTimeout(r, 2000));
        window.location.reload();
    }

    const renderLottie = () => {
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: ShieldAnim.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            },
            width: '100%',
            height: '100%'
        };
        return (
            <Lottie options={defaultOptions}/>
        );
    }

    return (
        <Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: 200 }}>
                    {renderLottie()}
                </Box>
                <Typography variant="h4" sx={{ mt: 1 }}>
                    All done
                </Typography>
                <Typography variant="body1" textAlign="center" sx={{ mt: 3 }}>
                    You're all set! From now on, you {props.unenrollment ? "won't" : "will"} be required to enter a code from the Google Authenticator app to complete sign in.
                </Typography>
            </Box>
        </Fragment>
    );
};

export default MFAStepApp;