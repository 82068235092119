/*eslint-disable*/
import React from "react";
import moment from 'moment';
import Select from "react-select";
import { Row, Col, FormGroup } from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";
import { FiCheck, FiX } from 'react-icons/fi';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import duration from "constants/duration";
import time from "constants/time";
import { CDN_URL } from "constants/urls";

class BookingService extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            staffOptions: [],
            serviceOptions: []
        };
    }

    componentDidMount() {
        this.getServiceStaffOptions();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.locationId !== this.props.locationId) {
            this.getServiceStaffOptions();
        }
    }

    getServiceStaffOptions() {
        let staffOptions = [];
        this.props.staff.filter(x => x.enabled === 1).forEach((staffObj, staffIndex) => {
            let foundBusinessLocationStaffMap = this.props.businessLocationStaffMap.find(x => x.staff_id === staffObj.id && x.business_location_id === this.props.locationId);
            if(foundBusinessLocationStaffMap) {
                let serviceStaffMapFound = this.props.serviceStaffMap.find(x => x.service_business_detail_id === this.props.serviceId && x.staff_id === staffObj.id);
                staffOptions.push({
                    id: staffIndex,
                    staff_img: staffObj.staff_img,
                    staff_firstname: staffObj.firstname,
                    staff_lastname: staffObj.lastname,
                    label: <div><img className="staff-select-img" src={`${CDN_URL}/` + staffObj.staff_img} alt="StaffImg"/> {staffObj.firstname + ' ' + staffObj.lastname}</div>,
                    value: staffObj.id,
                    staffMapFound: this.props.serviceId ? (serviceStaffMapFound ? true: false) : null
                });
            } else {
                if(this.props.staffId === staffObj.id) {
                    this.props.handleBookingServiceChange({ value: null }, 'staff', this.props.index)
                }
            }
        });
        let serviceOptions = [];
        this.props.service.filter(x => x.enabled === 1).forEach((serviceObj, serviceIndex) => {
            let serviceDetailData = this.props.serviceDetail.find(x => x.service_business_detail_id === this.props.serviceId);
            let foundServiceLocationMap = this.props.serviceLocationMap.find(x => x.service_business_id === serviceObj.service_id && x.business_location_id === this.props.locationId);
            if(foundServiceLocationMap) {
                let serviceDetailOptions = [];
                this.props.serviceDetail.filter(x => x.service_business_id === serviceObj.service_id && x.service_business_detail_enabled === 1).forEach((serviceDetailObj, serviceDetailIndex) => {
                    serviceDetailOptions.push({
                        label: serviceObj.service_name + ' (' + serviceDetailObj.service_business_detail_name + ')',
                        value: serviceDetailObj.service_business_detail_id,
                        visible: serviceDetailObj.service_business_detail_enabled === 1
                    });
                });
                serviceOptions.push({
                    label: serviceObj.service_name,
                    options: serviceDetailOptions
                });
            } else {
                if(serviceDetailData && serviceDetailData.service_business_id === serviceObj.service_id) {
                    this.props.handleBookingServiceChange({ value: null }, 'service', this.props.index);
                }
            }
        });
        this.setState({
            staffOptions,
            serviceOptions
        });
    }

    formatServiceOptionLabel({ label, value }) {
        return (
            <div style={{ display: "flex", flexDirection: 'row' }}>
                {label}
            </div>
        );
    };

    formatStaffOptionLabel({ staff_img, staff_firstname, staff_lastname, label, value, staffMapFound }) {
        return (
            <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                <img className="staff-select-img" src={`${CDN_URL}/` + staff_img} alt="StaffImg"/>
                <div>
                    <div>{staff_firstname + ' ' + staff_lastname}</div>
                    {staffMapFound === true ? <div className="text-success customer-option-contact"><FiCheck size={13}/> Able to do selected service</div> : null}
                    {staffMapFound === false ? <div className="text-danger customer-option-contact"><FiX size={13}/> Unable to do selected service</div> : null}
                </div>
            </div>
        );
    };

    updateStaffServiceOptions(e) {
        let staffOptions = [...this.state.staffOptions];
        staffOptions.forEach((staffOptionObj, staffOptionIndex) => {
            if(e.value) {
                let serviceStaffMapFound = this.props.serviceStaffMap.find(x => x.service_business_detail_id === e.value && x.staff_id === staffOptionObj.value);
                if(serviceStaffMapFound){
                    staffOptions[staffOptionIndex].staffMapFound = true;
                } else {
                    staffOptions[staffOptionIndex].staffMapFound = false;
                }
            } else {
                staffOptions[staffOptionIndex].staffMapFound = null;
            }
        });
        this.setState({ staffOptions });
    }

    customStaffOptionFilter(option, searchText) {
        if((option.data.staff_firstname.toLowerCase() + " " + option.data.staff_lastname.toLowerCase()).includes(searchText.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        let serviceSelectValue = null;
        if(this.props.serviceId) {
            // Locate chosen service
            for (let i = 0; i < this.state.serviceOptions.length; i++) {
                let serviceOption = this.state.serviceOptions[i];
                let serviceOptionFound = serviceOption.options.find(x => x.value === this.props.serviceId)
                if(serviceOptionFound){
                    serviceSelectValue = serviceOptionFound;
                    break;
                }
            }
        }
        let staffSelectValue = this.state.staffOptions.find(x => x.value === this.props.staffId);
        return (
            <li className="timeline-inverted">
                <div className="timeline-badge success">
                    {this.props.index + 1}
                </div>
                <AnimatePresence initial={true}>
                    <motion.div className="timeline-panel" initial={{ scale: 0.9, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: 0.5 }}>
                        <i className="now-ui-icons ui-1_simple-remove delete-booking-service-icon" onClick={() => this.props.removeBookingService(this.props.bookingService)}/>
                        <div className="timeline-body">
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                    <label>Service <span className="text-danger">*</span></label>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            options={this.state.serviceOptions}
                                            formatOptionLabel={this.formatServiceOptionLabel}
                                            value={serviceSelectValue ? serviceSelectValue : null}
                                            onChange={(e) => { this.props.handleBookingServiceChange(e, 'service', this.props.index); this.updateStaffServiceOptions(e); }}
                                            captureMenuScroll={true}
                                            controlShouldRenderValue={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <label>Staff <span className="text-danger">*</span></label>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            options={this.state.staffOptions}
                                            formatOptionLabel={this.formatStaffOptionLabel}
                                            filterOption={this.customStaffOptionFilter}
                                            value={staffSelectValue ? staffSelectValue : null}
                                            onChange={(e) => this.props.handleBookingServiceChange(e, 'staff', this.props.index)}
                                            captureMenuScroll={true}
                                            controlShouldRenderValue={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                    <label>Start Time <span className="text-danger">*</span></label>
                                    <Select
                                        className="react-select primary booking-time-input"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.props.startTime ? time.find(x => x.value === this.props.startTime.format('HH:mm')) : null}
                                        onChange={(e) => this.props.handleBookingServiceChange(moment(e.value, 'HH:mm'), 'startTime', this.props.index)}
                                        placeholder={"--:--"}
                                        captureMenuScroll={true}
                                        controlShouldRenderValue={true}
                                    />
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <label>Duration <span className="text-danger">*</span></label>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            options={duration}
                                            value={duration.find(x => x.id === this.props.durationId)}
                                            onChange={(e) => this.props.handleBookingServiceChange(e, 'duration', this.props.index)}
                                            captureMenuScroll={true}
                                            controlShouldRenderValue={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </li>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        staff: state.staff,
        service: state.service,
        serviceDetail: state.serviceDetail,
        serviceStaffMap: state.serviceStaffMap,
        serviceLocationMap: state.serviceLocationMap,
        businessLocationStaffMap: state.businessLocationStaffMap
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingService);