import { Fragment, useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { Eye, EyeSlash } from '@phosphor-icons/react';

function MFAStepAuth(props) {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Fragment>
            <Typography variant="body1">
                To get started, confirm your password.
            </Typography>
            <Box sx={{ mt: 2 }}>
                <TextField
                    id="password"
                    fullWidth
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    value={props.password}
                    onChange={(e) => props.setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <Eye /> : <EyeSlash />}
                            </IconButton>
                          </InputAdornment>
                        )
                    }}
                />
            </Box>
        </Fragment>
    );
};

export default MFAStepAuth;