/*eslint-disable*/
import React from "react";
import moment from "moment";
// reactstrap components
import { Card, CardBody, Row, Col, FormGroup, Button } from "reactstrap";

import Datetime from "react-datetime";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BookingService from "components/Booking/BookingService.js";
import BookingServiceAdd from "components/Booking/BookingServiceAdd.js";
import BookingDetails from "components/Booking/BookingDetails.js";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from '../../api/index';
// Constants
import durationOptions from "constants/duration";
import finiteOptions from "constants/finite";
import frequencyOptions from "constants/frequency";
import currencyOptions from "constants/currency";
// Decorators
import withRouter from "utilities/withRouter";

class BookingAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingType: 'booking',
            user_id: null,
            business_location_id: null,
            bookingDate: moment().tz(this.props.business.timezone_name, false),
            bookingServices: [],
            notes: null,
            recurring: false,
            frequency_id: null,
            end_id: null,
            finite_id: null,
            end_date: null
        }
        this.addBookingService = this.addBookingService.bind(this);
        this.removeBookingService = this.removeBookingService.bind(this);
        this.handleBookingDetailsChange = this.handleBookingDetailsChange.bind(this);
        this.handleBookingServiceChange = this.handleBookingServiceChange.bind(this);
    }

    async componentDidMount() {
        this.addBookingService();
        this.getUrlParams();
    }

    getUrlParams() {
        try {
            const query = new URLSearchParams(window.location.search);
            // Walk-in param
            let is_walkin = query.get("walkin");
            if(is_walkin === 'true') {
                this.setState({ bookingType: 'walkin' });
            }
            // Date param
            let booking_date = query.get("date");
            if(booking_date && /^\d{4}-\d{2}-\d{2}$/.test(booking_date)) {
                this.setState({ bookingDate: moment(booking_date, 'YYYY-MM-DD') });
            }
            // User param
            let user_id = query.get("user_id");
            if(user_id) {
                user_id = parseInt(user_id);
            }
            if(user_id && Number.isInteger(user_id)) {
                this.setState({ user_id });
            }
            // Business location param
            let business_location_id = query.get("business_location_id");
            if(business_location_id) {
                business_location_id = parseInt(business_location_id);
            } else {
                if(this.props.settings && this.props.settings.selected_business_location_id) {
                    // Populate from options
                    business_location_id = this.props.settings.selected_business_location_id;
                } else {
                    let enabledBusinessLocations = this.props.businessLocation.filter(x => x.enabled === 1);
                    if(enabledBusinessLocations && enabledBusinessLocations.length > 0) {
                        // Use first location
                        business_location_id = enabledBusinessLocations[0].business_location_id;
                    }
                }
            }
            if(business_location_id && Number.isInteger(business_location_id)) {
                this.setState({ business_location_id });
            }
        } catch(err) {
            console.log(err);
        }
    }

    addBookingService() {
        let bookingServices = [...this.state.bookingServices], newStartTime = null, staff_id = null;
        if(bookingServices.length > 0) {
            // There are multiple services - set start time of new service
            let previousBookingService = Object.assign({}, bookingServices[bookingServices.length - 1]);
            if(previousBookingService.start_time && previousBookingService.service_detail_id) {
                // Enough data exists to be able to pre-populate the start time
                let previousBookingServiceObj = this.props.serviceDetail.find(x => x.service_business_detail_id === previousBookingService.service_detail_id)
                if(previousBookingServiceObj) {
                    newStartTime = moment(previousBookingService.start_time).add((previousBookingServiceObj.service_business_detail_duration_a + previousBookingServiceObj.service_business_detail_duration_break + previousBookingServiceObj.service_business_detail_duration_b), 'm');
                }
            }
            if(previousBookingService.staff_id) {
                staff_id = previousBookingService.staff_id;
            }
        } else if(bookingServices.length === 0) {
            // Time param
            const query = new URLSearchParams(window.location.search);
            let start_time = query.get("time");
            if(start_time && /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(start_time)) {
                newStartTime = moment(start_time, 'HH:mm');
            }
            // Staff param
            let query_staff_id = parseInt(query.get("staff_id"));
            if(query_staff_id &&  Number.isInteger(query_staff_id)) {
                staff_id = query_staff_id;
            }
        }
        bookingServices.push({
            business_event_id: 0,
            service_detail_id: null,
            staff_id,
            start_time: newStartTime,
            duration_id: null,
            state: 1
        });
        this.setState({ bookingServices });
    }

    removeBookingService(bookingServiceObj) {
        if(this.state.bookingServices.length === 1) {
            this.props.triggerNotification("At least one service is required.", "warning", "bc", 4);
            return;
        }
        let bookingServices = [...this.state.bookingServices];
        this.setState({ bookingServices: bookingServices.filter(x => x != bookingServiceObj) });
    }

    handleBookingDetailsChange(e, name) {
        if(name === 'date') {
            this.setState({ bookingDate: e })
        } else if(name === 'type') {
            this.setState({ bookingType: e });
        } else if(name === 'user') {
            this.setState({ user_id: e });
        } else if(name === 'businessLocation') {
            this.setState({ business_location_id: e });
        } else if(name === 'notes') {
            this.setState({ notes: e.target.value });
        } else if(name === 'recurring') {
            this.setState({ recurring: e === 1 ? true : false });
        } else if(name === 'recurringFrequency') {
            this.setState({ frequency_id: e });
        } else if(name === 'recurringEnd') {
            this.setState({ end_id: e });
        } else if(name === 'recurringFinite') {
            this.setState({ finite_id: e });
        } else if(name === 'endDate') {
            this.setState({ end_date: e });
        }
    }

    async addBooking() {
        // Check date
        if(!this.state.bookingDate || !moment.isMoment(this.state.bookingDate)) {
            this.props.triggerNotification("No date selected", "danger", "bc", 4);
            return;
        }
        // Check type
        if(!this.state.bookingType || (this.state.bookingType !== 'booking' && this.state.bookingType !== 'walkin')){
            this.props.triggerNotification("Invalid booking type", "danger", "bc", 4);
            return;
        }
        // Validate extra booking fields
        if(this.state.bookingType === 'booking') {
            // Check user
            if(!this.state.user_id) {
                this.props.triggerNotification("No customer selected", "danger", "bc", 4);
                return;
            }
            if(this.state.recurring) {
                // Check frequency
                if(!this.state.frequency_id) {
                    this.props.triggerNotification("A frequency is required for a recurring booking", "danger", "bc", 4);
                    return;
                }
                // Check end type
                if(!this.state.end_id) {
                    this.props.triggerNotification("An end type is required for a recurring booking", "danger", "bc", 4);
                    return;
                }
                // Check end selection
                if(this.state.end_id === 2 && !this.state.finite_id) {
                    this.props.triggerNotification("Please select the number of times the recurring booking should occur", "danger", "bc", 4);
                    return;
                } else if(this.state.end_id === 3 && !this.state.end_date) {
                    this.props.triggerNotification("Please select the end date of the recurring booking", "danger", "bc", 4);
                    return;
                } else if(this.state.end_id === 3 && this.state.end_date && this.state.bookingDate > this.state.end_date) {
                    this.props.triggerNotification("The end date must be after the start date", "danger", "bc", 4);
                    return;
                }
            }
        }
        // Validate business location
        if(!this.state.business_location_id) {
            this.props.triggerNotification("No business location is selected", "danger", "bc", 4);
            return;
        }
        // Validate booking services
        if(!Array.isArray(this.state.bookingServices) || this.state.bookingServices.length === 0) {
            this.props.triggerNotification("At least one service is required", "danger", "bc", 4);
            return;
        }
        let bookingServiceError = false;
        for (let i = 0; i < this.state.bookingServices.length; i++) {
            let bookingService = this.state.bookingServices[i];
            // Check service is selected
            if(!bookingService.service_detail_id || !Number.isInteger(bookingService.service_detail_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("No service selected on service " + (i + 1), "danger", "bc", 4);
                break;
            }
            // Check service is available at this location
            let serviceBusinessDetailData = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingService.service_detail_id);
            if(!this.props.serviceLocationMap.find(x => x.service_business_id === serviceBusinessDetailData.service_business_id && x.business_location_id === this.state.business_location_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("Service " + (i + 1) + " is not available at this location", "danger", "bc", 4);
                break;
            }
            // Check staff member is selected
            if(!bookingService.staff_id || !Number.isInteger(bookingService.staff_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("No staff member selected on service " + (i + 1), "danger", "bc", 4);
                break;
            }
            // Check staff member works at this location
            if(!this.props.businessLocationStaffMap.find(x => x.staff_id === bookingService.staff_id && x.business_location_id === this.state.business_location_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("The staff member on service " + (i + 1) + " does not work at this location", "danger", "bc", 4);
                break;   
            }
            // Check start time is selected
            if(!bookingService.start_time || !moment.isMoment(bookingService.start_time)) {
                bookingServiceError = true;
                this.props.triggerNotification("No start time selected on service " + (i + 1), "danger", "bc", 4);
                break;
            }
            // Check duration is selected
            if(!bookingService.duration_id || !Number.isInteger(bookingService.duration_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("No duration selected on service " + (i + 1), "danger", "bc", 4);
                break;
            }
        }
        if(bookingServiceError === true) {
            return;
        }
        // Inputs valid - submit booking
        this.props.actions.loadLoadingSpinner(true);
        // Calculate end date if necessary
        let end_date = null;
        if(this.state.end_id === 2){
            let finiteObj = finiteOptions.find(x => x.id === this.state.finite_id);
            let frequencyObj = frequencyOptions.find(x => x.id === this.state.frequency_id);
            let multiplyingFactor = finiteObj.value * frequencyObj.frequency_magnitude;
            end_date = moment(this.state.bookingDate).add(multiplyingFactor, frequencyObj.frequency_unit).format('YYYY-MM-DD');
        } else if(this.state.end_id === 3){
            end_date = this.state.end_date.format('YYYY-MM-DD');
        }
        let bookingServices = [];
        this.state.bookingServices.forEach((bookingServiceObj, bookingServiceIndex) => {
            let durationObj = durationOptions.find(x => x.id === bookingServiceObj.duration_id);
            let end_time = moment(bookingServiceObj.start_time).add(durationObj.value, 'm').format('HH:mm');
            bookingServices.push({
                service_detail_id: bookingServiceObj.service_detail_id,
                staff_id: bookingServiceObj.staff_id,
                start_time: bookingServiceObj.start_time.format('HH:mm'),
                end_time: end_time
            });
        });
        let data = {
            type: this.state.bookingType,
            user_id: this.state.bookingType === "booking" ? this.state.user_id : null,
            date: this.state.bookingDate.format('YYYY-MM-DD'),
            business_location_id: this.state.business_location_id,
            notes: this.state.notes,
            recurring: this.state.recurring,
            recurring_frequency_id: this.state.frequency_id,
            recurring_end_date: end_date,
            services: bookingServices
        }
        console.log(data);
        try {
            await Api.addBooking(data);
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Booking added successfully", "success", "bc", 4);
            this.props.navigate("/admin/calendar?date=" + this.state.bookingDate.format('YYYY-MM-DD') + "&time=" + this.state.bookingServices[0].start_time.format('HH:mm'));
        } catch(e) {
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("An unexpected error occured whilst trying to add the booking. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
        }
    }

    handleBookingServiceChange(e, name, index) {
        let bookingServices = [...this.state.bookingServices];
        if(name === 'service') {
            bookingServices[index].service_detail_id = e.value;
            // Attribute correct duration
            let bookingServiceData = this.props.serviceDetail.find(x => x.service_business_detail_id === e.value);
            if(bookingServiceData) {
                let durationOption = durationOptions.find(x => x.value === (bookingServiceData.service_business_detail_duration_a + bookingServiceData.service_business_detail_duration_break + bookingServiceData.service_business_detail_duration_b));
                bookingServices[index].duration_id = durationOption.id;
            }
        } else if(name === 'staff') {
            bookingServices[index].staff_id = e.value;
        } else if(name === 'startTime') {
            bookingServices[index].start_time = e;
        } else if(name === 'duration') {
            bookingServices[index].duration_id = e.id;
        }
        this.setState({ bookingServices });
    }

    renderTotalRow() {
        let currencyObj = currencyOptions.find(x => x.id === this.props.business.currency_id);
        let deposit = 0, duration = 0, total = 0;
        this.state.bookingServices.forEach((bookingServiceObj, bookingServiceIndex) => {
            // Duration
            let durationOption = durationOptions.find(x => x.id === bookingServiceObj.duration_id);
            if(durationOption) {
                duration += durationOption.value;
            }
            let bookingServiceData = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingServiceObj.service_detail_id);
            if(bookingServiceData) {
                // Deposit
                if(bookingServiceData.service_business_detail_deposit_required === 1) {
                    deposit += bookingServiceData.service_business_detail_deposit_amount;
                }
                // Total
                total += bookingServiceData.service_business_detail_price;
            }
        });
        return (
            <div className="total-container">
                <Row>
                    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                        <div className="total-container-row">
                            <div className="text-bold">Deposit:</div>
                            <div>{deposit && deposit > 0 ? (currencyObj ? currencyObj.symbol : '£') + Number(deposit / 100).toFixed(2) : 'N/A'}</div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                        <div className="total-container-row">
                            <div className="text-bold">Dur:</div>
                            <div>{duration && duration > 0 ? duration : 0} mins</div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                        <div className="total-container-row">
                            <div className="text-bold">Total:</div>
                            <div>{total && total > 0 ? (currencyObj ? currencyObj.symbol : '£') + Number(total / 100).toFixed(2) : 'FREE'}</div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                        <Button
                            color="primary"
                            style={{ width: '100%', margin: 0 }}
                            onClick={() => this.addBooking()}
                        >
                            Add Booking
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        return (
        <>
            <PanelHeader size="sm"/>
            <div className="content" style={{ paddingBottom: 0 }}>
                <Row>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <BookingDetails
                            handleBookingDetailsChange={this.handleBookingDetailsChange}
                            bookingType={this.state.bookingType}
                            bookingUserId={this.state.user_id}
                            bookingLocationId={this.state.business_location_id}
                            bookingNotes={this.state.notes}
                            bookingRecurring={this.state.recurring}
                            bookingRecurringFrequency={this.state.frequency_id}
                            bookingRecurringEnd={this.state.end_id}
                            bookingRecurringFinite={this.state.finite_id}
                            bookingRecurringEndDate={this.state.end_date}
                            triggerNotification={this.props.triggerNotification}
                            usage={'add'}
                        />
                    </Col>
                    <Col lg={7} md={12} sm={12} xs={12} className="ms-auto me-auto">
                        <Card className="booking-date-card">
                            <CardBody>
                                <Datetime
                                    dateFormat={"dddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "Select Date", style: { border: 0, fontSize: '1.2em' }}}
                                    value={this.state.bookingDate ? this.state.bookingDate : moment().tz(this.props.business.timezone_name, false)}
                                    onChange={(e) => this.handleBookingDetailsChange(e, 'date')}
                                />
                            </CardBody>
                        </Card>
                        <Card className="card-timeline card-plain">
                            <CardBody style={{ padding: 0 }}>
                                <ul className="timeline timeline-simple timeline-booking">
                                    {this.state.bookingServices.map((bookingServiceObj, bookingServiceIndex) => {
                                        return (
                                            <BookingService
                                                index={bookingServiceIndex}
                                                removeBookingService={this.removeBookingService}
                                                handleBookingServiceChange={this.handleBookingServiceChange}
                                                bookingService={bookingServiceObj}
                                                serviceId={bookingServiceObj.service_detail_id}
                                                staffId={bookingServiceObj.staff_id}
                                                locationId={this.state.business_location_id}
                                                startTime={bookingServiceObj.start_time}
                                                durationId={bookingServiceObj.duration_id}
                                            />
                                        );
                                    })}
                                    <BookingServiceAdd addBookingService={this.addBookingService}/>
                                </ul>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                {this.renderTotalRow()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        businessLocation: state.businessLocation,
        businessLocationStaffMap: state.businessLocationStaffMap,
        serviceDetail: state.serviceDetail,
        serviceLocationMap: state.serviceLocationMap,
        settings: state.settings
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingAdd));