import * as types from "../constants/actionTypes";

export default function serviceCategoryReducer(state = [], action) {

    switch (action.type) {
        case types.LOAD_SERVICE_CATEGORY_SUCCESS:
            return action.serviceCategory;
        case types.CLEAR_SERVICE_CATEGORY_SUCCESS:
            return [];
        default:
            return state;
    };
};