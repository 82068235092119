const ends = [
    {
        id: 1,
        label: "Never",
        value: 1
    },
    {
        id: 2,
        label: "After finite times",
        value: 2
    },
    {
        id: 3,
        label: "At a specific date",
        value: 3
    }
];

export default ends;