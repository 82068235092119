import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Button
} from "reactstrap";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ServiceInfo from "components/Services/ServiceInfo.js";
import ServiceTier from "components/Services/ServiceTier.js";
// Decorators
import withRouter from "utilities/withRouter";
import customValidation from "utilities/customValidation";
// API
import Api from '../../api/index';

class ServiceAdd extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            serviceName: '',
            serviceCategoryId: null,
            serviceDescription: '',
            bookingUrl: null,
            serviceBookingEnabled: true,
            serviceColour: null,
            serviceDetail: [],
            locationTags: []
        };
        this.handleServiceInfoChange = this.handleServiceInfoChange.bind(this);
        this.handleServiceDetailChange = this.handleServiceDetailChange.bind(this);
        this.handleTierStaffChange = this.handleTierStaffChange.bind(this);
        this.removeServiceDetail = this.removeServiceDetail.bind(this);
    }

    componentDidMount() {
        this.addServiceDetail();
        // Get category id if provided
        try {
            const query = new URLSearchParams(window.location.search);
            let service_business_category_id = query.get("service_business_category_id");
            if(service_business_category_id) {
                service_business_category_id = parseInt(service_business_category_id);
                if(Number.isInteger(service_business_category_id)){
                    this.setState({ serviceCategoryId: service_business_category_id })
                }
            }
        } catch(err) {
            console.log(err);
        }
    }

    addServiceDetail() {
        let serviceDetailData = [...this.state.serviceDetail];
        let newServiceDetailObj = {
            service_business_detail_id: null,
            state: 1,
            service_business_detail_price: null,
            service_business_detail_poa: 0,
            service_business_detail_name: null,
            service_business_detail_description: null,
            service_business_detail_split: 0,
            service_business_detail_duration_a: null,
            service_business_detail_duration_break: null,
            service_business_detail_duration_b: null,
            service_business_detail_deposit_required: 0,
            service_business_detail_deposit_amount: null,
            service_business_detail_staff_map: []
        };
        serviceDetailData.push(newServiceDetailObj);
        this.setState({ serviceDetail: serviceDetailData });
    }

    handleServiceInfoChange(e, name) {
        if(name === 'service_name') {
            this.setState({ serviceName: e.target.value });
        } else if(name === 'service_category') {
            this.setState({ serviceCategoryId: e.value });
        } else if(name === 'service_description') {
            this.setState({ serviceDescription: e.target.value });
        } else if(name === 'booking_url') {
            this.setState({ bookingUrl: e.target.value });
        } else if(name === 'service_booking_enabled') {
            this.setState({ serviceBookingEnabled: e });
        } else if(name === 'service_colour') {
            this.setState({ serviceColour: e });
        } else if(name === 'service_locations') {
            let locationTags = [...this.state.locationTags];
            if(e && e.length > 0) {
                // Add new tags
                e.forEach((tagObj, tagIndex) => {
                    let foundObj = locationTags.find(x => x.id === tagObj.id);
                    if(!foundObj) {
                        locationTags.push({ id: tagObj.id });
                    }
                });
                // Remove existing tags
                locationTags.forEach((locationTagObj, locationTagIndex) => {
                    let foundObj = e.find(x => x.id === locationTagObj.id);
                    if(!foundObj) {
                        locationTags = locationTags.filter(x => x.id !== locationTagObj.id);
                    }
                });
            } else {
                // All tags removed
                locationTags = [];
            }
            this.setState({ locationTags });
        }
    }

    handleServiceDetailChange(e, name, index) {
        if(e) {
            let serviceDetailData = [...this.state.serviceDetail];
            if(name === 'service_business_detail_name') {
                serviceDetailData[index].service_business_detail_name = e.target.value.replace(",", "%comma%").replace("'", "%apostrophe%");
            } else if(name === 'service_business_detail_price') {
                serviceDetailData[index].service_business_detail_price = Math.round(e * 100);
            } else if(name === 'service_business_detail_poa') {
                serviceDetailData[index].service_business_detail_poa = (e.value === true ? 1 : 0);
            } else if(name === 'service_business_detail_description') {
                serviceDetailData[index].service_business_detail_description = e.target.value.replace(",", "%comma%").replace("'", "%apostrophe%");
            } else if(name === 'service_business_detail_split') {
                serviceDetailData[index].service_business_detail_split = (e.target.checked ? 1 : 0);
            } else if(name === 'service_business_detail_duration_a') {
                serviceDetailData[index].service_business_detail_duration_a = e.value;
            } else if(name === 'service_business_detail_duration_break') {
                serviceDetailData[index].service_business_detail_duration_break = e.value;
            } else if(name === 'service_business_detail_duration_b') {
                serviceDetailData[index].service_business_detail_duration_b = e.value;
            } else if(name === 'service_business_detail_deposit_required') {
                serviceDetailData[index].service_business_detail_deposit_required = (e.value === true ? 1 : 0);
            } else if(name === 'service_business_detail_deposit_amount') {
                serviceDetailData[index].service_business_detail_deposit_amount = Math.round(e * 100);
            }
            this.setState({ serviceDetail: serviceDetailData });
        }
    }

    handleTierStaffChange(staffId, tierIndex, value) {
        let serviceDetailData = [...this.state.serviceDetail];
        if(value === true) {
            let existingStaffMap = serviceDetailData[tierIndex].service_business_detail_staff_map.find(x => x.staff_id === staffId);
            if(!existingStaffMap) {
                serviceDetailData[tierIndex].service_business_detail_staff_map.push({
                    staff_id: staffId,
                    state: 1
                });
            }
        } else {
            serviceDetailData[tierIndex].service_business_detail_staff_map = serviceDetailData[tierIndex].service_business_detail_staff_map.filter(x => x.staff_id !== staffId);
        }
        this.setState({ serviceDetail: serviceDetailData });
    }

    removeServiceDetail(tierIndex) {
        if(this.state.serviceDetail && this.state.serviceDetail.length > 1) {
            let serviceDetailData = [...this.state.serviceDetail];
            let tierState = serviceDetailData[tierIndex].state;
            if(tierState === 1) {
                serviceDetailData.splice(tierIndex, 1);
            } else if(tierState === 0 || tierState === 2) {
                serviceDetailData[tierIndex].state = 3;
            }
            this.setState({ serviceDetail: serviceDetailData });
        } else {
            this.props.triggerNotification("At least one service tier is required", "warning", "bc", 4);
        }
    }

    async submitService() {
        try {
            // Validate service name
            if(!this.state.serviceName || this.state.serviceName.length === 0){
                this.props.triggerNotification("A service name is required.", "warning", "bc", 4);
                return;
            }
            // Validate service category
            let serviceCategoryIds = [];
            this.props.serviceCategory.forEach((serviceCategoryObj, serviceCategoryIndex) => {
                serviceCategoryIds.push(serviceCategoryObj.service_business_category_id);
            });
            if(!this.state.serviceCategoryId || !Number.isInteger(this.state.serviceCategoryId) || !serviceCategoryIds.includes(this.state.serviceCategoryId)){
                this.props.triggerNotification("A valid service category is required.", "warning", "bc", 4);
                return;
            }
            // Validate booking URL
            if(this.state.bookingUrl?.length > 0 && !customValidation.validateUrl(this.state.bookingUrl, true)) {
                this.props.triggerNotification("Invalid Booking URL.", "warning", "bc", 4);
                return;
            }
            // Validate service tiers
            for (let i = 0; i < this.state.serviceDetail.length; i++) {
                let serviceTierData = this.state.serviceDetail[i];
                if(!serviceTierData.service_business_detail_name || serviceTierData.service_business_detail_name.length === 0) {
                    this.props.triggerNotification("A name is required for all service tiers.", "warning", "bc", 4);
                    return;
                }
                if(!Number.isInteger(serviceTierData.service_business_detail_price) || serviceTierData.service_business_detail_price < 0) {
                    this.props.triggerNotification("A valid price is required for all service tiers.", "warning", "bc", 4);
                    return;
                }
                if(serviceTierData.service_business_detail_deposit_required === 1) {
                    if(!Number.isInteger(serviceTierData.service_business_detail_deposit_amount) || serviceTierData.service_business_detail_deposit_amount < 0) {
                        this.props.triggerNotification("A valid deposit amount is required for all service tiers where a deposit is required.", "warning", "bc", 4);
                        return;
                    }
                }
                if(serviceTierData.service_business_detail_split === 1) {
                    if(!serviceTierData.service_business_detail_duration_a || !Number.isInteger(serviceTierData.service_business_detail_duration_a) || serviceTierData.service_business_detail_duration_a < 0) {
                        this.props.triggerNotification("A valid duration is required for all service tiers.", "warning", "bc", 4);
                        return;
                    }
                    if(!serviceTierData.service_business_detail_duration_break || !Number.isInteger(serviceTierData.service_business_detail_duration_break) || serviceTierData.service_business_detail_duration_break < 0) {
                        this.props.triggerNotification("A valid duration is required for all service tiers.", "warning", "bc", 4);
                        return;
                    }
                    if(!serviceTierData.service_business_detail_duration_b || !Number.isInteger(serviceTierData.service_business_detail_duration_b) || serviceTierData.service_business_detail_duration_b < 0) {
                        this.props.triggerNotification("A valid duration is required for all service tiers.", "warning", "bc", 4);
                        return;
                    }
                } else {
                    if(!serviceTierData.service_business_detail_duration_a || !Number.isInteger(serviceTierData.service_business_detail_duration_a) || serviceTierData.service_business_detail_duration_a < 0) {
                        this.props.triggerNotification("A valid duration is required for all service tiers.", "warning", "bc", 4);
                        return;
                    }
                }
            }
            // Validate business locations
            let locationTags = [...this.state.locationTags];
            let enabledBusinessLocations = this.props.businessLocation.filter(x => x.enabled === 1);
            if(enabledBusinessLocations && enabledBusinessLocations.length === 1 && locationTags.length === 0) {
                locationTags.push({ id: enabledBusinessLocations[0].business_location_id });
            }
            if(locationTags.length === 0) {
                this.props.triggerNotification("At least one business location must be selected.", "warning", "bc", 4);
                return;
            }
            // Validation passed - submit service
            let serviceData = {
                service_name: this.state.serviceName.replace(",", "%comma%").replace("'", "%apostrophe%"),
                service_business_category_id: this.state.serviceCategoryId,
                service_description: this.state.serviceDescription ? this.state.serviceDescription.replace(",", "%comma%").replace("'", "%apostrophe%") : this.state.serviceDescription,
                service_booking_enabled: this.state.serviceBookingEnabled,
                service_colour: this.state.serviceColour,
                service_tiers: this.state.serviceDetail,
                service_locations: locationTags,
                booking_url: this.state.bookingUrl ? this.state.bookingUrl : null
            }
            this.props.actions.loadLoadingSpinner(true);
            await Api.addService(serviceData);
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Service added successfully.", "success", "bc", 4);
            this.props.navigate('/admin/services');
        } catch(err) {
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("An unexpected error occured whilst processing the service. Please contact Support if the problem persists.", "danger", "bc", 8);
            console.log(err);
            return;
        }
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={7} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Add Service</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} lg={10} xl={7} className="ms-auto me-auto">
                        <ServiceInfo
                            serviceName={this.state.serviceName}
                            serviceCategoryId={this.state.serviceCategoryId}
                            serviceDescription={this.state.serviceDescription}
                            serviceBookingEnabled={this.state.serviceBookingEnabled}
                            serviceColour={this.state.serviceColour}
                            serviceLocationTags={this.state.locationTags}
                            bookingUrl={this.state.bookingUrl}
                            handleChange={this.handleServiceInfoChange}
                        />
                    </Col>
                    <Col xs={12} lg={10} xl={7} className="ms-auto me-auto">
                        <h5>Service Tiers</h5>
                        <p>Service Tiers allow you to add the various staffing and pricing options.</p>
                        {this.state.serviceDetail.filter(x => x.state !== 3).map((serviceDetailObj, serviceDetailIndex) => {
                            return(
                                <ServiceTier
                                    key={'serviceDetail' + serviceDetailIndex}
                                    serviceDetailData={serviceDetailObj}
                                    serviceDetailIndex={serviceDetailIndex}
                                    handleChange={this.handleServiceDetailChange}
                                    handleTierStaffChange={this.handleTierStaffChange}
                                    removeServiceDetail={this.removeServiceDetail}
                                />
                            );
                        })}
                        <Row>
                            <Col md={12} className="ms-auto me-auto text-center">
                                <Button color="primary" className="btn-round" outline onClick={() => this.addServiceDetail()}>
                                    <i className="now-ui-icons ui-1_simple-add" /> Add Service Tier
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={10} xl={7} className="ms-auto me-auto">
                        <hr/>
                        <Row>
                            <Col md={12}>
                                <Button color="primary" onClick={() => this.submitService()}>
                                    Add Service
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        businessLocation: state.businessLocation,
        user: state.user,
        serviceCategory: state.serviceCategory,
        service: state.service,
        serviceDetail: state.serviceDetail
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceAdd));