import React from "react";

import Box from '@mui/material/Box';

const CustomCard = (props) => {
    return (
        <Box
            sx={{
                borderRadius: '0.1875rem',
                px: '1rem',
                py: '1rem',
                bgcolor: 'white',
                mb: 2,
                boxShadow: '0 1px 15px 1px rgba(39, 39, 39, 0.1)',
                ...props.style
            }}
        >
            {props.children}
        </Box>
    )
};

export default CustomCard;