/*eslint-disable*/
import React, { useState } from "react";
// MUI components
import Modal from "components/Material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
// Utils
import customValidation from "utilities/customValidation";

import Api from '../../api/index';

function ForgotPassword(props) {

    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const handleInputChange = (e) => {
        setEmail(e.target.value);
    }

    const submitForgotPassword = async () => {
        if(success || loading) {
            return;
        }
        if(!customValidation.validateEmail(email)){
            setError('Invalid email address');
            return;
        }
        setError(null);
        setLoading(true);
        try {
            await Api.resetPasswordNoAuth({ email });
            setSuccess(true);

        } catch(err) {
            if(err?.response?.data?.message) {
                setError(err.response.data.message);
            } else {
                setError('An unexpected error occured');
            }
        }
        setLoading(false);
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={props.setVisible}
            fullWidth
            maxWidth="sm"
        >
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mb: 2, fontSize: 26 }} align="center">
                    Forgot Password
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                    Enter your email address below and we'll get you back into your account in no time.
                </Typography>
                <TextField
                    id="email"
                    label="Email"
                    value={email}
                    error={error}
                    disabled={success || loading}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 3 }}
                />
                <LoadingButton
                    fullWidth
                    variant="contained"
                    onClick={submitForgotPassword}
                    loading={loading}
                    color={success ? "success" : "primary"}
                    sx={{ fontWeight: '600', textTransform: 'uppercase', padding: '10px 16px', fontSize: 15, mb: 2 }}
                >
                    {success ? "Sent" : "Send"}
                </LoadingButton>
                {error && <Alert severity="error">{error}</Alert>}
            </Box>
        </Modal>
    );
}

export default ForgotPassword;