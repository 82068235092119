const customerStatus = [
    {
        id: 1,
        label: "Active",
        value: 0
    },
    {
        id: 2,
        label: "Blocked",
        value: 1
    }
];

export default customerStatus;