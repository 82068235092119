import React from "react";
import moment from "moment";
import {
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    Spinner
} from "reactstrap";
import Select from 'react-select';
import DatePicker from "components/DatePicker/DatePicker";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { FiHelpCircle, FiMapPin } from 'react-icons/fi';

import { CDN_URL } from "constants/urls";

import Api from "api/index";

import StaffScheduleKey from "./StaffScheduleKey";
import StaffScheduleOptions from "./StaffScheduleOptions";
import StaffHours from "./StaffHours";
import StaffScheduleAdd from "./StaffScheduleAdd";
import StaffScheduleEdit from "./StaffScheduleEdit";
import StaffSchedules from "./StaffSchedules";

class StaffScheduleGrid extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().tz(this.props.business.timezone_name, false).startOf('isoWeek'),
            hoursData: [],
            hoursLoading: false,
            selectedLocationId: null,
            selectedStaffId: null,
            selectedCellData: null,
            selectedEditType: null,
            datePickerModalVisible: false,
            scheduleKeyModalVisible: false,
            scheduleOptionsModalVisible: false,
            staffHoursModalVisible: false,
            staffScheduleAddModalVisible: false,
            staffScheduleEditModalVisible: false,
            staffSchedulesModalVisible: false
        };
        this.toggleDatePickerModal = this.toggleDatePickerModal.bind(this);
        this.submitDatePickerChange = this.submitDatePickerChange.bind(this);
        this.toggleScheduleKeyModal = this.toggleScheduleKeyModal.bind(this);
        this.toggleScheduleOptionsModal = this.toggleScheduleOptionsModal.bind(this);
        this.toggleStaffHoursModal = this.toggleStaffHoursModal.bind(this);
        this.submitScheduleOption = this.submitScheduleOption.bind(this);
        this.submitStaffHours = this.submitStaffHours.bind(this);
        this.toggleStaffScheduleAddModal = this.toggleStaffScheduleAddModal.bind(this);
        this.submitStaffScheduleAdd = this.submitStaffScheduleAdd.bind(this);
        this.toggleStaffScheduleEditModal = this.toggleStaffScheduleEditModal.bind(this);
        this.submitStaffScheduleEdit = this.submitStaffScheduleEdit.bind(this);
        this.toggleStaffSchedulesModal = this.toggleStaffSchedulesModal.bind(this);
        this.selectStaffSchedule = this.selectStaffSchedule.bind(this);
    }

    componentDidMount() {
        // Populate location options
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj, businessLocationIndex) => {
            locationOptions.push({
                id: businessLocationObj.business_location_id,
                label: businessLocationObj.business_location_name,
                value: businessLocationObj.business_location_id
            });
        });
        let locationId;
        if(locationOptions.length > 0) {
            if(this.props.settings && this.props.settings.selected_business_location_id) {
                locationId = this.props.settings.selected_business_location_id;
            } else {
                locationId = locationOptions[0].id;
            }
        }
        this.setState({ locationOptions, selectedLocationId: locationId });
        let startDate = moment().tz(this.props.business.timezone_name, false).startOf('isoWeek');
        this.getStaffHours(startDate, locationId);
    }

    async getStaffHours(startDate, locationId) {
        if(!locationId) {
            return;
        }
        this.setState({ hoursLoading: true });
        try {
            let response = await Api.getStaffHours(startDate.format('YYYY-MM-DD'), moment(startDate).add(6, 'days').format('YYYY-MM-DD'), locationId);
            this.setState({ hoursData: response.data.staffHoursData, hoursLoading: false });
        } catch(e) {
            this.props.triggerNotification("Unable to load staff hours. This is likely due to a staff schedule conflict. If you are unable to resolve this conflict, please contact us at support@styler.digital.", "danger", "bc", 10);
        }
    }

    renderStaffRow(staffObj, staffIndex) {
        // Check if staff member is available at the selected location
        let foundStaffMap = this.props.businessLocationStaffMap.find(x => x.staff_id === staffObj.id && x.business_location_id === this.state.selectedLocationId);
        if(!foundStaffMap) {
            return null;
        }
        // Check for access to this staff members hours
        if(this.props.userRole?.hours?.read_all === false && this.props.user.staff_id !== staffObj.id) {
            return null;
        }
        // Render hours cells
        let hourCells = [];
        for(let i = 0; i < 7; i++) {
            let cellData = this.state.hoursData.find(x => x.staff_id === staffObj.id && moment(x.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, true).isSame(moment(this.state.startDate).add(i, 'days'), 'day'));
            let hourCell = this.renderHourCell(cellData);
            hourCells.push(hourCell);
        }
        return (
            <tr>
                <td className="staff-cell" onClick={() => this.setState({ staffSchedulesModalVisible: true, selectedStaffId: staffObj.id })}>
                    <img alt="staff-img" className="img img-raised" src={`${CDN_URL}/` + staffObj.staff_img}/>
                    <div className="staff-cell-info-container">
                        <div>{staffObj.firstname + ' ' + staffObj.lastname}</div>
                        <div className="text-muted staff-cell-schedule-label">View schedules</div>
                    </div>
                </td>
                {hourCells}
            </tr>
        );
    }

    handleCellClick(cellData) {
        this.setState({ selectedCellData: cellData, scheduleOptionsModalVisible: true });
    }

    renderHourCell(cellData) {
        if(!cellData || this.state.hoursLoading) {
            return (
                <td className="time-cell" onClick={() => this.handleCellClick(cellData)}><Spinner size="sm" color="success"/></td>
            );
        }
        if(cellData.time_status && cellData.time_status === 2) {
            return (
                <td className="time-cell closed-cell" onClick={() => this.handleCellClick(cellData)}>CLOSED</td>
            );
        } else if(cellData.time_status && cellData.time_status === 1) {
            return (
                <td className="time-cell amended-cell" onClick={() => this.handleCellClick(cellData)}>{cellData.start.substring(0,5) + ' - ' + cellData.end.substring(0,5)}</td>
            );
        } else if(cellData.time_status && cellData.time_status === 3) {
            return (
                <td className="time-cell holiday-cell" onClick={() => this.handleCellClick(cellData)}>HOLIDAY</td>
            );
        } else {
            if(cellData.working === 0) {
                return (
                    <td className="time-cell not-working-cell" onClick={() => this.handleCellClick(cellData)}>NOT WORKING</td>
                );
            } else {
                return (
                    <td className="time-cell normal-cell" onClick={() => this.handleCellClick(cellData)}>{cellData.start.substring(0,5) + ' - ' + cellData.end.substring(0,5)}</td>
                );
            }
        }
    }

    handleNextWeek() {
        let startDate = moment(this.state.startDate).add(7, 'days');
        this.setState({ startDate });
        this.getStaffHours(startDate, this.state.selectedLocationId);
    }

    handlePreviousWeek() {
        let startDate = moment(this.state.startDate).subtract(7, 'days');
        this.setState({ startDate });
        this.getStaffHours(startDate, this.state.selectedLocationId);
    }

    handleLocationChange(e) {
        if(e.id === this.state.selectedLocationId) {
            return;
        }
        this.setState({ selectedLocationId: e.id });
        this.getStaffHours(this.state.startDate, e.id);
        // Modify selected location in settings
        let settingsObj = Object.assign({}, this.props.settings);
        settingsObj.selected_business_location_id = e.id;
        this.props.actions.loadSettings(settingsObj, this.props.user);
    }

    renderScheduleControls() {
        let startDate = this.state.startDate;
        let endDate = moment(this.state.startDate).add(6, 'days');
        let formattedDate = null;
        if(startDate.isSame(endDate, 'year')) {
            if(startDate.isSame(endDate, 'month')) {
                // Same month
                formattedDate = startDate.format('MMM D') + ' - ' + endDate.format('D, YYYY');
            } else {
                // Different months
                formattedDate = startDate.format('MMM D') + ' - ' + endDate.format('MMM D, YYYY'); 
            }
        } else {
            formattedDate = startDate.format('MMM D, YYYY') + ' - ' + endDate.format('MMM D, YYYY');
        }
        return (
            <div className="mb-3 calendar-header-container staff-schedule-grid-header" style={{ padding: 0 }}>
                <div className="calendar-header-item calendar-header-item-select">
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        options={this.state.locationOptions}
                        value={this.state.locationOptions ? this.state.locationOptions.find(x => x.id === this.state.selectedLocationId) : null}
                        placeholder={'Choose location...'}
                        onChange={(e) => this.handleLocationChange(e)}
                        captureMenuScroll={true}
                        controlShouldRenderValue={true}
                    />
                </div>
                <div className="calendar-header-item calendar-header-item-2">
                    <div className="calendar-header-title-container">
                        <Button color="primary" className="calendar-header-title-button" onClick={() => this.handlePreviousWeek()}>
                            <span className="btn-label">
                            <i className="now-ui-icons arrows-1_minimal-left" />
                            </span>
                        </Button>
                        <div className="calendar-header-title" onClick={this.toggleDatePickerModal}>
                            {formattedDate}
                        </div>
                        <Button color="primary" className="calendar-header-title-button" onClick={() => this.handleNextWeek()}>
                            <span className="btn-label">
                            <i className="now-ui-icons arrows-1_minimal-right" />
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="calendar-header-item calendar-header-item-3">
                    <Button className="btn-outline-primary calendar-header-button pull-right" onClick={this.toggleScheduleKeyModal}>
                        <FiHelpCircle size={16}/>
                    </Button>
                </div>
            </div>
        );
    }

    toggleDatePickerModal = () => {
        this.setState({
            datePickerModalVisible: !this.state.datePickerModalVisible
        });
    };

    submitDatePickerChange(e) {
        let startDate = e.startOf('isoWeek');
        this.setState({ startDate, datePickerModalVisible: false });
        this.getStaffHours(startDate, this.state.selectedLocationId);
    }

    renderDatePickerModal() {
        return (
            <DatePicker
                visible={this.state.datePickerModalVisible}
                curDate={this.state.startDate}
                submitDate={this.submitDatePickerChange}
                toggleVisible={this.toggleDatePickerModal}
                timezoneName={this.props.business.timezone_name}
            />
        );
    }

    toggleScheduleKeyModal = () => {
        this.setState({
            scheduleKeyModalVisible: !this.state.scheduleKeyModalVisible
        });
    };

    renderScheduleKey() {
        return (
            <StaffScheduleKey visible={this.state.scheduleKeyModalVisible} toggleVisible={this.toggleScheduleKeyModal} />
        );
    }

    toggleScheduleOptionsModal = () => {
        this.setState({
            scheduleOptionsModalVisible: !this.state.scheduleOptionsModalVisible
        });
    };

    async submitScheduleOption(action) {
        switch(action) {
            case "editSchedule":
                this.setState({ scheduleOptionsModalVisible: false, staffScheduleEditModalVisible: true, selectedEditType: 'date', selectedScheduleLocationId: this.state.selectedLocationId });
                break;
            case "editHours":
                this.setState({ staffHoursModalVisible: true, scheduleOptionsModalVisible: false, selectedScheduleLocationId: this.state.selectedLocationId });
                break;
            case "deleteHours":
                this.handleDeleteHours();
                break;
            case "notWorking":
                this.handleNotWorking();
                break;
            case "openBusiness":
                this.handleBusinessDayChange(true);
                break;
            case "closeBusiness":
                this.handleBusinessDayChange(false);
                break;
            default:
                break;
        }
    }

    async handleBusinessDayChange(isOpen) {
        this.setState({ scheduleOptionsModalVisible: false });
        try {
            let open = (isOpen ? 1 : 0);
            await Api.updateBusinessDate({ date: moment(this.state.selectedCellData.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'), open, business_location_id: this.state.selectedLocationId });
            let openLabel = (isOpen ? "Open" : "Closed");
            this.props.triggerNotification("Business marked as " + openLabel + " on " + moment(this.state.selectedCellData.date, 'YYYY-MM-DD HH:mm:ss').format('ddd D MMM') + ".", "success", "bc", 4);
            if(!isOpen) {
                let hoursData = [...this.state.hoursData];
                let hoursFilteredData = hoursData.filter(x => moment(x.date, 'YYYY-MM-DD HH:mm:ss').isSame(moment(this.state.selectedCellData.date, 'YYYY-MM-DD HH:mm:ss'), 'day'));
                hoursFilteredData.forEach((hoursObj) => {
                    let hoursIndex = hoursData.findIndex(x => x === hoursObj);
                    hoursData[hoursIndex].time_status = 2;
                });
                this.setState({ hoursData });
            } else {
                this.getStaffHours(this.state.startDate, this.state.selectedLocationId);
            }
        } catch(e) {
            console.log(e);
            if(e.response && e.response.data && e.response.data.message) {
                this.props.triggerNotification(`${e.response.data.message} Please go to the Settings => Closed Dates page to close/open this date.`, "danger", "bc", 15);
            } else {
                this.props.triggerNotification("An unexpected error occured. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
            }
        }
    }

    async handleDeleteHours() {
        this.setState({ scheduleOptionsModalVisible: false });
        try {
            await Api.deleteStaffHours({
                staff_id: this.state.selectedCellData.staff_id,
                date: moment(this.state.selectedCellData.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
                business_location_id: this.state.selectedLocationId
            });
            this.getStaffHours(this.state.startDate, this.state.selectedLocationId);
            let staffObj = this.props.staff.find(x => x.id === this.state.selectedCellData.staff_id);
            this.props.triggerNotification(staffObj.firstname + "'s amended hours on " + moment(this.state.selectedCellData.date, 'YYYY-MM-DD HH:mm:ss').format('ddd D MMM') + " have been deleted.", "success", "bc", 4);
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
        }
    }

    async handleNotWorking() {
        this.setState({ scheduleOptionsModalVisible: false });
        try {
            await Api.updateStaffHours({
                date: moment(this.state.selectedCellData.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
                start_time: '09:00',
                end_time: '17:00',
                staff_id: this.state.selectedCellData.staff_id,
                business_location_id: this.state.selectedLocationId,
                working: false
            });
            let hoursData = [...this.state.hoursData];
            let hoursIndex = hoursData.findIndex(x => x.staff_id === this.state.selectedCellData.staff_id && moment(x.date, 'YYYY-MM-DD HH:mm:ss').isSame(moment(this.state.selectedCellData.date, 'YYYY-MM-DD HH:mm:ss'), 'day'));
            if(hoursIndex) {
                hoursData[hoursIndex].time_status = 0;
                hoursData[hoursIndex].working = 0;
                this.setState({ hoursData });
            } else {
                this.getStaffHours(this.state.startDate, this.state.selectedLocationId);
            }
            let staffObj = this.props.staff.find(x => x.id === this.state.selectedCellData.staff_id);
            this.props.triggerNotification(staffObj.firstname + " is no longer working on " + moment(this.state.selectedCellData.date, 'YYYY-MM-DD HH:mm:ss').format('ddd D MMM') + ".", "success", "bc", 4);
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
        }
    }

    renderScheduleOptions() {
        return (
            <StaffScheduleOptions
                visible={this.state.scheduleOptionsModalVisible}
                toggleVisible={this.toggleScheduleOptionsModal}
                data={this.state.selectedCellData}
                submitOption={this.submitScheduleOption}
                editSchedule={true}
            />
        );
    }

    submitStaffHours(data) {
        this.toggleStaffHoursModal();
        let hoursData = [...this.state.hoursData];
        console.log(hoursData);
        let hoursIndex = hoursData.findIndex(x => x.staff_id === data.staff_id && moment(x.date, 'YYYY-MM-DD HH:mm:ss').isSame(moment(data.date, 'YYYY-MM-DD HH:mm:ss'), 'day'));
        if(hoursIndex) {
            hoursData[hoursIndex].start = data.start_time + ':00';
            hoursData[hoursIndex].end = data.end_time + ':00';
            hoursData[hoursIndex].time_status = 1;
            this.setState({ hoursData });
        } else {
            this.getStaffHours(this.state.startDate, this.state.selectedLocationId);
        }
        this.props.triggerNotification("Staff hours updated successfully.", "success", "bc", 4);
    }

    toggleStaffHoursModal = () => {
        this.setState({
            staffHoursModalVisible: !this.state.staffHoursModalVisible
        });
    };

    renderStaffHoursModal() {
        if(this.state.staffHoursModalVisible && this.state.selectedCellData) {
            return (
                <StaffHours
                    visible={this.state.staffHoursModalVisible}
                    toggleVisible={this.toggleStaffHoursModal}
                    data={this.state.selectedCellData}
                    locationId={this.state.selectedLocationId}
                    onSubmit={this.submitStaffHours}
                />
            );
        } else {
            return null;
        } 
    }

    submitStaffScheduleAdd() {
        this.getStaffHours(this.state.startDate, this.state.selectedLocationId);
        this.toggleStaffScheduleAddModal();
    }

    toggleStaffScheduleAddModal = (selectedScheduleLocationId = null) => {
        this.setState({
            selectedScheduleLocationId,
            staffScheduleAddModalVisible: !this.state.staffScheduleAddModalVisible
        });
    };

    renderStaffScheduleAddModal() {
        if(this.state.staffScheduleAddModalVisible) {
            return (
                <StaffScheduleAdd
                    visible={this.state.staffScheduleAddModalVisible}
                    toggleVisible={this.toggleStaffScheduleAddModal}
                    locationId={this.state.selectedScheduleLocationId}
                    staffId={this.state.selectedStaffId}
                    onSubmit={this.submitStaffScheduleAdd}
                    triggerNotification={this.props.triggerNotification}
                />
            );
        } else {
            return null;
        }
    }

    submitStaffScheduleEdit() {
        this.getStaffHours(this.state.startDate, this.state.selectedLocationId);
        this.toggleStaffScheduleEditModal();
    }

    toggleStaffScheduleEditModal = () => {
        this.setState({
            staffScheduleEditModalVisible: !this.state.staffScheduleEditModalVisible
        });
    };

    renderStaffScheduleEditModal() {
        if(this.state.staffScheduleEditModalVisible && ((this.state.selectedCellData && this.state.selectedEditType === 'date') || (this.state.selectedScheduleId && this.state.selectedEditType === 'id'))) {
            return (
                <StaffScheduleEdit
                    data={this.state.selectedCellData}
                    visible={this.state.staffScheduleEditModalVisible}
                    toggleVisible={this.toggleStaffScheduleEditModal}
                    editType={this.state.selectedEditType}
                    scheduleId={this.state.selectedScheduleId}
                    locationId={this.state.selectedScheduleLocationId}
                    staffId={this.state.selectedStaffId}
                    onSubmit={this.submitStaffScheduleEdit}
                    triggerNotification={this.props.triggerNotification}
                />
            );
        } else {
            return null;
        } 
    }
    
    toggleStaffSchedulesModal = () => {
        this.setState({
            staffSchedulesModalVisible: !this.state.staffSchedulesModalVisible
        });
    };

    selectStaffSchedule(staff_schedule_id, business_location_id) {
        this.setState({ selectedScheduleId: staff_schedule_id, selectedScheduleLocationId: business_location_id, selectedEditType: 'id', staffSchedulesModalVisible: false, staffScheduleEditModalVisible: true })
    }

    renderStaffSchedules() {
        if(this.state.staffSchedulesModalVisible && this.state.selectedStaffId) {
            return (
                <StaffSchedules
                    staff={this.props.staff}
                    staffId={this.state.selectedStaffId}
                    locationId={this.state.selectedLocationId}
                    visible={this.state.staffSchedulesModalVisible}
                    toggleVisible={this.toggleStaffSchedulesModal}
                    toggleAddVisible={this.toggleStaffScheduleAddModal}
                    onSubmit={this.selectStaffSchedule}
                />
            );
        } else {
            return null;
        }
    }
    
    render() {
        return (
            <Col md={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                {this.renderScheduleControls()}
                            </Col>
                            <Col md={12}>
                                {this.props.businessLocation && this.props.businessLocation.filter(x => x.enabled === 1).length > 0 && this.state.selectedLocationId ? (
                                    <Table responsive className="staff-schedule-table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{moment(this.state.startDate).format('ddd D MMM')}</th>
                                                <th>{moment(this.state.startDate).add(1, 'days').format('ddd D MMM')}</th>
                                                <th>{moment(this.state.startDate).add(2, 'days').format('ddd D MMM')}</th>
                                                <th>{moment(this.state.startDate).add(3, 'days').format('ddd D MMM')}</th>
                                                <th>{moment(this.state.startDate).add(4, 'days').format('ddd D MMM')}</th>
                                                <th>{moment(this.state.startDate).add(5, 'days').format('ddd D MMM')}</th>
                                                <th>{moment(this.state.startDate).add(6, 'days').format('ddd D MMM')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.staff.filter(x => x.enabled === 1).map((staffObj, staffIndex) => {
                                                return this.renderStaffRow(staffObj, staffIndex)
                                            })}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <div className="text-center mb-3">
                                        <hr style={{ marginBottom: 15, marginTop: 0 }}/>
                                        <FiMapPin size={35}/>
                                        <h6 className="mt-3">No business location selected</h6>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {this.renderDatePickerModal()}
                {this.renderScheduleKey()}
                {this.renderScheduleOptions()}
                {this.renderStaffHoursModal()}
                {this.renderStaffScheduleAddModal()}
                {this.renderStaffScheduleEditModal()}
                {this.renderStaffSchedules()}
            </Col>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        staff: state.staff,
        business: state.business,
        businessLocation: state.businessLocation,
        businessLocationStaffMap: state.businessLocationStaffMap,
        settings: state.settings,
        user: state.user,
        userRole: state.userRole
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffScheduleGrid);