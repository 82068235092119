import React from "react";
import GoogleMapReact from 'google-map-react';
import {
  Input,
  Button
} from "reactstrap";
import { FiPlus, FiMapPin } from 'react-icons/fi';
// Redux
import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";
// Components
import BusinessLocation from "components/Business/BusinessLocation";
import BusinessMapPin from "components/Maps/BusinessMapPin";
// Constants
import { GOOGLE_MAPS_PROD_KEY, GOOGLE_MAPS_DEV_KEY } from "constants/urls";
// Utilities
import withRouter from "utilities/withRouter";

class BusinessLocations extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      businessLocationData: null,
      businessLocationHoverId: null,
      mapInstance: null,
      mapApi: null,
      businessLocationSearchString: null,
      businessLocationSearchResults: null,
    };
  }

  componentDidMount() {
    this.props.actions.loadBusinessLocationsAuto();
  }

  googleApiHasLoaded(map, maps) {
    this.setState({
        mapInstance: map,
        mapApi: maps,
    }, () => {
      if(this.props.lat && this.props.lng){
          map.setCenter({lat: Number(this.props.lat), lng: Number(this.props.lng)});
          map.setZoom(16);
      }
    });
  }

  handleLocationHover(businessLocationId) {
    this.setState({ businessLocationHoverId: businessLocationId });
  }

  renderBusinessMapPins() {
    let businessLocationData;
    if(this.state.businessLocationSearchResults && this.state.businessLocationSearchResults.length > 0) {
      businessLocationData = this.state.businessLocationSearchResults;
    } else {
      businessLocationData = this.props.businessLocation.filter(x => x.enabled === 1);
    }
    return businessLocationData && businessLocationData.map((businessLocationObj, businessLocationIndex) => {
      if(businessLocationObj.address_latitude && businessLocationObj.address_longitude) {
        return (
          <BusinessMapPin
            lat={businessLocationObj.address_latitude}
            lng={businessLocationObj.address_longitude}
            hovered={businessLocationObj.business_location_id === this.state.businessLocationHoverId}
          />
        );
      } else {
        return null;
      }
    });
  }

  handleSearchChange(e) {
    let searchString = e.target.value;
    if(searchString === ''){
      this.setState({ businessLocationSearchString: null, businessLocationSearchResults: null, });
    } else {
      let businessLocationSearchResults = this.props.businessLocation.filter(x => x.enabled === 1 && x.business_location_name && x.business_location_name.toLowerCase().includes(searchString.toLowerCase()));
      this.setState({ businessLocationSearchString: searchString, businessLocationSearchResults });
    }
  }

  renderBusinessLocations() {
    let businessLocationData;
    if(this.state.businessLocationSearchResults && this.state.businessLocationSearchResults.length > 0) {
      businessLocationData = this.state.businessLocationSearchResults;
    } else {
      businessLocationData = this.props.businessLocation.filter(x => x.enabled === 1);
    }
    return businessLocationData && businessLocationData.map((businessLocationObj, businessLocationIndex) => {
      return (
        <div
          onMouseEnter={() => this.handleLocationHover(businessLocationObj.business_location_id)}
          onMouseLeave={() => this.handleLocationHover(null)}
          onClick={() => this.props.navigate('/admin/edit-business-location?business_location_id=' + businessLocationObj.business_location_id)}
          >
          <BusinessLocation data={businessLocationObj} />
        </div>
      )
    })
  }
  
  render() {
    return (
        <div className="business-locations-container">
              <div className="business-locations-column">
                <div className="business-locations-column-container">
                  <div className="business-locations-column-header">
                    <Input
                      type="text"
                      placeholder="Filter locations..."
                      onChange={(e) => this.handleSearchChange(e)}
                    />
                    <Button color="primary" className="calendar-header-button pull-right" style={{ margin: 0 }} onClick={() => this.props.navigate('/admin/add-business-location')}>
                      <FiPlus size={16} color={'#1ab394'}/> Add
                    </Button>
                  </div>
                  <div>
                    {this.renderBusinessLocations()}
                  </div>
                </div>
                {this.props.businessLocation && this.props.businessLocation.filter(x => x.enabled === 1).length === 0 ? (
                  <div className="no-locations-container">
                    <FiMapPin size={30} style={{ marginBottom: 10 }}/>
                    <h6 style={{ marginBottom: 5 }}>No locations</h6>
                    <Button color="primary" onClick={() => this.props.navigate('/admin/add-business-location')}>
                      Add Location
                    </Button>
                  </div>
                ) : null}
              </div>
              <div className="business-locations-map-container">
                {window.innerWidth >= 769 ?
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: (process.env.NODE_ENV === "production" ? GOOGLE_MAPS_PROD_KEY : GOOGLE_MAPS_DEV_KEY), libraries: ['places', 'geometry'] }}
                    defaultCenter={{ lat: 54.4190221, lng: -4.7505935 }}
                    defaultZoom={4.7}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.googleApiHasLoaded(map, maps)}
                  >
                    {this.renderBusinessMapPins()}
                  </GoogleMapReact>
                : null}
              </div>
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    businessLocation: state.businessLocation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessLocations)) 