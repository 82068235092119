import React from "react";

// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col,
    Button
} from "reactstrap";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { FiPlus } from 'react-icons/fi';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
// constants
import currency from "constants/currency";

import withRouter from "utilities/withRouter";

class Products extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        this.props.actions.loadProductAuto();
    }

    getProductTableColumns() {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        let breakLayoutWidth = 540;
        let tableColumns = [
            {
                accessor: "product_img",
                disableFilters: true,
                disableSortBy: true,
                Cell: props => <span><img alt="product-img" src={'https://cdn.whatstyle.com/' + props.value} style={{ height: 60, width: 60, objectFit: 'cover' }} /></span>,
                colStyle: { minWidth: 74, maxWidth: 74, width: 74, padding: 0 }
            },
            {
                Header: window.innerWidth > breakLayoutWidth ? <span>Name</span> : <div/>,
                accessor: "product_name",
                disableFilters: true,
                disableSortBy: window.innerWidth <= breakLayoutWidth,
                Cell: props => this.renderProductNameCell(props, breakLayoutWidth, currencyObj),
            },
        ];
        if(window.innerWidth > breakLayoutWidth) {
            tableColumns.push(
                {
                    Header: "Stock",
                    accessor: "product_stock_level",
                    disableFilters: true,
                    colStyle: { minWidth: 75, flex: 0.25 }
                },
                {
                    Header: () => <span className="pull-left">Price</span>,
                    accessor: "product_price",
                    Cell: props => <span>{props.value > 0 ? (currencyObj ? currencyObj.symbol : '£') + Number(props.value / 100).toFixed(2) : 'FREE'}</span>,
                    disableFilters: true,
                    colStyle: { minWidth: 75, flex: 0.25 }
                }
            );
        }
        return tableColumns;
    }

    renderProductNameCell(props, breakLayoutWidth, currencyObj) {
        if(window.innerWidth > breakLayoutWidth) {
            return <span className="product-name-cell-title">{props.value}</span>;
        } else {
            return <div>
                <div className="product-name-cell-title">{props.value}</div>
                <div className="product-minimised-data">Price: {props.row.original.product_price > 0 ? (currencyObj ? currencyObj.symbol : '£') + Number(props.row.original.product_price / 100).toFixed(2) : 'FREE'}</div>
                <div className="product-minimised-data">Stock: {props.row.original.product_stock_level}</div>
            </div>;
        }
    }

    handleRowClick(rowData) {
        this.props.navigate('/admin/edit_product?product_id=' + rowData.id);
    }

    render() {
        const createPermission = this.props.userRole.product.create === true;
        return (
        <>
            <PanelHeader size="sm"/>
            <div className="content">
                {this.state.alert}
                <Row>
                    <Col xs={12} sm={12} md={10} lg={10} xl={7} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div>
                                        <div className="products-card-title">Products</div>
                                    </div>
                                    {createPermission ? (
                                        <Button
                                            color="primary"
                                            style={{ margin: 0 }}
                                            onClick={() => this.props.navigate('/admin/add_product')}
                                        >
                                            <FiPlus size={16} color={'#1ab394'}/> Add
                                        </Button>
                                    ) : <br/>}
                                </div>
                                <br/>
                                {this.props.product && this.props.product.length > 0 ? (
                                    <ReactTable
                                        data={this.props.product}
                                        pagination={true}
                                        masterClassName="products-table"
                                        striped={false}
                                        columns={this.getProductTableColumns()}
                                        rowClick={this.handleRowClick}
                                        searchPlaceholder={"Filter products..."}
                                        noDataPlaceholder={'No products found'}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <h2 style={{ marginBottom: 10 }}><i className="now-ui-icons shopping_bag-16" /></h2>
                                        <h5 style={{ marginBottom: 10 }}>Get started by adding your first product</h5>
                                        {createPermission && (
                                            <Button color="primary" onClick={() => this.props.navigate('/admin/add_product')}>
                                                Add Product
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        product: state.product,
        userRole: state.userRole
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Products));