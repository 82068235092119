/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    Label,
    Button,
    CardBody,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Alert,
    UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";
import CurrencyInput from 'react-currency-input-field';
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { CDN_URL } from "constants/urls";

import { FiAlertTriangle, FiHelpCircle } from "react-icons/fi";

// Constants
import duration from '../../constants/duration';
import bool from '../../constants/bool';
import currency from '../../constants/currency';

import ServiceTierStaffPicker from './ServiceTierStaffPicker';

class ServiceTier extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            split: false,
            serviceDetailStaff: [],
            tierStaffPickerVisible: false
        };
        this.handleStaffChange = this.handleStaffChange.bind(this);
        this.toggleTierStaffPickerVisible = this.toggleTierStaffPickerVisible.bind(this);
    }

    componentDidMount() {
        this.setState({
            serviceDetailStaff: this.props.serviceDetailData.service_business_detail_staff_map,
            split: this.props.serviceDetailData.service_business_detail_split === 1
        });
    }

    handleStaffChange(staffId, value) {
        let serviceDetailStaff = [...this.state.serviceDetailStaff];
        if(value === true){
            if(!serviceDetailStaff.find(x => x.staff_id === staffId)){
                serviceDetailStaff.push({
                    staff_id: staffId,
                    state: 1
                });
            }
        } else {
            serviceDetailStaff = serviceDetailStaff.filter(x => x.staff_id !== staffId);
        }
        this.setState({ serviceDetailStaff });
        this.props.handleTierStaffChange(staffId, this.props.serviceDetailIndex, value);
    }

    toggleTierStaffPickerVisible() {
        this.setState({ tierStaffPickerVisible: !this.state.tierStaffPickerVisible });
    }

    render() {
        const { disabled } = this.props;
        const currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        return (
            <div>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <h5 style={{ marginBottom: 5 }}>
                                    {this.props.serviceDetailData.service_business_detail_name ? this.props.serviceDetailData.service_business_detail_name : "Service Tier " + (this.props.serviceDetailIndex + 1)}
                                    <i className="now-ui-icons ui-1_simple-remove pull-right delete-tier-icon" onClick={() => this.props.removeServiceDetail(this.props.serviceDetailData.service_business_detail_id)}/>
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <label>Tier Name</label>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        defaultValue={this.props.serviceDetailData.service_business_detail_name}
                                        placeholder="Senior Stylist"
                                        onChange={(e) => this.props.handleChange(e, 'service_business_detail_name', this.props.serviceDetailIndex)}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <label>Tier Price</label>
                                <InputGroup>
                                    <InputGroupText className="currency-input-symbol">{currencyObj ? currencyObj.symbol : '£'}</InputGroupText>
                                    <CurrencyInput
                                        className="form-control currency-input-control"
                                        placeholder="0.00"
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        allowNegativeValue={false}
                                        step={1}
                                        defaultValue={this.props.serviceDetailData.service_business_detail_price ? this.props.serviceDetailData.service_business_detail_price / 100 : ''}
                                        onValueChange={(value, name) => this.props.handleChange(value, 'service_business_detail_price', this.props.serviceDetailIndex)}
                                        disabled={disabled}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={12} md={3}>
                                <label>Tier POA <FiHelpCircle size={12} id={`TierPoaTooltip${this.props.serviceDetailIndex}`} style={{ marginLeft: 5 }}/></label>
                                <UncontrolledTooltip placement="right" target={`TierPoaTooltip${this.props.serviceDetailIndex}`}>
                                    Would you like us to display Price On Asking (POA) rather than the price?
                                </UncontrolledTooltip>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={bool}
                                        defaultValue={bool.find(x => x.value === (this.props.serviceDetailData.service_business_detail_poa === 1 ? true : false))}
                                        onChange={(e) => this.props.handleChange(e, 'service_business_detail_poa', this.props.serviceDetailIndex)}
                                        isDisabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={3}>
                                <label>Tier Deposit <FiHelpCircle size={12} id={`TierDepositTooltip${this.props.serviceDetailIndex}`} style={{ marginLeft: 5 }}/></label>
                                <UncontrolledTooltip placement="right" target={`TierDepositTooltip${this.props.serviceDetailIndex}`}>
                                    Is a deposit required to book this service tier?
                                </UncontrolledTooltip>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={bool}
                                        defaultValue={bool.find(x => x.value === (this.props.serviceDetailData.service_business_detail_deposit_required === 1 ? true : false))}
                                        onChange={(e) => this.props.handleChange(e, 'service_business_detail_deposit_required', this.props.serviceDetailIndex)}
                                        isDisabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <label>Tier Deposit</label>
                                <InputGroup>
                                    <InputGroupText className="currency-input-symbol">{currencyObj ? currencyObj.symbol : '£'}</InputGroupText>
                                    <CurrencyInput
                                        className="form-control currency-input-control"
                                        placeholder="0.00"
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        allowNegativeValue={false}
                                        step={1}
                                        defaultValue={this.props.serviceDetailData.service_business_detail_deposit_amount ? this.props.serviceDetailData.service_business_detail_deposit_amount / 100 : ''}
                                        onValueChange={(value, name) => this.props.handleChange(value, 'service_business_detail_deposit_amount', this.props.serviceDetailIndex)}
                                        disabled={disabled}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={12} md={12}>
                                <label>Tier Description</label>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        defaultValue={this.props.serviceDetailData.service_business_detail_description}
                                        onChange={(e) => this.props.handleChange(e, 'service_business_detail_description', this.props.serviceDetailIndex)}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={12}>
                                <label>Tier Duration</label>
                                <InputGroup className={this.state.split? "duration-split-input-group" : null}>
                                    <FormGroup check className="duration-split-form-group">
                                        <InputGroupText className="duration-split-input-group-text">
                                            <Label check>
                                                <Input
                                                    addon
                                                    type="checkbox"
                                                    defaultChecked={this.props.serviceDetailData.service_business_detail_split === 1}
                                                    onChange={(e) => { this.props.handleChange(e, 'service_business_detail_split', this.props.serviceDetailIndex); this.setState({ split: e.target.checked });}}
                                                    aria-label="Checkbox for split services"
                                                    disabled={disabled}
                                                />
                                                <span className="form-check-sign" />
                                                <Label className="duration-split-label">Split</Label>
                                            </Label>
                                        </InputGroupText>
                                    </FormGroup>
                                    {this.state.split ? <label className="duration-split-input-label">Block A Duration</label> : null}
                                    <Select
                                        className={this.state.split ? "react-select primary duration-input-control no-border-radius" : "react-select primary duration-input-control" }
                                        classNamePrefix="react-select"
                                        options={duration}
                                        defaultValue={duration.find(x => x.value === this.props.serviceDetailData.service_business_detail_duration_a)}
                                        onChange={(e) => this.props.handleChange(e, 'service_business_detail_duration_a', this.props.serviceDetailIndex)}
                                        isDisabled={disabled}
                                    />
                                    {this.state.split ?
                                        <div className="duration-input-separator">
                                            <InputGroupText className="duration-split-separator">-</InputGroupText>
                                        </div>
                                    : null}
                                    {this.state.split ?
                                        <>
                                            <label className="duration-split-input-label">Break Duration</label>
                                            <Select
                                                className="react-select primary duration-input-control no-border-radius"
                                                classNamePrefix="react-select"
                                                options={duration}
                                                defaultValue={duration.find(x => x.value === this.props.serviceDetailData.service_business_detail_duration_break)}
                                                onChange={(e) => this.props.handleChange(e, 'service_business_detail_duration_break', this.props.serviceDetailIndex)}
                                                isDisabled={disabled}
                                            />
                                        </>
                                    : null}
                                    {this.state.split ?
                                        <div className="duration-input-separator">
                                            <InputGroupText className="duration-split-separator">-</InputGroupText>
                                        </div>
                                    : null}
                                    {this.state.split ?
                                        <>
                                            <label className="duration-split-input-label">Block B Duration</label>
                                            <Select
                                                className="react-select primary duration-input-control"
                                                classNamePrefix="react-select"
                                                options={duration}
                                                defaultValue={duration.find(x => x.value === this.props.serviceDetailData.service_business_detail_duration_b)}
                                                onChange={(e) => this.props.handleChange(e, 'service_business_detail_duration_b', this.props.serviceDetailIndex)}
                                                isDisabled={disabled}
                                            />
                                        </>
                                    : null}
                                </InputGroup>
                            </Col>
                            <Col xs={12} md={12}>
                                <label>Tier Staff</label>
                                {this.state.serviceDetailStaff.length > 0 ?
                                    <Row>
                                        {this.state.serviceDetailStaff.sort((a, b) => {
                                            if(a.staff_id < b.staff_id){
                                                return -1;
                                            } else if (a.staff_id > b.staff_id){
                                                return 1;
                                            } else {
                                                return 0;
                                            }
                                        }).map((mapObj, mapIndex) => {
                                            let staffObj = this.props.staff.find(x => x.id === mapObj.staff_id);
                                            if(staffObj && staffObj.enabled === 1) {
                                                return (
                                                    <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                                                        <div className="tier-staff-row">
                                                            <div>
                                                                <img className="tier-staff-img" alt="Tier Staff" src={staffObj ? `${CDN_URL}/` + staffObj.staff_img : null}/>
                                                                <div className="tier-staff-name">{staffObj ? staffObj.firstname + ' ' + staffObj.lastname[0] : null}</div>
                                                            </div>
                                                            <i className="now-ui-icons ui-1_simple-remove tier-staff-icon" onClick={() => !disabled ? this.handleStaffChange(staffObj.id, false) : null}/>
                                                        </div>
                                                    </Col>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                        <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                                            <div className="tier-staff-row tier-add-staff-container" onClick={() => !disabled ? this.toggleTierStaffPickerVisible() : null}>
                                                <div className="tier-staff-name">Add Staff</div>
                                                <i className="now-ui-icons ui-1_simple-add tier-staff-icon" style={{  color: '#0a122b' }} />
                                            </div>
                                        </Col>
                                    </Row>
                                :
                                    <Row>
                                        <Col md={12}>
                                            <Button color="primary" size="sm" className="text-center" style={{ marginTop: 0 }} onClick={() => !disabled ? this.toggleTierStaffPickerVisible() : null}>Add Staff</Button>
                                        </Col>
                                        {this.props.serviceDetailData.state !== 1 ?
                                            <Col md={12}>
                                                <Alert color="warning" className="mb-0" style={{ padding: '10px 15px' }}>
                                                    <FiAlertTriangle size={16} style={{ marginRight: 5 }}/>There are no staff members assigned to this service tier.
                                                </Alert>
                                            </Col>
                                        : null}
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <ServiceTierStaffPicker
                    serviceDetailStaff={this.state.serviceDetailStaff}
                    handleTierStaffChange={this.handleStaffChange}
                    toggleVisible={this.toggleTierStaffPickerVisible}
                    visible={this.state.tierStaffPickerVisible}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        serviceCategory: state.serviceCategory,
        staff: state.staff
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTier);