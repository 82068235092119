//INTERNAL CALLS
export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const END_AJAX_CALL = "END_AJAX_CALL";
export const ERROR_AJAX_CALL = "ERROR_AJAX_CALL";

export const LOAD_LOADING_SUCCESS = "LOAD_LOADING_SUCCESS";

export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const CLEAR_USER_SUCCESS = "CLEAR_USER_SUCCESS";

export const LOAD_USER_ROLE_SUCCESS = "LOAD_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const CLEAR_USER_ROLE_SUCCESS = "CLEAR_USER_ROLE_SUCCESS";

export const LOAD_BUSINESS_SUCCESS = "LOAD_BUSINESS_SUCCESS";
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS";
export const CLEAR_BUSINESS_SUCCESS = "CLEAR_BUSINESS_SUCCESS";

export const LOAD_BUSINESS_LOCATION_SUCCESS = "LOAD_BUSINESS_LOCATION_SUCCESS";
export const UPDATE_BUSINESS_LOCATION_SUCCESS = "UPDATE_BUSINESS_LOCATION_SUCCESS";
export const ADD_BUSINESS_LOCATION_SUCCESS = "ADD_BUSINESS_LOCATION_SUCCESS";
export const DELETE_BUSINESS_LOCATION_SUCCESS = "DELETE_BUSINESS_LOCATION_SUCCESS";
export const CLEAR_BUSINESS_LOCATION_SUCCESS = "CLEAR_BUSINESS_LOCATION_SUCCESS";

export const LOAD_BUSINESS_LOCATION_STAFF_MAP_SUCCESS = "LOAD_BUSINESS_LOCATION_STAFF_MAP_SUCCESS";

export const LOAD_STAFF_SUCCESS = "LOAD_STAFF_SUCCESS";
export const CLEAR_STAFF_SUCCESS = "LOAD_STAFF_SUCCESS";

export const LOAD_TO_DO_SUCCESS = "LOAD_TO_DO_SUCCESS";
export const UPDATE_TO_DO_SUCCESS = "UPDATE_TO_DO_SUCCESS";
export const ADD_TO_DO_SUCCESS = "ADD_TO_DO_SUCCESS";
export const DELETE_TO_DO_SUCCESS = "DELETE_TO_DO_SUCCESS";
export const CLEAR_TO_DO_SUCCESS = "CLEAR_TO_DO_SUCCESS";

export const LOAD_CALENDAR_SETTINGS_SUCCESS = "LOAD_CALENDAR_SETTINGS_SUCCESS";
export const LOAD_SETTINGS_SUCCESS = "LOAD_SETTINGS_SUCCESS";

export const LOAD_SERVICE_CATEGORY_SUCCESS = "LOAD_SERVICE_CATEGORY_SUCCESS";
export const CLEAR_SERVICE_CATEGORY_SUCCESS = "CLEAR_SERVICE_CATEGORY_SUCCESS";

export const LOAD_SERVICE_SUCCESS = "LOAD_SERVICE_SUCCESS";

export const LOAD_SERVICE_DETAIL_SUCCESS = "LOAD_SERVICE_DETAIL_SUCCESS";

export const LOAD_SERVICE_STAFF_MAP_SUCCESS = "LOAD_SERVICE_STAFF_MAP_SUCCESS";

export const LOAD_SERVICE_LOCATION_MAP_SUCCESS = "LOAD_SERVICE_LOCATION_MAP_SUCCESS";

export const LOAD_PRODUCT_SUCCESS = "LOAD_PRODUCT_SUCCESS";