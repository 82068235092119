import { Fragment } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Logos
import GoogleAuthenticatorLogo from 'assets/img/google-authenticator.svg';
import AppStoreBadge from "assets/img/placeholders/AppStoreBadge.svg";
import GooglePlayBadge from "assets/img/placeholders/GooglePlayBadge.png";

function MFAStepApp() {
    return (
        <Fragment>
            <Typography variant="body1">
                Let's secure your account with a time-based one-time password. To begin, download the Google Authenticator app.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 3,
                        p: 4,
                        borderRadius: '20%',
                        boxShadow: '0 1px 2px 0 rgba(60,64,67,.6), 0 1px 3px 1px rgba(60,64,67,.3)',
                        width: '30%',
                        minWidth: 150,
                        aspectRatio: '1 / 1',
                        justifyContent: 'center'
                    }}
                >
                    <img src={GoogleAuthenticatorLogo} />
                </Box>
                <Typography variant="h4" sx={{ mt: 3 }} textAlign="center" fontWeight={300}>Google Authenticator</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 3 }}>
                    <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">
                        <img src={AppStoreBadge} alt="App Store Badge" style={{ height: 40 }} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noreferrer">
                        <img src={GooglePlayBadge} alt="Google Play Badge" style={{ height: 40 }} />
                    </a>
                </Box>
            </Box>
        </Fragment>
    );
};

export default MFAStepApp;