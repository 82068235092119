import { createTheme } from '@mui/material/styles';

const stylerTheme = {
    primary: '#0a122b',
    secondary: '#ff2b54',
    success: '#1ab394',
    default: ''
};

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: stylerTheme.primary,
        },
        secondary: {
            main: stylerTheme.secondary,
        },
        success: {
            main: stylerTheme.success,
            contrastText: "#fff"
        }
    },
    typography: {
        fontFamily: "'Public Sans', 'Helvetica Neue', Arial, sans-serif",
        subtitle1: {
            fontWeight: 600,
            color: stylerTheme.primary,
            textTransform: 'none'
        },
        subtitle2: {
            textTransform: 'none',
            color: 'rgba(0, 0, 0, 0.52)'
        },
        button: {
            textTransform: 'uppercase',
            fontWeight: 600
        },
        h5: {
            fontWeight: 700,
            fontSize: '1.6em'
        },
        h6: {
            fontWeight: 500,
            fontSize: '1.35em',
            textTransform: 'none'
        }
    }
});

export default theme;