import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    FormText,
    Spinner,
    Alert
} from "reactstrap";
// Compressor
import Compressor from 'compressorjs';
// Icons
import { FiImage, FiX } from 'react-icons/fi';
// Controls
import Switch from "react-switch";
import Select from 'react-select';

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ImageCropper from "components/ImageCropper/ImageCropper";

import Api from '../../api/index';

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

// Decorators
import withRouter from "utilities/withRouter";

class GalleryAdd extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            error: null,
            imageCropperVisible: false,
            imageFile: null,
            imageFileCropped: null,
            
            caption: null,
            staffTags: [],
            serviceTags: [],
            productTags: [],
            appHomeScreen: false,
            
            staffOptions: [],
            serviceOptions: [],
            productOptions: []
        };
        this.toggleImageCropper = this.toggleImageCropper.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.submitCroppedImage = this.submitCroppedImage.bind(this);
        this.galleryImgClick = this.galleryImgClick.bind(this);
        this.handleRemoveGalleryImg = this.handleRemoveGalleryImg.bind(this);
    }

    componentDidMount() {
        let staffOptions = [], serviceOptions = [], productOptions = [];
        // Populate staff options
        this.props.staff.forEach((staffObj, staffIndex) => {
            if(staffObj.enabled === 1) {
                staffOptions.push({
                    id: staffObj.id,
                    label: staffObj.firstname + ' ' + staffObj.lastname,
                    value: staffObj.id
                });
            }
        });
        // Populate service options
        this.props.serviceDetail.forEach((serviceDetailObj, serviceDetailIndex) => {
            if(serviceDetailObj.service_business_detail_enabled === 1) {
                let foundServiceObj = this.props.service.find(x => x.service_id === serviceDetailObj.service_business_id && x.enabled === 1);
                if(foundServiceObj) {
                    serviceOptions.push({
                        id: serviceDetailObj.service_business_detail_id,
                        label: foundServiceObj.service_name + ' (' + serviceDetailObj.service_business_detail_name + ')',
                        value: serviceDetailObj.service_business_detail_id
                    });
                }
            }
        });
        // Populate product options
        this.props.product.forEach((productObj, productIndex) => {
            productOptions.push({
                id: productObj.id,
                label: productObj.product_name,
                value: productObj.id
            });
        });
        this.setState({ staffOptions, serviceOptions, productOptions });
    }

    handleChange(e, name) {
        if(name === 'caption') {
            this.setState({ caption: e.target.value });
        } else if(name === 'staff_tag') {
            let staffTags = [...this.state.staffTags];
            if(e && e.length > 0) {
                // Add new tags
                e.forEach((tagObj, tagIndex) => {
                    let foundObj = staffTags.find(x => x.id === tagObj.id);
                    if(!foundObj) {
                        staffTags.push({ id: tagObj.id });
                    }
                });
                // Remove existing tags
                staffTags.forEach((staffTagObj, staffTagIndex) => {
                    let foundObj = e.find(x => x.id === staffTagObj.id);
                    if(!foundObj) {
                        staffTags = staffTags.filter(x => x.id !== staffTagObj.id);
                    }
                });
            } else {
                // All tags removed
                staffTags = [];
            }
            this.setState({ staffTags });
        } else if(name === 'service_tag') {
            let serviceTags = [...this.state.serviceTags];
            if(e && e.length > 0) {
                // Add new tags
                e.forEach((tagObj, tagIndex) => {
                    let foundObj = serviceTags.find(x => x.id === tagObj.id);
                    if(!foundObj) {
                        serviceTags.push({ id: tagObj.id });
                    }
                });
                // Remove existing tags
                serviceTags.forEach((serviceTagObj, serviceTagIndex) => {
                    let foundObj = e.find(x => x.id === serviceTagObj.id);
                    if(!foundObj) {
                        serviceTags = serviceTags.filter(x => x.id !== serviceTagObj.id);
                    }
                });
            } else {
                // All tags removed
                serviceTags = [];
            }
            this.setState({ serviceTags });
        } else if(name === 'product_tag') {
            let productTags = [...this.state.productTags];
            if(e && e.length > 0) {
                // Add new tags
                e.forEach((tagObj, tagIndex) => {
                    let foundObj = productTags.find(x => x.id === tagObj.id);
                    if(!foundObj) {
                        productTags.push({ id: tagObj.id });
                    }
                });
                // Remove existing tags
                productTags.forEach((productTagObj, productTagIndex) => {
                    let foundObj = e.find(x => x.id === productTagObj.id);
                    if(!foundObj) {
                        productTags = productTags.filter(x => x.id !== productTagObj.id);
                    }
                });
            } else {
                // All tags removed
                productTags = [];
            }
            this.setState({ productTags });
        } else if(name === 'app_home_screen') {
            this.setState({ appHomeScreen: e });
        }
    }

    toggleImageCropper() {
        this.setState({ imageCropperVisible: !this.state.imageCropperVisible });
    }

    async onFileChange(e) {
        if(e.target && e.target.files && e.target.files.length === 1) {
            const imgFile = e.target.files[0];
            let imageDataUrl = await this.readFile(imgFile);
            this.setState({ imageFile: imageDataUrl, imageCropperVisible: true });
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            this.getNormalizedFile(file).then(normalizedFile => reader.readAsDataURL(normalizedFile)).catch(error => reject(error));
        });
    }

    getNormalizedFile(file) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 1000,
                maxHeight: 1000,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    }

    submitCroppedImage(file) {
        this.setState({ imageFileCropped: file, imageCropperVisible: false });
    }

    async submitImage() {
        if(this.state.loading) {
            return;
        }
        if(!this.state.imageFileCropped) {
            this.setState({ error: 'No image has been uploaded.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let data = {
                gallery_image: this.state.imageFileCropped,
                gallery_image_caption: this.state.caption,
                gallery_image_staff_tags: this.state.staffTags,
                gallery_image_service_tags: this.state.serviceTags,
                gallery_image_product_tags: this.state.productTags,
                gallery_image_app_home_screen: this.state.appHomeScreen
            };
            await Api.addGalleryImage(data);
            this.props.triggerNotification("Gallery image added successfully.", "success", "bc", 4);
            this.props.navigate('/admin/gallery');
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    handleRemoveGalleryImg(e) {
        e.preventDefault();
        this.setState({ imageFileCropped: null });
    }

    galleryImgClick(e) {
        if(e.defaultPrevented) return
        this.galleryImgInput.click();
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Add Image</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={8} md={7} lg={6} xl={4} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <div style={{ marginBottom: 15 }}>
                                    <input type="file" ref={fileInput => this.galleryImgInput = fileInput} accept="image/png, image/gif, image/jpeg, .jpg, .jpeg, .png" onChange={this.onFileChange} style={{ display: 'none' }} />
                                    <div className="gallery-img-container" onClick={this.galleryImgClick}>
                                        {this.state.imageFileCropped ?
                                            <div>
                                                <img alt="GalleryImg" src={this.state.imageFileCropped} style={{ width: '100%' }}/>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="btn-icon"
                                                    style={{ float: 'right', marginBottom: 0, marginTop: -30, marginRight: 0 }}
                                                    onClick={this.handleRemoveGalleryImg}
                                                >
                                                    <FiX size={14}/>
                                                </Button>
                                            </div>
                                        :
                                            <div className="gallery-img-icon-container">
                                                <FiImage size={30}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Caption</h6>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                style={{ padding: 10 }}
                                                value={this.state.caption}
                                                onChange={(e) => this.handleChange(e, 'caption')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Tags</h6>
                                        <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                            You can tag services, staff and products in your images. Tags will determine where the image is displayed on your widget and/or app.
                                        </FormText>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <label>Staff</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isMulti
                                                options={this.state.staffOptions}
                                                placeholder={'Tag staff...'}
                                                onChange={(e) => this.handleChange(e, 'staff_tag')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <label>Services</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isMulti
                                                options={this.state.serviceOptions}
                                                placeholder={'Tag services...'}
                                                onChange={(e) => this.handleChange(e, 'service_tag')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <label>Products</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                isMulti
                                                options={this.state.productOptions}
                                                placeholder={'Tag products...'}
                                                onChange={(e) => this.handleChange(e, 'product_tag')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Visibility</h6>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} className="mt-2">
                                        <FormGroup>
                                            <p className="react-switch-label">App Home Screen</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'app_home_screen') }}
                                                disabled={this.props.business.business_account_type_id === 2}
                                                checked={this.state.appHomeScreen}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                            <FormText color="muted" style={{ marginTop: '1rem' }}>
                                                Should this image be visible on your business app home screen?
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <Button color="primary" onClick={() => this.submitImage()}>
                                    {this.state.loading ? <Spinner color="success" size="sm" /> : 'Add Image'}
                                </Button>
                            </Col>
                        </Row>
                        <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                            <span>
                                {this.state.error}
                            </span>
                        </Alert>
                    </Col>
                </Row>
            </div>
            <ImageCropper
                visible={this.state.imageCropperVisible}
                toggleVisible={this.toggleImageCropper}
                imageFile={this.state.imageFile}
                submitCroppedImage={this.submitCroppedImage}
                xDim={720}
                yDim={720}
            />
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        staff: state.staff,
        service: state.service,
        serviceDetail: state.serviceDetail,
        product: state.product
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GalleryAdd));