import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import { FiMail, FiPhone, FiMessageSquare, FiBell, FiBook, FiRepeat, FiSmartphone, FiMonitor } from 'react-icons/fi';
import { AiFillApple, AiOutlineGoogle, AiFillFacebook } from 'react-icons/ai';
import { FaCircle } from 'react-icons/fa';
import moment from 'moment';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from '../../api/index';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import CustomerPushNotification from "components/Customers/CustomerPushNotification.js";
import CustomerEdit from "components/Customers/CustomerEdit.js";
// constants
import currency from "constants/currency";
import { CDN_URL } from "constants/urls";
// Decorators
import withRouter from "utilities/withRouter";

class CustomerProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customer_id: null,
            customer_data: null,
            upcoming_order_data: [],
            upcoming_order_count: 5,
            previous_order_data: [],
            previous_order_count: 5,
            order_event_data: [],
            recurring_order_data: [],
            recurring_order_event_data: [],
            booking_tab: 'upcoming',
            customerPushNotificationVisible: false,
            customerEditVisible: false,
            totalCount: 0,
            completedCount: 0,
            cancelledCount: 0,
            noShowCount: 0
        };
        this.toggleCustomerPushNotification = this.toggleCustomerPushNotification.bind(this);
        this.toggleCustomerEdit = this.toggleCustomerEdit.bind(this);
        this.updateCustomerState = this.updateCustomerState.bind(this);
    }

    async componentDidMount() {
        try {
            const query = new URLSearchParams(window.location.search);
            let customer_id = query.get("id");
            if(customer_id) {
                customer_id = parseInt(customer_id);
                if(Number.isInteger(customer_id)){
                    this.setState({ customer_id });
                    this.loadCustomer(customer_id);
                } else {
                    this.props.navigate('/admin/customers');
                }
            } else {
                this.props.navigate('/admin/customers');
            }
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst loading the customer.", "danger", "bc", 4);
            console.log(err);
        }
    }

    async loadCustomer(customer_id) {
        this.props.actions.loadLoadingSpinner(true);
        let customerResponse = await Api.getCustomer({ customer_id });
        let customerBookingResponse = await Api.getCustomerBookings({ customer_id });
        let customerBookingData = customerBookingResponse.data.customerData;
        let customerUpcomingOrderData = [];
        let customerPreviousOrderData = [];
        let completedCount = 0, cancelledCount = 0, noShowCount = 0;
        let momentNow = moment().tz(this.props.business.timezone_name, false);
        // Sort into upcoming and previous
        customerBookingData.order_data.forEach((order_data_obj, index) => {
            let order_data_events = customerBookingData.order_event_data.filter(x => x.user_order_id === order_data_obj.user_order_id);
            if(order_data_events.some(x => moment(x.start, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, true).isAfter(momentNow))){
                if(order_data_events.length > 0) {
                    if(order_data_events[0].status === 'Cancelled') {
                        cancelledCount++;
                    } else if(order_data_events[0].status === 'No-show') {
                        noShowCount++;
                    }
                }
                customerUpcomingOrderData.push(order_data_obj);
            } else {
                if(order_data_events.length > 0) {
                    if(order_data_events[0].status !== 'Cancelled' && order_data_events[0].status !== 'No-show') {
                        completedCount++;
                    } else if(order_data_events[0].status === 'Cancelled') {
                        cancelledCount++;
                    } else if(order_data_events[0].status === 'No-show') {
                        noShowCount++;
                    }
                }
                customerPreviousOrderData.push(order_data_obj);
            }
        });
        // Arrange upcoming orders by date
        if(customerUpcomingOrderData.length > 0){
            customerUpcomingOrderData.sort((a,b) => {
                let a_data_events = customerBookingData.order_event_data.filter(x => x.user_order_id === a.user_order_id);
                let b_data_events = customerBookingData.order_event_data.filter(x => x.user_order_id === b.user_order_id);
                if(a_data_events?.length === 0 || b_data_events?.length === 0) {
                    return 0;
                }
                if(moment(a_data_events[0].start, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(b_data_events[0].start, 'YYYY-MM-DD HH:mm:ss'))){
                    return -1;
                }
                if(moment(a_data_events[0].start, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(b_data_events[0].start, 'YYYY-MM-DD HH:mm:ss'))){
                    return 1;
                }
                return 0;
            });
        }
        // Arrange previous orders by date
        if(customerPreviousOrderData.length > 0){
            customerPreviousOrderData.sort((a,b) => {
                let a_data_events = customerBookingData.order_event_data.filter(x => x.user_order_id === a.user_order_id);
                let b_data_events = customerBookingData.order_event_data.filter(x => x.user_order_id === b.user_order_id);
                if(a_data_events?.length === 0 || b_data_events?.length === 0) {
                    return 0;
                }
                if(moment(a_data_events[0].start, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(b_data_events[0].start, 'YYYY-MM-DD HH:mm:ss'))){
                    return -1;
                }
                if(moment(a_data_events[0].start, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(b_data_events[0].start, 'YYYY-MM-DD HH:mm:ss'))){
                    return 1;
                }
                return 0;
            });
        }
        // Go through recurring orders and assign next date
        if(customerBookingData.recurring_order_data.length > 0){
            customerBookingData.recurring_order_data.forEach((recurring_order_data_obj, index) => {
                try {
                    let recurring_order_data_events = customerBookingData.recurring_order_event_data.filter(x => x.user_order_recurring_id === recurring_order_data_obj.user_order_recurring_id);
                    var cur_date = moment(recurring_order_data_events[0].start_date, 'YYYY-MM-DD').tz(this.props.business.timezone_name, true);
                    // If start date has not occured yet
                    if(cur_date.isBefore(momentNow, 'day')){
                        // Loop to find next occurrence
                        while (cur_date.isBefore(momentNow, 'day')) {
                            cur_date.add(recurring_order_data_events[0].frequency_magnitude, recurring_order_data_events[0].frequency_unit);
                        }
                        if(recurring_order_data_events[0].end_date && moment(recurring_order_data_events[0].end_date, 'YYYY-MM-DD').tz(this.props.business.timezone_name, true).isBefore(cur_date, 'day')){
                            cur_date = null;
                        }
                    } else {
                        cur_date = null;
                    }
                } catch(err) {
                    cur_date = null;
                }
                customerBookingData.recurring_order_data[index].next_date = cur_date;
            });
        }
        this.setState({
            customer_data: customerResponse.data.customerData,
            upcoming_order_data: customerUpcomingOrderData,
            previous_order_data: customerPreviousOrderData,
            order_event_data: customerBookingData.order_event_data,
            recurring_order_data: customerBookingData.recurring_order_data,
            recurring_order_event_data: customerBookingData.recurring_order_event_data,
            totalCount: customerUpcomingOrderData.length + customerPreviousOrderData.length + customerBookingData.recurring_order_data.length,
            completedCount,
            cancelledCount,
            noShowCount
        });
        this.props.actions.loadLoadingSpinner(false);
    }

    updateCustomerState(data) {
        let curCustomerState = Object.assign({}, this.state.customer_data);
        curCustomerState.user_firstname = data.user_firstname;
        curCustomerState.user_lastname = data.user_lastname;
        curCustomerState.user_email = data.user_email;
        curCustomerState.user_phone = data.user_phone;
        curCustomerState.user_notification_email = data.user_notification_email ? 1 : 0;
        curCustomerState.user_notification_sms = data.user_notification_sms ? 1 : 0;
        curCustomerState.user_marketing_email = data.user_marketing_email ? 1 : 0;
        curCustomerState.user_marketing_sms = data.user_marketing_sms ? 1 : 0;
        curCustomerState.user_block_id = data.user_block ? 1 : null;
        curCustomerState.user_note = data.user_note;
        this.setState({ customer_data: curCustomerState });
    }

    renderCustomerAccountType() {
        if(this.state.customer_data && this.state.customer_data.sign_up_procedure_id === 1) {
            return(
                <div><FiMail size={16} color={'#000000'}/> Email</div>
            );
        } else if(this.state.customer_data && this.state.customer_data.sign_up_procedure_id === 2) {
            return(
                <div><AiFillApple size={16} color={'#000000'}/> Apple</div>
            );
        } else if(this.state.customer_data && this.state.customer_data.sign_up_procedure_id === 3) {
            return(
                <div><AiOutlineGoogle size={16} color={'#4285f4'}/> Google</div>
            );
        } else if(this.state.customer_data && this.state.customer_data.sign_up_procedure_id === 4) {
            return(
                <div><AiFillFacebook size={16} color={'#4267B2'}/> Facebook</div>
            );
        } else if(this.state.customer_data && this.state.customer_data.sign_up_procedure_id === 5) {
            return(
                <div>Created by Styler Partner</div>
            );
        } else {
            return(
                <div>-----</div>
            );
        }
    }

    renderCustomerAccountSource() {
        if(this.state.customer_data && this.state.customer_data.sign_up_method_id === 1) {
            return(
                <div><FiMonitor size={16} color={'#000000'}/> Widget</div>
            );
        } else if(this.state.customer_data && this.state.customer_data.sign_up_method_id === 2) {
            return(
                <div><FiSmartphone size={16} color={'#000000'}/> Business App</div>
            );
        } else if(this.state.customer_data && this.state.customer_data.sign_up_method_id === 3) {
            return(
                <div>Partner</div>
            );
        } else if(this.state.customer_data && this.state.customer_data.sign_up_method_id === 4) {
            return(
                <div>Styler</div>
            );
        } else {
            return(
                <div>-----</div>
            );
        }
    }

    renderCustomerNotificationPreference() {
        if(this.state.customer_data && this.state.customer_data.user_notification_email === 1 && this.state.customer_data.user_notification_sms === 1) {
            return('Email & SMS');
        } else if(this.state.customer_data && this.state.customer_data.user_notification_email === 1 && this.state.customer_data.user_notification_sms === 0) {
            return('Email Only');
        } else if(this.state.customer_data && this.state.customer_data.user_notification_email === 0 && this.state.customer_data.user_notification_sms === 1) {
            return('SMS Only');
        } else {
            return('No Notifications');
        }
    }

    renderCustomerMarketingPreference() {
        if(this.state.customer_data && this.state.customer_data.user_marketing_email === 1 && this.state.customer_data.user_marketing_sms === 1) {
            return('Email & SMS');
        } else if(this.state.customer_data && this.state.customer_data.user_marketing_email === 1 && this.state.customer_data.user_marketing_sms === 0) {
            return('Email Only');
        } else if(this.state.customer_data && this.state.customer_data.user_marketing_email === 0 && this.state.customer_data.user_marketing_sms === 1) {
            return('SMS Only');
        } else {
            return('No Marketing');
        }
    }

    toggleCustomerPushNotification() {
        this.setState({ customerPushNotificationVisible: !this.state.customerPushNotificationVisible });
    }

    toggleCustomerEdit() {
        this.setState({ customerEditVisible: !this.state.customerEditVisible });
        console.log(this.state.customerEditVisible);
    }

    handleBookingClick(user_order_id, user_order_recurring) {
        if(user_order_recurring) {
            this.props.navigate("/admin/edit_booking?user_order_id=" + user_order_id + "&user_order_recurring=true");
        } else {
            this.props.navigate("/admin/edit_booking?user_order_id=" + user_order_id);
        }
    }

    renderCustomerBookings() {
        if(this.state.booking_tab === 'upcoming') {
            if(this.state.upcoming_order_data.length === 0) {
                return (
                    <Row>
                        <Col md={12} className="ms-auto me-auto text-center">
                            <FiBook size={26} style={{ marginTop: 5 }} />
                            <div className="customer-no-results">No upcoming bookings</div>
                        </Col>
                    </Row>
                );
            } else {
                let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
                return (
                    <div className="customer-profile-bookings">
                        {this.state.upcoming_order_data.map((userOrderObj, userOrderIndex) => {
                            if(userOrderIndex >= this.state.upcoming_order_count){
                                return null;
                            }
                            let orderEvents = this.state.order_event_data.filter(x => x.user_order_id === userOrderObj.user_order_id);
                            let total = 0;
                            orderEvents.forEach((orderEventObj, orderEventIndex) => {
                            total = total + Number(orderEventObj.service_business_detail_price);
                            });
                            let cancelled = orderEvents[0].status === 'Cancelled';
                            let noShow = orderEvents[0].status === 'No-show';
                            return (
                                <div className="customer-profile-booking-container" style={cancelled || noShow ? { borderLeftColor: '#ed5565' } : null} onClick={() => this.handleBookingClick(userOrderObj.user_order_id, false)}>
                                    <div className="customer-profile-booking-info">
                                        <strong>{moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</strong>, {moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY')} {cancelled ? <div className="customer-profile-booking-cancelled">CANCELLED</div> : null}{noShow ? <div className="customer-profile-booking-cancelled">NO-SHOW</div> : null}
                                    </div>
                                    <div className="customer-profile-booking-info">Booking Ref: {userOrderObj.user_order_uid}</div>
                                    <div>
                                        {orderEvents.map((userOrderEventObj, userOrderEventIndex) => {
                                            var E_start = moment(userOrderEventObj.start, 'YYYY-MM-DD HH:mm:ss');
                                            var E_end = moment(userOrderEventObj.end, 'YYYY-MM-DD HH:mm:ss');
                                            return (
                                                <div className="customer-profile-booking-service-container" style={orderEvents.length === 1 ? { marginBottom: 0 } : null}>
                                                    <div className="customer-profile-booking-service">
                                                        {userOrderEventObj.service_name ? userOrderEventObj.service_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null} ({userOrderEventObj.service_business_detail_name ? userOrderEventObj.service_business_detail_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null})
                                                        <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(userOrderEventObj.service_business_detail_price / 100).toFixed(2)}</div>
                                                    </div>
                                                    <div className="customer-profile-booking-service-info">{E_end.diff(E_start, 'minutes')} mins with {userOrderEventObj.firstname + ' ' + userOrderEventObj.lastname}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {orderEvents && orderEvents.length > 1 ?
                                        <div>
                                            <hr/>
                                            <div className="customer-profile-booking-total">
                                                Total
                                                <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(total / 100).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            )
                            })}
                            {this.state.upcoming_order_data.length > this.state.upcoming_order_count ?
                                <Row>
                                    <Col md={12} className="ms-auto me-auto text-center">
                                        <Button color="primary" className="btn-round" outline onClick={() => this.setState({ upcoming_order_count: this.state.upcoming_order_count + 5 })}>
                                            Load more...
                                        </Button>
                                    </Col>
                                </Row>
                            : null}
                    </div>
                );
            }
        } else if(this.state.booking_tab === 'previous') {
            if(this.state.previous_order_data.length === 0) {
                return (
                    <Row>
                        <Col md={12} className="ms-auto me-auto text-center">
                            <FiBook size={26} style={{ marginTop: 5 }} />
                            <div className="customer-no-results">No previous bookings</div>
                        </Col>
                    </Row>
                );
            } else {
                let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
                return (
                    <div className="customer-profile-bookings">
                        {this.state.previous_order_data.map((userOrderObj, userOrderIndex) => {
                            if(userOrderIndex >= this.state.previous_order_count){
                                return null;
                            }
                            let orderEvents = this.state.order_event_data.filter(x => x.user_order_id === userOrderObj.user_order_id);
                            let total = 0;
                            orderEvents.forEach((orderEventObj, orderEventIndex) => {
                                total = total + Number(orderEventObj.service_business_detail_price);
                            });
                            let cancelled = orderEvents[0].status === 'Cancelled';
                            let noShow = orderEvents[0].status === 'No-show';
                            return (
                                <div className="customer-profile-booking-container" style={cancelled || noShow ? { borderLeftColor: '#ed5565' } : { borderLeftColor: '#8a8a8a' }} onClick={() => this.handleBookingClick(userOrderObj.user_order_id, false)}>
                                    <div className="customer-profile-booking-info">
                                        <strong>{moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</strong>, {moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY')} {cancelled ? <div className="customer-profile-booking-cancelled">CANCELLED</div> : null}{noShow ? <div className="customer-profile-booking-cancelled">NO-SHOW</div> : null}
                                    </div>
                                    <div className="customer-profile-booking-info">Booking Ref: {userOrderObj.user_order_uid}</div>
                                    <div>
                                        {orderEvents.map((userOrderEventObj, userOrderEventIndex) => {
                                            var E_start = moment(userOrderEventObj.start, 'YYYY-MM-DD HH:mm:ss');
                                            var E_end = moment(userOrderEventObj.end, 'YYYY-MM-DD HH:mm:ss');
                                            return (
                                                <div className="customer-profile-booking-service-container" style={orderEvents.length === 1 ? { marginBottom: 0 } : null}>
                                                    <div className="customer-profile-booking-service">
                                                        {userOrderEventObj.service_name ? userOrderEventObj.service_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null} ({userOrderEventObj.service_business_detail_name ? userOrderEventObj.service_business_detail_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null})
                                                        <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(userOrderEventObj.service_business_detail_price / 100).toFixed(2)}</div>
                                                    </div>
                                                    <div className="customer-profile-booking-service-info">{E_end.diff(E_start, 'minutes')} mins with {userOrderEventObj.firstname + ' ' + userOrderEventObj.lastname}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {orderEvents && orderEvents.length > 1 ?
                                        <div>
                                            <hr/>
                                            <div className="customer-profile-booking-total">
                                                Total
                                                <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(total / 100).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            )
                            })}
                            {this.state.previous_order_data.length > this.state.previous_order_count ?
                                <Row>
                                    <Col md={12} className="ms-auto me-auto text-center">
                                        <Button color="primary" className="btn-round" outline onClick={() => this.setState({ previous_order_count: this.state.previous_order_count + 5 })}>
                                            Load more...
                                        </Button>
                                    </Col>
                                </Row>
                            : null}
                    </div>
                );
            }
        } else if(this.state.booking_tab === 'recurring') {
            if(this.state.recurring_order_data.length === 0) {
                return (
                    <Row>
                        <Col md={12} className="ms-auto me-auto text-center">
                            <FiBook size={26} style={{ marginTop: 5 }} />
                            <div className="customer-no-results">No recurring bookings</div>
                        </Col>
                    </Row>
                );
            } else {
                let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
                return (
                    <div className="customer-profile-bookings">
                        {this.state.recurring_order_data.map((userOrderObj, userOrderIndex) => {
                            console.log(userOrderObj);
                            let orderEvents = this.state.recurring_order_event_data.filter(x => x.user_order_recurring_id === userOrderObj.user_order_recurring_id);
                            let total = 0;
                            orderEvents.forEach((orderEvent, index) => {
                                total = total + Number(orderEvent.service_business_detail_price);
                            });
                            return (
                                <div className="customer-profile-booking-container" onClick={() => this.handleBookingClick(userOrderObj.user_order_recurring_id, true)}>
                                    <div className="customer-profile-booking-info">
                                        <strong>{moment(orderEvents[0].start_time, 'HH:mm:ss').format('HH:mm')} - {moment(orderEvents[orderEvents.length - 1].end_time, 'HH:mm:ss').format('HH:mm')}</strong>
                                        <div className="pull-right"><FiRepeat size={18}/></div>
                                    </div>
                                    {userOrderObj.next_date ? <div className="customer-profile-booking-info">Next date: {userOrderObj.next_date.format('ddd Do MMM YYYY')}</div> : null}
                                    <div className="customer-profile-booking-info">Start date: {moment(orderEvents[0].start_date, 'YYYY-MM-DD').format('ddd Do MMM YYYY')}</div>
                                    <div className="customer-profile-booking-info">End date: {orderEvents[0].end_date ? moment(orderEvents[0].end_date, 'YYYY-MM-DD').format('ddd Do MMM YYYY') : '------'}</div>
                                    <div className="customer-profile-booking-info">Occurs: {orderEvents[0].business_events_recurring_frequency ? orderEvents[0].business_events_recurring_frequency : null}</div>
                                    <div className="customer-profile-booking-info">Booking Ref: {userOrderObj.user_order_recurring_uid}</div>
                                    <div>
                                        {orderEvents.map((userOrderEventObj, userOrderEventIndex) => {
                                            var E_start = moment(userOrderEventObj.start_time, 'HH:mm:ss');
                                            var E_end = moment(userOrderEventObj.end_time, 'HH:mm:ss');
                                            return (
                                                <div className="customer-profile-booking-service-container" style={orderEvents.length === 1 ? { marginBottom: 0 } : null}>
                                                    <div className="customer-profile-booking-service">
                                                        {userOrderEventObj.service_name ? userOrderEventObj.service_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null} ({userOrderEventObj.service_business_detail_name ? userOrderEventObj.service_business_detail_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null})
                                                        <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(userOrderEventObj.service_business_detail_price / 100).toFixed(2)}</div>
                                                    </div>
                                                    <div className="customer-profile-booking-service-info">{E_end.diff(E_start, 'minutes')} mins with {userOrderEventObj.firstname + ' ' + userOrderEventObj.lastname}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {orderEvents && orderEvents.length > 1 ?
                                        <div>
                                            <hr/>
                                            <div className="customer-profile-booking-total">
                                                Total
                                                <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(total / 100).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            )
                        })}
                    </div>
                );
            }
        } else {
            return null;
        }
    }

    renderStatsCard() {
        return (
            <Card>
                <CardBody className="text-center">
                    <Row>
                        <Col md={3} sm={6} xs={6}>
                            <h3 className="customer-profile-stat-title">{this.state.totalCount ? this.state.totalCount : 0}</h3>
                            <h6 className="text-muted">Total</h6>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                            <h3 className="customer-profile-stat-title">{this.state.completedCount ? this.state.completedCount : 0}</h3>
                            <h6 className="text-muted">Completed</h6>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                            <h3 className="customer-profile-stat-title">{this.state.cancelledCount ? this.state.cancelledCount : 0}</h3>
                            <h6 className="text-muted">Cancelled</h6>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                            <h3 className="customer-profile-stat-title text-danger">{this.state.noShowCount ? this.state.noShowCount : 0}</h3>
                            <h6 className="text-danger">No-show</h6>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    render() {
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                <Col xl={4} lg={5} md={12}>
                    <Card className="card-user card-user-profile">
                        <div className="image">
                            <img alt="CustomerProfile" src={`${CDN_URL}/` + this.props.business.business_img} />
                        </div>
                        <CardBody>
                            <div className="author">
                                <div className="customer-profile-avatar">
                                    <div className="customer-profile-avatar-initials">{this.state.customer_data ? this.state.customer_data.user_firstname.charAt(0) + this.state.customer_data.user_lastname.charAt(0) : null}</div>
                                </div>
                                <h5 className="title">{this.state.customer_data ? this.state.customer_data.user_firstname + ' ' + this.state.customer_data.user_lastname : 'Loading...'}</h5>
                                <p className="description">Customer</p>
                            </div>
                            <div className="customer-profile-buttons">
                                <Button color="success" className="customer-profile-button" onClick={() => this.props.navigate("/admin/add_booking?user_id=" + this.state.customer_data.user_id)}>New Booking</Button>
                                <Button color="muted" className="customer-profile-button btn-icon" onClick={() => this.state.customer_data ? window.open(`tel:${this.state.customer_data.user_phone}`, '_self') : null}><FiPhone style={{ marginBottom: 3 }} /></Button>
                                <Button color="muted" className="customer-profile-button btn-icon" onClick={() => this.state.customer_data ? window.open(`sms:${this.state.customer_data.user_phone}`, '_self') : null}><FiMessageSquare style={{ marginBottom: 3 }} /></Button>
                                <Button color="muted" className="customer-profile-button btn-icon" onClick={() => this.state.customer_data ? window.open(`mailto:${this.state.customer_data.user_email}`, '_self') : null}><FiMail style={{ marginBottom: 3 }} /></Button>
                                {this.props.userRole.notification.send === true && <Button color="muted" className="customer-profile-button btn-icon" onClick={this.toggleCustomerPushNotification}><FiBell style={{ marginBottom: 3 }} /></Button>}
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Email</div>
                                <div className="customer-profile-info">{this.state.customer_data && this.state.customer_data.user_email ? this.state.customer_data.user_email : '-----'}</div>
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Contact Number</div>
                                <div className="customer-profile-info">{this.state.customer_data && this.state.customer_data.user_phone ? this.state.customer_data.user_phone : '-----'}</div>
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Account Type</div>
                                <div className="customer-profile-info">{this.renderCustomerAccountType()}</div>
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Account Source</div>
                                <div className="customer-profile-info">{this.renderCustomerAccountSource()}</div>
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Account Created</div>
                                <div className="customer-profile-info">{this.state.customer_data && this.state.customer_data.created ? moment.utc(this.state.customer_data.created, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, false).format('dddd, Do MMM YYYY') : '-----'}</div>
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Notification Preferences</div>
                                <div className="customer-profile-info">{this.renderCustomerNotificationPreference()}</div>
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Marketing Preferences</div>
                                <div className="customer-profile-info">{this.renderCustomerMarketingPreference()}</div>
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Status</div>
                                <div className="customer-profile-info">
                                    <FaCircle size={10} color={this.state.customer_data && this.state.customer_data.user_block_id > 0 ? '#ed5565' : '#1ab394'} />
                                    {this.state.customer_data &&  this.state.customer_data.user_block_id > 0 ? ' Blocked' : ' Active'}
                                </div>
                            </div>
                            <hr style={{ marginLeft: 0, marginRight: 0 }} />
                            <div className="customer-profile-info-container">
                                <div className="customer-profile-info customer-profile-info-bold">Notes</div>
                                <div className="customer-profile-info">{this.state.customer_data && this.state.customer_data.user_note ? this.state.customer_data.user_note : '-----'}</div>
                            </div>
                            {this.props.userRole.customer?.update === true && (
                                <>
                                    <hr style={{ marginLeft: 0, marginRight: 0 }} />
                                    <div className="customer-profile-info-container">
                                        <Button block color="primary" className="btn-block" onClick={() => this.toggleCustomerEdit()}>Edit Details</Button>
                                    </div>
                                </>
                            )}
                        </CardBody>
                    </Card>
                    </Col>
                    <Col xl={8} lg={7} md={12}>
                        <Row>
                            <Col md={12}>
                                {this.renderStatsCard()}
                            </Col>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Nav pills className="nav-pills-primary">
                                            <NavItem className="customer-profile-pill">
                                                <NavLink
                                                    className={this.state.booking_tab === "upcoming" ? "active" : ""}
                                                    onClick={() => this.setState({ booking_tab: "upcoming", upcoming_order_count: 5 })}
                                                >
                                                    Upcoming
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="customer-profile-pill">
                                                <NavLink
                                                    className={this.state.booking_tab === "recurring" ? "active" : ""}
                                                    onClick={() => this.setState({ booking_tab: "recurring" })}
                                                >
                                                    Recurring
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="customer-profile-pill">
                                                <NavLink
                                                    className={this.state.booking_tab === "previous" ? "active" : ""}
                                                    onClick={() => this.setState({ booking_tab: "previous", previous_order_count: 5 })}
                                                >
                                                    Previous
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <hr/>
                                        {this.renderCustomerBookings()}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.state.customerPushNotificationVisible ? <CustomerPushNotification isOpen={this.state.customerPushNotificationVisible} toggleVisible={this.toggleCustomerPushNotification} customerId={this.state.customer_id} triggerNotification={this.props.triggerNotification}/> : null}
                {this.state.customerEditVisible ? <CustomerEdit visible={this.state.customerEditVisible} toggleVisible={this.toggleCustomerEdit} customerData={this.state.customer_data} triggerNotification={this.props.triggerNotification} updateCustomerState={this.updateCustomerState}/> : null}
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerProfile));