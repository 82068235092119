import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    FormText,
    Input,
    InputGroup
} from "reactstrap";
import Switch from "react-switch";
import { FiGlobe } from "react-icons/fi";
// Components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
// Decorators
import withRouter from "utilities/withRouter";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import Api from 'api/index';

class AppPreferences extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productsEnabled: false,
            waitListEnabled: false,
            contactCallEnabled: false,
            contactSmsEnabled: false,
            contactEmailEnabled: false
        };
    }

    componentDidMount() {
        this.getAppPreferences();
    }

    async getAppPreferences() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let response = await Api.getAppPreferences();
            let preferencesData = response.data.appPreferencesData;
            let productsEnabled = (preferencesData.app_business_products_enabled === 1);
            let waitListEnabled = (preferencesData.app_business_wait_list_enabled === 1);
            let contactCallEnabled = (preferencesData.contact_call_enabled === 1);
            let contactSmsEnabled = (preferencesData.contact_sms_enabled === 1);
            let contactEmailEnabled = (preferencesData.contact_email_enabled === 1);
            this.setState({ productsEnabled, waitListEnabled, contactCallEnabled, contactSmsEnabled, contactEmailEnabled });
            this.props.actions.loadLoadingSpinner(false);
        } catch(e) {
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("An unexpected error occured while trying to load your app preferences.", "danger", "bc", 4);
            this.props.navigate("/admin/settings");
        }
    }

    handleChange(e, name) {
        this.setState({ [name]: e })
    }

    async submitPreferences() {
        try {
            this.props.actions.loadLoadingSpinner(true);
            let data = {
                products_enabled: this.state.productsEnabled,
                wait_list_enabled: this.state.waitListEnabled,
                contact_call_enabled: this.state.contactCallEnabled,
                contact_sms_enabled: this.state.contactSmsEnabled,
                contact_email_enabled: this.state.contactEmailEnabled
            };
            await Api.updateAppPreferences(data);
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("App preferences updated successfully", "success", "bc", 4);
            this.props.navigate("/admin/settings");
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while trying to update your app preferences. Please contact Support if the problem persists.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    getBookingUrl() {
        return this.props.business.booking_url ? this.props.business.booking_url : 'Not set';
    }

    copyBookingUrl() {
        if(this.props.business.booking_url) {
            let booking_url = this.getBookingUrl();
            navigator.clipboard.writeText(booking_url);
            this.props.triggerNotification("Booking URL copied to clipboard", "success", "bc", 2);
        }
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>App Preferences</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12} className="mb-2">
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>Products</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'productsEnabled') }}
                                                checked={this.state.productsEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                        <FormText color="muted" style={{ marginTop: '1rem' }}>
                                            Enabling products will allow your customers to view products on your app. All of the products which have retail sales enabled will be shown.
                                            You can currently add new products and/or edit existing products on Hub V1 - this functionality will be coming to Hub V2 shortly.
                                        </FormText>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12} className="mb-2">
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>Wait List</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'waitListEnabled') }}
                                                checked={this.state.waitListEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                        <FormText color="muted" style={{ marginTop: '1rem' }}>
                                            The wait list allows your customers to be automatically notified when a booking slot becomes available. If wait lists are enabled your customers will have the option to receive notifications on a specific date and in a preferred time range.
                                            This is a great way to automatically notify your customers of cancellations!
                                            <br/><br/>
                                            For example, suppose that a customer isn't able to find any availability on 23rd December and the customer is only available between 09:00 and 12:00. If the wait list feature is enabled, the customer can decide to join the wait list on the 23rd December and select their preferred times (between 09:00 and 12:00 in this example).
                                            In the event that availability opens up on that date, and within their preferred time range, they will be automatically notified that a booking slot has become available.
                                        </FormText>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {this.props.business.business_account_type_id === 3 ?
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>Booking Link</p>
                                        </Col>
                                        <Col md={12} sm={12}>
                                            <FormText color="muted" style={{ margin: '1rem 0' }}>
                                                Users of your app will be directed to the following booking link to book with you. Please contact your consultant if you would like to change your booking link.
                                            </FormText>
                                            <InputGroup onClick={() => this.copyBookingUrl()}>
                                                <div style={{ lineHeight: '43px', padding: '0px 14px', border: '1px solid #e3e3e3', borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                                                    <FiGlobe size={22} color={'#b1b1b1'}/>
                                                </div>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={this.getBookingUrl()}
                                                    style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        : null}
                        <Card style={{ marginBottom: 0 }}>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12}>
                                        <p style={{ fontSize: '1.2em', fontWeight: '600', paddingTop: 0, color: '#0a122b' }}>Contact Methods</p>
                                    </Col>
                                    <Col md={12} sm={12} className="mb-2">
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>Email</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'contactEmailEnabled') }}
                                                checked={this.state.contactEmailEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>Call</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'contactCallEnabled') }}
                                                checked={this.state.contactCallEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>SMS</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'contactSmsEnabled') }}
                                                checked={this.state.contactSmsEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                        <FormText color="muted" style={{ marginTop: '1rem' }}>
                                            Choose which contact methods are available to your app users when they try to contact you. The enabled contact options will be shown in the 'Contact Us' popup on the app home screen. The following contact details will be shown:
                                        </FormText>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <label>Email</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.props.business ? this.props.business.business_email : 'Not set'}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <label>Contact Number</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.props.business ? '+' + this.props.business.business_phone_no : 'Not set'}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormText color="muted">
                                            <b>Note</b>: You can modify these contact details in the Business Settings screen.
                                        </FormText>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <div className="action-button-row">
                                    <Button color="primary" onClick={() => this.submitPreferences()}>
                                        Save Changes
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppPreferences));