import React from "react";
import moment from "moment";
import 'moment-timezone';
// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardFooter,
    Input,
    FormGroup,
    Spinner,
    Alert,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import CountUp from 'react-countup';
// API
import Api from 'api/index';
// Constants
import { CDN_URL } from "constants/urls";
// Decorators
import withRouter from "utilities/withRouter";

class HubAppearance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tokenCountData: null,
            appData: null,
            title: null,
            message: null,
            loading: false,
            success: false,
            notificationModalVisible: false,
            notificationData: null,
            notificationPage: 1,
            notificationLoading: false
        };
    }

    componentDidMount() {
        this.getPushNotificationInformation();
    }

    handleChange(e, name) {
        let inputValue = e.target.value;
        if(name === "title") {
            this.setState({ title: inputValue });
        } else if(name === "message") {
            this.setState({ message: inputValue });
        }
    }

    async getPushNotificationInformation() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let response = await Api.getPushNotificationInformation();
            let tokenCountData = response.data.tokenCountData;
            let appData = response.data.appData;
            this.setState({ tokenCountData, appData });
            this.props.actions.loadLoadingSpinner(false);
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while loading push notification information.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
            this.props.navigate("/admin/settings");
        }
    }

    async submitPushNotification() {
        if(this.state.loading || this.state.success) {
            return;
        }
        if(!this.state.title) {
            this.setState({ error: 'A title is required.' });
            return;
        }
        if(!this.state.message) {
            this.setState({ error: 'A message is required.' });
            return;
        }
        if(this.state.title && this.state.title.length > 40) {
            this.setState({ error: 'The character limit for the title is 40 characters.' });
            return;
        }
        if(this.state.message && this.state.message.length > 150) {
            this.setState({ error: 'The character limit for the message is 150 characters.' });
            return;
        }
        if(this.state.tokenCountData && this.state.tokenCountData.iosCount === 0 && this.state.tokenCountData.androidCount === 0) {
            this.setState({ error: 'There are currently no devices registered to receive push notifications.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            await Api.sendPushNotification({ title: this.state.title, body: this.state.message });
            this.setState({ loading: false, success: true });
            this.props.triggerNotification("Push notification sent successfully.", "success", "bc", 4);
        } catch(err) {
            this.setState({ loading: false, error: 'Unable to send push notification. If the problem persists, please contact Support.' });
        }
    }

    renderPushNotificationPreview() {
        return (
            <div className="push-notification-container">
                <div className="text-muted" style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <div
                            className="push-notification-icon"
                            style={this.state.appData && this.state.appData.business_app_icon ? { backgroundImage: `url(${CDN_URL}/businessApps/` + this.state.appData.business_app_icon + ")" } : null}
                        />
                        <div class="text-uppercase">{this.state.appData && this.state.appData.business_app_name ? this.state.appData.business_app_name : "APP NAME"}</div>
                    </div>
                    <div>Now</div>
                </div>
                {this.state.title ? <div className="push-notification-title">{this.state.title}</div> : <div className="push-notification-title-placeholder"/>}
                {this.state.message ?
                    <div className="push-notification-message">{this.state.message}</div>
                :
                    <div>
                        <div className="push-notification-message-placeholder"/>
                        <div className="push-notification-message-placeholder"/>
                    </div>
                }
            </div>
        );
    }

    renderPushNotificationDevices() {
        return (
            <Row>
                <Col md={12} sm={12} xs={12}>
                    <div className="mb-2">
                        <div className="text-bold">Audience</div>
                        <small>Devices which have opted to receive push notifications from your app.</small>
                    </div>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <div className="mb-3">
                        <FaApple size={28} style={{ marginRight: 5 }}/>
                        iOS: {this.state.tokenCountData && this.state.tokenCountData.iosCount > 0 ? <CountUp start={0} end={this.state.tokenCountData.iosCount} duration={1}/> : 0} devices
                    </div>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <div className="mb-3">
                        <FcGoogle size={28} style={{ marginRight: 5 }}/>
                        Android: {this.state.tokenCountData && this.state.tokenCountData.androidCount > 0 ? <CountUp start={0} end={this.state.tokenCountData.androidCount} duration={1}/> : 0} devices
                    </div>
                </Col>
            </Row>
        );
    }

    async getPushNotifications() {
        this.setState({ notificationLoading: true });
        try {
            let response = await Api.getPushNotifications();
            this.setState({ notificationLoading: false, notificationData: response.data.pushNotificationData });
        } catch(e) {
            this.setState({ notificationLoading: false });
            this.props.triggerNotification("Unable to load push notifications. If the problem persists, please contact Support.", "danger", "bc", 6);
        }
    }

    toggleNotificationModalVisible() {
        if(this.state.notificationModalVisible === false && !this.state.notificationData) {
            this.getPushNotifications();
        }
        this.setState({ notificationModalVisible: !this.state.notificationModalVisible });
    }

    loadNextNotificationPage() {
        let nextPage = this.state.notificationPage + 1;
        this.setState({ notificationPage: nextPage });
    }

    renderNotificationModal() {
        return (
            <Modal
                isOpen={this.state.notificationModalVisible}
                toggle={() => this.toggleNotificationModalVisible()}
                className="text-center"
            >
                <ModalHeader
                    className="justify-content-center uppercase title"
                    toggle={() => this.toggleNotificationModalVisible()}
                    tag="h4"
                >
                    Push Notifications
                </ModalHeader>
                <ModalBody className="text-start">
                    {this.state.notificationData && this.state.notificationData.length > 0 ?
                        <div className="push-notification-list-container">
                            {this.state.notificationData.slice(0, 5 * this.state.notificationPage).map((notificationObj, notificationIndex) => {
                                return (
                                    <div className="push-notification-container" style={{ marginBottom: 15 }}>
                                        <div className="text-muted" style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    className="push-notification-icon"
                                                    style={this.state.appData && this.state.appData.business_app_icon ? { backgroundImage: `url(${CDN_URL}/businessApps/` + this.state.appData.business_app_icon + ")" } : null}
                                                />
                                                <div class="text-uppercase">{this.state.appData && this.state.appData.business_app_name ? this.state.appData.business_app_name : "APP NAME"}</div>
                                            </div>
                                            <div>{this.props.business && this.props.business.timezone_name ? moment.utc(notificationObj.date_created, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, false).format("D MMM YY, HH:mm") : moment(notificationObj.date_created, 'YYYY-MM-DD HH:mm:ss').format("D MMM YY, HH:mm")}</div>
                                        </div>
                                        <div className="push-notification-title">{notificationObj.app_business_notification_title}</div>
                                        <div className="push-notification-message">{notificationObj.app_business_notification}</div>
                                    </div>
                                );
                            })}
                            {this.state.notificationData.length > this.state.notificationPage * 5 ?
                                <Button color="primary" className="btn-round" outline block style={{ margin: 0 }} onClick={() => this.loadNextNotificationPage()}>Load more...</Button>
                            : null}
                        </div>
                    : (this.state.notificationLoading ? <div className="text-center"><Spinner color="success" size="lg" /></div> : <div className="text-center">No notifications to display</div>)}
                </ModalBody>
            </Modal>
        );
    }

    render() {
        const sendPermission = this.props.userRole.notification.send === true;
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Push Notifications</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={10} md={8} lg={6} xl={5} className="ms-auto me-auto">
                        <Card style={{ marginBottom: 0 }}>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        {this.renderPushNotificationDevices()}
                                    </Col>
                                </Row>
                                <hr className="mt-0"/>
                                <Row>
                                    <Col md={12}>
                                        <p className="text-bold">Preview</p>
                                        {this.renderPushNotificationPreview()}
                                    </Col>
                                </Row>
                                {sendPermission && (
                                    <>
                                        <hr/>
                                        <p className="text-muted" style={{ fontSize: 13 }}>
                                            The character limit is 40 characters for the title and 150 characters for the message.
                                            This limit ensures that the full push notification is displayed on most devices and operating systems.
                                        </p>
                                        <Row>
                                            <Col md={12}>
                                                <label>Title <span className="text-danger">*</span></label>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        onChange={(e) => this.handleChange(e, 'title')}
                                                        disabled={this.state.loading || this.state.success}
                                                    />
                                                    <div className={"pull-right " + (this.state.title && this.state.title.length > 40 ? "text-danger" : "text-muted")}>{this.state.title ? 40 - this.state.title.length : 40}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <label>Message <span className="text-danger">*</span></label>
                                                <FormGroup>
                                                    <Input
                                                        type="textarea"
                                                        style={{ padding: 10 }}
                                                        value={this.state.message}
                                                        onChange={(e) => this.handleChange(e, 'message')}
                                                        disabled={this.state.loading || this.state.success}
                                                    />
                                                    <div className={"pull-right " + (this.state.message && this.state.message.length > 150 ? "text-danger" : "text-muted")}>{this.state.message ? 150 - this.state.message.length : 150}</div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </CardBody>
                            <CardFooter>
                                {sendPermission && (
                                    <Button className="btn-sm-block" color={this.state.success ? "success" : "primary"} onClick={() => this.submitPushNotification()}>
                                        {this.state.loading ? <Spinner color="success" size="sm" /> : (this.state.success ? "Sent" : "Send Notification")}
                                    </Button>
                                )}
                                <Button className={`btn-sm-block ${sendPermission ? "pull-right" : ""}`} color="secondary" onClick={() => this.toggleNotificationModalVisible()}>
                                    View Notifications
                                </Button>
                                <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error ? true : false} toggle={() => this.setState({ error: null })} style={{ fontSize: 13 }}>
                                    <span>
                                        {this.state.error}
                                    </span>
                                </Alert>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
            {this.renderNotificationModal()}
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        userRole: state.userRole
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HubAppearance));