import React from "react";
import { CopyBlock, ocean } from "react-code-blocks";
// reactstrap components
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Input,
    InputGroup,
    FormText
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import { FiGlobe } from "react-icons/fi";

import Lottie from 'react-lottie';
import * as appStatusDeployed from 'assets/animations/appStatus/appStatusNotStarted.json';

class BookingWidget extends React.Component {

    getBookingUrl() {
        let business_slug = this.props.business.business_slug.replace(" ", "%20");
        return "https://booking.styler.digital/?business=" + business_slug;
    }

    copyBookingUrl() {
        let booking_url = this.getBookingUrl();
        navigator.clipboard.writeText(booking_url);
        this.props.triggerNotification("Booking URL copied to clipboard", "success", "bc", 2);
    }

    handleOpenWidget() {
        try {
            let booking_url = this.getBookingUrl();
            window.open(booking_url);
        } catch(e) {
            this.props.triggerNotification("Unable to open booking widget", "success", "bc", 6);
        }
    }

    renderStatusAnimation() {
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: appStatusDeployed.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div>
                <Lottie options={defaultOptions}/>
            </div>
        );
    }

    renderCodeBlock(name) {
        let booking_url = this.getBookingUrl();
        let code;
        if(name === 'button') {
            code = 
`<a style="background-color: #0a122b; color: #ffffff; padding: 10px 15px; border-radius: 5px; font-family: 'Public Sans', Arial, Helvetica, sans-serif; font-size: 14px; text-decoration: none;" href="${booking_url}">
    <img alt="image" height="22px" width="22px" style="margin-bottom: -5px;" src="http://cdn.mcauto-images-production.sendgrid.net/551b5c931db6d8e0/029dce40-33e8-42ec-a716-6b7cccbd135b/1000x1000.png">
    <div style="padding-left: 5px; display: inline-block;">Book Now</div>
</a>`;
        } else {
            code =
`<iframe id="whatstyle-booking-widget" src="${booking_url}" style="width: 100%; max-width: 1200px; height: 750px; display: block; margin-left: auto; margin-right: auto;"/>`;
        }
        return (
            <div style={{ borderRadius: 6, border: '1px solid rgb(227, 227, 227)' }}>
                <CopyBlock
                    text={code}
                    language={'html'}
                    showLineNumbers={false}
                    theme={ocean}
                    codeBlock={true}
                />
            </div>
        );
    }

    render() {
        return (
            <>
                <PanelHeader
                    size="md"
                    content={
                        <Row>
                            <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                                <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>
                                    Booking Widget
                                </h2>
                            </Col>
                        </Row>
                    }
                />
                <div className="content">
                    <Row>
                        <Col xs={12} sm={11} md={10} lg={9} xl={6} className="ms-auto me-auto">
                            <Card>
                                <CardHeader>
                                    <h6 className="title mb-0">Status</h6>
                                </CardHeader>
                                <CardBody style={{ padding: 10 }}>
                                    <div className="action-button-row">
                                        <div className="app-status-container">
                                            <div style={{ height: 60, width: 60 }}>
                                                {this.renderStatusAnimation()}
                                            </div>
                                            <div className="app-status-title">
                                                Deployed
                                            </div>
                                        </div>
                                        <Button color="primary" onClick={() => this.handleOpenWidget()}>
                                            Open
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <h6 className="title mb-0">Design</h6>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                                If you would like to change the design of your booking widget please contact your Styler Consultant for further details.
                                            </FormText>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card style={{ marginBottom: 0 }}>
                                <CardHeader>
                                    <h6 className="title mb-0">Embedding & Linking Options</h6>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                                <b>Need help embedding the widget on your site?</b> Please contact your Styler Consultant and a member of our development team can provide assistance.
                                            </FormText>
                                        </Col>
                                        <Col md={12} sm={12}>
                                            <label>Direct Link</label>
                                            <InputGroup onClick={() => this.copyBookingUrl()}>
                                                <div style={{ lineHeight: '43px', padding: '0px 14px', border: '1px solid #e3e3e3', borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                                                    <FiGlobe size={22} color={'#b1b1b1'}/>
                                                </div>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={this.getBookingUrl()}
                                                    style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col md={12} sm={12}>
                                            <div className="mb-2">
                                                <label>HTML Button</label>
                                                {this.renderCodeBlock('button')}
                                            </div>
                                        </Col>
                                        <Col md={12} sm={12}>
                                            <div>
                                                <label>Embedded iframe</label>
                                                {this.renderCodeBlock('iframe')}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(BookingWidget);