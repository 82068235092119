import React, { useState, useEffect } from 'react';

import {
    Button,
    Modal,
    ModalBody,
  } from "reactstrap";

function CalendarNewEventOptions(props) {
    const [visible, setVisible] = useState(false);
    const [visibleDate, setVisibleDate] = useState(null);

    useEffect(() => {
        if(props.visible) {
            setVisible(true);
            setVisibleDate(Date.now());
        }
    }, []);

    useEffect(() => {
        if(props.visible) {
            const unixDate = Date.now();
            setVisible(true);
            setVisibleDate(unixDate);
        } else {
            setVisible(false);
            setVisibleDate(null);
        }
    }, [props.visible]);

    const handleToggleVisible = () => {
        if(!visible) return
        const unixDate = Date.now();
        if(unixDate - visibleDate < 500) {
            return;
        }
        setVisible(false);
        setVisibleDate(null);
        props.toggleVisible();
    };

    const {
        selectedDate,
        selectedTime,
        selectedStaffId,
        selectedBusinessLocationId,
        userRole
    } = props;
    // Access permission
    const createBooking = userRole?.booking.create;
    const createBreak = userRole?.break.create;
    return (
        <Modal
            isOpen={visible}
            toggle={() => handleToggleVisible()}
            size="mini"
            modalClassName="modal-primary"
        >
            <ModalBody>
                <p style={{ marginBottom: 8 }}>New event at</p>
                <h4 className="text-center" style={{ marginTop: 0 }}>{selectedTime}</h4>
                {createBooking && (
                    <>
                        <Button
                            block
                            className="btn-block"
                            onClick={() => props.navigate(`/admin/add_booking?date=${selectedDate}&time=${selectedTime}&staff_id=${selectedStaffId}&business_location_id=${selectedBusinessLocationId}`)}
                        >
                            Booking
                        </Button>
                        <Button
                            block
                            className="btn-block"
                            onClick={() => props.navigate(`/admin/add_booking?walkin=true&date=${selectedDate}&time=${selectedTime}&staff_id=${selectedStaffId}&business_location_id=${selectedBusinessLocationId}`)}
                        >
                            Walk-in
                        </Button>  
                    </>
                )}
                {createBreak && (
                    <Button
                        block
                        className="btn-block"
                        onClick={(e) => props.breakClick(e)}
                    >
                        Break
                    </Button>
                )}
            </ModalBody>
        </Modal>
    );
}

export default CalendarNewEventOptions;