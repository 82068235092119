import { loadStripe } from '@stripe/stripe-js';

let publishableKey = null;
if(process.env.NODE_ENV === "production") {
    // PROD
    publishableKey = 'pk_live_51GxzB6FQ8tU2VmRuSkgfhmWioyfOQwEYnCX2TBrbKNYlKGD9YZkufezTf9Zy5CCTmxsOz1HwagrrmGPcxvlgPYiY00odjejsAJ';
} else {
    // DEV
    publishableKey = 'pk_test_51GxzB6FQ8tU2VmRuTC3ufD9Vty8befyESFWsIsl9q5pyZEjl996fwL2bRx2wwLA1QCDfAHe5mo5dqNzidEkOGf9l00uBZXYCNp';
}

const stripe = loadStripe(publishableKey);

export default stripe;