import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormText
} from "reactstrap";
import { FiDatabase, FiEdit, FiSettings, FiEye, FiZap } from "react-icons/fi";
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import Api from 'api/index';
import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import Lottie from 'react-lottie';
// Assets
import * as appStatusInProgress from 'assets/animations/appStatus/appStatusInProgress.json';
import * as appStatusNotStarted from 'assets/animations/appStatus/appStatusDeployed.json';
import * as appStatusDeployed from 'assets/animations/appStatus/appStatusNotStarted.json';
import * as appDesign from 'assets/animations/appStatus/appDesign.json';
import * as appOnboarding from 'assets/animations/appStatus/appOnboarding.json';
import * as appTesting from 'assets/animations/appStatus/appTesting.json';
import AppIconPlaceholder from "assets/img/placeholders/AppIconPlaceholder.jpg";
import AppStoreBadge from "assets/img/placeholders/AppStoreBadge.svg";
import GooglePlayBadge from "assets/img/placeholders/GooglePlayBadge.png";
// Contants
import { CDN_URL } from "constants/urls";
// Decorator
import withRouter from "utilities/withRouter";

class AppStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ios_status_id: 1,
            ios_version: null,
            ios_store_url: null,
            android_status_id: 1,
            android_version: null,
            android_store_url: null,
            app_icon: null
        };
    }

    componentDidMount() {
        this.getAppStatus();
    }

    async getAppStatus() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let response = await Api.getAppStatus();
            let appStatusData = response.data.appStatusData;
            this.setState({
                ios_status_id: appStatusData.app_business_apple_status_id,
                ios_version: appStatusData.app_business_apple_version,
                ios_store_url: appStatusData.app_business_apple_url,
                android_status_id: appStatusData.app_business_google_status_id,
                android_version: appStatusData.app_business_google_version,
                android_store_url: appStatusData.app_business_google_url,
                app_icon: appStatusData.app_business_icon
            });
            this.props.actions.loadLoadingSpinner(false);
        } catch(e) {
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("An unexpected error occured while trying to load your app status.", "danger", "bc", 4);
            this.props.navigate("/admin/settings");
        }
    }

    renderAppAnimation(iosStatusId, androidStatusId) {
        let animation = null;
        if(iosStatusId === 1 || androidStatusId === 1) {
            animation = appOnboarding;
        } else if([2].includes(iosStatusId) || [2].includes(androidStatusId === 2)) {
            animation = appDesign;
        } else {
            animation = appTesting;
        }
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: animation.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}/>
        );
    }

    renderAppStatusAnimation(appStatusId) {
        let animation = null;
        if(appStatusId === 1 || appStatusId === 7) {
            animation = appStatusNotStarted.default;
        } else if(appStatusId === 2 || appStatusId === 3 || appStatusId === 4 || appStatusId === 6) {
            animation = appStatusInProgress.default;
        } else {
            animation = appStatusDeployed.default;
        }
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: animation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div>
                <Lottie options={defaultOptions}/>
            </div>
        );
    }

    getStatusTimelineProgress(appStatusId) {
        let progress = 0;
        if(appStatusId === 1) {
            progress = 10;
        } else if(appStatusId === 2) {
            progress = 30;
        } else if(appStatusId === 3) {
            progress = 50;
        } else if(appStatusId === 4) {
            progress = 70;
        } else if(appStatusId === 5) {
            progress = 90;
        } else if(appStatusId === 6) {
            progress = 90;
        }
        return progress;
    }

    renderStatusTimeline(iosStatusId, androidStatusId) {
        let iosProgress = this.getStatusTimelineProgress(iosStatusId);
        let androidProgress = this.getStatusTimelineProgress(androidStatusId);
        return (
            <Card className="card-timeline card-plain">
                <CardBody className="pe-0">
                    <ul className="timeline timeline-simple timeline-status pt-0 mb-0 pb-0">
                        <div className="app-status-progress-timeline-container">
                            <div style={{ marginTop: -27, paddingBottom: 5, display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <FaApple size={18} color={window.innerWidth > 767 ? '#ffffff' : '#000000'}/>
                                    <div className="app-status-progress-overlay-1" style={{ height: '100%' }}/>
                                    <div className="app-status-progress-overlay-1" style={{ height: iosProgress + '%', backgroundColor: '#15987e' }}/>
                                </div>
                                <div>
                                    <FcGoogle size={18} color={'#ffffff'}/>
                                    <div className="app-status-progress-overlay-2" style={{ height: '100%' }}/>
                                    <div className="app-status-progress-overlay-2" style={{ height: androidProgress + '%', backgroundColor: '#15987e' }}/>
                                </div>
                            </div>
                        </div>
                        <li className="timeline-inverted">
                            <div className={["timeline-badge", iosStatusId > 1 && androidStatusId > 1 ? "success" : "muted", iosStatusId === 1 || androidStatusId === 1 ? "warning" : null].join(' ')}>
                                <FiDatabase size={21}/>
                            </div>
                            <div className="timeline-panel mb-0">
                                <div className="timeline-body">
                                    Data Onboarding
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted mb-0">
                            <div className={["timeline-badge", iosStatusId > 2 && androidStatusId > 2 ? "success" : "muted", iosStatusId === 2 || androidStatusId === 2 ? "warning" : null].join(' ')}>
                                <FiEdit size={21}/>
                            </div>
                            <div className="timeline-panel">
                                <div className="timeline-body">
                                    Design
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted mb-0">
                            <div className={["timeline-badge", iosStatusId > 3 && androidStatusId > 3 ? "success" : "muted", iosStatusId === 3 || androidStatusId === 3 ? "warning" : null].join(' ')}>
                                <FiSettings size={21}/>
                            </div>
                            <div className="timeline-panel">
                                <div className="timeline-body">
                                    Testing
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted mb-0">
                            <div className={["timeline-badge", iosStatusId > 4 && androidStatusId > 4 ? "success" : "muted", iosStatusId === 4 || androidStatusId === 4 ? "warning" : null].join(' ')}>
                                <FiEye size={21}/>
                            </div>
                            <div className="timeline-panel">
                                <div className="timeline-body">
                                    In Review
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted mb-0">
                            <div className={["timeline-badge", iosStatusId >= 5 || androidStatusId >= 5 ? "success" : "muted"].join(' ')}>
                                <FiZap size={21}/>
                            </div>
                            <div className="timeline-panel">
                                <div className="timeline-body">
                                    Deployed
                                </div>
                            </div>
                        </li>
                    </ul>
                </CardBody>
            </Card>
        );
    }

    renderAppStatus(appStatusId) {
        if(appStatusId === 1) {
            return "Data Onboarding";
        } else if(appStatusId === 2) {
            return "Design";
        } else if(appStatusId === 3) {
            return "Testing";
        } else if(appStatusId === 4) {
            return "In Review";
        } else if(appStatusId === 5) {
            return "Deployed";
        } else if(appStatusId === 6) {
            return "Updating";
        } else if(appStatusId === 7) {
            return "Removed";
        } else {
            return "Unknown";
        }
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>App Status</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} lg={12} xl={8} className="ms-auto me-auto">
                        <Row>
                            <Col sm={12} md={7} lg={6} xl={6} className="ps-0 pe-0">
                                <div className="app-status-animation-container">
                                    {this.renderAppAnimation(this.state.ios_status_id, this.state.android_status_id)}
                                </div>
                            </Col>
                            <Col sm={12} md={5} lg={6} xl={6}>
                                {this.renderStatusTimeline(this.state.ios_status_id, this.state.android_status_id)}
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <CardHeader className="text-center">
                                        <h5 className="mb-0"><FaApple size={26} style={{ marginRight: 5, marginTop: -4 }}/>iOS</h5>
                                        <hr className="mb-0" />
                                    </CardHeader>
                                    <CardBody>
                                        <div className="text-center">
                                            <img className="ios-app-icon" src={this.state.app_icon ? `${CDN_URL}/businessApps/` + this.state.app_icon : AppIconPlaceholder} alt="app-icon" />
                                        </div>
                                        <div className="app-status-container">
                                            <div style={{ height: 60, width: 60 }}>
                                                {this.renderAppStatusAnimation(this.state.ios_status_id)}
                                            </div>
                                            <div className="app-status-title">
                                                {this.renderAppStatus(this.state.ios_status_id)}
                                            </div>
                                        </div>
                                        {this.state.ios_store_url ?
                                            <div className="text-center mb-3">
                                                <a href={this.state.ios_store_url} target="_blank" rel="noreferrer noopener">
                                                    <img src={AppStoreBadge} alt="apple-app-store-badge" style={{ height: 40 }} />
                                                </a>
                                            </div>
                                        : null}
                                        {this.state.ios_version ? <div className="app-status-version">V{this.state.ios_version}</div> : null}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <CardHeader className="text-center">
                                        <h5 className="mb-0"><FcGoogle size={26} style={{ marginRight: 5, marginTop: -4 }}/>Android</h5>
                                        <hr className="mb-0" />
                                    </CardHeader>
                                    <CardBody>
                                        <div className="text-center">
                                            <img className="android-app-icon" src={this.state.app_icon ? `${CDN_URL}/businessApps/` + this.state.app_icon : AppIconPlaceholder} alt="app-icon" />
                                        </div>
                                        <div className="app-status-container">
                                            <div style={{ height: 60, width: 60 }}>
                                                {this.renderAppStatusAnimation(this.state.android_status_id)}
                                            </div>
                                            <div className="app-status-title">
                                                {this.renderAppStatus(this.state.android_status_id)}
                                            </div>
                                        </div>
                                        {this.state.android_store_url ?
                                            <div className="text-center mb-3">
                                                <a href={this.state.android_store_url} target="_blank" rel="noreferrer noopener">
                                                    <img src={GooglePlayBadge} alt="google-play-badge" style={{ height: 40 }} />
                                                </a>
                                            </div>
                                        : null}
                                        {this.state.android_version ? <div className="app-status-version">V{this.state.android_version}</div> : null}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <FormText className="text-center">Apple, Apple App Store and the Apple logo(s) are trademarks of Apple Inc. Google, Google Play and the Google Play logo are trademarks of Google LLC.</FormText>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppStatus));