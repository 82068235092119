const timezone = [
    {"id": 1, "label": "(GMT -11:00) Pacific/Niue", "value": "Pacific/Niue"},
    {"id": 2, "label": "(GMT -11:00) Pacific/Pago Pago", "value": "Pacific/Pago_Pago"},
    {"id": 3, "label": "(GMT -10:00) America/Adak", "value": "America/Adak"},
    {"id": 4, "label": "(GMT -10:00) Pacific/Honolulu", "value": "Pacific/Honolulu"},
    {"id": 5, "label": "(GMT -10:00) Pacific/Rarotonga", "value": "Pacific/Rarotonga"},
    {"id": 6, "label": "(GMT -10:00) Pacific/Tahiti", "value": "Pacific/Tahiti"},
    {"id": 7, "label": "(GMT -9:30) Pacific/Marquesas", "value": "Pacific/Marquesas"},
    {"id": 8, "label": "(GMT -9:00) America/Anchorage", "value": "America/Anchorage"},
    {"id": 9, "label": "(GMT -9:00) America/Juneau", "value": "America/Juneau"},
    {"id": 10, "label": "(GMT -9:00) America/Metlakatla", "value": "America/Metlakatla"},
    {"id": 11, "label": "(GMT -9:00) America/Nome", "value": "America/Nome"},
    {"id": 12, "label": "(GMT -9:00) America/Sitka", "value": "America/Sitka"},
    {"id": 13, "label": "(GMT -9:00) America/Yakutat", "value": "America/Yakutat"},
    {"id": 14, "label": "(GMT -9:00) Pacific/Gambier", "value": "Pacific/Gambier"},
    {"id": 15, "label": "(GMT -9:00) America/Los Angeles", "value": "America/Los_Angeles"},
    {"id": 16, "label": "(GMT -8:00) America/Tijuana", "value": "America/Tijuana"},
    {"id": 17, "label": "(GMT -8:00) America/Vancouver", "value": "America/Vancouver"},
    {"id": 18, "label": "(GMT -8:00) Pacific/Pitcairn", "value": "Pacific/Pitcairn"},
    {"id": 19, "label": "(GMT -7:00) America/Boise", "value": "America/Boise"},
    {"id": 20, "label": "(GMT -7:00) America/Cambridge Bay", "value": "America/Cambridge_Bay"},
    {"id": 21, "label": "(GMT -7:00) America/Chihuahua", "value": "America/Chihuahua"},
    {"id": 22, "label": "(GMT -7:00) America/Dawson", "value": "America/Dawson"},
    {"id": 23, "label": "(GMT -7:00) America/Dawson Creek", "value": "America/Dawson_Creek"},
    {"id": 29, "label": "(GMT -7:00) America/Denver", "value": "America/Denver"},
    {"id": 30, "label": "(GMT -7:00) America/Edmonton", "value": "America/Edmonton"},
    {"id": 31, "label": "(GMT -7:00) America/Fort Nelson", "value": "America/Fort_Nelson"},
    {"id": 32, "label": "(GMT -7:00) America/Hermosillo", "value": "America/Hermosillo"},
    {"id": 33, "label": "(GMT -7:00) America/Inuvik", "value": "America/Inuvik"},
    {"id": 34, "label": "(GMT -7:00) America/Mazatlan", "value": "America/Mazatlan"},
    {"id": 35, "label": "(GMT -7:00) America/Ojinaga", "value": "America/Ojinaga"},
    {"id": 36, "label": "(GMT -7:00) America/Phoenix", "value": "America/Phoenix"},
    {"id": 37, "label": "(GMT -7:00) America/Whitehorse", "value": "America/Whitehorse"},
    {"id": 38, "label": "(GMT -7:00) America/Yellowknife", "value": "America/Yellowknife"},
    {"id": 39, "label": "(GMT -6:00) America/Bahia Banderas", "value": "America/Bahia_Banderas"},
    {"id": 40, "label": "(GMT -6:00) America/Belize", "value": "America/Belize"},
    {"id": 41, "label": "(GMT -6:00) America/Chicago", "value": "America/Chicago"},
    {"id": 42, "label": "(GMT -6:00) America/Costa Rica", "value": "America/Costa_Rica"},
    {"id": 43, "label": "(GMT -6:00) America/El Salvador", "value": "America/El_Salvador"},
    {"id": 44, "label": "(GMT -6:00) America/Guatemala", "value": "America/Guatemala"},
    {"id": 45, "label": "(GMT -6:00) America/Indiana/Knox", "value": "America/Indiana/Knox"},
    {"id": 46, "label": "(GMT -6:00) America/Indiana/Tell City", "value": "America/Indiana/Tell_City"},
    {"id": 47, "label": "(GMT -6:00) America/Managua", "value": "America/Managua"},
    {"id": 48, "label": "(GMT -6:00) America/Matamoros", "value": "America/Matamoros"},
    {"id": 49, "label": "(GMT -6:00) America/Menominee", "value": "America/Menominee"},
    {"id": 50, "label": "(GMT -6:00) America/Merida", "value": "America/Merida"},
    {"id": 51, "label": "(GMT -6:00) America/Mexico City", "value": "America/Mexico_City"},
    {"id": 52, "label": "(GMT -6:00) America/Monterrey", "value": "America/Monterrey"},
    {"id": 53, "label": "(GMT -6:00) America/North Dakota/Beulah", "value": "America/North_Dakota/Beulah"},
    {"id": 54, "label": "(GMT -6:00) America/North Dakota/Center", "value": "America/North_Dakota/Center"},
    {"id": 55, "label": "(GMT -6:00) America/North Dakota/New Salem", "value": "America/North_Dakota/New_Salem"},
    {"id": 56, "label": "(GMT -6:00) America/Rainy River", "value": "America/Rainy_River"},
    {"id": 57, "label": "(GMT -6:00) America/Rankin Inlet", "value": "America/Rankin_Inlet"},
    {"id": 58, "label": "(GMT -6:00) America/Regina", "value": "America/Regina"},
    {"id": 59, "label": "(GMT -6:00) America/Resolute", "value": "America/Resolute"},
    {"id": 60, "label": "(GMT -6:00) America/Swift Current", "value": "America/Swift_Current"},
    {"id": 61, "label": "(GMT -6:00) America/Tegucigalpa", "value": "America/Tegucigalpa"},
    {"id": 62, "label": "(GMT -6:00) America/Winnipeg", "value": "America/Winnipeg"},
    {"id": 63, "label": "(GMT -6:00) Pacific/Easter", "value": "Pacific/Easter"},
    {"id": 64, "label": "(GMT -6:00) Pacific/Galapagos", "value": "Pacific/Galapagos"},
    {"id": 65, "label": "(GMT -5:00) America/Bogota", "value": "America/Bogota"},
    {"id": 66, "label": "(GMT -5:00) America/Cancun", "value": "America/Cancun"},
    {"id": 67, "label": "(GMT -5:00) America/Detroit", "value": "America/Detroit"},
    {"id": 68, "label": "(GMT -5:00) America/Eirunepe", "value": "America/Eirunepe"},
    {"id": 69, "label": "(GMT -5:00) America/Grand Turk", "value": "America/Grand_Turk"},
    {"id": 70, "label": "(GMT -5:00) America/Guayaquil", "value": "America/Guayaquil"},
    {"id": 71, "label": "(GMT -5:00) America/Havana", "value": "America/Havana"},
    {"id": 72, "label": "(GMT -5:00) America/Indiana/Indianapolis", "value": "America/Indiana/Indianapolis"},
    {"id": 73, "label": "(GMT -5:00) America/Indiana/Marengo", "value": "America/Indiana/Marengo"},
    {"id": 74, "label": "(GMT -5:00) America/Indiana/Petersburg", "value": "America/Indiana/Petersburg"},
    {"id": 75, "label": "(GMT -5:00) America/Indiana/Vevay", "value": "America/Indiana/Vevay"},
    {"id": 76, "label": "(GMT -5:00) America/Indiana/Vincennes", "value": "America/Indiana/Vincennes"},
    {"id": 77, "label": "(GMT -5:00) America/Indiana/Winamac", "value": "America/Indiana/Winamac"},
    {"id": 78, "label": "(GMT -5:00) America/Iqaluit", "value": "America/Iqaluit"},
    {"id": 79, "label": "(GMT -5:00) America/Jamaica", "value": "America/Jamaica"},
    {"id": 80, "label": "(GMT -5:00) America/Kentucky/Louisville", "value": "America/Kentucky/Louisville"},
    {"id": 81, "label": "(GMT -5:00) America/Kentucky/Monticello", "value": "America/Kentucky/Monticello"},
    {"id": 82, "label": "(GMT -5:00) America/Lima", "value": "America/Lima"},
    {"id": 83, "label": "(GMT -5:00) America/New York", "value": "America/New_York"},
    {"id": 84, "label": "(GMT -5:00) America/Nipigon", "value": "America/Nipigon"},
    {"id": 85, "label": "(GMT -5:00) America/Panama", "value": "America/Panama"},
    {"id": 86, "label": "(GMT -5:00) America/Pangnirtung", "value": "America/Pangnirtung"},
    {"id": 87, "label": "(GMT -5:00) America/Port-au-Prince", "value": "America/Port-au-Prince"},
    {"id": 88, "label": "(GMT -5:00) America/Rio Branco", "value": "America/Rio_Branco"},
    {"id": 89, "label": "(GMT -5:00) America/Thunder Bay", "value": "America/Thunder_Bay"},
    {"id": 90, "label": "(GMT -5:00) America/Toronto", "value": "America/Toronto"},
    {"id": 91, "label": "(GMT -4:00) America/Asuncion", "value": "America/Asuncion"},
    {"id": 92, "label": "(GMT -4:00) America/Barbados", "value": "America/Barbados"},
    {"id": 93, "label": "(GMT -4:00) America/Boa Vista", "value": "America/Boa_Vista"},
    {"id": 94, "label": "(GMT -4:00) America/Campo Grande", "value": "America/Campo_Grande"},
    {"id": 95, "label": "(GMT -4:00) America/Caracas", "value": "America/Caracas"},
    {"id": 96, "label": "(GMT -4:00) America/Cuiaba", "value": "America/Cuiaba"},
    {"id": 97, "label": "(GMT -4:00) America/Glace Bay", "value": "America/Glace_Bay"},
    {"id": 98, "label": "(GMT -4:00) America/Goose Bay", "value": "America/Goose_Bay"},
    {"id": 99, "label": "(GMT -4:00) America/Guyana", "value": "America/Guyana"},
    {"id": 100, "label": "(GMT -4:00) America/Halifax", "value": "America/Halifax"},
    {"id": 101, "label": "(GMT -4:00) America/Manaus", "value": "America/Manaus"},
    {"id": 102, "label": "(GMT -4:00) America/Martinique", "value": "America/Martinique"},
    {"id": 103, "label": "(GMT -4:00) America/Moncton", "value": "America/Moncton"},
    {"id": 104, "label": "(GMT -4:00) America/Porto Velho", "value": "America/Porto_Velho"},
    {"id": 105, "label": "(GMT -4:00) America/Puerto Rico", "value": "America/Puerto_Rico"},
    {"id": 106, "label": "(GMT -4:00) America/Santiago", "value": "America/Santiago"},
    {"id": 107, "label": "(GMT -4:00) America/Santo Domingo", "value": "America/Santo_Domingo"},
    {"id": 108, "label": "(GMT -4:00) America/Thule", "value": "America/Thule"},
    {"id": 109, "label": "(GMT -4:00) Atlantic/Bermuda", "value": "Atlantic/Bermuda"},
    {"id": 110, "label": "(GMT -3:30) America/St Johns", "value": "America/St_Johns"},
    {"id": 111, "label": "(GMT -3:00) America/Araguaina", "value": "America/Araguaina"},
    {"id": 112, "label": "(GMT -3:00) America/Argentina/Buenos Aires", "value": "America/Argentina/Buenos_Aires"},
    {"id": 113, "label": "(GMT -3:00) America/Argentina/Catamarca", "value": "America/Argentina/Catamarca"},
    {"id": 114, "label": "(GMT -3:00) America/Argentina/Cordoba", "value": "America/Argentina/Cordoba"},
    {"id": 115, "label": "(GMT -3:00) America/Argentina/Jujuy", "value": "America/Argentina/Jujuy"},
    {"id": 116, "label": "(GMT -3:00) America/Argentina/La Rioja", "value": "America/Argentina/La_Rioja"},
    {"id": 117, "label": "(GMT -3:00) America/Argentina/Mendoza", "value": "America/Argentina/Rio_Gallegos"},
    {"id": 118, "label": "(GMT -3:00) America/Argentina/Rio Gallegos", "value": "America/Argentina/Rio_Gallegos"},
    {"id": 119, "label": "(GMT -3:00) America/Argentina/Salta", "value": "America/Argentina/Salta"},
    {"id": 120, "label": "(GMT -3:00) America/Argentina/San Juan", "value": "America/Argentina/San_Juan"},
    {"id": 121, "label": "(GMT -3:00) America/Argentina/San Luis", "value": " America/Argentina/San_Luis"},
    {"id": 122, "label": "(GMT -3:00) America/Argentina/Tucuman", "value": "America/Argentina/Tucuman"},
    {"id": 123, "label": "(GMT -3:00) America/Argentina/Ushuaia", "value": "America/Argentina/Ushuaia"},
    {"id": 124, "label": "(GMT -3:00) America/Bahia", "value": "America/Bahia"},
    {"id": 125, "label": "(GMT -3:00) America/Belem", "value": "America/Belem"},
    {"id": 126, "label": "(GMT -3:00) America/Cayenne", "value": "America/Cayenne"},
    {"id": 127, "label": "(GMT -3:00) America/Fortaleza", "value": "America/Fortaleza"},
    {"id": 128, "label": "(GMT -3:00) America/Maceio", "value": "America/Maceio"},
    {"id": 129, "label": "(GMT -3:00) America/Miquelon", "value": "America/Miquelon"},
    {"id": 130, "label": "(GMT -3:00) America/Montevideo", "value": "America/Montevideo"},
    {"id": 131, "label": "(GMT -3:00) America/Nuuk", "value": "America/Nuuk"},
    {"id": 132, "label": "(GMT -3:00) America/Paramaribo", "value": "America/Paramaribo"},
    {"id": 133, "label": "(GMT -3:00) America/Punta Arenas", "value": "America/Punta_Arenas"},
    {"id": 134, "label": "(GMT -3:00) America/Recife", "value": "America/Recife"},
    {"id": 135, "label": "(GMT -3:00) America/Santarem", "value": "America/Santarem"},
    {"id": 136, "label": "(GMT -3:00) America/Sao Paulo", "value": "America/Sao_Paulo"},
    {"id": 137, "label": "(GMT -3:00) Antarctica/Palmer", "value": "Antarctica/Palmer"},
    {"id": 138, "label": "(GMT -3:00) Antarctica/Rothera", "value": "Antarctica/Rothera"},
    {"id": 139, "label": "(GMT -3:00) Atlantic/Stanley", "value": "Atlantic/Stanley"},
    {"id": 140, "label": "(GMT -2:00) America/Noronha", "value": "America/Noronha"},
    {"id": 141, "label": "(GMT -2:00) Atlantic/South Georgia", "value": "Atlantic/South_Georgia"},
    {"id": 142, "label": "(GMT -1:00) America/Scoresbysund", "value": "America/Scoresbysund"},
    {"id": 143, "label": "(GMT -1:00) Atlantic/Azores", "value": "Atlantic/Azores"},
    {"id": 144, "label": "(GMT -1:00) Atlantic/Cape Verde", "value": "Atlantic/Cape_Verde"},
    {"id": 145, "label": "(GMT +0:00) Africa/Abidjan", "value": "Africa/Abidjan"},
    {"id": 146, "label": "(GMT +0:00) Africa/Bissau", "value": "Africa/Bissau"},
    {"id": 147, "label": "(GMT +0:00) Africa/Monrovia", "value": "Africa/Monrovia"},
    {"id": 148, "label": "(GMT +0:00) Africa/Sao Tome", "value": "Africa/Sao_Tome"},
    {"id": 149, "label": "(GMT +0:00) America/Danmarkshavn", "value": "America/Danmarkshavn"},
    {"id": 150, "label": "(GMT +0:00) Antarctica/Troll", "value": "Antarctica/Troll"},
    {"id": 151, "label": "(GMT +0:00) Atlantic/Canary", "value": "Atlantic/Canary"},
    {"id": 152, "label": "(GMT +0:00) Atlantic/Faroe", "value": "Atlantic/Faroe"},
    {"id": 153, "label": "(GMT +0:00) Atlantic/Madeira", "value": "Atlantic/Madeira"},
    {"id": 154, "label": "(GMT +0:00) Atlantic/Reykjavik", "value": "Atlantic/Reykjavik"},
    {"id": 155, "label": "(GMT +0:00) Europe/Lisbon", "value": "Europe/Lisbon"},
    {"id": 156, "label": "(GMT +0:00) Europe/London", "value": "Europe/London"},
    {"id": 157, "label": "(GMT +1:00) Africa/Algiers", "value": "Africa/Algiers"},
    {"id": 158, "label": "(GMT +1:00) Africa/Casablanca", "value": "Africa/Casablanca"},
    {"id": 159, "label": "(GMT +1:00) Africa/Ceuta", "value": "Africa/Ceuta"},
    {"id": 160, "label": "(GMT +1:00) Africa/El Aaiun", "value": "Africa/El_Aaiun"},
    {"id": 161, "label": "(GMT +1:00) Africa/Lagos", "value": "Africa/Lagos"},
    {"id": 162, "label": "(GMT +1:00) Africa/Ndjamena", "value": "Africa/Ndjamena"},
    {"id": 163, "label": "(GMT +1:00) Africa/Tunis", "value": "Africa/Tunis"},
    {"id": 164, "label": "(GMT +1:00) Europe/Amsterdam", "value": "Europe/Amsterdam"},
    {"id": 165, "label": "(GMT +1:00) Europe/Andorra", "value": "Europe/Andorra"},
    {"id": 166, "label": "(GMT +1:00) Europe/Belgrade", "value": "Europe/Belgrade"},
    {"id": 167, "label": "(GMT +1:00) Europe/Berlin", "value": "Europe/Berlin"},
    {"id": 168, "label": "(GMT +1:00) Europe/Brussels", "value": "Europe/Brussels"},
    {"id": 169, "label": "(GMT +1:00) Europe/Budapest", "value": "Europe/Budapest"},
    {"id": 170, "label": "(GMT +1:00) Europe/Copenhagen", "value": "Europe/Copenhagen"},
    {"id": 171, "label": "(GMT +1:00) Europe/Dublin", "value": "Europe/Dublin"},
    {"id": 172, "label": "(GMT +1:00) Europe/Gibraltar", "value": "Europe/Gibraltar"},
    {"id": 173, "label": "(GMT +1:00) Europe/Luxembourg", "value": "Europe/Luxembourg"},
    {"id": 174, "label": "(GMT +1:00) Europe/Madrid", "value": "Europe/Madrid"},
    {"id": 175, "label": "(GMT +1:00) Europe/Malta", "value": "Europe/Malta"},
    {"id": 176, "label": "(GMT +1:00) Europe/Monaco", "value": "Europe/Monaco"},
    {"id": 177, "label": "(GMT +1:00) Europe/Oslo", "value": "Europe/Oslo"},
    {"id": 178, "label": "(GMT +1:00) Europe/Paris", "value": "Europe/Paris"},
    {"id": 179, "label": "(GMT +1:00) Europe/Prague", "value": "Europe/Prague"},
    {"id": 180, "label": "(GMT +1:00) Europe/Rome", "value": "Europe/Rome"},
    {"id": 181, "label": "(GMT +1:00) Europe/Stockholm", "value": "Europe/Stockholm"},
    {"id": 182, "label": "(GMT +1:00) Europe/Tirane", "value": "Europe/Tirane"},
    {"id": 183, "label": "(GMT +1:00) Europe/Vienna", "value": "Europe/Vienna"},
    {"id": 184, "label": "(GMT +1:00) Europe/Warsaw", "value": "Europe/Warsaw"},
    {"id": 185, "label": "(GMT +1:00) Europe/Zurich", "value": "Europe/Zurich"},
    {"id": 186, "label": "(GMT +2:00) Africa/Cairo", "value": "Africa/Cairo"},
    {"id": 187, "label": "(GMT +2:00) Africa/Johannesburg", "value": "Africa/Johannesburg"},
    {"id": 188, "label": "(GMT +2:00) Africa/Juba", "value": "Africa/Juba"},
    {"id": 189, "label": "(GMT +2:00) Africa/Khartoum", "value": "Africa/Khartoum"},
    {"id": 190, "label": "(GMT +2:00) Africa/Maputo", "value": "Africa/Maputo"},
    {"id": 191, "label": "(GMT +2:00) Africa/Tripoli", "value": "Africa/Tripoli"},
    {"id": 192, "label": "(GMT +2:00) Africa/Windhoek", "value": "Africa/Windhoek"},
    {"id": 193, "label": "(GMT +2:00) Asia/Amman", "value": "Asia/Amman"},
    {"id": 194, "label": "(GMT +2:00) Asia/Beirut", "value": "Asia/Beirut"},
    {"id": 195, "label": "(GMT +2:00) Asia/Damascus", "value": "Asia/Damascus"},
    {"id": 196, "label": "(GMT +2:00) Asia/Famagusta", "value": "Asia/Famagusta"},
    {"id": 197, "label": "(GMT +2:00) Asia/Gaza", "value": "Asia/Gaza"},
    {"id": 198, "label": "(GMT +2:00) Asia/Hebron", "value": "Asia/Hebron"},
    {"id": 199, "label": "(GMT +2:00) Asia/Jerusalem", "value": "Asia/Jerusalem"},
    {"id": 200, "label": "(GMT +2:00) Asia/Nicosia", "value": "Asia/Nicosia"},
    {"id": 201, "label": "(GMT +2:00) Europe/Athens", "value": "Europe/Athens"},
    {"id": 202, "label": "(GMT +2:00) Europe/Bucharest", "value": "Europe/Bucharest"},
    {"id": 203, "label": "(GMT +2:00) Europe/Chisinau", "value": "Europe/Chisinau"},
    {"id": 204, "label": "(GMT +2:00) Europe/Helsinki", "value": "Europe/Helsinki"},
    {"id": 205, "label": "(GMT +2:00) Europe/Kaliningrad", "value": "Europe/Kaliningrad"},
    {"id": 206, "label": "(GMT +2:00) Europe/Kiev", "value": "Europe/Kiev"},
    {"id": 207, "label": "(GMT +2:00) Europe/Riga", "value": "Europe/Riga"},
    {"id": 208, "label": "(GMT +2:00) Europe/Sofia", "value": "Europe/Sofia"},
    {"id": 209, "label": "(GMT +2:00) Europe/Tallinn", "value": "Europe/Tallinn"},
    {"id": 210, "label": "(GMT +2:00) Europe/Uzhgorod", "value": "Europe/Uzhgorod"},
    {"id": 211, "label": "(GMT +2:00) Europe/Vilnius", "value": "Europe/Vilnius"},
    {"id": 212, "label": "(GMT +2:00) Europe/Zaporozhye", "value": "Europe/Zaporozhye"},
    {"id": 213, "label": "(GMT +3:00) Africa/Nairobi", "value": "Africa/Nairobi"},
    {"id": 214, "label": "(GMT +3:00) Asia/Baghdad", "value": "Asia/Baghdad"},
    {"id": 215, "label": "(GMT +3:00) Asia/Qatar", "value": "Asia/Qatar"},
    {"id": 216, "label": "(GMT +3:00) Asia/Riyadh", "value": "Asia/Riyadh"},
    {"id": 217, "label": "(GMT +3:00) Europe/Istanbul", "value": "Europe/Istanbul"},
    {"id": 218, "label": "(GMT +3:00) Europe/Kirov", "value": "Europe/Kirov"},
    {"id": 219, "label": "(GMT +3:00) Europe/Minsk", "value": "Europe/Minsk"},
    {"id": 220, "label": "(GMT +3:00) Europe/Moscow", "value": "Europe/Moscow"},
    {"id": 221, "label": "(GMT +3:00) Europe/Simferopol", "value": "Europe/Simferopol"},
    {"id": 222, "label": "(GMT +3:00) Europe/Volgograd", "value": "Europe/Volgograd"},
    {"id": 223, "label": "(GMT +3:30) Asia/Tehran", "value": "Asia/Tehran"},
    {"id": 224, "label": "(GMT +4:00) Asia/Baku", "value": "Asia/Baku"},
    {"id": 225, "label": "(GMT +4:00) Asia/Dubai", "value": "Asia/Dubai"},
    {"id": 226, "label": "(GMT +4:00) Asia/Tbilisi", "value": "Asia/Tbilisi"},
    {"id": 227, "label": "(GMT +4:00) Asia/Yerevan", "value": "Asia/Yerevan"},
    {"id": 228, "label": "(GMT +4:00) Europe/Astrakhan", "value": "Europe/Astrakhan"},
    {"id": 229, "label": "(GMT +4:00) Europe/Samara", "value": "Europe/Samara"},
    {"id": 230, "label": "(GMT +4:00) Europe/Saratov", "value": "Europe/Saratov"},
    {"id": 231, "label": "(GMT +4:00) Europe/Ulyanovsk", "value": "Europe/Ulyanovsk"},
    {"id": 232, "label": "(GMT +4:00) Indian/Mahe", "value": "Indian/Mahe"},
    {"id": 233, "label": "(GMT +4:00) Indian/Mauritius", "value": "Indian/Mauritius"},
    {"id": 234, "label": "(GMT +4:00) Indian/Reunion", "value": "Indian/Reunion"},
    {"id": 235, "label": "(GMT +4:30) Asia/Kabul", "value": "Asia/Kabul"},
    {"id": 236, "label": "(GMT +4:30) Antarctica/Mawson", "value": "Antarctica/Mawson"},
    {"id": 237, "label": "(GMT +5:00) Asia/Aqtau", "value": "Asia/Aqtau"},
    {"id": 238, "label": "(GMT +5:00) Asia/Aqtobe", "value": "Asia/Aqtobe"},
    {"id": 239, "label": "(GMT +5:00) Asia/Ashgabat", "value": "Asia/Ashgabat"},
    {"id": 240, "label": "(GMT +5:00) Asia/Atyrau", "value": "Asia/Atyrau"},
    {"id": 241, "label": "(GMT +5:00) Asia/Dushanbe", "value": "Asia/Dushanbe"},
    {"id": 242, "label": "(GMT +5:00) Asia/Karachi", "value": "Asia/Karachi"},
    {"id": 243, "label": "(GMT +5:00) Asia/Oral", "value": "Asia/Oral"},
    {"id": 244, "label": "(GMT +5:00) Asia/Qyzylorda", "value": "Asia/Qyzylorda"},
    {"id": 245, "label": "(GMT +5:00) Asia/Samarkand", "value": "Asia/Samarkand"},
    {"id": 246, "label": "(GMT +5:00) Asia/Tashkent", "value": "Asia/Tashkent"},
    {"id": 247, "label": "(GMT +5:00) Asia/Yekaterinburg", "value": "Asia/Yekaterinburg"},
    {"id": 248, "label": "(GMT +5:00) Indian/Kerguelen", "value": "Indian/Kerguelen"},
    {"id": 249, "label": "(GMT +5:00) Indian/Maldives", "value": "Indian/Maldives"},
    {"id": 250, "label": "(GMT +5:30) Asia/Colombo", "value": "Asia/Colombo"},
    {"id": 251, "label": "(GMT +5:30) Asia/Kolkata", "value": "Asia/Kolkata"},
    {"id": 252, "label": "(GMT +5:45) Asia/Kathmandu", "value": "Asia/Kathmandu"},
    {"id": 253, "label": "(GMT +6:00) Antarctica/Vostok", "value": "Antarctica/Vostok"},
    {"id": 254, "label": "(GMT +6:00) Asia/Almaty", "value": "Asia/Almaty"},
    {"id": 255, "label": "(GMT +6:00) Asia/Bishkek", "value": "Asia/Bishkek"},
    {"id": 256, "label": "(GMT +6:00) Asia/Dhaka", "value": "Asia/Dhaka"},
    {"id": 257, "label": "(GMT +6:00) Asia/Omsk", "value": "Asia/Omsk"},
    {"id": 258, "label": "(GMT +6:00) Asia/Qostanay", "value": "Asia/Qostanay"},
    {"id": 259, "label": "(GMT +6:00) Asia/Thimphu", "value": "Asia/Thimphu"},
    {"id": 260, "label": "(GMT +6:00) Asia/Urumqi", "value": "Asia/Urumqi"},
    {"id": 261, "label": "(GMT +6:00) Indian/Chagos", "value": "Indian/Chagos"},
    {"id": 262, "label": "(GMT +6:30) Asia/Yangon", "value": "Asia/Yangon"},
    {"id": 263, "label": "(GMT +6:30) Indian/Cocos", "value": "Indian/Cocos"},
    {"id": 264, "label": "(GMT +7:00) Antarctica/Davis", "value": "Antarctica/Davis"},
    {"id": 265, "label": "(GMT +7:00) Asia/Bangkok", "value": "Asia/Bangkok"},
    {"id": 266, "label": "(GMT +7:00) Asia/Barnaul", "value": "Asia/Barnaul"},
    {"id": 267, "label": "(GMT +7:00) Asia/Ho Chi Minh", "value": "Asia/Ho_Chi_Minh"},
    {"id": 268, "label": "(GMT +7:00) Asia/Hovd", "value": "Asia/Hovd"},
    {"id": 269, "label": "(GMT +7:00) Asia/Jakarta", "value": "Asia/Jakarta"},
    {"id": 270, "label": "(GMT +7:00) Asia/Krasnoyarsk", "value": "Asia/Krasnoyarsk"},
    {"id": 271, "label": "(GMT +7:00) Asia/Novokuznetsk", "value": "Asia/Novokuznetsk"},
    {"id": 272, "label": "(GMT +7:00) Asia/Novosibirsk", "value": "Asia/Novosibirsk"},
    {"id": 273, "label": "(GMT +7:00) Asia/Pontianak", "value": "Asia/Pontianak"},
    {"id": 274, "label": "(GMT +7:00) Asia/Tomsk", "value": "Asia/Tomsk"},
    {"id": 275, "label": "(GMT +7:00) Indian/Christmas", "value": "Indian/Christmas"},
    {"id": 276, "label": "(GMT +8:00) Asia/Brunei", "value": "Asia/Brunei"},
    {"id": 277, "label": "(GMT +8:00) Asia/Choibalsan", "value": "Asia/Choibalsan"},
    {"id": 278, "label": "(GMT +8:00) Asia/Hong Kong", "value": "Asia/Hong_Kong"},
    {"id": 279, "label": "(GMT +8:00) Asia/Irkutsk", "value": "Asia/Irkutsk"},
    {"id": 280, "label": "(GMT +8:00) Asia/Kuala Lumpur", "value": "Asia/Kuala_Lumpur"},
    {"id": 281, "label": "(GMT +8:00) Asia/Kuching", "value": "Asia/Kuching"},
    {"id": 282, "label": "(GMT +8:00) Asia/Macau", "value": "Asia/Macau"},
    {"id": 283, "label": "(GMT +8:00) Asia/Makassar", "value": "Asia/Makassar"},
    {"id": 284, "label": "(GMT +8:00) Asia/Manila", "value": "Asia/Manila"},
    {"id": 285, "label": "(GMT +8:00) Asia/Shanghai", "value": "Asia/Shanghai"},
    {"id": 286, "label": "(GMT +8:00) Asia/Singapore", "value": "Asia/Singapore"},
    {"id": 287, "label": "(GMT +8:00) Asia/Taipei", "value": "Asia/Taipei"},
    {"id": 288, "label": "(GMT +8:00) Asia/Ulaanbaatar", "value": "Asia/Ulaanbaatar"},
    {"id": 289, "label": "(GMT +8:00) Australia/Perth", "value": "Australia/Perth"},
    {"id": 290, "label": "(GMT +8:45) Australia/Eucla", "value": "Australia/Eucla"},
    {"id": 291, "label": "(GMT +9:00) Asia/Chita", "value": "Asia/Chita"},
    {"id": 292, "label": "(GMT +9:00) Asia/Dili", "value": "Asia/Dili"},
    {"id": 293, "label": "(GMT +9:00) Asia/Jayapura", "value": "Asia/Jayapura"},
    {"id": 294, "label": "(GMT +9:00) Asia/Khandyga", "value": "Asia/Khandyga"},
    {"id": 295, "label": "(GMT +9:00) Asia/Pyongyang", "value": "Asia/Pyongyang"},
    {"id": 296, "label": "(GMT +9:00) Asia/Seoul", "value": "Asia/Seoul"},
    {"id": 297, "label": "(GMT +9:00) Asia/Tokyo", "value": "Asia/Tokyo"},
    {"id": 298, "label": "(GMT +9:00) Asia/Yakutsk", "value": "Asia/Yakutsk"},
    {"id": 299, "label": "(GMT +9:00) Pacific/Palau", "value": "Pacific/Palau"},
    {"id": 300, "label": "(GMT +9:30) Australia/Adelaide", "value": "Australia/Adelaide"},
    {"id": 301, "label": "(GMT +9:30) Australia/Broken Hill", "value": "Australia/Broken_Hill"},
    {"id": 302, "label": "(GMT +9:30) Australia/Darwin", "value": "Australia/Darwin"},
    {"id": 303, "label": "(GMT +10:00) Antarctica/Macquarie", "value": "Antarctica/Macquarie"},
    {"id": 304, "label": "(GMT +10:00) Asia/Ust-Nera", "value": "Asia/Ust-Nera"},
    {"id": 305, "label": "(GMT +10:00) Asia/Vladivostok", "value": "Asia/Vladivostok"},
    {"id": 306, "label": "(GMT +10:00) Australia/Brisbane", "value": "Australia/Brisbane"},
    {"id": 307, "label": "(GMT +10:00) Australia/Hobart", "value": "Australia/Hobart"},
    {"id": 308, "label": "(GMT +10:00) Australia/Lindeman", "value": "Australia/Lindeman"},
    {"id": 309, "label": "(GMT +10:00) Australia/Melbourne", "value": "Australia/Melbourne"},
    {"id": 310, "label": "(GMT +10:00) Australia/Sydney", "value": "Australia/Sydney"},
    {"id": 311, "label": "(GMT +10:00) Pacific/Chuuk", "value": "Pacific/Chuuk"},
    {"id": 312, "label": "(GMT +10:00) Pacific/Guam", "value": "Pacific/Guam"},
    {"id": 313, "label": "(GMT +10:00) Pacific/Port Moresby", "value": "Pacific/Port_Moresby"},
    {"id": 314, "label": "(GMT +10:30) Australia/Lord Howe", "value": "Australia/Lord_Howe"},
    {"id": 315, "label": "(GMT +11:00) Antarctica/Casey", "value": "Antarctica/Casey"},
    {"id": 316, "label": "(GMT +11:00) Asia/Magadan", "value": "Asia/Magadan"},
    {"id": 317, "label": "(GMT +11:00) Asia/Sakhalin", "value": "Asia/Sakhalin"},
    {"id": 318, "label": "(GMT +11:00) Asia/Srednekolymsk", "value": "Asia/Srednekolymsk"},
    {"id": 319, "label": "(GMT +11:00) Pacific/Bougainville", "value": "Pacific/Bougainville"},
    {"id": 320, "label": "(GMT +11:00) Pacific/Efate", "value": "Pacific/Efate"},
    {"id": 321, "label": "(GMT +11:00) Pacific/Guadalcanal", "value": "Pacific/Guadalcanal"},
    {"id": 322, "label": "(GMT +11:00) Pacific/Kosrae", "value": "Pacific/Kosrae"},
    {"id": 323, "label": "(GMT +11:00) Pacific/Norfolk", "value": "Pacific/Norfolk"},
    {"id": 324, "label": "(GMT +11:00) Pacific/Noumea", "value": "Pacific/Noumea"},
    {"id": 325, "label": "(GMT +11:00) Pacific/Pohnpei", "value": "Pacific/Pohnpei"},
    {"id": 326, "label": "(GMT +12:00) Asia/Anadyr", "value": "Asia/Anadyr"},
    {"id": 327, "label": "(GMT +12:00) Asia/Kamchatka", "value": "Asia/Kamchatka"},
    {"id": 328, "label": "(GMT +12:00) Pacific/Auckland", "value": "Pacific/Auckland"},
    {"id": 329, "label": "(GMT +12:00) Pacific/Fiji", "value": "Pacific/Fiji"},
    {"id": 330, "label": "(GMT +12:00) Pacific/Funafuti", "value": "Pacific/Funafuti"},
    {"id": 331, "label": "(GMT +12:00) Pacific/Kwajalein", "value": "Pacific/Kwajalein"},
    {"id": 332, "label": "(GMT +12:00) Pacific/Majuro", "value": "Pacific/Majuro"},
    {"id": 333, "label": "(GMT +12:00) Pacific/Nauru", "value": "Pacific/Nauru"},
    {"id": 334, "label": "(GMT +12:00) Pacific/Tarawa", "value": "Pacific/Tarawa"},
    {"id": 335, "label": "(GMT +12:00) Pacific/Wake", "value": "Pacific/Wake"},
    {"id": 336, "label": "(GMT +12:00) Pacific/Wallis", "value": "Pacific/Wallis"},
    {"id": 337, "label": "(GMT +12:45) Pacific/Chatham", "value": "Pacific/Chatham"},
    {"id": 338, "label": "(GMT +13:00) Pacific/Apia", "value": "Pacific/Apia"},
    {"id": 339, "label": "(GMT +13:00) Pacific/Fakaofo", "value": "Pacific/Fakaofo"},
    {"id": 340, "label": "(GMT +13:00) Pacific/Kanton", "value": "Pacific/Kanton"},
    {"id": 341, "label": "(GMT +13:00) Pacific/Tongatapu", "value": "Pacific/Tongatapu"},
    {"id": 342, "label": "(GMT +14:00) Pacific/Kiritimati", "value": "Pacific/Kiritimati"}
];

export default timezone;