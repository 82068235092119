import React from "react";
import Lottie from 'react-lottie';
import * as Construction from 'assets/animations/adhoc/construction.json';

class UnderConstruction extends React.Component {

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: Construction.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div className="centered-container">
                <div className="under-construction-animation">
                    <Lottie options={defaultOptions}/>
                </div>
                <div className="under-construction-title">Under Construction</div>
                <p className="under-construction-description">We're hard at work bringing this functionality to you - we'll let you know when it's available!</p>
            </div>
        );
    }

}

export default UnderConstruction;