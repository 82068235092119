import React from 'react';
// Material components
import {
    Stack,
    Typography
} from '@mui/material';
// Custom components
import UserAvatar from 'components/Material/UserAvatar';
// Constants
import { CDN_URL } from 'constants/urls';

export default function StaffDisplay(props) {
    const { name, subtitle, img } = props;    
    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <UserAvatar name={name} imgPath={img ? `${CDN_URL}/${img}` : null} />
            <div>
                <Typography variant="subtitle1">{name}</Typography>
                {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
            </div>
        </Stack>
    );
}