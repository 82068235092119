/*eslint-disable*/
import React from "react";

// reactstrap components
import { Modal, ModalBody, ModalHeader, Input, Label, FormGroup } from "reactstrap";
import Checkbox from '@mui/material/Checkbox';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import { CDN_URL } from "constants/urls";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { MdDragHandle } from 'react-icons/md';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 ${grid}px 0`,
    // styles we need to apply on draggables
    ...draggableStyle
});

class CalendarStaffPicker extends React.Component {

    constructor(props){
        super(props);
    }

    onDragEnd(result, that) {
        // Dropped outside the list
        if (!result.destination) {
          return;
        }
        let curCalendarSettings = Object.assign({}, that.props.calendarSettings);
        const [removed] = curCalendarSettings.calendarResources.splice(result.source.index, 1);
        if(!removed) {
            return;
        }
        curCalendarSettings.calendarResources.splice(result.destination.index, 0, removed);
        that.props.actions.loadCalendarSettings(curCalendarSettings);
    }

    sortStaff(a,b) {
        if(this.props.calendarSettings && this.props.calendarSettings.calendarResources && this.props.calendarSettings.calendarResources.includes(a.id) && !this.props.calendarSettings.calendarResources.includes(b.id)) {
            return -1;
        } else if(this.props.calendarSettings && this.props.calendarSettings.calendarResources && !this.props.calendarSettings.calendarResources.includes(a.id) && this.props.calendarSettings.calendarResources.includes(b.id)) {
            return 1;
        } else if(this.props.calendarSettings && this.props.calendarSettings.calendarResources && this.props.calendarSettings.calendarResources.includes(a.id) && this.props.calendarSettings.calendarResources.includes(b.id)) {
            let aFoundIndex = this.props.calendarSettings.calendarResources.findIndex(x => x === a.id);
            let bFoundIndex = this.props.calendarSettings.calendarResources.findIndex(x => x === b.id);
            if(aFoundIndex < bFoundIndex) {
                return -1;
            } else {
                return 1;
            }
        } else {
            return 0;
        }
    }

    staffLocationFilter = (staffObj) => {
        const businessLocationMapFound = this.props.businessLocationStaffMap.find(x => x.staff_id === staffObj.id && x.business_location_id === this.props.locationId);
        if(businessLocationMapFound && (this.props.userRole.booking.read_all === true || (this.props.userRole.booking.read_all === false && this.props.user.staff_id === staffObj.id))) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
        <>
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible} className="text-center">
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Select Calendar Staff</ModalHeader> 
                <ModalBody>
                    <DragDropContext onDragEnd={(e) => this.onDragEnd(e, this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {this.props.staff.filter(this.staffLocationFilter).sort((a,b) => this.sortStaff(a,b)).map((staffObj, staffIndex) => {
                                        let staffChecked = false;
                                        let staffListIndex = null;
                                        if(this.props.calendarSettings && this.props.calendarSettings.calendarResources && this.props.calendarSettings.calendarResources.find(x => x === staffObj.id)){
                                            staffChecked = true;
                                            staffListIndex = this.props.calendarSettings.calendarResources.findIndex(x => x === staffObj.id);
                                        } else {
                                            staffListIndex = 100 + staffIndex
                                        }
                                        return (
                                            <Draggable key={staffObj.id} draggableId={staffObj.id.toString()} index={staffListIndex}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                        )}
                                                    >
                                                        <div className="tier-staff-row" key={'CalendarStaffPicker' + staffObj.id} style={snapshot.isDragging ? { boxShadow: '0 1px 15px 1px rgb(39 39 39 / 25%)' } : null}>
                                                            <div>
                                                                <MdDragHandle size={20} color={'#aeaeae'} style={{ marginRight: 5 }} />
                                                                <img className="tier-staff-img" src={`${CDN_URL}/` + staffObj.staff_img}/>
                                                                <div className="tier-staff-name">{staffObj.firstname + ' ' + staffObj.lastname}</div>
                                                            </div>
                                                            <Checkbox defaultChecked={staffChecked} onChange={(e) => this.props.toggleStaff(staffObj.id)} />
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </ModalBody>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        userRole: state.userRole,
        staff: state.staff,
        businessLocationStaffMap: state.businessLocationStaffMap,
        calendarSettings: state.calendarSettings
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(CalendarStaffPicker);