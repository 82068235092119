import * as types from "../constants/actionTypes";

export default function businessLocationReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_BUSINESS_LOCATION_SUCCESS:
            return action.businessLocation
        case types.UPDATE_BUSINESS_LOCATION_SUCCESS:
            let businessLocationIndex = state.findIndex(x => x.business_location_id === action.businessLocation.business_location_id);
            state[businessLocationIndex] = action.businessLocation;
            return state;
        case types.ADD_BUSINESS_LOCATION_SUCCESS:
            return [...state, action.businessLocation];
        case types.DELETE_BUSINESS_LOCATION_SUCCESS:
            return [...state.filter(x => x.business_location_id !== action.businessLocation.business_location_id)];
        case types.CLEAR_BUSINESS_LOCATION_SUCCESS:
            return [];
        default:
            return state;
    };
};