import React from "react";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Nav,
    NavLink,
    NavItem
} from "reactstrap";
import α from 'color-alpha';
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { FiPlus } from 'react-icons/fi';

// Components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import StaffCard from "components/Staff/StaffCard";
import StaffScheduleGrid from "components/Staff/StaffScheduleGrid";
import UnderConstruction from "components/Generic/UnderConstruction";
// Utilities
import withRouter from "utilities/withRouter";

class Staff extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1
        };
    }

    componentDidMount() {
        this.props.actions.loadStaff();
    }

    renderStaffCard(staffObj) {
        return (
            <StaffCard staffObj={staffObj} navigate={this.props.navigate}/>
        );
    }

    renderStaffScheduleGrid() {
        return (
            <StaffScheduleGrid triggerNotification={this.props.triggerNotification}/>
        );
    }

    renderSelectedTab() {
        if(this.state.selectedTab === 1) {
            if(this.props.staff && this.props.staff.filter(x => x.enabled === 1).length > 0) {
                return (
                    <Col md={12} >
                        <Row>
                            {this.props.staff.filter(x => x.enabled === 1).map((staffObj, staffIndex) => {
                                return this.renderStaffCard(staffObj, staffIndex)
                            })}
                            <Col md={12}>
                                <Button className="staff-minified-button" color="primary" onClick={() => this.props.navigate("/admin/add_staff")}>
                                    <FiPlus size={16} color={'#1ab394'}/> Add Staff
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                );
            } else {
                return(
                    <Col md={12}>
                        <div className="staff-empty-container">
                            <div className="centered-container text-center">
                                <h5>No staff to display</h5>
                                <Button color="primary" onClick={() => this.props.navigate("/admin/add_staff")}>
                                    Add Staff
                                </Button>
                            </div>
                        </div>
                    </Col>
                );
            }
        } else if(this.state.selectedTab ===  2) {
            return (
                <Col md={12} >
                    <Row>
                        {this.renderStaffScheduleGrid()}
                    </Row>
                </Col>
            );
        } else if(this.state.selectedTab ===  3) {
            return (
                <UnderConstruction/>
            );
        }
    }

    render() {
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content" style={{ marginTop: -55, position: 'relative', zIndex: 2 }}>
                <Row>
                    <Col md={12} style={{ marginBottom: 30 }}>
                        <div className="staff-header-row">
                            <div>
                                <Nav pills className="nav-pills-neutral">
                                    <NavItem>
                                        <NavLink
                                            className={this.state.selectedTab === 1 ? "active" : ""}
                                            style={this.state.selectedTab === 1 ? (
                                                // Selected
                                                this.props.business.hub_header_gradient_start && this.props.business.hub_header_gradient_start_text ? {
                                                    backgroundColor: this.props.business.hub_header_gradient_start_text,
                                                    color: this.props.business.hub_header_gradient_start
                                                } : null
                                            ) : (
                                                // Unselected
                                                this.props.business.hub_header_gradient_start && this.props.business.hub_header_gradient_start_text ? {
                                                    backgroundColor: α(this.props.business.hub_header_gradient_start_text, .3),
                                                    color: this.props.business.hub_header_gradient_start_text
                                                } : null
                                            )}
                                            onClick={() => this.setState({ selectedTab: 1 })}
                                        >
                                            Staff
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.selectedTab === 2 ? "active" : ""}
                                            style={this.state.selectedTab === 2 ? (
                                                // Selected
                                                this.props.business.hub_header_gradient_start && this.props.business.hub_header_gradient_start_text ? {
                                                    backgroundColor: this.props.business.hub_header_gradient_start_text,
                                                    color: this.props.business.hub_header_gradient_start
                                                } : null
                                            ) : (
                                                // Unselected
                                                this.props.business.hub_header_gradient_start && this.props.business.hub_header_gradient_start_text ? {
                                                    backgroundColor: α(this.props.business.hub_header_gradient_start_text, .3),
                                                    color: this.props.business.hub_header_gradient_start_text
                                                } : null
                                            )}
                                            onClick={() => this.setState({ selectedTab: 2 })}
                                        >
                                            Working Hours
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.selectedTab === 3 ? "active" : ""}
                                            style={this.state.selectedTab === 3 ? (
                                                // Selected
                                                this.props.business.hub_header_gradient_start && this.props.business.hub_header_gradient_start_text ? {
                                                    backgroundColor: this.props.business.hub_header_gradient_start_text,
                                                    color: this.props.business.hub_header_gradient_start
                                                } : null
                                            ) : (
                                                // Unselected
                                                this.props.business.hub_header_gradient_start && this.props.business.hub_header_gradient_start_text ? {
                                                    backgroundColor: α(this.props.business.hub_header_gradient_start_text, .3),
                                                    color: this.props.business.hub_header_gradient_start_text
                                                } : null
                                            )}
                                            onClick={() => this.setState({ selectedTab: 3 })}
                                        >
                                            Holidays
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            {this.state.selectedTab === 1 ?
                                <div>
                                    <Button
                                        className="staff-header-button"
                                        outline
                                        color="default"
                                        onClick={() => this.props.navigate("/admin/add_staff")}
                                        style={{ color: this.props.business.hub_header_gradient_end_text ? this.props.business.hub_header_gradient_end_text : '#ffffff' }}
                                    >
                                        <FiPlus size={16} color={'#1ab394'}/> Add Staff
                                    </Button>
                                </div>
                            : null}
                        </div>
                    </Col>
                    {this.renderSelectedTab()}
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        user: state.user,
        staff: state.staff
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Staff));