import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { isEqual } from "lodash";
import socketIOClient from "socket.io-client";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// React-toastify for notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/react-toastify-variables.css';

import '../assets/css/reactstrap-variables.css';

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";

import adminRoutes from "routes/adminRoutes";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../actions/index';
import Api from '../api/index';

import { FiCheck, FiBell, FiX, FiAlertTriangle, FiAlertOctagon } from 'react-icons/fi';
// Constants
import { SOCKET_URL_PROD, SOCKET_URL_DEV } from "constants/urls";
// Utilities
import withRouter from "utilities/withRouter";
// Cookies
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarMini: true,
      backgroundColor: "blue",
    };
    this.triggerNotification = this.triggerNotification.bind(this);
  }
  mainPanel = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
    if(!cookies.get('session')){
      return;
    }
    // Check auth state
    this.getAuthState();
    // Handle calendar settings
    if(this.props.businessLocation?.length > 0) {  
      if(this.props.calendarSettings?.calendarResources?.length > 0) {
        // Remove any staff from calendar resources that aren't in staff data
        let curCalendarSettings = Object.assign({}, this.props.calendarSettings);
        this.props.calendarSettings.calendarResources.forEach((staffId, resourceIndex) => {
          let foundStaffObj = this.props.staff.find(x => x.id === staffId);
          if(!foundStaffObj) {
            curCalendarSettings.calendarResources.splice(resourceIndex, 1);
          }
        });
        // Only update if a difference exists
        if(!isEqual(this.props.calendarSettings, curCalendarSettings)) {
          this.props.actions.loadCalendarSettings(curCalendarSettings);
        }
      } else if(this.props.calendarSettings?.calendarResources?.length === 0) {
        // No staff selected - no action required
      } else {
        // Set default settings
        let calendarResources = [];
        if(this.props.staff.length > 0) {
          calendarResources = this.props.staff.map(x => x.id);
        };
        let curCalendarSettings = {
          calendarFirstDay: { value: 1, label: "Monday" },
          calendarResources,
          slotDuration: { value: "00:05:00", label: "5 minutes" }
        };
        this.props.actions.loadCalendarSettings(curCalendarSettings);
      }
    }
    this.props.actions.loadLoadingSpinner(false);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.mainPanel.current.scrollTop = 0;
  }
  minimizeSidebar = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  async getAuthState() {
    try {
      let response = await Api.getAuthState();
      if(response && response.data && response.data.sessionValid === true) {
        // Establish WebSocket connection
        this.establishSocketConnection();
        // Authorised - fetch data
        const settings = this.props.settings ? this.props.settings : {};
        this.props.actions.loadBusinessLocationsAuto(settings, this.props.user);
        this.props.actions.loadStaff();
        this.props.actions.loadServiceCategory();
        this.props.actions.loadService();
        this.props.actions.loadProductAuto();
        // Only refresh business state and user state if stale
        const { business: existingBusinessState, user: existingUserState } = this.props;
        if(!existingBusinessState?.last_refresh || ((new Date().getTime() - existingBusinessState?.last_refresh) / 1000 >= 60)) {
          this.props.actions.loadBusinessAuto();
        }
        if(!existingUserState?.last_refresh || ((new Date().getTime() - existingUserState?.last_refresh) / 1000 >= 60)) {
          this.props.actions.loadUserAuto();
        }
      }
    } catch(e) {
      // Clear down local storage
      this.props.actions.clearBusiness();
      this.props.actions.clearBusinessLocation();
      this.props.actions.loadCalendarSettings({});
      this.props.actions.clearServiceCategory();
      this.props.actions.clearService();
      this.props.actions.clearStaff();
      this.props.actions.clearToDo();
      this.props.actions.clearUser();
      this.props.actions.clearUserRole();
      this.props.actions.loadProduct([]);
      this.props.actions.loadSettings({});
      // Login required
      setTimeout(() => this.props.navigate('/auth/login-page'), 500);
    }
  }
  establishSocketConnection() {
    if(process.env.NODE_ENV !== "production") {
      return;
    }
    try {
      let socket_endpoint = null;
      if(process.env.NODE_ENV === "production") {
        socket_endpoint = `${SOCKET_URL_PROD}/hub`;
      } else {
        socket_endpoint = `${SOCKET_URL_DEV}/hub`;
      }
      const socket = socketIOClient(socket_endpoint, { withCredentials: true });
      socket.on("notification", data => {
        if(data.NotificationType === "new_booking") {
          let notificationMessage = <div><div className="text-bold">New Booking</div>{data.NotificationMessage}</div>;
          this.triggerNotification(notificationMessage, "new_booking_notification", "br", 10, data);
        } else if(data.NotificationType === "cancellation") {
          let notificationMessage = <div><div className="text-bold">Cancellation Alert</div>{data.NotificationMessage}</div>;
          this.triggerNotification(notificationMessage, "cancellation_notification", "br", 10, data);
        }
      });
    } catch(e) {
      console.log('Unable to establish socket connection');
    }
  }
  triggerNotification = (message, type, place, dismiss = 7, messageData = null) => {
    // Handle position
    let position = null;
    switch(place) {
      case "tl":
        position = toast.POSITION.TOP_LEFT;
        break;
      case "tc":
        position = toast.POSITION.TOP_CENTER;
        break;
      case "tr":
        position = toast.POSITION.TOP_RIGHT;
        break;
      case "bl":
        position = toast.POSITION.BOTTOM_LEFT;
        break;
      case "bc":
        position = toast.POSITION.BOTTOM_CENTER;
        break;
      case "br":
        position = toast.POSITION.BOTTOM_RIGHT;
        break;
      default:
        position = toast.POSITION.BOTTOM_CENTER;
    }
    // Handle style
    let notificationClass = null, notificationProgressClass = null, notificationIcon = null, notificationClick = null;
    switch(type) {
      case "new_booking_notification":
        notificationClass = "toastify-primary";
        notificationProgressClass = "toastify-success-progress";
        notificationIcon = <FiCheck size={30} color={'#1ab394'}/>;
        notificationClick = messageData && messageData.EntityId ? () => this.props.navigate('/admin/edit_booking?user_order_id=' + messageData.EntityId) : null;
        break;
      case "cancellation_notification":
        notificationClass = "toastify-primary";
        notificationProgressClass = "toastify-danger-progress";
        notificationIcon = <FiX size={30} color={'#ed5565'}/>;
        notificationClick = messageData && messageData.EntityId ? () => this.props.navigate('/admin/edit_booking?user_order_id=' + messageData.EntityId) : null;
        break;
      case "success":
        notificationClass = null;
        notificationProgressClass = "toastify-success-progress";
        notificationIcon = <FiCheck size={30} color={'#1ab394'}/>;
        break;
      case "warning":
        notificationClass = null;
        notificationProgressClass = "toastify-warning-progress";
        notificationIcon = <FiAlertTriangle size={30} color={'#ffb236'}/>;
        break;
      case "danger":
        notificationClass = null;
        notificationProgressClass = "toastify-danger-progress";
        notificationIcon = <FiAlertOctagon size={30} color={'#ed5565'}/>;
        break;
      default:
        notificationClass = null;
        notificationProgressClass = "";
        notificationIcon = <FiBell size={30}/>;
    }
    // Handle toast options
    var options = {
      className: notificationClass,
      progressClassName: notificationProgressClass,
      position,
      icon: notificationIcon,
      autoClose: dismiss * 1000,
      onClick: notificationClick
    };
    toast(message, options);
  };
  handleColorClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  getRoutes = (routes) => {
    return routes.map((prop,) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "admin") {
        const RouteComponent = prop.component;
        return (
          <Route
            path={prop.path}
            element={
              <RouteComponent
                triggerNotification={this.triggerNotification}
              />
            }
            key={prop.name}
          >
            {prop.views?.map((innerProp,) => {
              const NestedRouteComponent = innerProp.component;
              console.log(innerProp);
              return (
                <Route
                  path={innerProp.path}
                  element={
                    <NestedRouteComponent
                      triggerNotification={this.triggerNotification}
                    /> 
                  }
                  key={innerProp.name}
                />
              );
            })}
          </Route>
        );
      } else {
        return null;
      }
    });
  };
  isFullScreen = () => {
    const noFooterRoutes = [
      "full-screen-maps",
      "calendar",
      "business-locations",
      "emails"
    ];
    for(const noFooterRoute of noFooterRoutes) {
      if(window.location.href.indexOf(noFooterRoute) !== -1) {
        return true; 
      }
    }
    return false;
  }
  render() {
    return (
      <div className="wrapper">
        <LoadingSpinner/>
        <ToastContainer draggablePercent={60}/>
        <Sidebar
          {...this.props}
          routes={adminRoutes}
          minimizeSidebar={this.minimizeSidebar}
          backgroundColor={this.state.backgroundColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <AdminNavbar
            {...this.props}
            routes={adminRoutes}
            isFullScreen={this.isFullScreen}
          />
          <Routes>
            {this.getRoutes(adminRoutes)}
            <Route path="*" element={<Navigate to="home" replace/>}/>
          </Routes>
          {!this.isFullScreen() ? <Footer fluid /> : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    business: state.business,
    businessLocation: state.businessLocation,
    staff: state.staff,
    calendarSettings: state.calendarSettings,
    settings: state.settings
  }
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));
