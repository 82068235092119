// API URL
export const API_URL_PROD = "https://api-neo.whatstyle.com";
export const API_URL_DEV = "http://192.168.1.76:8080";
//export const API_URL_DEV = "http://localhost:8080";
// CDN URL
export const CDN_URL = "https://cdn.whatstyle.com";
// Socket URL
export const SOCKET_URL_PROD = "https://socket.whatstyle.com";
export const SOCKET_URL_DEV = "http://localhost:3030";
// Google Maps
export const GOOGLE_MAPS_PROD_KEY = "AIzaSyBubnSwOM_gzSyGt8S2fPeXYmS5BPD7uNI";
export const GOOGLE_MAPS_DEV_KEY = "AIzaSyBSJgqJqA_vwOjHNyYjGnX30xKrYGHy99c";