const frequency = [
    {
        id: 1,
        label: "Daily",
        value: 1,
        frequency_unit: 'Day',
        frequency_magnitude: 1
    },
    {
        id: 2,
        label: "Every 2 Days",
        value: 2,
        frequency_unit: 'Day',
        frequency_magnitude: 2
    },
    {
        id: 3,
        label: "Every 3 Days",
        value: 3,
        frequency_unit: 'Day',
        frequency_magnitude: 3
    },
    {
        id: 4,
        label: "Every 4 Days",
        value: 4,
        frequency_unit: 'Day',
        frequency_magnitude: 4
    },
    {
        id: 5,
        label: "Every 5 Days",
        value: 5,
        frequency_unit: 'Day',
        frequency_magnitude: 5
    },
    {
        id: 6,
        label: "Every 6 Days",
        value: 6,
        frequency_unit: 'Day',
        frequency_magnitude: 6
    },
    {
        id: 7,
        label: "Every 7 Days",
        value: 7,
        frequency_unit: 'Day',
        frequency_magnitude: 7
    },
    {
        id: 8,
        label: "Weekly",
        value: 8,
        frequency_unit: 'Week',
        frequency_magnitude: 1
    },
    {
        id: 9,
        label: "Every 2 Weeks",
        value: 9,
        frequency_unit: 'Week',
        frequency_magnitude: 2
    },
    {
        id: 10,
        label: "Every 3 Weeks",
        value: 10,
        frequency_unit: 'Week',
        frequency_magnitude: 3
    },
    {
        id: 11,
        label: "Every 4 Weeks",
        value: 11,
        frequency_unit: 'Week',
        frequency_magnitude: 4
    },
    {
        id: 12,
        label: "Every 5 Weeks",
        value: 12,
        frequency_unit: 'Week',
        frequency_magnitude: 5
    },
    {
        id: 13,
        label: "Every 6 Weeks",
        value: 13,
        frequency_unit: 'Week',
        frequency_magnitude: 6
    },
    {
        id: 14,
        label: "Every 7 Weeks",
        frequency_unit: 'Week',
        frequency_magnitude: 7
    },
    {
        id: 15,
        label: "Every 8 Weeks",
        value: 15,
        frequency_unit: 'Week',
        frequency_magnitude: 8
    },
    {
        id: 16,
        label: "Every 9 Weeks",
        value: 16,
        frequency_unit: 'Week',
        frequency_magnitude: 9
    },
    {
        id: 17,
        label: "Every 10 Weeks",
        value: 17,
        frequency_unit: 'Week',
        frequency_magnitude: 10
    },
    {
        id: 18,
        label: "Every 11 Weeks",
        value: 18,
        frequency_unit: 'Week',
        frequency_magnitude: 11
    },
    {
        id: 19,
        label: "Every 12 Weeks",
        value: 19,
        frequency_unit: 'Week',
        frequency_magnitude: 12
    },
    {
        id: 20,
        label: "Monthly",
        value: 20,
        frequency_unit: 'Month',
        frequency_magnitude: 1
    },
    {
        id: 21,
        label: "Every 2 Months",
        value: 21,
        frequency_unit: 'Month',
        frequency_magnitude: 2
    },
    {
        id: 22,
        label: "Every 3 Months",
        value: 22,
        frequency_unit: 'Month',
        frequency_magnitude: 3
    },
    {
        id: 23,
        label: "Every 4 Months",
        value: 23,
        frequency_unit: 'Month',
        frequency_magnitude: 4
    },
    {
        id: 24,
        label: "Every 5 Months",
        value: 24,
        frequency_unit: 'Month',
        frequency_magnitude: 5
    },
    {
        id: 25,
        label: "Every 6 Months",
        value: 25,
        frequency_unit: 'Month',
        frequency_magnitude: 6
    },
    {
        id: 26,
        label: "Every 7 Months",
        value: 26,
        frequency_unit: 'Month',
        frequency_magnitude: 7
    },
    {
        id: 27,
        label: "Every 8 Months",
        value: 27,
        frequency_unit: 'Month',
        frequency_magnitude: 8
    },
    {
        id: 28,
        label: "Every 9 Months",
        value: 28,
        frequency_unit: 'Month',
        frequency_magnitude: 9
    },
    {
        id: 29,
        label: "Every 10 Months",
        value: 29,
        frequency_unit: 'Month',
        frequency_magnitude: 10
    },
    {
        id: 30,
        label: "Every 11 Months",
        value: 30,
        frequency_unit: 'Month',
        frequency_magnitude: 11
    },
    {
        id: 31,
        label: "Yearly",
        value: 31,
        frequency_unit: 'Year',
        frequency_magnitude: 1
    }
];

export default frequency;