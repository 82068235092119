import { combineReducers } from 'redux';
import loading from './loading';
import user from './user';
import userRole from './userRole';
import business from './business';
import businessLocation from './businessLocation';
import businessLocationStaffMap from './businessLocationStaffMap';
import staff from './staff';
import toDo from './toDo';
import calendarSettings from './calendarSettings';
import settings from './settings';
import service from './service';
import serviceCategory from './serviceCategory';
import serviceDetail from './serviceDetail';
import serviceStaffMap from './serviceStaffMap';
import serviceLocationMap from './serviceLocationMap';
import product from './product';

export default combineReducers({
    loading,
    user,
    userRole,
    business,
    businessLocation,
    businessLocationStaffMap,
    toDo,
    staff,
    calendarSettings,
    settings,
    service,
    serviceCategory,
    serviceDetail,
    serviceStaffMap,
    serviceLocationMap,
    product
});