import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    FormText,
    Spinner,
    Alert,
    InputGroup,
    InputGroupText
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ImageCropper from "components/ImageCropper/ImageCropper";

import Compressor from 'compressorjs';

import Api from '../../api/index';

import { FiImage, FiX } from 'react-icons/fi';

import Switch from "react-switch";

import CurrencyInput from "react-currency-input-field";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import currency from '../../constants/currency';

import withRouter from "utilities/withRouter";

class ProductAdd extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            error: null,
            imageCropperVisible: false,
            imageFile: null,
            imageFileCropped: null,
            
            productName: null,
            productVolume: null,
            productDescription: null,
            productPrice: null,
            productStockLevel: null,
            productVisible: true
        };
        this.toggleImageCropper = this.toggleImageCropper.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.submitCroppedImage = this.submitCroppedImage.bind(this);
        this.productImgClick = this.productImgClick.bind(this);
        this.handleRemoveProductImg = this.handleRemoveProductImg.bind(this);
    }

    handleChange(e, name) {
        switch(name) {
            case "product_name":
                this.setState({ productName: e.target.value });
                break;
            case "product_volume":
                this.setState({ productVolume: e.target.value });
                break;
            case "product_description":
                this.setState({ productDescription: e.target.value });
                break;
            case "product_price":
                this.setState({ productPrice: Math.round(e * 100) });
                break;
            case "product_stock_level":
                this.setState({ productStockLevel: e.target.value });
                break;
            case "product_visible":
                this.setState({ productVisible: e });
                break;
            default:
                return;
        }
    }

    toggleImageCropper() {
        this.setState({ imageCropperVisible: !this.state.imageCropperVisible });
    }

    async onFileChange(e) {
        if(e.target && e.target.files && e.target.files.length === 1) {
            const imgFile = e.target.files[0];
            let imageDataUrl = await this.readFile(imgFile);
            this.setState({ imageFile: imageDataUrl, imageCropperVisible: true });
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            this.getNormalizedFile(file).then(normalizedFile => reader.readAsDataURL(normalizedFile)).catch(error => reject(error));
        });
    }

    getNormalizedFile(file) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 1000,
                maxHeight: 1000,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    }

    submitCroppedImage(file) {
        this.setState({ imageFileCropped: file, imageCropperVisible: false });
    }

    async submitImage() {
        if(this.state.loading) {
            return;
        }
        if(!this.state.productName || this.state.productName.length === 0) {
            this.setState({ loading: false, error: 'A product name is required.' });
            return;
        }
        if(!this.state.productVolume || this.state.productVolume.length === 0) {
            this.setState({ loading: false, error: 'A product volume/size is required.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let data = {
                product_img: this.state.imageFileCropped,
                product_name: this.state.productName,
                product_volume: this.state.productVolume,
                product_description: this.state.productDescription,
                product_price: this.state.productPrice ? this.state.productPrice : 0,
                product_stock_level: this.state.productStockLevel ? this.state.productStockLevel : 0,
                product_visible: this.state.productVisible
            };
            await Api.addProduct(data);
            this.props.triggerNotification("Product added successfully.", "success", "bc", 4);
            this.props.navigate('/admin/products');
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    handleRemoveProductImg(e) {
        e.preventDefault();
        this.setState({ imageFileCropped: null });
    }

    productImgClick(e) {
        if(e.defaultPrevented) return
        this.productImgInput.click();
    }

    render() {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Add Product</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={8} md={7} lg={6} xl={4} xxl={3} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <div style={{ marginBottom: 15 }}>
                                    <input type="file" ref={fileInput => this.productImgInput = fileInput} accept="image/png, image/gif, image/jpeg, .jpg, .jpeg, .png" onChange={this.onFileChange} style={{ display: 'none' }} />
                                    <div className="gallery-img-container" onClick={this.productImgClick}>
                                        {this.state.imageFileCropped ?
                                            <div>
                                                <img alt="ProductImg" src={this.state.imageFileCropped} style={{ width: '100%' }}/>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="btn-icon"
                                                    style={{ float: 'right', marginBottom: 0, marginTop: -30, marginRight: 0 }}
                                                    onClick={this.handleRemoveProductImg}
                                                >
                                                    <FiX size={14}/>
                                                </Button>
                                            </div>
                                        :
                                            <div className="gallery-img-icon-container">
                                                <FiImage size={30}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <Row>
                                    <Col md={12}>
                                        <label>Product Name</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.productName}
                                                onChange={(e) => this.handleChange(e, 'product_name')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Product Volume/Size</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                placeholder="e.g. 200ml"
                                                value={this.props.productVolume}
                                                onChange={(e) => this.handleChange(e, 'product_volume')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Product Description</label>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                style={{ padding: 10 }}
                                                value={this.state.productDescription}
                                                onChange={(e) => this.handleChange(e, 'product_description')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <label>Product Price</label>
                                        <InputGroup>
                                            <InputGroupText className="currency-input-symbol">{currencyObj ? currencyObj.symbol : '£'}</InputGroupText>
                                            <CurrencyInput
                                                className="form-control currency-input-control"
                                                placeholder="0.00"
                                                decimalsLimit={2}
                                                decimalScale={2}
                                                allowNegativeValue={false}
                                                step={1}
                                                onValueChange={(value, name) => this.handleChange(value, 'product_price')}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <label>Product Stock Level</label>
                                        <FormGroup>
                                            <Input
                                                type="number"
                                                placeholder="0"
                                                min={0}
                                                step={1}
                                                style={{ padding: 10 }}
                                                value={this.state.productStockLevel}
                                                onChange={(e) => this.handleChange(e, 'product_stock_level')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Visibility</h6>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} className="mt-2">
                                        <FormGroup>
                                            <p className="react-switch-label">App Products Screen</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'product_visible') }}
                                                checked={this.state.productVisible}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                            <FormText color="muted" style={{ marginTop: '1rem' }}>
                                                Should this image be visible on your business app products screen?
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <Button color="primary" onClick={() => this.submitImage()}>
                                    {this.state.loading ? <Spinner color="success" size="sm" /> : 'Add Product'}
                                </Button>
                            </Col>
                        </Row>
                        <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                            <span>
                                {this.state.error}
                            </span>
                        </Alert>
                    </Col>
                </Row>
            </div>
            <ImageCropper
                visible={this.state.imageCropperVisible}
                toggleVisible={this.toggleImageCropper}
                imageFile={this.state.imageFile}
                submitCroppedImage={this.submitCroppedImage}
                xDim={500}
                yDim={500}
            />
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        product: state.product
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductAdd));