const currency = [
    {
        id: 1,
        label: "United Arab Emirates Dirham (AED)",
        iso: "AED",
        symbol: "د.إ"
    },
    {
        id: 2,
        label: "Afghan Afghani (AFN)",
        iso: "AFN",
        symbol: "؋"
    },
    {
        id: 3,
        label: "Albanian Lek (ALL)",
        iso: "ALL",
        symbol: "L"
    },
    {
        id: 4,
        label: "Armenian Dram (AMD)",
        iso: "AMD",
        symbol: "֏"
    },
    {
        id: 5,
        label: "Netherlands Antillean Guilder (ANG)",
        iso: "ANG",
        symbol: "NAf"
    },
    {
        id: 6,
        label: "Angolan Kwanza (AOA)",
        iso: "AOA",
        symbol: "Kz"
    },
    {
        id: 7,
        label: "Argentine Peso (ARS)",
        iso: "ARS",
        symbol: "$"
    },
    {
        id: 8,
        label: "Australian Dollar (AUD)",
        iso: "AUD",
        symbol: "A$"
    },
    {
        id: 9,
        label: "Aruban Florin (AWG)",
        iso: "AWG",
        symbol: "ƒ"
    },
    {
        id: 10,
        label: "Azerbaijani Manat (AZN)",
        iso: "AZN",
        symbol: "₼"
    },
    {
        id: 11,
        label: "Bosnia-Herzegovina Convertible Mark (BAM)",
        iso: "BAM",
        symbol: "KM"
    },
    {
        id: 12,
        label: "Barbadian Dollar (BBD)",
        iso: "BBD",
        symbol: "Bds$"
    },
    {
        id: 13,
        label: "Bangladeshi Taka (BDT)",
        iso: "BDT",
        symbol: "৳"
    },
    {
        id: 14,
        label: "Bulgarian Lev (BGN)",
        iso: "BGN",
        symbol: "Лв."
    },
    {
        id: 15,
        label: "Bahraini Dinar (BHD)",
        iso: "BHD",
        symbol: ".د.ب"
    },
    {
        id: 16,
        label: "Burundian Franc (BIF)",
        iso: "BIF",
        symbol: "FBu"
    },
    {
        id: 17,
        label: "Bermudan Dollar (BMD)",
        iso: "BMD",
        symbol: "$"
    },
    {
        id: 18,
        label: "Brunei Dollar (BND)",
        iso: "BMD",
        symbol: "B$"
    },
    {
        id: 19,
        label: "Bolivian Boliviano (BOB)",
        iso: "BOB",
        symbol: "Bs"
    },
    {
        id: 20,
        label: "Brazilian Real (BRL)",
        iso: "BRL",
        symbol: "R$"
    },
    {
        id: 21,
        label: "Bahamian Dollar (BSD)",
        iso: "BSD",
        symbol: "B$"
    },
    {
        id: 22,
        label: "Bhutanese Ngultrum (BTN)",
        iso: "BTN",
        symbol: "Nu."
    },
    {
        id: 23,
        label: "Botswanan Pula (BWP)",
        iso: "BWP",
        symbol: "P"
    },
    {
        id: 24,
        label: "Belarusian Ruble (BYN)",
        iso: "BYN",
        symbol: "BYN"
    },
    {
        id: 25,
        label: "Belize Dollar (BZD)",
        iso: "BZD",
        symbol: "$"
    },
    {
        id: 26,
        label: "Canadian Dollar (CAD)",
        iso: "CAD",
        symbol: "C$"
    },
    {
        id: 27,
        label: "Congolese Franc (CDF)",
        iso: "CDF",
        symbol: "FC"
    },
    {
        id: 28,
        label: "Swiss Franc (CHF)",
        iso: "CHF",
        symbol: "CHf"
    },
    {
        id: 29,
        label: "Chilean Unit of Account (CLF)",
        iso: "CLF",
        symbol: "UF"
    },
    {
        id: 30,
        label: "Chilean Peso (CLP)",
        iso: "CLP",
        symbol: "$"
    },
    {
        id: 31,
        label: "Chinese Yuan - Offshore (CNH)",
        iso: "CNH",
        symbol: "CNH"
    },
    {
        id: 32,
        label: "Chinese Yuan (CNY)",
        iso: "CNY",
        symbol: "¥"
    },
    {
        id: 33,
        label: "Colombian Peso (COP)",
        iso: "COP",
        symbol: "$"
    },
    {
        id: 34,
        label: "Costa Rican Colón (CRC)",
        iso: "CRC",
        symbol: "₡"
    },
    {
        id: 35,
        label: "Cuban Convertible Peso (CUC)",
        iso: "CUC",
        symbol: "CUC$"
    },
    {
        id: 36,
        label: "Cuban Peso (CUP)",
        iso: "CUP",
        symbol: "$"
    },
    {
        id: 37,
        label: "Cape Verdean Escudo (CVE)",
        iso: "CVE",
        symbol: "$"
    },
    {
        id: 38,
        label: "Czech Republic Koruna (CZK)",
        iso: "CZK",
        symbol: "Kč"
    },
    {
        id: 39,
        label: "Djiboutian Franc (DJF)",
        iso: "DJF",
        symbol: "Fdj"
    },
    {
        id: 40,
        label: "Danish Krone (DKK)",
        iso: "DKK",
        symbol: "Kr"
    },
    {
        id: 41,
        label: "Dominican Peso (DOP)",
        iso: "DOP",
        symbol: "RD$"
    },
    {
        id: 42,
        label: "Algerian Dinar (DZD)",
        iso: "DZD",
        symbol: "دج"
    },
    {
        id: 43,
        label: "Egyptian Pound (EGP)",
        iso: "EGP",
        symbol: "E£"
    },
    {
        id: 44,
        label: "Eritrean Nakfa (ERN)",
        iso: "ERN",
        symbol: "Nfk"
    },
    {
        id: 45,
        label: "Ethiopian Birr (ETB)",
        iso: "ETB",
        symbol: "Br"
    },
    {
        id: 46,
        label: "Euro (EUR)",
        iso: "EUR",
        symbol: "€"
    },
    {
        id: 47,
        label: "Fijian Dollar (FJD)",
        iso: "FJD",
        symbol: "FJ$"
    },
    {
        id: 48,
        label: "Falkland Islands Pound (FKP)",
        iso: "FKP",
        symbol: "£"
    },
    {
        id: 49,
        label: "British Pound Sterling (GBP)",
        iso: "GBP",
        symbol: "£"
    },
    {
        id: 50,
        label: "Georgian Lari (GEL)",
        iso: "GEL",
        symbol: "GEL"
    },
    {
        id: 51,
        label: "Guernsey Pound (GGP)",
        iso: "GGP",
        symbol: "£"
    },
    {
        id: 52,
        label: "Ghanaian Cedi (GHS)",
        iso: "GHS",
        symbol: "GH¢"
    },
    {
        id: 53,
        label: "Gibraltar Pound (GIP)",
        iso: "GIP",
        symbol: "£"
    },
    {
        id: 54,
        label: "Gambian Dalasi (GMD)",
        iso: "GMD",
        symbol: "D"
    },
    {
        id: 55,
        label: "Guinean Franc (GNF)",
        iso: "GNF",
        symbol: "FG"
    },
    {
        id: 56,
        label: "Guatemalan Quetzal (GTQ)",
        iso: "GTQ",
        symbol: "Q"
    },
    {
        id: 57,
        label: "Guyanaese Dollar (GYD)",
        iso: "GYD",
        symbol: "G$"
    },
    {
        id: 58,
        label: "Hong Kong Dollar (HKD)",
        iso: "HKD",
        symbol: "HK$"
    },
    {
        id: 59,
        label: "Honduran Lempira (HNL)",
        iso: "HNL",
        symbol: "L"
    },
    {
        id: 60,
        label: "Croatian Kuna (HRK)",
        iso: "HRK",
        symbol: "kn"
    },
    {
        id: 61,
        label: "Haitian Gourde (HTG)",
        iso: "HTG",
        symbol: "G"
    },
    {
        id: 62,
        label: "Hungarian Forint (HUF)",
        iso: "HUF",
        symbol: "Ft"
    },
    {
        id: 63,
        label: "Indonesian Rupiah (IDR)",
        iso: "IDR",
        symbol: "Rp"
    },
    {
        id: 64,
        label: "ILS	Israeli New Sheqel (ILS)",
        iso: "ILS",
        symbol: "₪"
    },
    {
        id: 65,
        label: "Manx pound (IMP)",
        iso: "IMP",
        symbol: "£"
    },
    {
        id: 66,
        label: "Indian Rupee (INR)",
        iso: "INR",
        symbol: "₹"
    },
    {
        id: 67,
        label: "Iraqi Dinar (IQD)",
        iso: "IQD",
        symbol: "ع.د"
    },
    {
        id: 68,
        label: "Iranian Rial (IRR)",
        iso: "IRR",
        symbol: "﷼"
    },
    {
        id: 69,
        label: "Icelandic Króna (ISK)",
        iso: "ISK",
        symbol: "kr"
    },
    {
        id: 70,
        label: "Jersey Pound (JEP)",
        iso: "JEP",
        symbol: "£"
    },
    {
        id: 71,
        label: "Jamaican Dollar (JMD)",
        iso: "JMD",
        symbol: "$"
    },
    {
        id: 72,
        label: "Jordanian Dinar (JOD)",
        iso: "JOD",
        symbol: "د.ا"
    },
    {
        id: 73,
        label: "Japanese Yen (JPY)",
        iso: "JPY",
        symbol: "¥"
    },
    {
        id: 74,
        label: "Kenyan Shilling (KES)",
        iso: "KES",
        symbol: "/-"
    },
    {
        id: 75,
        label: "Kyrgystani Som (KGS)",
        iso: "KGS",
        symbol: "Лв"
    },
    {
        id: 76,
        label: "Cambodian Riel (KHR)",
        iso: "KHR",
        symbol: "៛"
    },
    {
        id: 77,
        label: "Comorian Franc (KMF)",
        iso: "KMF",
        symbol: "CF"
    },
    {
        id: 78,
        label: "North Korean Won (KPW)",
        iso: "KPW",
        symbol: "₩"
    },
    {
        id: 79,
        label: "South Korean Won (KRW)",
        iso: "KRW",
        symbol: "₩"
    },
    {
        id: 80,
        label: "Kuwaiti Dinar (KWD)",
        iso: "KWD",
        symbol: "KD"
    },
    {
        id: 81,
        label: "Cayman Islands Dollar (KYD)",
        iso: "KYD",
        symbol: "$"
    },
    {
        id: 82,
        label: "Kazakhstani Tenge (KZT)",
        iso: "KZT",
        symbol: "₸"
    },
    {
        id: 83,
        label: "Laotian Kip (LAK)",
        iso: "LAK",
        symbol: "₭"
    },
    {
        id: 84,
        label: "Lebanese Pound (LBP)",
        iso: "LBP",
        symbol: "ل.ل"
    },
    {
        id: 85,
        label: "Sri Lankan Rupee (LKR)",
        iso: "LKR",
        symbol: "Rs"
    },
    {
        id: 86,
        label: "Liberian Dollar (LRD)",
        iso: "LRD",
        symbol: "L$"
    },
    {
        id: 87,
        label: "Lesotho Loti (LSL)",
        iso: "LSL",
        symbol: "L"
    },
    {
        id: 88,
        label: "Libyan Dinar (LYD)",
        iso: "LYD",
        symbol: "LD"
    },
    {
        id: 89,
        label: "Moroccan Dirham (MAD)",
        iso: "MAD",
        symbol: "MAD"
    },
    {
        id: 90,
        label: "Moldovan Leu (MDL)",
        iso: "MDL",
        symbol: "L"
    },
    {
        id: 91,
        label: "Malagasy Ariary (MGA)",
        iso: "MGA",
        symbol: "Ar"
    },
    {
        id: 92,
        label: "Macedonian Denar (MKD)",
        iso: "MKD",
        symbol: "Ден"
    },
    {
        id: 93,
        label: "Myanma Kyat (MMK)",
        iso: "MMK",
        symbol: "K"
    },
    {
        id: 94,
        label: "Mongolian Tugrik (MNT)",
        iso: "MNT",
        symbol: "₮"
    },
    {
        id: 95,
        label: "Macanese Pataca (MOP)",
        iso: "MNT",
        symbol: "MOP$"
    },
    {
        id: 96,
        label: "Mauritanian Ouguiya (MRU)",
        iso: "MRU",
        symbol: "MRU"
    },
    {
        id: 97,
        label: "Mauritian Rupee (MUR)",
        iso: "MUR",
        symbol: "₨"
    },
    {
        id: 98,
        label: "Maldivian Rufiyaa (MVR)",
        iso: "MVR",
        symbol: ".ރ"
    },
    {
        id: 99,
        label: "Malawian Kwacha (MWK)",
        iso: "MWK",
        symbol: "MK"
    },
    {
        id: 100,
        label: "Mexican Peso (MXN)",
        iso: "MXN",
        symbol: "Mex$"
    },
    {
        id: 101,
        label: "Malaysian Ringgit (MYR)",
        iso: "MYR",
        symbol: "RM"
    },
    {
        id: 102,
        label: "Mozambican Metical (MZN)",
        iso: "MZN",
        symbol: "MT"
    },
    {
        id: 103,
        label: "Namibian Dollar (NAD)",
        iso: "NAD",
        symbol: "N$"
    },
    {
        id: 104,
        label: "Nigerian Naira (NGN)",
        iso: "NGN",
        symbol: "₦"
    },
    {
        id: 105,
        label: "Nicaraguan Córdoba (NIO)",
        iso: "NIO",
        symbol: "C$"
    },
    {
        id: 106,
        label: "Norwegian Krone (NOK)",
        iso: "NOK",
        symbol: "kr"
    },
    {
        id: 107,
        label: "Nepalese Rupee (NPR)",
        iso: "NPR",
        symbol: "Re."
    },
    {
        id: 108,
        label: "New Zealand Dollar (NZD)",
        iso: "NZD",
        symbol: "$"
    },
    {
        id: 109,
        label: "Omani Rial (OMR)",
        iso: "OMR",
        symbol: "ر.ع."
    },
    {
        id: 110,
        label: "Panamanian Balboa (PAB)",
        iso: "PAB",
        symbol: "B/."
    },
    {
        id: 111,
        label: "Peruvian Nuevo Sol (PEN)",
        iso: "PEN",
        symbol: "S/"
    },
    {
        id: 112,
        label: "Papua New Guinean Kina (PGK)",
        iso: "PGK",
        symbol: "K"
    },
    {
        id: 113,
        label: "Philippine Peso (PHP)",
        iso: "PHP",
        symbol: "₱"
    },
    {
        id: 114,
        label: "Pakistani Rupee (PKR)",
        iso: "PKR",
        symbol: "₨"
    },
    {
        id: 115,
        label: "Polish Zloty (PLN)",
        iso: "PLN",
        symbol: "zł"
    },
    {
        id: 116,
        label: "Paraguayan Guarani (PYG)",
        iso: "PYG",
        symbol: "₲"
    },
    {
        id: 117,
        label: "Qatari Rial (QAR)",
        iso: "QAR",
        symbol: "QR"
    },
    {
        id: 118,
        label: "Romanian Leu (RON)",
        iso: "RON",
        symbol: "lei"
    },
    {
        id: 119,
        label: "Serbian Dinar (RSD)",
        iso: "RSD",
        symbol: "din"
    },
    {
        id: 120,
        label: "Russian Ruble (RUB)",
        iso: "RUB",
        symbol: "₽"
    },
    {
        id: 121,
        label: "Rwandan Franc (RWF)",
        iso: "RWF",
        symbol: "FRw"
    },
    {
        id: 122,
        label: "Saudi Riyal (SAR)",
        iso: "SAR",
        symbol: "SR"
    },
    {
        id: 123,
        label: "Solomon Islands Dollar (SBD)",
        iso: "SBD",
        symbol: "Si$"
    },
    {
        id: 124,
        label: "Seychellois Rupee (SCR)",
        iso: "SCR",
        symbol: "SR"
    },
    {
        id: 125,
        label: "Sudanese Pound (SDG)",
        iso: "SDG",
        symbol: "ج.س."
    },
    {
        id: 126,
        label: "Swedish Krona (SEK)",
        iso: "SEK",
        symbol: "kr"
    },
    {
        id: 127,
        label: "Singapore Dollar (SGD)",
        iso: "SGD",
        symbol: "S$"
    },
    {
        id: 128,
        label: "Saint Helena Pound (SHP)",
        iso: "SHP",
        symbol: "£"
    },
    {
        id: 129,
        label: "Sierra Leonean Leone (SLL)",
        iso: "SLL",
        symbol: "Le"
    },
    {
        id: 130,
        label: "Somali Shilling (SOS)",
        iso: "SOS",
        symbol: "SOS"
    },
    {
        id: 131,
        label: "Surinamese Dollar (SRD)",
        iso: "SRD",
        symbol: "$"
    },
    {
        id: 132,
        label: "South Sudanese Pound (SSP)",
        iso: "SSP",
        symbol: "£"
    },
    {
        id: 133,
        label: "São Tomé and Príncipe Dobra (STN)",
        iso: "STN",
        symbol: "Db"
    },
    {
        id: 134,
        label: "Salvadoran Colón (SVC)",
        iso: "SVC",
        symbol: "SVC"
    },
    {
        id: 135,
        label: "Syrian Pound (SYP)",
        iso: "SYP",
        symbol: "LS"
    },
    {
        id: 136,
        label: "Swazi Lilangeni (SZL)",
        iso: "SZL",
        symbol: "E"
    },
    {
        id: 137,
        label: "Thai Baht (THB)",
        iso: "THB",
        symbol: "฿"
    },
    {
        id: 138,
        label: "Tajikistani Somoni (TJS)",
        iso: "TJS",
        symbol: "ЅM"
    },
    {
        id: 139,
        label: "Turkmenistani Manat (TMT)",
        iso: "TMT",
        symbol: "T"
    },
    {
        id: 140,
        label: "Tunisian Dinar (TND)",
        iso: "TND",
        symbol: "DT"
    },
    {
        id: 141,
        label: "Tongan Pa'anga (TOP)",
        iso: "TOP",
        symbol: "PT"
    },
    {
        id: 142,
        label: "Turkish Lira (TRY)",
        iso: "TRY",
        symbol: "₺"
    },
    {
        id: 143,
        label: "Trinidad and Tobago Dollar (TTD)",
        iso: "TTD",
        symbol: "TT$"
    },
    {
        id: 144,
        label: "New Taiwan Dollar (TWD)",
        iso: "TWD",
        symbol: "NT$"
    },
    {
        id: 145,
        label: "Tanzanian Shilling (TZS)",
        iso: "TZS",
        symbol: "TSh"
    },
    {
        id: 146,
        label: "Ukrainian Hryvnia (UAH)",
        iso: "UAH",
        symbol: "₴"
    },
    {
        id: 147,
        label: "Ugandan Shilling (UGX)",
        iso: "UGX",
        symbol: "USh"
    },
    {
        id: 148,
        label: "United States Dollar (USD)",
        iso: "USD",
        symbol: "$"
    },
    {
        id: 149,
        label: "Uruguayan Peso (UYU)",
        iso: "UYU",
        symbol: "$U"
    },
    {
        id: 150,
        label: "Uzbekistan Som (UZS)",
        iso: "UZS",
        symbol: "so'm"
    },
    {
        id: 151,
        label: "Venezuelan Bolívar Fuerte (VEF)",
        iso: "VEF",
        symbol: "Bs"
    },
    {
        id: 152,
        label: "Vietnamese Dong (VEF)",
        iso: "VND",
        symbol: "₫"
    },
    {
        id: 153,
        label: "Vanuatu Vatu (VUV)",
        iso: "VUV",
        symbol: "VT"
    },
    {
        id: 154,
        label: "Samoan Tala (WST)",
        iso: "WST",
        symbol: "SAT"
    },
    {
        id: 155,
        label: "East Caribbean Dollar (XCD)",
        iso: "XCD",
        symbol: "$"
    },
    {
        id: 156,
        label: "Yemeni Rial (YER)",
        iso: "YER",
        symbol: "﷼"
    },
    {
        id: 157,
        label: "South African Rand (ZAR)",
        iso: "ZAR",
        symbol: "R"
    },
    {
        id: 158,
        label: "Zambian Kwacha (ZMW)",
        iso: "ZMW",
        symbol: "ZK"
    },
    {
        id: 159,
        label: "Zimbabwean Dollar (ZWL)",
        iso: "ZWL",
        symbol: "Z$"
    }
];

export default currency;