import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Badge,
  Button,
  CardFooter
} from "reactstrap";
import Lottie from 'react-lottie';
import { connect } from "react-redux";
import moment from 'moment';
import 'moment-timezone';
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from '../../api/index';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import * as referralGift from 'assets/animations/adhoc/referralGift.json';

class Referrals extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            referralsData: []
        };
    }

    componentDidMount() {
        this.getReferrals();
        this.props.actions.loadBusinessAuto();
    }
    
    async getReferrals() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let response = await Api.getReferrals();
            this.setState({ referralsData: response.data.referralsData });
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst trying to retrieve your existing referrals.", "danger", "bc", 4);
        }
        this.props.actions.loadLoadingSpinner(false);
    }

    loadPrizeAnimation() {
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: referralGift.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div className="referral-prize-animation-container">
                <Lottie options={defaultOptions}/>
            </div>
        );
    }

    renderReferralStatus(referral_status_id) {
        let statusBadge = null;
        switch(referral_status_id) {
            case 1:
                statusBadge = <Badge color="primary" style={{ margin: 'auto' }}>Pending</Badge>
                break;
            case 2:
                statusBadge = <Badge color="warning" style={{ margin: 'auto' }}>Processing</Badge>
                break;
            case 3:
                statusBadge = <Badge color="success" style={{ margin: 'auto' }}>Redeemed</Badge>
                break;
            default:
                // code block
                statusBadge = <Badge color="primary" style={{ margin: 'auto' }}>Pending</Badge>
        }
        return statusBadge;
    }

    copyReferralCode() {
        if(this.props.business && this.props.business.business_referral_code) {
            navigator.clipboard.writeText(this.props.business.business_referral_code);
            this.props.triggerNotification("Referral code copied to clipboard", "success", "bc", 2);
        }
    }

    render() {
        console.log(this.state.referralsData);
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={4} sm={4} xs={4} className="ms-auto me-auto">
                                        {this.loadPrizeAnimation()}
                                    </Col>
                                    <Col md={8}>
                                        <div className="centered-container" style={{ height: '100%' }}>
                                            <div className="referral-code-title">Referrals</div>
                                            <div className="referral-code-subtitle">Your promo code:</div>
                                            <div className="referral-code" onClick={() => this.copyReferralCode()}>
                                                {this.props.business && this.props.business.business_referral_code ? this.props.business.business_referral_code : '-----'}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <h4 className="mb-3 mt-0">How does it work?</h4>
                        <Card>
                            <CardBody>
                                <div className="referral-instruction-row">
                                    <div style={{ minWidth: 60 }}><b>Step 1</b></div>
                                    <div>Invite a friend to join Styler.</div>
                                </div>
                                <div className="referral-instruction-row">
                                    <div style={{ minWidth: 60 }}><b>Step 2</b></div>
                                    <div>Give your friend your unique referral code provided above - make sure that they enter your promo code when they sign up.</div>
                                </div>
                                <div className="referral-instruction-row">
                                    <div style={{ minWidth: 60 }}><b>Step 3</b></div>
                                    <div>Your friend will get a 15% discount applied to their subscription fee upon sign up.</div>
                                </div>
                                <div className="referral-instruction-row mb-0">
                                    <div style={{ minWidth: 60 }}><b>Step 4</b></div>
                                    <div>Once your friend's app goes live, you will get 3 months subscription-free! If they haven't opted for an app, the 3 months subscription-free reward will be applied when their subscription payment is taken one month after sign up.</div>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <p>Our full referral program terms and conditions can be found here:</p>
                                <Button color="primary" outline size="sm" onClick={() => window.open("https://help.styler.digital/legal/referral_program_terms", '_blank')}>Full Terms & Conditions</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <h4 className="mb-3 mt-0">Referrals</h4>
                        <Card>
                            <CardBody>
                                <div>
                                    {this.state.referralsData && this.state.referralsData.length > 0 ?
                                        <ReactTable
                                            data={this.state.referralsData ? this.state.referralsData : []}
                                            pagination={false}
                                            columns={[
                                            {
                                                Header: "Business",
                                                accessor: "business_name",
                                                disableFilters: true,
                                                Cell: (props) => <b>{props.value}</b>
                                            },
                                            {
                                                Header: "Created",
                                                accessor: "created",
                                                disableFilters: true,
                                                Cell: props => <span>{this.props.business && this.props.business.timezone_name ? moment.utc(props.value, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, false).format('Do MMM YY') : moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('Do MMM YY')}</span>
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "business_referral_status_id",
                                                disableFilters: true,
                                                Cell: props => <span style={{ float: 'right' }}>{this.renderReferralStatus(props.value)}</span>
                                            }
                                            ]}
                                        />
                                    : <div className="text-center font-weight-bold">No referrals found</div>}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);