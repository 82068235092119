const bookingAdvanceMonths = [
    {
        id: 1,
        label: "1 month in advance",
        value: 1
    },
    {
        id: 2,
        label: "2 months in advance",
        value: 2
    },
    {
        id: 3,
        label: "3 months in advance",
        value: 3
    },
    {
        id: 4,
        label: "4 months in advance",
        value: 4
    },
    {
        id: 5,
        label: "5 months in advance",
        value: 5
    },
    {
        id: 6,
        label: "6 months in advance",
        value: 6
    },
    {
        id: 7,
        label: "7 months in advance",
        value: 7
    },
    {
        id: 8,
        label: "8 months in advance",
        value: 8
    },
    {
        id: 9,
        label: "9 months in advance",
        value: 9
    },
    {
        id: 10,
        label: "10 months in advance",
        value: 10
    },
    {
        id: 11,
        label: "11 months in advance",
        value: 11
    },
    {
        id: 12,
        label: "12 months in advance",
        value: 12
    }
];

export default bookingAdvanceMonths;