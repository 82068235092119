import React from "react";
import { Link } from "react-router-dom";
// MUI components
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
// Icon
import { ArrowLeft } from "@phosphor-icons/react";

const AuthNavbar = () => {
  return (
    <AppBar
      position="static"
      style={{ background: 'transparent', boxShadow: 'none'}}
    >
      <Toolbar>
        <IconButton LinkComponent={Link} to="https://styler.digital">
          <ArrowLeft size={30} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default AuthNavbar;