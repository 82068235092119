/*eslint-disable*/
import React from "react";
// Lodash
import { isEqual } from "lodash";
// Jquery
import $ from 'jquery';
// Moment
import moment from "moment";
import 'moment-timezone';
// Select
import Select from "react-select";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner
} from "reactstrap";
// Device detect
import { isMobile } from 'react-device-detect';
// Fullcalendar
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import BreakAdd from "components/Break/BreakAdd";
import BreakEdit from "components/Break/BreakEdit";
import CalendarStaffPicker from "components/Calendar/CalendarStaffPicker";
import CalendarMinifiedOptions from "components/Calendar/CalendarMinifiedOptions";
import CalendarBookingOptions from "components/Calendar/CalendarBookingOptions";
import CalendarResourceOptions from "components/Calendar/CalendarResourceOptions";
import CalendarNewEventOptions from "components/Calendar/CalendarNewEventOptions";

import StaffScheduleOptions from "components/Staff/StaffScheduleOptions";
import StaffHours from "components/Staff/StaffHours";
import DatePicker from "components/DatePicker/DatePicker";
// Decorators
import withRouter from "utilities/withRouter";

import { CDN_URL } from "constants/urls";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import { events } from "variables/general.js";

import { FiCheck, FiUsers, FiPlus, FiSettings, FiRepeat, FiCalendar } from 'react-icons/fi';
import { FaEllipsisV } from 'react-icons/fa';

import Api from '../../api/index';

import "./FullCalendarOverride.css";

class Calendar extends React.Component {

  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.state = {
      events: events,
      staffHoursData: null,
      locationOptions: [],
      selectedLocationId: null,
      alert: null,
      calendarLoading: false,
      calendarHeight: this.getCalendarHeight(),
      calendarInitialised: false,
      calendarStartDate: null,
      calendarEndDate: null,
      calendarSelectedTime: null,
      calendarSelectedResource: null,
      calendarSelectedEvent: null,
      calendarSelectedEventConversion: false,
      staffPickerModalVisible: false,
      minifiedOptionsModalVisible: false,
      bookingOptionsModalVisible: false,
      resourceOptionsModalVisible: false,
      staffScheduleOptionsModalVisible: false,
      staffHoursModalVisible: false,
      datePickerModalVisible: false,
      settingsModalVisible: false,
      breakAddModalVisible: false,
      breakEditModalVisible: false,
      newEventModalVisible: false,
      editTypeModalVisible: false
    };
    this.renderCalendarEvent = this.renderCalendarEvent.bind(this);
    this.getCalendarEvents = this.getCalendarEvents.bind(this);
    this.toggleBreakAddModal = this.toggleBreakAddModal.bind(this);
    this.toggleBreakEditModal = this.toggleBreakEditModal.bind(this);
    this.toggleResourceOptionsModal = this.toggleResourceOptionsModal.bind(this);
    this.breakAddSubmit = this.breakAddSubmit.bind(this);
    this.breakEditSubmit = this.breakEditSubmit.bind(this);
    this.toggleResource = this.toggleResource.bind(this);
    this.submitBookingOptionsStatusChange = this.submitBookingOptionsStatusChange.bind(this);
    this.submitDatePickerChange = this.submitDatePickerChange.bind(this);
  }

  async componentDidMount() {
    // Initialise calendar
    window.addEventListener('resize', () => this.setState({ calendarHeight: this.getCalendarHeight() }));
    // Get business locations
    let locationOptions = [];
    this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj, businessLocationIndex) => {
        locationOptions.push({
            id: businessLocationObj.business_location_id,
            label: businessLocationObj.business_location_name,
            value: businessLocationObj.business_location_id
        });
    });
    let selectedLocationId = null;
    if(locationOptions.length > 0) {
      if(this.props.settings && this.props.settings.selected_business_location_id) {
        selectedLocationId = this.props.settings.selected_business_location_id;
      } else {
        selectedLocationId = locationOptions[0].id;
      }
    }
    this.setState({
      calendarInitialised: true,
      locationOptions,
      selectedLocationId
    });
    this.setCalendarSettings();
    this.initialiseMouseFollower();
    setTimeout(() => {
      this.getCalendarResourceHours(null);
      this.getCalendarEvents(null);
    }, 100);
  }

  componentWillUnmount() {
    let calendarHeight = this.getCalendarHeight();
    window.removeEventListener('resize', () => this.setState({ calendarHeight }));
  }

  toggleBreakAddModal() {
    this.setState({ breakAddModalVisible: !this.state.breakAddModalVisible });
  }

  getCalendarHeight() {
    if(window.innerWidth < 1024) {
      return window.innerHeight - 100 + 30 - 15 - 51 - 11;
    } else {
      return window.innerHeight - 102 + 30 - 56 - 21;
    }
  }

  initialiseMouseFollower() {
    if(isMobile) {
      return;
    }
    $(document).on({
      mouseenter: function(e) {
        let cellWidth = $(this).width();
        let cellHeight = 22;
        let parentOffset = $(this).parent().offset();
        let relY = e.pageY - parentOffset.top;
        $(this).append(`
          <div
            class="time-tooltip"
            style="height:${cellHeight}px;width:${cellWidth}px;top:${relY}px;">
          </div>`
        );
      },
      mousemove: function(e) {
        let parentOffset = $(this).parent().offset();
        let relY = e.pageY - parentOffset.top;
        let cellHeight = 22;
        let cellIndex = Math.floor(relY/cellHeight); 
        $(this).children('.time-tooltip').css('top', cellHeight * cellIndex);
        let timeData = $(`.fc-timegrid-slots tbody tr:nth-child(${cellIndex + 1}) td:first-child`).data('time');
        if(timeData) {
          $(this).children('.time-tooltip').html(timeData.slice(0, -3));
        }
      },
      mouseleave: function() {
          $(this).children('.time-tooltip').remove();
      }
    }, 'td.fc-timegrid-col.fc-day.fc-resource');
  }

  breakAddSubmit(e) {
    if(this.state.calendarInitialised){
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.addEvent({
        id: e.breakId,
        title: "Break",
        start: e.date.format('YYYY-MM-DD') + " " + e.start.format('HH:mm:ss'),
        end: e.date.format('YYYY-MM-DD') + " " + e.end.format('HH:mm:ss'),
        resourceId: e.staffId,
        type: 'businessEvent',
        color: '#dadada',
        textColor: '#6f6f6f',
        borderColor: '#bfbfbf',
        classNames: ['fc-event-break', 'fc-event-generic'],
        bookingId: null,
        customerFirstname: null,
        customerLastname: null,
        service: null,
        eventNotes: e.title,
        recurring: e.recurring,
        extendedProps: {
          staffId: e.staffId
        }
      });
    } else {
      this.getCalendarEvents(null);
    }
  }

  breakEditSubmit(e, deleted) {
    if(this.state.calendarInitialised){
      let calendarApi = this.calendarRef.current.getApi();
      let breakEvent = calendarApi.getEventById(e.breakId);
      if(breakEvent) {
        if(deleted === true) {
          breakEvent.remove();
        } else {
          if(e.recurring === false) {
              breakEvent.setStart(e.date.format('YYYY-MM-DD') + ' ' + e.start.format('HH:mm:ss'));
              breakEvent.setEnd(e.date.format('YYYY-MM-DD') + ' ' + e.end.format('HH:mm:ss'));
              breakEvent.setResources([e.staffId]);
              breakEvent.setExtendedProp('staffId', e.staffId);
              breakEvent.setExtendedProp('eventNotes', e.title);
          } else {
            this.getCalendarEvents(null);
          }
        }
      } else {
        this.getCalendarEvents(null);
      }
    } else {
      this.getCalendarEvents(null);
    }
  }

  toggleBreakEditModal() {
    if(this.state.breakEditModalVisible === true) {
      // Reset conversion flag when closing break edit modal
      this.setState({ calendarSelectedEventConversion: false });
    }
    this.setState({ breakEditModalVisible: !this.state.breakEditModalVisible });
  }

  toggleNewEventModal = (e) => {
    this.setState({ newEventModalVisible: !this.state.newEventModalVisible });
  }

  toggleEditTypeModal() {
    this.setState({ editTypeModalVisible: !this.state.editTypeModalVisible });
  }

  setCalendarSettings() {
    let calendarApi = this.calendarRef.current.getApi();
    // Set First Day
    if(this.props.calendarSettings && this.props.calendarSettings.calendarFirstDay){
      calendarApi.setOption('firstDay', this.props.calendarSettings.calendarFirstDay.value);
    } else {
      calendarApi.setOption('firstDay', 1);
    }
    // Set slot duration
    if(this.props.calendarSettings && this.props.calendarSettings.slotDuration){
      calendarApi.setOption('slotDuration', this.props.calendarSettings.slotDuration.value);
    } else {
      calendarApi.setOption('slotDuration', "00:05:00");
    }
    // Get url params
    const query = new URLSearchParams(window.location.search);
    let date = query.get("date");
    if(date && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
      calendarApi.gotoDate(date);
    }
    let time = query.get("time");
    if(time && /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)) {
      calendarApi.scrollToTime(time);
    }
  }

  handleEventClick(e) {
    if(e.event.extendedProps.recurring && e.event.extendedProps.recurring === true) {
      // Recurring
      if(e.event.title === 'Booking') {
        this.setState({ calendarSelectedEvent: e.event, bookingOptionsModalVisible: true });
      } else if(e.event.title === 'Break') {
        this.setState({ calendarSelectedEvent: e.event, editTypeModalVisible: true });
      }
    } else {
      // Non-recurring
      if(e.event.title === 'Booking') {
        this.setState({ calendarSelectedEvent: e.event, bookingOptionsModalVisible: true });
      } else if(e.event.title === 'Walk-in') {
        this.props.navigate('/admin/edit_booking?user_order_id=' + parseInt(e.event.extendedProps.userOrderId));
      } else if(e.event.title === 'Break') {
        this.setState({ calendarSelectedEvent: e.event, breakEditModalVisible: true });
      }
    }
  }

  handleDateClick = (e) => {
    if(e.view.type === "resourceTimeGridDay") {
      if(this.props.userRole?.booking?.create !== true && this.props.userRole?.break?.create !== true) {
        this.props.triggerNotification("You don't have permission to create bookings or breaks.", "danger", "bc", 4);
        return;
      }
      this.setState({
        calendarSelectedTime: moment(e.date).tz(this.props.business.timezone_name, false),
        calendarSelectedResource: parseInt(e.resource.id)
      });
      this.toggleNewEventModal(e);
    };
  }

  handleLocationChange(e) {
    let selectedLocationId = e.id;
    if(selectedLocationId === this.state.selectedLocationId) {
      return;
    }
    let calendarResources = [];
    this.props.staff.map((staffObj,) => {
      if(this.props.businessLocationStaffMap.find(x => x.staff_id === staffObj.id && x.business_location_id === selectedLocationId)) {
        calendarResources.push(staffObj.id);
      }
    });
    if(!isEqual(this.props.calendarSettings.calendarResources, calendarResources)) {
      let curCalendarSettings = Object.assign({}, this.props.calendarSettings);
      curCalendarSettings.calendarResources = calendarResources;
      this.props.actions.loadCalendarSettings(curCalendarSettings);
    }
    this.setState({ selectedLocationId });
    // Modify selected location in settings
    let settingsObj = Object.assign({}, this.props.settings);
    settingsObj.selected_business_location_id = selectedLocationId;
    this.props.actions.loadSettings(settingsObj, this.props.user);
    // Load location resource hours and events
    let resourcesOverride = calendarResources.map((staffId,) => {
      return { id: staffId }
    });
    this.getCalendarResourceHours(null, resourcesOverride, selectedLocationId);
    this.getCalendarEvents(null, selectedLocationId);
  }

  getCalendarResources(fetchInfo, successCallback, faulureCallback) {
    let calendarResources = [];
    if(this.props.calendarSettings && this.props.calendarSettings.calendarResources && this.props.calendarSettings.calendarResources.length > 0){
      this.props.calendarSettings.calendarResources.map((staffId, staffIndex) => {
        let staffObj = this.props.staff.find(x => x.id === staffId);
        if(staffObj){
          let locationStaffMapObj = this.props.businessLocationStaffMap.find(x => x.staff_id === staffId && x.business_location_id === this.state.selectedLocationId);
          if(locationStaffMapObj) {
            const permissionCheck = this.props.userRole.booking.read_all === true || (this.props.userRole.booking.read_all === false && this.props.user.staff_id === staffObj.id)
            if(permissionCheck) {
              let resourceObj = {
                id: staffObj.id,
                title: window.innerWidth > 707 ? staffObj.firstname + ' ' + staffObj.lastname : staffObj.firstname.charAt(0) + ' ' + staffObj.lastname,
                order: staffIndex,
                extendedProps: { img: staffObj.staff_img },
              };
              calendarResources.push(resourceObj);
            }
          }
        }
      });
    }
    successCallback(calendarResources);
  }

  getCalendarResourceHours(e, calendarResourcesOverride = null, businessLocationId = null){
    let that = this;
    if(this.state.calendarInitialised){
      let calendarApi = this.calendarRef.current.getApi();
      let calendarResources;
      if(calendarResourcesOverride) {
        calendarResources = calendarResourcesOverride;
      } else {
        calendarResources = calendarApi.getResources();
      }
      if(calendarResources && calendarResources.length === 0) {
        // Try to populate from the staff location map
        this.props.businessLocationStaffMap.filter(x => x.business_location_id === this.state.selectedLocationId).map((staffMapObj,) => {
          calendarResources.push({ id: staffMapObj.staff_id });
        });
        if(calendarResources.length === 0) {
          return;
        }
      }
      let calendarView = calendarApi.view.type;
      if(!e) {
        e = { start: calendarApi.view.currentStart, end: calendarApi.view.currentEnd }
      }
      if(this.props.calendarSettings && this.props.calendarSettings.calendarResources && this.props.calendarSettings.calendarResources.length > 0){
        let locationId;
        if(businessLocationId) {
          locationId = businessLocationId;
        } else {
          locationId = this.state.selectedLocationId;
        }
        Api.getStaffHours(moment(e.start).format('YYYY-MM-DD'), moment(e.end).subtract(1, 'day').format('YYYY-MM-DD'), locationId).then(f => {
          return f.data;
        }).then((result) => {
          calendarApi.batchRendering(() => {
            // Remove existing month background events
            for (let i = 0; i < 32; i++) {
              let existingHoursEvent = calendarApi.getEventById('resourceHoursM' + i);
              if(existingHoursEvent) {
                existingHoursEvent.remove();
              }
            }
            calendarResources.map((resourceObj, resourceIndex) => {
              // Remove existing day background events
              let existingHoursEvent = calendarApi.getEventById('resourceHours' + resourceObj.id);
              if(existingHoursEvent) {
                existingHoursEvent.remove();
              }
              // Remove existing week background events
              for (let i = 0; i < 7; i++) {
                let existingHoursEvent = calendarApi.getEventById('resourceHours' + resourceObj.id + 'W' + i);
                if(existingHoursEvent) {
                  existingHoursEvent.remove();
                }
              }
              // Process Hours
              if(calendarView === 'resourceTimeGridDay'){
                that.setState({ staffHoursData: result.staffHoursData });
                let staffHoursData = result.staffHoursData.find(x => x.staff_id === parseInt(resourceObj.id) && moment(x.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, true).isSame(moment(e.start).tz(this.props.business.timezone_name, false), 'day'));
                if(staffHoursData){
                  if(staffHoursData.working === 1){
                    calendarApi.addEvent({
                      id: 'resourceHours' + resourceObj.id,
                      start: moment(e.start).format('YYYY-MM-DD') + ' ' + staffHoursData.start,
                      end: moment(e.start).format('YYYY-MM-DD') + ' ' + staffHoursData.end,
                      display: 'inverse-background',
                      resourceId: resourceObj.id,
                      type: 'businessHours'
                    });
                  } else {
                    calendarApi.addEvent({
                      id: 'resourceHours' + resourceObj.id,
                      start: moment(e.start).format('YYYY-MM-DD') + ' 00:00:00',
                      end: moment(e.start).format('YYYY-MM-DD') + ' 00:00:01',
                      display: 'inverse-background',
                      resourceId: resourceObj.id,
                      type: 'businessHours'
                    });
                  }
                }
              } else if(calendarView === 'resourceTimeGridWeek' || calendarView === 'resourceTimeGridThreeDay') {
                let weekStartDate = moment(e.start);
                let weekEndDate = moment(e.end)
                let weekCurDate = weekStartDate;
                let weekCurDateIndex = 0;
                while(moment(weekCurDate).isBefore(weekEndDate, 'day')){
                  let staffHoursData = result.staffHoursData.find(x => x.staff_id === parseInt(resourceObj.id) && moment(x.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, true).format('YYYY-MM-DD') === moment(weekCurDate).format('YYYY-MM-DD'));
                  if(staffHoursData){
                    if(staffHoursData.working === 1){
                      calendarApi.addEvent({
                        id: 'resourceHours' + resourceObj.id + 'W' + weekCurDateIndex,
                        start: moment(weekCurDate).format('YYYY-MM-DD') + ' ' + staffHoursData.start,
                        end: moment(weekCurDate).format('YYYY-MM-DD') + ' ' + staffHoursData.end,
                        display: 'inverse-background',
                        resourceId: resourceObj.id,
                        groupId: 'weekResourceHours',
                        type: 'businessHours'
                      });
                    } else {
                      calendarApi.addEvent({
                        id: 'resourceHours' + resourceObj.id + 'W' + weekCurDateIndex,
                        start: moment(weekCurDate).format('YYYY-MM-DD') + ' 00:00:00',
                        end: moment(weekCurDate).format('YYYY-MM-DD') + ' 00:00:01',
                        display: 'inverse-background',
                        resourceId: resourceObj.id,
                        groupId: 'weekResourceHours',
                        type: 'businessHours'
                      });
                    }
                  }
                  weekCurDate = weekCurDate.add(1, 'day');
                  weekCurDateIndex = weekCurDateIndex + 1;
                }
              }
            });

            if(calendarView === 'dayGridMonth') {
              let closedData = result.staffHoursData.filter(x => x.time_status === 2);
              let closedDates = [];
              closedData.map((closedObj, closedIndex) => {
                let closedDate = moment(closedObj.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                let findClosedDate = closedDates.find(x => x === closedDate);
                if(!findClosedDate) {
                  // Block out month date
                  calendarApi.addEvent({
                    id: 'resourceHoursM' + closedDates.length,
                    start: closedDate,
                    end: closedDate,
                    allDay: true,
                    display: 'background',
                    type: 'businessHours'
                  });
                  closedDates.push(closedDate);
                }
              });
              //this.props.actions.loadLoadingSpinner(false);
            } else if(calendarView === 'resourceTimeGridWeek') {
              //this.props.actions.loadLoadingSpinner(false);
            }
          });
        }).catch(error => {
          console.log(error);
        });
      }
    }
  }

  getCalendarEvents(e, businessLocationId = null) {
    let that = this;
    if(this.state.calendarInitialised){
      this.setState({ calendarLoading: true });
      let calendarApi = this.calendarRef.current.getApi();
      let existingCalendarEvents = calendarApi.getEvents();
      let viewStart, viewEnd, viewType;
      if(!e || !e.start || !e.end) {
        viewStart = calendarApi.view.currentStart;
        viewEnd = calendarApi.view.currentEnd;
      } else {
        viewStart = e.start;
        viewEnd = e.end;
      }
      viewType = calendarApi.view.type;
      // Batch render
      calendarApi.batchRendering(function() {
        // Remove existing business events
        let filteredCalendarEvents = existingCalendarEvents.filter(x => x._def.extendedProps.type === "businessEvent");
        filteredCalendarEvents.forEach((calendarEventObj, calendarEventIndex) => {
          calendarEventObj.remove();
        });
        let locationId;
        if(businessLocationId) {
          locationId = businessLocationId;
        } else {
          locationId = that.state.selectedLocationId;
        }
        Api.getCalendarEvents(moment(viewStart).format('YYYY-MM-DD'), moment(viewEnd).format('YYYY-MM-DD'), locationId).then(f => {
          return f.data;
        }).then((result) => {
          if(result.calendarEventData && result.calendarEventData.length > 0) {
            result.calendarEventData.map((calendarEventObj, calendarEventIndex) => {
              let eventClass = null, eventBackground = null, eventText = null;
              if(calendarEventObj.title === 'Booking' || calendarEventObj.title === 'Walk-in'){
                if(calendarEventObj.status === "Booked") {
                  if(calendarEventObj.service_colour) {
                    eventClass = "fc-event-placeholder";
                  } else {
                    eventClass = 'fc-event-booked';
                  }
                } else if(calendarEventObj.status === "In-progress") {
                  eventClass = 'fc-event-in-progress';
                } else if(calendarEventObj.status === "Completed") {
                  eventClass = 'fc-event-completed';
                } else if(calendarEventObj.status === "No-show") {
                  eventClass = 'fc-event-no-show';
                } else {
                  eventClass = 'fc-event-booked';
                }
                eventBackground = '#ffffff';
                eventText = '#0a122b';
              } else if(calendarEventObj.title === 'Break'){
                eventClass = 'fc-event-break';
                eventBackground = '#dadada';
                eventText = '#6f6f6f'
              }
              if(viewType === 'listDay' && that.props.calendarSettings && that.props.calendarSettings.calendarResources && !that.props.calendarSettings.calendarResources.includes(calendarEventObj.resourceId)) {
                return null;
              }
              calendarApi.addEvent({
                id: calendarEventObj.id,
                title: calendarEventObj.title,
                start: moment(calendarEventObj.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                end: moment(calendarEventObj.end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                resourceId: calendarEventObj.resourceId,
                staffId: calendarEventObj.resourceId,
                type: 'businessEvent',
                color: eventBackground,
                textColor: eventText,
                borderColor: '#bfbfbf',
                classNames: [eventClass, 'fc-event-generic'],
                bookingId: calendarEventObj.booking_id,
                userOrderId: calendarEventObj.user_order_id,
                customerFirstname: calendarEventObj.customer_firstname,
                customerLastname: calendarEventObj.customer_lastname,
                customerEmail: calendarEventObj.customer_email,
                customerPhone: calendarEventObj.customer_phone,
                service: calendarEventObj.service_name,
                serviceColour: calendarEventObj.service_colour,
                status: calendarEventObj.status,
                eventNotes: calendarEventObj.event_notes,
                recurring: (calendarEventObj.recurring_event === 1 ? true : false)
              });
            });
          }
          that.setState({ calendarLoading: false });
        });
      });
    }
  }

  handleCalendarResourceClick(resourceId) {
    this.setState({ calendarSelectedResource: resourceId, resourceOptionsModalVisible: true });
  }

  renderCalendarResource(resourceObj) {
    return (
      <div className="calendar-resource-container" onClick={() => this.handleCalendarResourceClick(parseInt(resourceObj.resource.id))}>
        <img className="calendar-resource-image" src={`${CDN_URL}/` + resourceObj.resource.extendedProps.img}/>
        <div className="calendar-resource-title">{resourceObj.resource.title}</div>
      </div>
    );
  }

  renderListDayEventResource = (resourceId) => {
    let staffObj = this.props.staff.find(x => x.id === resourceId);
    return (
      <div className="calendar-booking-service" style={{ display: 'flex', alignItems: 'center' }}>
        <img className="img img-round" alt="StaffImg" src={`${CDN_URL}/` + staffObj.staff_img} style={{ height: 20, marginRight: 5 }} />  
        <div>{staffObj.firstname + ' ' + staffObj.lastname}</div>
      </div>
    );
  }

  renderCalendarEvent(e) {
    if(e.event.title === 'Booking'){
      return (
        <>
          {e.event.extendedProps.serviceColour && e.event.extendedProps.status === "Booked" ?
            <div className="fc-event-left-border-mockup" style={{ backgroundColor: e.event.extendedProps.serviceColour }}/>
          : null}
          <div className="calendar-event-container" style={e.event.extendedProps.serviceColour && e.event.extendedProps.status === "Booked" ? { paddingLeft: 6 } : null}>
            <div className="calendar-booking-times">{moment(e.event.start).tz(this.props.business.timezone_name, false).format('HH:mm')} - {moment(e.event.end).tz(this.props.business.timezone_name, false).format('HH:mm')}</div>
            <div className="calendar-booking-title">{e.event.extendedProps.customerFirstname} {e.event.extendedProps.customerLastname}</div>
            <div className="calendar-booking-service">{e.event.extendedProps.service}</div>
            {e.view.type === 'listDay' ? this.renderListDayEventResource(e.event.extendedProps.staffId) : null}
            {e.event.extendedProps.recurring ? <div className="calendar-booking-recurring"><FiRepeat size={13}/></div> : null}
          </div>
        </>
      );
    } else if(e.event.title === 'Walk-in'){
      return (
        <div className="calendar-event-container">
          <div className="calendar-booking-times">{moment(e.event.start).tz(this.props.business.timezone_name).format('HH:mm')} - {moment(e.event.end).tz(this.props.business.timezone_name).format('HH:mm')}</div>
          <div className="calendar-booking-title">Walk-in</div>
          <div className="calendar-booking-service">{e.event.extendedProps.service}</div>
          {e.view.type === 'listDay' ? this.renderListDayEventResource(e.event.extendedProps.staffId) : null}
        </div>
      );
    } else if(e.event.title === 'Break'){
      return (
        <div className="calendar-event-container">
          <div className="calendar-booking-times">{moment(e.event.start).tz(this.props.business.timezone_name).format('HH:mm')} - {moment(e.event.end).tz(this.props.business.timezone_name).format('HH:mm')}</div>
          <div className="calendar-booking-title">{e.event.extendedProps && e.event.extendedProps.eventNotes ? e.event.extendedProps.eventNotes : 'Break'}</div>
          {e.view.type === 'listDay' ? this.renderListDayEventResource(e.event.extendedProps.staffId) : null}
          {e.event.extendedProps && e.event.extendedProps.recurring ? <div className="calendar-booking-recurring"><FiRepeat size={13}/></div> : null}
        </div>
      );
    }
  }

  renderCalendarHeader() {
    let calendarDate = null, calendarApi = null;
    if(this.state.calendarInitialised){
      calendarApi = this.calendarRef.current.getApi();
      let viewType = calendarApi.view.type, viewName = null, formattedDate = null;
      if(viewType === 'resourceTimeGridDay'){
        viewName = 'Day';
        if(window.innerWidth > 707) {
          formattedDate = moment(this.state.calendarStartDate).format('dddd, Do MMM YYYY')
        } else {
          formattedDate = moment(this.state.calendarStartDate).format('ddd, Do MMM YYYY')
        }
      } else if(viewType === 'resourceTimeGridThreeDay') {
        viewName = '3 Day';
        formattedDate = calendarApi.view.title;
      } else if(viewType === 'resourceTimeGridWeek'){
        viewName = 'Week';
        formattedDate = calendarApi.view.title;
      } else if(viewType === 'dayGridMonth'){
        viewName = 'Month';
        formattedDate = calendarApi.view.title;
      } else if(viewType === 'listDay'){
        viewName = 'List Day';
        formattedDate = calendarApi.view.title;
      }
      // Permissions
      const createBooking = this.props.userRole?.booking?.create === true;
      const createBreak = this.props.userRole?.break?.create === true;
      return (
        <div>
          <div className="calendar-header">
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="ms-auto me-auto calendar-header-column">
                <div className="calendar-header-container">
                  <div className="calendar-header-item calendar-header-item-4">
                    <Button className="btn-outline-primary calendar-header-button" style={{ padding: '11px 8px' }} onClick={this.toggleMinifiedOptionsModal}>
                      <FaEllipsisV size={16}/>
                    </Button>
                  </div>
                  <div className="calendar-header-item calendar-header-item-1">
                    <div className="calendar-header-item-select">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        options={this.state.locationOptions}
                        value={this.state.locationOptions.find(x => x.id === this.state.selectedLocationId)}
                        onChange={(e) => this.handleLocationChange(e) }
                        placeholder={'Choose locations...'}
                        captureMenuScroll={true}
                        controlShouldRenderValue={true}
                      />
                    </div>
                    <Button className="btn-outline-primary calendar-header-button calendar-resource-dropdown" onClick={this.toggleStaffPickerModal} style={{ marginLeft: 3, marginRight: 3 }}>
                      <FiUsers size={16}/>
                    </Button>
                  </div>
                  <div className="calendar-header-item calendar-header-item-2">
                    <div className="calendar-header-title-container">
                      <Button color="primary" className="calendar-header-title-button" onClick={() => calendarApi.prev()}>
                        <span className="btn-label">
                          <i className="now-ui-icons arrows-1_minimal-left" />
                        </span>
                      </Button>
                      <div className="calendar-header-title" onClick={this.toggleDatePickerModal}>
                        {formattedDate}
                      </div>
                      <Button color="primary" className="calendar-header-title-button" onClick={() => calendarApi.next()}>
                        <span className="btn-label">
                          <i className="now-ui-icons arrows-1_minimal-right" />
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div className="calendar-header-item calendar-header-item-3">
                    <Button className="btn-outline-primary calendar-header-button" onClick={this.toggleSettingsModal} style={{ marginLeft: 3, marginRight: 3 }}>
                      <FiSettings size={16}/>
                    </Button>
                    <UncontrolledDropdown className="calendar-header-dropdown-container" style={{ marginRight: 3 }}>
                      <DropdownToggle className="btn-outline-primary calendar-header-dropdown" caret>
                        {viewName}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>Calendar</DropdownItem>
                        <DropdownItem onClick={() => viewName !== 'Day' ? calendarApi.changeView('resourceTimeGridDay') : null}>Day{viewName === 'Day' ? <FiCheck size={16} color={'#1ab394'} className="calendar-header-dropdown-icon"/> : null}</DropdownItem>
                        <DropdownItem onClick={() => viewName !== '3 Day' ? calendarApi.changeView('resourceTimeGridThreeDay') : null}>3 Day{viewName === '3 Day' ? <FiCheck size={16} color={'#1ab394'} className="calendar-header-dropdown-icon"/> : null}</DropdownItem>
                        <DropdownItem onClick={() => viewName !== 'Week' ? calendarApi.changeView('resourceTimeGridWeek') : null}>Week{viewName === 'Week' ? <FiCheck size={16} color={'#1ab394'} className="calendar-header-dropdown-icon"/> : null}</DropdownItem>
                        <DropdownItem onClick={() => viewName !== 'Month' ? calendarApi.changeView('dayGridMonth') : null}>Month{viewName === 'Month' ? <FiCheck size={16} color={'#1ab394'} className="calendar-header-dropdown-icon"/> : null}</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem header>List</DropdownItem>
                        <DropdownItem onClick={() => viewName !== 'List Day' ? calendarApi.changeView('listDay') : null}>List Day{viewName === 'List Day' ? <FiCheck size={16} color={'#1ab394'} className="calendar-header-dropdown-icon"/> : null}</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {(createBooking || createBreak) && (
                      <UncontrolledDropdown style={{ display: 'inline-flex' }}>
                        <DropdownToggle color="primary" className="calendar-header-button pull-right" style={{ margin: '1px  0px' }}>
                          <FiPlus size={16} color={'#1ab394'}/><span className="calendar-header-add-button-text"> Add</span>
                        </DropdownToggle>
                        <DropdownMenu end>
                          {createBooking && <DropdownItem onClick={() => this.props.navigate('/admin/add_booking?date=' + this.state.calendarStartDate.format('YYYY-MM-DD') + '&business_location_id=' + this.state.selectedLocationId)}>Booking</DropdownItem>}
                          {createBooking && <DropdownItem onClick={() => this.props.navigate('/admin/add_booking?walkin=true&date=' + this.state.calendarStartDate.format('YYYY-MM-DD') + '&business_location_id=' + this.state.selectedLocationId)}>Walk-in</DropdownItem>}
                          {createBreak && <DropdownItem onClick={() => this.toggleBreakAddModal()}>Break</DropdownItem>}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  toggleResource(staffId) {
    if(this.props.calendarSettings && this.props.calendarSettings.calendarResources){
      let calendarApi = this.calendarRef.current.getApi();
      let viewTitle = calendarApi.view.type;
      if(this.props.calendarSettings.calendarResources.includes(staffId)){
        // Remove staff id from calendar resources
        let curCalendarSettings = Object.assign({}, this.props.calendarSettings);
        curCalendarSettings.calendarResources = curCalendarSettings.calendarResources.filter(x => x !== staffId && x !== null && x !== undefined);
        this.props.actions.loadCalendarSettings(curCalendarSettings);
      } else {
        // Add staff id from calendar resources
        if(!Number.isInteger(staffId)) {
          return;
        }
        let curCalendarSettings = Object.assign({}, this.props.calendarSettings);
        curCalendarSettings.calendarResources.push(staffId);
        this.props.actions.loadCalendarSettings(curCalendarSettings);
        // Fetch hours if first staff member added (even if not avaiable on the Calendar API yet)
        if(viewTitle === 'resourceTimeGridDay' || viewTitle === 'resourceTimeGridThreeDay') {
          this.getCalendarResourceHours(null, [{ id: staffId }], null);
        }
      }
      if(viewTitle === 'listDay') {
        this.getCalendarEvents(null);
      }
    } else {
      if(!Number.isInteger(staffId)) {
        return;
      }
      // Calendar resources not set
      this.props.actions.loadCalendarSettings({ calendarResources: [staffId] });
    }
  }

  toggleStaffPickerModal = () => {
    this.setState({
      staffPickerModalVisible: !this.state.staffPickerModalVisible,
    });
  };

  renderStaffPickerModal() {
    return (
      <CalendarStaffPicker
        visible={this.state.staffPickerModalVisible}
        toggleStaff={this.toggleResource}
        toggleVisible={this.toggleStaffPickerModal}
        locationId={this.state.selectedLocationId}
      />
    );
  }

  toggleMinifiedOptionsModal = () => {
    this.setState({
      minifiedOptionsModalVisible: !this.state.minifiedOptionsModalVisible,
    });
  };

  handleMinifiedOption = (option, type) => {
    this.toggleMinifiedOptionsModal();
    if(option === 'add') {
      if(type === 'booking') {
        this.props.navigate('/admin/add_booking?date=' + this.state.calendarStartDate.format('YYYY-MM-DD') + '&business_location_id=' + this.state.selectedLocationId);
      } else if(type === 'walk-in') {
        this.props.navigate('/admin/add_booking?walkin=true&date=' + this.state.calendarStartDate.format('YYYY-MM-DD') + '&business_location_id=' + this.state.selectedLocationId);
      } else if(type === 'break') {
        this.toggleBreakAddModal();
      }
    } else if(option === 'staff') {
      this.setState({ staffPickerModalVisible: true });
    } else if(option === 'settings') {
      this.setState({ settingsModalVisible: true });
    } else if(option === 'view') {
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.changeView(type);
    }
  }

  handleResourceOption = (option, resourceId) => {
    let calendarApi = this.calendarRef.current.getApi();
    switch(option) {
      case "booking":
        this.props.navigate('/admin/add_booking?date=' + this.state.calendarStartDate.format('YYYY-MM-DD') + '&staff_id=' + resourceId + '&business_location_id=' + this.state.selectedLocationId);
        break;
      case "walk-in":
        this.props.navigate('/admin/add_booking?walkin=true&date=' + this.state.calendarStartDate.format('YYYY-MM-DD') + '&staff_id=' + resourceId + '&business_location_id=' + this.state.selectedLocationId);
        break;
      case "break":
        this.toggleResourceOptionsModal();
        this.toggleBreakAddModal();
        break;
      case "dayView":
      case "threeDayView":
      case "weekView":
        if(calendarApi) {
          let that = this;
          calendarApi.batchRendering(function() {
            let viewTitle = calendarApi.view.type;
            if(option === "dayView" && viewTitle !== 'resourceTimeGridDay') {
              calendarApi.changeView('resourceTimeGridDay');
            } else if(option === "threeDayView" && viewTitle !== 'resourceTimeGridThreeDay') {
              calendarApi.changeView('resourceTimeGridThreeDay' );
            } else if(option === "weekView" && viewTitle !== 'resourceTimeGridWeek') {
              calendarApi.changeView('resourceTimeGridWeek');
            }
            if(that.props.calendarSettings && that.props.calendarSettings.calendarResources && 
              (that.props.calendarSettings.calendarResources.length > 1 || (that.props.calendarSettings.calendarResources.length === 1 && that.props.calendarSettings.calendarResources[0] !== resourceId))) {
              let curCalendarSettings = Object.assign({}, that.props.calendarSettings);
              curCalendarSettings.calendarResources = curCalendarSettings.calendarResources.filter(x => x === resourceId && x !== null && x !== undefined);
              that.props.actions.loadCalendarSettings(curCalendarSettings);
            }
          });
          this.toggleResourceOptionsModal();
        }
        break;
      case "workingHours":
        this.toggleResourceOptionsModal();
        this.toggleStaffScheduleOptionsModal();
        break;
      default:
        return;
    }
  };

  renderMinifiedOptions() {
    return (
      <CalendarMinifiedOptions
        visible={this.state.minifiedOptionsModalVisible}
        toggleVisible={this.toggleMinifiedOptionsModal}
        handleOption={this.handleMinifiedOption}
        userRole={this.props.userRole}
      />
    );
  }

  toggleBookingOptionsModal = () => {
    this.setState({
      bookingOptionsModalVisible: !this.state.bookingOptionsModalVisible,
    });
  };

  submitBookingOptionsStatusChange(newStatus) {
    try {
      let calendarApi = this.calendarRef.current.getApi();
      let calendarEvents = calendarApi.getEvents();
      if(calendarEvents.length > 0) {
        let eventClass = null;
        if(newStatus === "Booked") {
          eventClass = 'fc-event-booked';
        } else if(newStatus === "In-progress") {
          eventClass = 'fc-event-in-progress';
        } else if(newStatus === "Completed") {
          eventClass = 'fc-event-completed';
        } else if(newStatus === "No-show" || newStatus === "Cancelled") {
          eventClass = 'fc-event-no-show';
        } else {
          eventClass = 'fc-event-booked';
        }
        let bookingEvents = calendarEvents.filter(x => x.extendedProps.type === "businessEvent" && x.extendedProps.userOrderId === this.state.calendarSelectedEvent.extendedProps.userOrderId);
        bookingEvents.forEach((bookingEventObj) => {
          bookingEventObj.setProp('classNames', [eventClass, 'fc-event-generic']);
        });
      }
    } catch(e) {
      console.log(e);
    }
  }

  renderBookingOptionsModal() {
    if(this.state.bookingOptionsModalVisible) {
      return (
        <CalendarBookingOptions
          visible={this.state.bookingOptionsModalVisible}
          toggleVisible={this.toggleBookingOptionsModal}
          bookingData={this.state.calendarSelectedEvent}
          navigate={this.props.navigate}
          triggerNotification={this.props.triggerNotification}
          submitStatusChange={this.submitBookingOptionsStatusChange}
        />
      );
    } else {
      return null;
    }
  }

  toggleResourceOptionsModal = () => {
    this.setState({
      resourceOptionsModalVisible: !this.state.resourceOptionsModalVisible,
    });
  };

  renderResourceOptionsModal() {
    if(this.state.resourceOptionsModalVisible) {
      let calendarApi = this.calendarRef.current.getApi();
      let calendarView = calendarApi.view.type;
      return (
        <CalendarResourceOptions
          visible={this.state.resourceOptionsModalVisible}
          toggleVisible={this.toggleResourceOptionsModal}
          resourceId={this.state.calendarSelectedResource}
          handleOption={this.handleResourceOption}
          calendarView={calendarView}
        />
      );
    } else {
      return null;
    }
  }

  handleStaffScheduleOption = async (option) => {
    switch(option) {
      case "editHours":
        this.toggleStaffScheduleOptionsModal();
        this.toggleStaffHoursModal();
        break;
      case "deleteHours":
        this.setState({ calendarLoading: true, staffScheduleOptionsModalVisible: false });
        try {
            await Api.deleteStaffHours({
              staff_id: this.state.calendarSelectedResource,
              date: moment(this.state.calendarStartDate).format('YYYY-MM-DD'),
              business_location_id: this.state.selectedLocationId
            });
            let staffObj = this.props.staff.find(x => x.id === this.state.calendarSelectedResource);
            this.props.triggerNotification(staffObj.firstname + "'s amended hours on " + moment(this.state.calendarStartDate).format('ddd D MMM') + " have been deleted.", "success", "bc", 4);
            this.getCalendarResourceHours(null);
            this.setState({ calendarLoading: false });
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
            this.setState({ calendarLoading: false });
        }
        break;
      case "notWorking":
        this.setState({ calendarLoading: true, staffScheduleOptionsModalVisible: false });
        try {
          await Api.updateStaffHours({
              date: moment(this.state.calendarStartDate).format('YYYY-MM-DD'),
              start_time: '09:00',
              end_time: '17:00',
              staff_id: this.state.calendarSelectedResource,
              business_location_id: this.state.selectedLocationId,
              working: false
          });
          let staffObj = this.props.staff.find(x => x.id === this.state.calendarSelectedResource);
          this.props.triggerNotification(staffObj.firstname + " is no longer working on " + moment(this.state.calendarStartDate).format('ddd D MMM') + ".", "success", "bc", 4);
          this.getCalendarResourceHours(null);
          this.setState({ calendarLoading: false });
        } catch(e) {
          this.props.triggerNotification("An unexpected error occured. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
          this.setState({ calendarLoading: false });
        }
        break;
      case "openBusiness":
        this.setState({ calendarLoading: true, staffScheduleOptionsModalVisible: false });
        try {
            await Api.updateBusinessDate({
              date: moment(this.state.calendarStartDate).format('YYYY-MM-DD'),
              open: true,
              business_location_id: this.state.selectedLocationId
            });
            this.props.triggerNotification("Business marked as open on " + moment(this.state.calendarStartDate).format('ddd D MMM') + ".", "success", "bc", 4);
            this.getCalendarResourceHours(null);
            this.setState({ calendarLoading: false });
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
            this.setState({ calendarLoading: false });
        }
        break;
      case "closeBusiness":
        this.setState({ calendarLoading: true, staffScheduleOptionsModalVisible: false });
        try {
            await Api.updateBusinessDate({
              date: moment(this.state.calendarStartDate).format('YYYY-MM-DD'),
              open: false,
              business_location_id: this.state.selectedLocationId
            });
            this.props.triggerNotification("Business marked as closed on " + moment(this.state.calendarStartDate).format('ddd D MMM') + ".", "success", "bc", 4);
            this.getCalendarResourceHours(null);
            this.setState({ calendarLoading: false });
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
            this.setState({ calendarLoading: false });
        }
        break;
      default:
        return;
    }
  };

  toggleStaffScheduleOptionsModal = () => {
    this.setState({
      staffScheduleOptionsModalVisible: !this.state.staffScheduleOptionsModalVisible,
    });
  };

  renderStaffScheduleOptionsModal() {
    if(this.state.staffScheduleOptionsModalVisible) {
      return (
        <StaffScheduleOptions
          visible={this.state.staffScheduleOptionsModalVisible}
          toggleVisible={this.toggleStaffScheduleOptionsModal}
          data={this.state.staffHoursData && this.state.staffHoursData.find(x => x.staff_id === this.state.calendarSelectedResource && moment(x.date, 'YYYY-MM-DD HH:mm:ss').isSame(this.state.calendarStartDate, 'day'))}
          submitOption={this.handleStaffScheduleOption}
          editSchedule={false}
        />
      );
    } else {
      return null;
    }
  }

  toggleStaffHoursModal = () => {
    this.setState({
      staffHoursModalVisible: !this.state.staffHoursModalVisible,
    });
  };

  submitStaffHours = async () => {
    this.toggleStaffHoursModal();
    this.getCalendarResourceHours(null);
    this.props.triggerNotification("Staff hours updated successfully.", "success", "bc", 4);
  }

  renderStaffHoursModal() {
    if(this.state.staffHoursModalVisible) {
        return (
          <StaffHours
            visible={this.state.staffHoursModalVisible}
            toggleVisible={this.toggleStaffHoursModal}
            data={this.state.staffHoursData && this.state.staffHoursData.find(x => x.staff_id === this.state.calendarSelectedResource && moment(x.date, 'YYYY-MM-DD HH:mm:ss').isSame(this.state.calendarStartDate, 'day'))}
            locationId={this.state.selectedLocationId}
            onSubmit={this.submitStaffHours}
          />
        );
    } else {
        return null;
    } 
  }

  toggleDatePickerModal = () => {
    this.setState({
      datePickerModalVisible: !this.state.datePickerModalVisible
    });
  };

  submitDatePickerChange(e) {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(e.format('YYYY-MM-DD'));
    this.setState({ datePickerModalVisible: false });
  }

  renderDatePickerModal() {
    return (
      <DatePicker
        visible={this.state.datePickerModalVisible}
        curDate={this.state.calendarStartDate}
        submitDate={this.submitDatePickerChange}
        toggleVisible={this.toggleDatePickerModal}
        timezoneName={this.props.business.timezone_name}
      />
    );
  }

  toggleSettingsModal = () => {
    this.setState({
      settingsModalVisible: !this.state.settingsModalVisible,
    });
  };

  renderSettingsModal() {
    return (
      <Modal
        isOpen={this.state.settingsModalVisible}
        toggle={() => this.setState({ settingsModalVisible: false })}
        className="text-center"
      >
        <ModalHeader
          className="justify-content-center uppercase title"
          toggle={() => this.setState({ settingsModalVisible: false })}
          tag="h4"
        >
          Calendar Settings
        </ModalHeader>
        <ModalBody className="calendar-settings-modal-body">
          <Row>
            <Col md={12}>
              <label>First Day Of Week</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                placeholder="e.g. Monday"
                name="singleSelect"
                isSearchable={false}
                value={this.props.calendarSettings && this.props.calendarSettings.calendarFirstDay ? this.props.calendarSettings.calendarFirstDay : { value: 1, label: "Monday" }}
                options={[
                  { value: 1, label: "Monday" },
                  { value: 2, label: "Tuesday" },
                  { value: 3, label: "Wednesday" },
                  { value: 4, label: "Thursday" },
                  { value: 5, label: "Friday" },
                  { value: 6, label: "Saturday" },
                  { value: 0, label: "Sunday" }
                ]}
                onChange={(option) => {
                  let curCalendarSettings = Object.assign({}, this.props.calendarSettings);
                  curCalendarSettings.calendarFirstDay = option;
                  this.props.actions.loadCalendarSettings(curCalendarSettings);
                  let calendarApi = this.calendarRef.current.getApi();
                  calendarApi.setOption('firstDay', option.value);
                }}
              />
            </Col>
            <Col md={12} style={{ marginTop: 5 }}>
              <label>Slot Duration</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                placeholder="e.g. Monday"
                name="singleSelect"
                isSearchable={false}
                value={this.props.calendarSettings && this.props.calendarSettings.slotDuration ? this.props.calendarSettings.slotDuration : { value: "00:05:00", label: "5 minutes" }}
                options={[
                  { value: "00:05:00", label: "5 minutes" },
                  { value: "00:10:00", label: "10 minutes" },
                  { value: "00:15:00", label: "15 minutes" },
                  { value: "00:20:00", label: "20 minutes" },
                  { value: "00:30:00", label: "30 minutes" },
                  { value: "00:45:00", label: "45 minutes" },
                  { value: "01:00:00", label: "1 hour" },
                  { value: "02:00:00", label: "2 hours" }
                ]}
                onChange={(option) => {
                  let curCalendarSettings = Object.assign({}, this.props.calendarSettings);
                  curCalendarSettings.slotDuration = option;
                  this.props.actions.loadCalendarSettings(curCalendarSettings);
                  let calendarApi = this.calendarRef.current.getApi();
                  calendarApi.setOption('slotDuration', option.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }

  renderNewEventModal() {
    return (
      <CalendarNewEventOptions
        visible={this.state.newEventModalVisible}
        toggleVisible={this.toggleNewEventModal}
        selectedTime={moment(this.state.calendarSelectedTime).tz(this.props.business.timezone_name).format('HH:mm')}
        selectedDate={this.state.calendarStartDate?.format('YYYY-MM-DD')}
        selectedStaffId={this.state.calendarSelectedResource}
        selectedBusinessLocationId={this.state.selectedLocationId}
        businessTimezone={this.props.business.timezone_name}
        breakClick={(e) => { this.toggleNewEventModal(e); this.toggleBreakAddModal(); }}
        navigate={this.props.navigate}
        userRole={this.props.userRole}
      />
    )

    return (
      <Modal
        isOpen={this.state.newEventModalVisible}
        toggle={(e) => { this.toggleNewEventModal(e); }}
        size="mini"
        modalClassName="modal-primary"
      >
        <ModalBody>
          <p style={{ marginBottom: 8 }}>New event at</p>
          <h4 className="text-center" style={{ marginTop: 0 }}>{this.state.calendarSelectedTime ? moment(this.state.calendarSelectedTime).tz(this.props.business.timezone_name).format('HH:mm') : null}</h4>
          <Button block className="btn-block" onClick={() => this.props.navigate('/admin/add_booking?date=' + this.state.calendarStartDate.format('YYYY-MM-DD') + '&time=' + moment(this.state.calendarSelectedTime).tz(this.props.business.timezone_name).format('HH:mm') + '&staff_id=' + this.state.calendarSelectedResource + '&business_location_id=' + this.state.selectedLocationId)}>Booking</Button>
          <Button block className="btn-block" onClick={() => this.props.navigate('/admin/add_booking?walkin=true&date=' + this.state.calendarStartDate.format('YYYY-MM-DD') + '&time=' + moment(this.state.calendarSelectedTime).tz(this.props.business.timezone_name).format('HH:mm') + '&staff_id=' + this.state.calendarSelectedResource + '&business_location_id=' + this.state.selectedLocationId)}>Walk-in</Button>
          <Button block className="btn-block" onClick={(e) => { this.toggleNewEventModal(e); this.toggleBreakAddModal() }}>Break</Button>
        </ModalBody>
      </Modal>
    );
  }

  renderEditTypeModal() {
    return (
        <Modal
          isOpen={this.state.editTypeModalVisible}
          toggle={() => this.toggleEditTypeModal()}
          size="sm"
          modalClassName="modal-default"
        >
          <ModalHeader className="justify-content-center uppercase title" toggle={() => this.toggleEditTypeModal()} tag="h4">Edit Options</ModalHeader>
          <ModalBody>
            <Button block className="btn-block" onClick={() => this.editType1()}>Edit only this event</Button>
            <Button block className="btn-block" onClick={() => this.editType2()}>Edit recurring event</Button>
          </ModalBody>
        </Modal>
    );
  }

  editType1() {
    if(this.state.calendarSelectedEvent && this.state.calendarSelectedEvent.title === 'Booking') {
      if(this.props.userRole?.booking?.update === false) {
        this.props.triggerNotification("You don't have permission to edit this booking.", "danger", "bc", 4);
        return;
      }
      this.props.navigate('/admin/edit_booking?user_order_id=' + parseInt(this.state.calendarSelectedEvent.extendedProps.userOrderId) + '&user_order_recurring=true&recurring_conversion=true&recurring_conversion_date=' + this.state.calendarStartDate.format('YYYY-MM-DD'));
    } else if(this.state.calendarSelectedEvent && this.state.calendarSelectedEvent.title === 'Break') {
      if(this.props.userRole?.break?.update === false) {
        this.props.triggerNotification("You don't have permission to edit this break.", "danger", "bc", 4);
        return;
      }
      this.setState({ editTypeModalVisible: false, breakEditModalVisible: true, calendarSelectedEventConversion: true });
    }
  }

  editType2() {
    if(this.state.calendarSelectedEvent && this.state.calendarSelectedEvent.title === 'Booking') {
      if(this.props.userRole?.booking?.update === false) {
        this.props.triggerNotification("You don't have permission to edit this booking.", "danger", "bc", 4);
        return;
      }
      this.setState({ editTypeModalVisible: false, calendarSelectedEventConversion: false });
      this.props.navigate('/admin/edit_booking?user_order_id=' + parseInt(this.state.calendarSelectedEvent.extendedProps.userOrderId) + '&user_order_recurring=true');
    } else if(this.state.calendarSelectedEvent && this.state.calendarSelectedEvent.title === 'Break') {
      if(this.props.userRole?.break?.update === false) {
        this.props.triggerNotification("You don't have permission to edit this break.", "danger", "bc", 4);
        return;
      }
      this.setState({ editTypeModalVisible: false, breakEditModalVisible: true, calendarSelectedEventConversion: false });
    }
  }

  async handleEventDrop(e) {
    try {
      if(e.event.extendedProps.recurring === true) {
        this.props.triggerNotification("Event dragging is not supported for recurring events.", "danger", "bc", 6);
        e.revert();
        return;
      }
      // Access permission check
      switch(e.event.title) {
        case "Booking":
          if(this.props.userRole?.booking?.update !== true) {
            this.props.triggerNotification("You don't have permission to update this booking.", "danger", "bc", 6);
            e.revert();
            return;
          }
          break;
        case "Break":
          if(this.props.userRole?.break?.update !== true) {
            this.props.triggerNotification("You don't have permission to update this break.", "danger", "bc", 6);
            e.revert();
            return;
          }
          break;
        default:
          break;
      }
      // Submit event drop
      let eventDate = moment(e.event.start).tz(this.props.business.timezone_name).format('YYYY-MM-DD');
      let start_time = moment(e.event.start).tz(this.props.business.timezone_name).format('HH:mm');
      let end_time = moment(e.event.end).tz(this.props.business.timezone_name).format('HH:mm');
      let staff_id;
      if(e.newResource && e.newResource.id) {
        staff_id = parseInt(e.newResource.id);
      } else {
        staff_id = e.event.extendedProps.staffId;
      }
      await Api.dragUpdate({ start_time, end_time, event_id: parseInt(e.event.id), date: eventDate, staff_id });
      this.props.triggerNotification("Event rescheduled successfully.", "success", "bc", 6);
      e.event.setExtendedProp("staffId", staff_id);
    } catch(exc) {
      e.revert();
      this.props.triggerNotification("An unexpected error occured whilst trying to reschedule this event. Please contact Support if this problem persists.", "danger", "bc", 6);
    }
  }

  async handleEventResize(e) {
    try {
      if(e.event.extendedProps.recurring === true) {
        this.props.triggerNotification("Event resizing is not supported for recurring events.", "danger", "bc", 6);
        e.revert();
        return;
      }
      // Access permission check
      switch(e.event.title) {
        case "Booking":
          if(this.props.userRole?.booking?.update !== true) {
            this.props.triggerNotification("You don't have permission to update this booking.", "danger", "bc", 6);
            return;
          }
          break;
        case "Break":
          if(this.props.userRole?.break?.update !== true) {
            this.props.triggerNotification("You don't have permission to update this break.", "danger", "bc", 6);
            return;
          }
          break;
        default:
          break;
      }
      // Submit event resize
      let end_time = moment(e.event.end).tz(this.props.business.timezone_name).format('HH:mm');
      await Api.resizeUpdate({ end_time, event_id: parseInt(e.event.id) });
      this.props.triggerNotification("Event duration updated successfully.", "success", "bc", 6);
    } catch(exc) {
      e.revert();
      this.props.triggerNotification("An unexpected error occured whilst trying to update the duration of this event. Please contact Support if this problem persists.", "danger", "bc", 6);
    }
  }

  render() {
    let that = this;
    return (
      <>
        <PanelHeader size="sm" minimised/>
        <div className="content calendar-content pb-0">
          {this.state.calendarLoading && (
            <div className="calendar-card-spinner-container">
              <Spinner className="calendar-card-spinner" color="danger" />
            </div>
          )}
          <Row>
            <Col xs={12} md={12} className="ms-auto me-auto calendar-column">
              <Card className="card-calendar mb-0">
                <CardBody>
                  {this.renderCalendarHeader()}
                  {!this.props.calendarSettings || !this.props.calendarSettings.calendarResources || this.props.calendarSettings.calendarResources.length === 0 ? 
                    <div className="calendar-mock-container" style={{ height: this.state.calendarHeight }}>
                      <div className="calendar-no-resource-container">
                        <FiCalendar size={36} className="calendar-no-resource-icon"/>
                        <div className="calendar-no-resource-title">No staff selected</div>
                        <Button color="primary" style={{ margin: 0 }} onClick={this.toggleStaffPickerModal}>
                          Select Staff
                        </Button>
                      </div>
                    </div>
                  : null}
                  <div style={!this.props.calendarSettings || !this.props.calendarSettings.calendarResources || this.props.calendarSettings.calendarResources.length === 0 ? { display: 'none' } : null}>
                    <FullCalendar
                      schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                      ref={this.calendarRef}
                      plugins={[ dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin, scrollGridPlugin, momentTimezonePlugin, interactionPlugin, listPlugin ]}
                      initialView="resourceTimeGridDay"
                      resources={(fetchInfo, successCallback, faulureCallback) => this.getCalendarResources(fetchInfo, successCallback, faulureCallback)}
                      resourceOrder="order"
                      slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
                      slotLabelInterval={{hours:1}}
                      dayMaxEventRows={true}
                      views={{
                        resourceTimeGridThreeDay: {
                          type: 'resourceTimeGrid',
                          duration: { days: 3 },
                          buttonText: '3 Day',
                          dayHeaderFormat: function(e) { return moment(e.date).format('ddd, D') },
                        },
                        week: {
                          dayHeaderFormat: function(e) { return moment(e.date).format('ddd, D') },
                        },
                        dayGridMonth: {
                          dayMaxEventRows: 5
                        },
                        listDay: {
                          eventTimeFormat: {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                            meridiem: false
                          }
                        }
                      }}
                      height={this.state.calendarHeight}
                      resourceLabelContent={(e) => this.renderCalendarResource(e)}
                      headerToolbar={false}
                      datesSet={(e) => { this.setState({ calendarStartDate: moment(e.start), calendarEndDate: moment(e.end) }); this.getCalendarResourceHours(e); this.getCalendarEvents(e); } }
                      nowIndicator={true}
                      editable={true}
                      allDaySlot={false}
                      eventContent={this.renderCalendarEvent}
                      eventClick={(e) => this.handleEventClick(e)}
                      dateClick={this.handleDateClick}
                      timeZone={this.props.business && this.props.business.timezone_name ? this.props.business.timezone_name : 'local'}
                      scrollTime={moment().tz(this.props.business.timezone_name, false).format("HH:mm:00")}
                      scrollTimeReset={false}
                      eventDrop={(eventDropInfo) => this.handleEventDrop(eventDropInfo)}
                      eventResize={(eventResizeInfo) => this.handleEventResize(eventResizeInfo)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {this.renderStaffPickerModal()}
        {this.renderDatePickerModal()}
        {this.renderMinifiedOptions()}
        {this.renderSettingsModal()}
        {this.renderBookingOptionsModal()}
        {this.renderResourceOptionsModal()}
        {this.renderStaffScheduleOptionsModal()}
        {this.renderStaffHoursModal()}
        {this.state.breakAddModalVisible ?
          <BreakAdd
            visible={this.state.breakAddModalVisible}
            toggleVisible={this.toggleBreakAddModal}
            triggerNotification={this.props.triggerNotification}
            date={this.state.calendarStartDate}
            time={this.state.calendarSelectedTime}
            staffId={this.state.calendarSelectedResource}
            locationId={this.state.selectedLocationId}
            onSubmit={this.breakAddSubmit}
          />
        : null}
        {this.state.breakEditModalVisible ?
          <BreakEdit
            data={this.state.calendarSelectedEvent}
            conversion={this.state.calendarSelectedEventConversion}
            visible={this.state.breakEditModalVisible}
            toggleVisible={this.toggleBreakEditModal}
            triggerNotification={this.props.triggerNotification}
            locationId={this.state.selectedLocationId}
            onSubmit={this.breakEditSubmit}
          />
        : null}
        {this.renderNewEventModal()}
        {this.renderEditTypeModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    business: state.business,
    businessLocation: state.businessLocation,
    businessLocationStaffMap: state.businessLocationStaffMap,
    staff: state.staff,
    calendarSettings: state.calendarSettings,
    settings: state.settings,
    user: state.user,
    userRole: state.userRole
  }
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Calendar));
