import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    FormText,
    Input,
    InputGroup
} from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { FiX, FiGlobe } from "react-icons/fi";
import ColourPicker from '../ColourPicker/ColourPicker';

class ServiceInfo extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            bookingEnabled: true,
            categoryData: [],
            colorPickerVisible: false,
            locationOptions: []
        };
        this.toggleColorPickerVisible = this.toggleColorPickerVisible.bind(this);
        this.handleColourChange = this.handleColourChange.bind(this);
    }

    componentDidMount() {
        this.populateCategorySelectData();
        // Populate location options
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj, businessLocationIndex) => {
            locationOptions.push({
                id: businessLocationObj.business_location_id,
                label: businessLocationObj.business_location_name,
                value: businessLocationObj.business_location_id
            });
        });
        this.setState({ locationOptions });
    }

    populateCategorySelectData() {
        let categoryData = []
        this.props.serviceCategory.forEach((categoryObj) => {
            categoryData.push({
                value: categoryObj.service_business_category_id,
                label: categoryObj.service_business_category
            });
        });
        this.setState({ categoryData });
    }

    toggleColorPickerVisible() {
        this.setState({ colorPickerVisible: !this.state.colorPickerVisible });
    }

    handleColourChange(colorObj) {
        let formattedColorStr = colorObj ? colorObj : null;
        this.props.handleChange(formattedColorStr, 'service_colour');
        this.toggleColorPickerVisible();
    }

    getBookingUrl() {
        const businessSlug = this.props.business.business_slug.replace(" ", "%20");
        return `https://booking.styler.digital/?business=${businessSlug}&service=${this.props.serviceId}`;
    }

    copyBookingUrl() {
        let bookingUrl = this.getBookingUrl();
        navigator.clipboard.writeText(bookingUrl);
        this.props.triggerNotification("Booking URL copied to clipboard", "success", "bc", 2);
    }

    render() {
        const { disabled } = this.props;
        const selectedLocationIds = this.props.serviceLocationTags.filter(x => x.state !== 3).map(x => x.id);
        return (
            <>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <h6>Details</h6>
                            </Col>
                            <Col xs={12} md={6}>
                                <label>Service Name</label>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        value={this.props.serviceName}
                                        onChange={(e) => this.props.handleChange(e, 'service_name')}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <label>Service Category</label>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        name="singleSelect"
                                        options={this.state.categoryData}
                                        value={this.state.categoryData.find(x => x.value === this.props.serviceCategoryId)}
                                        onChange={(value) => this.props.handleChange(value, 'service_category')}
                                        isDisabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={12}>
                                <label>Description</label>
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        value={this.props.serviceDescription}
                                        style={{ padding: 10 }}
                                        onChange={(e) => this.props.handleChange(e, 'service_description')}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                            {this.props.businessLocation && this.props.businessLocation.filter(x => x.enabled === 1).length > 1 ?
                                <Col xs={12} md={12} className="mt-2">
                                    <Row>
                                        <Col md={12}>
                                            <h6>Locations</h6>
                                            <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                                Choose the locations where this service is available.
                                            </FormText>
                                        </Col>
                                        <Col xs={12} sm={12} md={12}>
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    isMulti
                                                    options={this.state.locationOptions}
                                                    value={this.state.locationOptions.filter(x => selectedLocationIds.includes(x.id))}
                                                    placeholder={'Choose locations...'}
                                                    onChange={(e) => this.props.handleChange(e, 'service_locations')}
                                                    captureMenuScroll={true}
                                                    controlShouldRenderValue={true}
                                                    isDisabled={disabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            : null}
                            {this.props.business?.business_account_type_id === 3 && (
                                <Col xs={12} md={12} className="mt-2">
                                    <Row>
                                        <Col md={12}>
                                            <h6>Booking URL</h6>
                                            <FormText color="muted" style={{ marginTop: 0, marginBottom: '0.5rem' }}>
                                                If set, app users will be directed to this URL when the 'Book' button is pressed on the Service page.
                                            </FormText>
                                        </Col>
                                        <Col xs={12} sm={12} md={12}>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    value={this.props.bookingUrl}
                                                    onChange={(e) => this.props.handleChange(e, 'booking_url')}
                                                    placeholder="e.g. https://book.styler.digital/my-service"
                                                    disabled={disabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            {this.props.business?.business_account_type_id !== 3 && this.props.serviceId && (
                                <Col xs={12} md={12} className="mt-2">
                                    <Row>
                                        <Col md={12}>
                                            <h6>Booking Link</h6>
                                        </Col>
                                        <Col md={12} sm={12} onClick={() => this.copyBookingUrl()}>
                                            <InputGroup>
                                                <div style={{ lineHeight: '43px', padding: '0px 14px', border: '1px solid #e3e3e3', borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                                                    <FiGlobe size={22} color={'#b1b1b1'}/>
                                                </div>
                                                <Input
                                                    type="text"
                                                    disabled={true}
                                                    value={this.getBookingUrl()}
                                                    style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <Col xs={12} md={12}>
                                <hr/>
                            </Col>
                            <Col xs={12} md={6} className="mt-2">
                                <FormGroup>
                                    <p className="react-switch-label">Booking Enabled</p>
                                    <Switch
                                        onChange={(e) => { this.props.handleChange(e, 'service_booking_enabled') }}
                                        checked={this.props.serviceBookingEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} className="mt-2">
                                <div className="color-row">
                                    <p className="react-switch-label">Service Colour</p>
                                    <div className="color-picker-swatch-container" style={{ borderColor: '#9a9a9a' }} onClick={() => !disabled ? this.toggleColorPickerVisible() : null}>
                                        <div className="color-picker-swatch text-center" style={this.props.serviceColour ? { backgroundColor: this.props.serviceColour } : null}>
                                            {this.props.serviceColour ? null : <FiX/>}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {this.state.colorPickerVisible ?
                    <ColourPicker
                        visible={this.state.colorPickerVisible}
                        toggleVisible={this.toggleColorPickerVisible}
                        initialColor={this.props.serviceColour}
                        onSubmit={this.handleColourChange}
                        remove
                    />
                : null}
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        serviceCategory: state.serviceCategory,
        business: state.business,
        businessLocation: state.businessLocation
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceInfo);