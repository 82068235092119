import React from 'react';
import {
    Modal,
    ModalBody,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import { FiX, FiMoreHorizontal } from 'react-icons/fi';

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { CDN_URL } from "constants/urls";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
//import "swiper/swiper-bundle.min.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Api from 'api/index';

class ImageCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sweetAlert: null,
            swiperInstance: null
        }
    }

    componentDidUpdate() {
        if(this.state.swiperInstance && this.props.selectedIndex && this.state.swiperInstance.activeIndex !== this.props.selectedIndex) {
            this.state.swiperInstance.slideTo(this.props.selectedIndex, 500);
        }
    }

    handleEditClick() {
        if(this.state.swiperInstance) {
            let gallery_image_id = this.props.imageData[this.state.swiperInstance.activeIndex].business_gallery_id;
            this.props.navigate('/admin/edit_gallery_image?id=' + gallery_image_id);
        }
    }

    handleDeleteClick() {
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    title="Are you sure?"
                    onConfirm={() => this.confirmDeleteClick()}
                    onCancel={() => this.setState({ sweetAlert: null })}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Are you sure that you want to delete this image? You cannot undo this action.
                </SweetAlert>
            ),
        });
    }

    async confirmDeleteClick() {
        this.setState({ sweetAlert: null });
        if(this.state.swiperInstance) {
            try {
                let gallery_image_id = this.props.imageData[this.state.swiperInstance.activeIndex].business_gallery_id;
                this.props.actions.loadLoadingSpinner(true);
                await Api.deleteGalleryImage({ gallery_image_id });
                this.props.actions.loadLoadingSpinner(false);
                this.props.triggerNotification("Gallery image deleted successfully.", "success", "bc", 4);
                if(this.props.submitDeleteImage) {
                    this.props.submitDeleteImage(gallery_image_id);
                }
            } catch(e) {
                this.props.actions.loadLoadingSpinner(false);
                this.props.triggerNotification("An unexpected error occured whilst trying to delete your gallery image. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
            }
        }
    }

    render() {
        const editPermission = this.props.userRole.gallery?.update === true;
        const deletePermission = this.props.userRole.gallery?.delete === true;
        return (
            <>
                {this.state.sweetAlert}
                <Modal
                    isOpen={this.props.visible}
                    toggle={() => this.props.toggleVisible()}
                    className="text-center"
                >
                    <ModalBody>
                        <div className="carousel-header-row">
                            <div className="carousel-header-title">
                                {this.props.business ? this.props.business.business_name : null}
                            </div>
                            <div style={{ display: 'flex' }}>
                                {(editPermission || deletePermission) && (
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret outline color="primary" className="carousel-header-button" style={{ marginRight: 5 }}>
                                            <FiMoreHorizontal size={21}/>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            {editPermission && <DropdownItem onClick={() => this.handleEditClick()}>Edit</DropdownItem>}
                                            {deletePermission && <DropdownItem onClick={() => this.handleDeleteClick()} className="text-danger">Delete</DropdownItem>}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                <Button color="danger" className="carousel-header-button" outline size="sm" onClick={this.props.toggleVisible}>
                                    <FiX size={18}/>
                                </Button>
                            </div>
                        </div>
                        <Swiper
                            ref={this.swiperRef}
                            modules={[Pagination, Navigation]}
                            grabCursor="true"
                            autoHeight="true"
                            centeredSlides="true"
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={{ clickable: true, dynamicBullets: true }}
                            navigation={true}
                            onSwiper={(swiperInstance) => this.setState({ swiperInstance })}
                        >
                            {this.props.imageData.map((imageObj, imageIndex) => {
                                return (
                                    <SwiperSlide>
                                        <div className="slide-img-container">
                                            <img alt={'gallery post'} src={`${CDN_URL}/` + imageObj.business_gallery_img}/>
                                        </div>
                                        <hr/>
                                        <div className="slide-caption">
                                            {imageObj.business_gallery_caption}
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        userRole: state.userRole
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel);