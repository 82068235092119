import React, { useEffect, useState } from 'react';
// Moment
import moment from 'moment';
// Material components
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
// Icons
import { ArrowLeft } from "@phosphor-icons/react";
// API
import Api from "api/index";
// Components
import ColourPicker from 'components/ColourPicker/ColourPicker';
// Constants
import currency from 'constants/currency';
// Utilities
import formattingUtils from 'utilities/formatting';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
}

function EmailTemplate(templateData, templateKey, templateSettings) {
    if(templateSettings?.email_header_gradient_start || templateSettings?.email_header_gradient_end) {
        if(!templateSettings.email_header_gradient_start) {
            templateSettings.email_header_gradient_start = "rgba(0, 0, 0, 0.6)";
        }
        if(!templateSettings.email_header_gradient_end) {
            templateSettings.email_header_gradient_end = "rgba(0, 0, 0, 0.6)";
        }
        templateData = templateData.replace(
            /linear-gradient\([^(]*(\([^)]*\)[^(]*)*[]*\)/,
            `linear-gradient(180deg, ${templateSettings.email_header_gradient_start}, ${templateSettings.email_header_gradient_end})`
        );
    }
    let currencyObj  = currency.find(x => x.id === templateSettings.currencyId);
    switch(templateKey) {
        case "userWelcome":
            let welcomeMessage = templateSettings.sign_up_email_message;
            if(!welcomeMessage) {
                welcomeMessage = "Thanks for signing up! We're thrilled you've decided to join the community. Do let us know if you have any questions - we're here to help.";
            }
            templateData = templateData.replace("[WELCOME_MESSAGE]", welcomeMessage);
            templateData = templateData.replace("[NAME]", templateSettings.userFirstName);
            break;
        case "bookingFee":
            templateData = templateData.replace("{{fee_name}}", "no-show fee");
            templateData = templateData.replace("{{fee_currency}}", currencyObj ? currencyObj.symbol : "£");
            templateData = templateData.replace("{{fee_amount}}", "10.00");
            templateData = templateData.replace("{{booking_reference}}", "ABC123");
            templateData = templateData.replace(/<strong>/g, "<br/><strong>");
            break;
        case "bookingUnsuccessful":
            templateData = templateData.replace(/<strong>/g, "<br/><strong>");
            templateData = templateData.replace("{{booking_date}}", moment().add(1, 'day').format('ddd Do MMM YYYY'));
            templateData = templateData.replace("{{booking_time}}", "10:00");
            break;
        case "bookingConfirmation":
        case "bookingUpdate":
        case "newBooking":
        case "cancelledBooking":
            let title;
            switch(templateKey) {
                case "bookingConfirmation":
                    title = "Booking Confirmation";
                    break;
                case "bookingUpdate":
                    title = "Booking Update";
                    break;
                case "newBooking":
                    title = "New Booking";
                    break;
                case "cancelledBooking":
                    title = "Cancelled Booking";
                    break;
            }
            templateData = templateData.replace("{{title}}", title);
            templateData = templateData.replace("{{booking_created}}", moment().format('HH:mm, ddd Do MMM YYYY'));
            templateData = templateData.replace("{{booking_date}}", `09:00, ${moment().add(7, 'days').format('ddd Do MMM YYYY')}`);
            templateData = templateData.replace("{{customer_name}}", `${templateSettings.userFirstName} ${templateSettings.userLastName}`);
            templateData = templateData.replace("{{booking_ref}}", "ABC123");
            templateData = templateData.replace(/{{currency_symbol}}/g, currencyObj ? currencyObj.symbol : "£");
            let serviceObj = templateSettings.service ? templateSettings.service[0] : null;
            let serviceDetailObj = templateSettings.serviceDetail.find(x => x.service_business_id === serviceObj?.service_id);
            templateData = templateData.replace("{{this.service_name}}", serviceObj ? serviceObj.service_name : "Test Service");
            templateData = templateData.replace("{{this.tier_name}}", `(${serviceDetailObj? serviceDetailObj.service_business_detail_name : "Senior"})`);
            templateData = templateData.replace("{{service_price}}", serviceDetailObj ? Number(serviceDetailObj.service_business_detail_price / 100).toFixed(2) : "100.00");
            templateData = templateData.replace("{{this.service_duration}}", serviceDetailObj ? serviceDetailObj.service_business_detail_duration_a : "20");
            let staffObj = templateSettings.staff ? templateSettings.staff[0] : null;
            templateData = templateData.replace("{{this.staff_name}}", staffObj ? `${staffObj.firstname} ${staffObj.lastname}` : "Test Staff");
            templateData = templateData.replace("{{total_price}}", serviceDetailObj ? Number(serviceDetailObj.service_business_detail_price / 100).toFixed(2) : "100.00");
            templateData = templateData.replace("{{total_paid}}", "5.00");
            templateData = templateData.replace("{{total_due}}", serviceDetailObj ? Number((serviceDetailObj.service_business_detail_price - 500) / 100).toFixed(2) : "0.00");
            templateData = templateData.replace("{{business_name}}", templateSettings.businessName);
            templateData = templateData.replace("{{business_location_name}}", templateSettings.businessLocationName);
            templateData = templateData.replace("{{business_address}}", templateSettings.businessLocationAddress);
            templateData = templateData.replace("{{directions_url}}", templateSettings.businessDirectionsUrl);
            templateData = templateData.replace("{{business_email}}", templateKey === "newBooking" ? "test-customer@styler.digital" : templateSettings.businessEmail);
            templateData = templateData.replace("{{business_phone}}", templateSettings.businessContactNumber);
            let cancellationPolicy = "You can cancel anytime.";
            if(templateSettings.cancellationPolicy === 0.5){
                cancellationPolicy = "We operate a 30 minute cancellation policy.";
            } else if(templateSettings.cancellationPolicy >= 1 && templateSettings.cancellationPolicy <= 48){
                cancellationPolicy = `We operate a ${templateSettings.cancellationPolicy} hour cancellation policy.`;
            } else if(templateSettings.cancellationPolicy > 48){
                cancellationPolicy = `We operate a ${templateSettings.cancellationPolicy / 24} day cancellation policy.`;
            }
            templateData = templateData.replace("{{cancellation_policy}}", cancellationPolicy);
            templateData = templateData.replace("{{important_information}}", templateSettings.booking_notice ? templateSettings.booking_notice : "Not set.");
            break;
        default:
            break;
    }
    return (
        <div
            dangerouslySetInnerHTML={{__html: templateData }}
            style={window.innerWidth < 769 ? { paddingLeft: '1em', paddingRight: '1em', backgroundColor: '#FFFFFF' } : null}
        />
    )
}

function Emails(props) {
    const [windowWidth, setWindowWidth] = useState(0);
    const [previewEmail, setPreviewEmail] = useState(false);
    const [emailTemplateKey, setEmailTemplateKey] = useState('userWelcome');
    const [emailTemplateData, setEmailTemplateData] = useState(null);
    const [emailTemplateSettings, setEmailTemplateSettings] = useState({});
    const [colourPickerVisible, setColourPickerVisible] = useState(false);
    const [colourPickerKey, setColourPickerKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getEmailTemplates = async () => {
            props.actions.loadLoadingSpinner(true);
            let response = await Api.getEmailPreferences();
            setEmailTemplateData(response.data.emailTemplateData);
            setEmailTemplateSettings(response.data.emailPreferencesData);
            props.actions.loadLoadingSpinner(false);
        }
        getEmailTemplates();
    }, []);


    const resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
      }, []);

    const handleChange = (e, key = null) => {
        let value;
        if(e?.target?.id === "new_booking_email_enabled" || e?.target?.id === "cancelled_booking_email_enabled") {
            value = e.target.checked;
        } else {
            value = typeof e === 'object' ? e.target.value : e;
        }
        let settingsData = Object.assign({}, emailTemplateSettings);
        settingsData[key ? key : e.target.id] = value;
        setEmailTemplateSettings(settingsData);
    }

    const saveEmailSettings = async () => {
        try {
            if(isLoading) {
                return;
            }
            if(!emailTemplateSettings.sign_up_email_message || emailTemplateSettings.sign_up_email_message === "") {
                emailTemplateSettings.sign_up_email_message = null;
            }
            if(emailTemplateSettings.sign_up_email_message?.length > 500) {
                props.triggerNotification("The Sign up email welcome message must be 500 characters or less.", "danger", "bc", 6);
                return;
            }
            setIsLoading(true);
            if(!emailTemplateSettings.email_header_gradient_start) {
                emailTemplateSettings.email_header_gradient_start = null;
            }
            if(!emailTemplateSettings.email_header_gradient_end) {
                emailTemplateSettings.email_header_gradient_end = null;
            }
            await Api.postEmailPreferences(emailTemplateSettings);
            props.triggerNotification("Email settings updated successfully.", "success", "bc", 6);
        } catch(e) {
            console.log(e);
            props.triggerNotification("An unexpected error occured whilst trying to update your email settings. Please contact Support if this problem persists.", "danger", "bc", 6);
        } finally {
            setIsLoading(false);
        }
    }

    const getEmailTitle = (key) => {
        switch(key) {
            case "userWelcome":
                return "Customer Welcome (Sign up)";
            case "bookingFee":
                return "Customer Booking Fee";
            case "bookingUnsuccessful":
                return "Customer Booking Unsuccessful";
            case "bookingConfirmation":
                return "Customer Booking Confirmation";
            case "bookingUpdate":
                return "Customer Booking Update";
            case "newBooking":
                return "New Booking";
            case "cancelledBooking":
                return "Cancelled Booking";
            default:
                return null;
        }
    }

    const getEmailDescription = (key) => {
        switch(key) {
            case "userWelcome":
                return "This email is sent to new customers who sign up via your app or widget. Edit the welcome message to send your clients a custom greeting.";
            case "bookingFee":
                return "This email is sent to a customer when they are charged a no-show or late cancellation fee.";
            case "bookingUnsuccessful":
                return "This email is rarely sent. It is used during times of peak system traffic to prevent race conditions on a booking time. For example, when a deposit payment is processing, another customer may reserve the booking slot or the staff member may become unavailable. In this situation, to prevent double bookings (or an event when the staff member isn't available) we send this email to the client to let them know that the booking has not been confirmed.";
            case "bookingConfirmation":
                return "This email is sent to a customer upon confirmation of a new booking."
            case "bookingUpdate":
                return "This email is sent to a customer when you update a booking on their behalf. For example, when there's a change to the booking time, services or location.";
            case "newBooking":
                return "This email can be optionally sent to you when a customer makes a booking.";
            case "cancelledBooking":
                return "This email can be optionally sent to you when a customer cancels a booking.";
            default:
                break;
        }
    }

    const renderEmailTemplateSettings = (key) => {
        return (
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Box sx={{ typography: 'subtitle1', pb: 2 }}>
                    {getEmailTitle(key)} Email Settings
                </Box>
                <Box sx={{ typography: 'caption', pb: 2 }}>
                    {getEmailDescription(key)}
                </Box>
                {key === "userWelcome" && (
                    <Box sx={{  pb: 2 }}>
                        <TextField
                            id="sign_up_email_message"
                            fullWidth
                            label="Welcome message"
                            multiline
                            rows={4}
                            value={emailTemplateSettings.sign_up_email_message ? emailTemplateSettings.sign_up_email_message : ""}
                            onChange={handleChange}
                            error={emailTemplateSettings.sign_up_email_message?.length > 500}
                        />
                        <Box
                            sx={{
                                typography: 'caption',
                                pt: 1,
                                textAlign: 'right',
                                color: emailTemplateSettings.sign_up_email_message?.length > 500 ? 'red' : undefined
                            }}
                        >
                            {emailTemplateSettings.sign_up_email_message?.length}/500
                        </Box> 
                    </Box>
                )}
                {["bookingConfirmation", "bookingUpdate"].includes(key) && (
                    <Box sx={{  pb: 2 }}>
                        <Box sx={{ typography: 'caption', pb: 2 }}>
                            The location address and contact details can be updated on the
                            {" "}<Link target="_blank" href="business-locations">Business Locations</Link>{" "}
                            page.
                        </Box>
                        <Box sx={{ typography: 'caption' }}>
                            The <b>Important Information</b> message and <b>Cancellation Policy</b> can be updated on the
                            {" "}<Link target="_blank" href="booking-preferences">Booking Preferences</Link>{" "}
                            page.
                        </Box>
                    </Box>
                )}
                {key === "newBooking" && emailTemplateSettings && (
                    <Box>
                        <Box sx={{ typography: 'caption', pb: 1 }}>
                        If enabled, this email will be sent to your business email address:
                        </Box>
                        <Box sx={{ typography: 'caption', pb: 1 }}>
                            <b>{props.business.business_email}</b>
                        </Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="new_booking_email_enabled"
                                    checked={emailTemplateSettings.new_booking_email_enabled}
                                    color="success"
                                    onChange={(e) => handleChange(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Enabled"
                            labelPlacement="end"
                        />
                    </Box>
                )}
                {key === "cancelledBooking" && emailTemplateSettings && (
                    <Box>
                        <Box sx={{ typography: 'caption', pb: 1 }}>
                            If enabled, this email will be sent to your business email address:
                        </Box>
                        <Box sx={{ typography: 'caption', pb: 1 }}>
                            <b>{props.business.business_email}</b>
                        </Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="cancelled_booking_email_enabled"
                                    checked={emailTemplateSettings.cancelled_booking_email_enabled}
                                    color="success"
                                    onChange={(e) => handleChange(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Enabled"
                            labelPlacement="end"
                        />
                    </Box>
                )}
            </Box>
        )
    }

    const renderTabs = () => {
        const tabSx = { fontWeight: '500', textTransform: 'none' };
        return (
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={emailTemplateKey}
                    onChange={(_, val) => setEmailTemplateKey(val) }
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="secondary"
                    TabIndicatorProps={{  sx: { fontWeight: '500', textTransform: 'none' } }}
                >
                    <Tab value="userWelcome" label={getEmailTitle("userWelcome")} sx={tabSx} />
                    <Tab value="bookingFee" label={getEmailTitle("bookingFee")} sx={tabSx} />
                    <Tab value="bookingConfirmation" label={getEmailTitle("bookingConfirmation")} sx={tabSx} />
                    <Tab value="bookingUpdate" label={getEmailTitle("bookingUpdate")} sx={tabSx} />
                    <Tab value="bookingUnsuccessful" label={getEmailTitle("bookingUnsuccessful")} sx={tabSx} />
                    <Tab value="newBooking" label={getEmailTitle("newBooking")} sx={tabSx} />
                    <Tab value="cancelledBooking" label={getEmailTitle("cancelledBooking")} sx={tabSx} />
                </Tabs>
            </Box>
        );
    }

    return (
        <>
            <div className="business-locations-container">
                <div className="business-locations-column" style={previewEmail ? { display: "none" } : null}>
                    <div className="business-locations-column-container">
                        {windowWidth < 769 && renderTabs()}
                        <Box sx={{ mx: 3, my: 3 }}>
                            {renderEmailTemplateSettings(emailTemplateKey)}
                            <Box>
                                {!['newBooking', 'cancelledBooking'].includes(emailTemplateKey) && (
                                    <Box>
                                        <Box sx={{ typography: 'subtitle1', pb: 2 }}>Header settings</Box>
                                        <Box sx={{ typography: 'caption', pb: 2 }}>
                                            The following header settings are applied to all emails.
                                            The logo and cover image can be changed on the
                                            {" "}<Link target="_blank" href="business-settings">Business Settings</Link>{" "}
                                            page.
                                        </Box>
                                        <Box sx={{ mb: 2 }}>
                                            <div className="color-row">
                                                <Box sx={{ typography: 'body1', lineHeight: '28px' }}>
                                                    Colour Top
                                                </Box>
                                                <div className="color-picker-swatch-container">
                                                    <div
                                                        className="color-picker-swatch"
                                                        style={{ backgroundColor: emailTemplateSettings.email_header_gradient_start }}
                                                        onClick={() => {
                                                            setColourPickerKey('email_header_gradient_start');
                                                            setColourPickerVisible(true);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Box>
                                        <Box sx={{ mb: 3 }}>
                                            <div className="color-row">
                                                <Box sx={{ typography: 'body1', lineHeight: '28px' }}>
                                                    Colour Bottom
                                                </Box>
                                                <div className="color-picker-swatch-container">
                                                    <div
                                                        className="color-picker-swatch"
                                                        style={{ backgroundColor: emailTemplateSettings.email_header_gradient_end }}
                                                        onClick={() => {
                                                            setColourPickerKey('email_header_gradient_end');
                                                            setColourPickerVisible(true);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Box>
                                    </Box>
                                )}
                                <Box>
                                    {windowWidth < 769 && (
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, pb: 2 }}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                fullWidth
                                                onClick={() => setPreviewEmail(true)}
                                            >
                                                Preview
                                            </Button>
                                        </Box>
                                    )}
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={() => saveEmailSettings()}
                                    >
                                        {isLoading ? (
                                            <CircularProgress
                                                color="secondary"
                                                size={25}
                                            />
                                        ) : 'Save' }
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </div>
                <div className="business-locations-map-container" style={previewEmail ? { display: "block" } : null}>
                    <Box sx={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF' }}>
                        {renderTabs()}
                        {emailTemplateData && (
                            <div style={{ overflow: 'auto', height: '100%', backgroundColor: '#f0f1f3' }}>
                                <TabPanel value="userWelcome" index={emailTemplateKey}>
                                    {emailTemplateKey === "userWelcome" && EmailTemplate(
                                        emailTemplateData['userWelcome'],
                                        'userWelcome',
                                        {...emailTemplateSettings, userFirstName: props.user.user_firstname}
                                    )}
                                </TabPanel>
                                <TabPanel value="bookingFee" index={emailTemplateKey}>
                                    {emailTemplateKey === "bookingFee" && EmailTemplate(emailTemplateData['bookingFee'], 'bookingFee', emailTemplateSettings)}
                                </TabPanel>
                                <TabPanel value="bookingConfirmation" index={emailTemplateKey}>
                                    {emailTemplateKey === "bookingConfirmation" && EmailTemplate(
                                        emailTemplateData['bookingConfirmation'],
                                        'bookingConfirmation',
                                        {
                                            ...emailTemplateSettings,
                                            userFirstName: props.user.user_firstname,
                                            userLastName: props.user.user_lastname,
                                            businessName: props.business.business_name,
                                            service: props.service,
                                            serviceDetail: props.serviceDetail,
                                            staff: props.staff,
                                            businessLocationName: `(${props.businessLocation[0].business_location_name})`,
                                            businessLocationAddress: formattingUtils.getFormattedBusinessLocationAddress(props.businessLocation[0]),
                                            businessDirectionsUrl: `https://www.google.com/maps?q=${props.businessLocation[0].address_latitude},${props.businessLocation[0].address_longitude}`,
                                            businessEmail: props.business.business_email,
                                            businessContactNumber: props.business.business_phone_no,
                                            cancellationPolicy: props.business.cancellation_period
                                        }
                                    )}
                                </TabPanel>
                                <TabPanel value="bookingUpdate" index={emailTemplateKey}>
                                    {emailTemplateKey === "bookingUpdate" && EmailTemplate(
                                        emailTemplateData['bookingConfirmation'],
                                        'bookingUpdate',
                                        {
                                            ...emailTemplateSettings,
                                            userFirstName: props.user.user_firstname,
                                            userLastName: props.user.user_lastname,
                                            businessName: props.business.business_name,
                                            service: props.service,
                                            serviceDetail: props.serviceDetail,
                                            staff: props.staff,
                                            businessLocationName: `(${props.businessLocation[0].business_location_name})`,
                                            businessLocationAddress: formattingUtils.getFormattedBusinessLocationAddress(props.businessLocation[0]),
                                            businessDirectionsUrl: `https://www.google.com/maps?q=${props.businessLocation[0].address_latitude},${props.businessLocation[0].address_longitude}`,
                                            businessEmail: props.business.business_email,
                                            cancellationPolicy: props.business.cancellation_period
                                        }
                                    )}
                                </TabPanel>
                                <TabPanel value="bookingUnsuccessful" index={emailTemplateKey}>
                                    {emailTemplateKey === "bookingUnsuccessful" && EmailTemplate(emailTemplateData['bookingUnsuccessful'], 'bookingUnsuccessful', emailTemplateSettings)}
                                </TabPanel>
                                <TabPanel value="newBooking" index={emailTemplateKey}>
                                    {emailTemplateKey === "newBooking" && EmailTemplate(
                                        emailTemplateData['newBooking'],
                                        'newBooking',
                                        {
                                            ...emailTemplateSettings,
                                            userFirstName: props.user.user_firstname,
                                            userLastName: props.user.user_lastname,
                                            businessName: props.business.business_name,
                                            service: props.service,
                                            serviceDetail: props.serviceDetail,
                                            staff: props.staff,
                                            businessLocationName: `(${props.businessLocation[0].business_location_name})`,
                                            businessLocationAddress: formattingUtils.getFormattedBusinessLocationAddress(props.businessLocation[0]),
                                            businessDirectionsUrl: `https://www.google.com/maps?q=${props.businessLocation[0].address_latitude},${props.businessLocation[0].address_longitude}`,
                                            businessEmail: props.business.business_email,
                                            cancellationPolicy: props.business.cancellation_period
                                        }
                                    )}
                                </TabPanel>
                                <TabPanel>
                                    {emailTemplateKey === "cancelledBooking" && EmailTemplate(
                                        emailTemplateData['cancelledBooking'],
                                        'cancelledBooking',
                                        {
                                            ...emailTemplateSettings,
                                            userFirstName: props.user.user_firstname,
                                            userLastName: props.user.user_lastname,
                                            businessName: props.business.business_name,
                                            service: props.service,
                                            serviceDetail: props.serviceDetail,
                                            staff: props.staff,
                                            businessLocationName: `(${props.businessLocation[0].business_location_name})`,
                                            businessLocationAddress: formattingUtils.getFormattedBusinessLocationAddress(props.businessLocation[0]),
                                            businessDirectionsUrl: `https://www.google.com/maps?q=${props.businessLocation[0].address_latitude},${props.businessLocation[0].address_longitude}`,
                                            businessEmail: props.business.business_email,
                                            cancellationPolicy: props.business.cancellation_period
                                        }
                                    )}
                                </TabPanel>
                            </div>
                        )}
                    </Box>
                    {windowWidth < 769 && (
                        <Box sx={{ position: 'absolute', bottom: 10, left: 5 }}>
                            <IconButton
                                variant="contained"
                                color="secondary"
                                sx={{ backgroundColor: '#ffffff',boxShadow: 2 }}
                                onClick={() => setPreviewEmail(false)}
                            >
                                <ArrowLeft size={30} />
                            </IconButton>
                        </Box>
                    )}
                </div>
            </div>
            {colourPickerVisible && (
                <ColourPicker
                    alpha
                    visible={colourPickerVisible}
                    toggleVisible={() => setColourPickerVisible(!colourPickerVisible)}
                    initialColor={emailTemplateSettings[colourPickerKey]}
                    onSubmit={(e) => {
                        handleChange(e, colourPickerKey);
                        setColourPickerVisible(false);
                    }}
                />
            )}
        </>
    );
}

export default Emails;