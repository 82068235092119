import React from 'react';
// Material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
// Icons
import { CaretUp, CaretDown } from '@phosphor-icons/react';
// Custom components
import UserAvatar from 'components/Material/UserAvatar';
import StaffDisplay from './StaffDisplay';
// Constants
import { CDN_URL } from 'constants/urls';

function StaffAccountsGrid(props) {

    const [openUserId, setOpenUserId] = React.useState(null);

    const cellStyle = { borderRight: '1px solid rgba(224, 224, 224, 1)' };
    const headerCellStyle = { textTransform: 'uppercase', fontWeight: '600', borderBottomWidth: 2, ...cellStyle };
    const displayCellStyle = { display: { xs: 'none', sm: 'table-cell' } };
    
    function renderPermissionCell(businessUserRoleTypeId, businessUserId) {
        return (
            <Select
                sx={{ width: '100%', border: 'none' }}
                value={businessUserRoleTypeId}
                disabled={businessUserRoleTypeId === 1}
                size="medium"
                on
                onChange={(event) => props.handleChange(businessUserId, 'business_user_role_type_id', event.target.value)}
            >
                <MenuItem value={1} sx={{ display: 'none' }} disabled>Admin</MenuItem>
                <MenuItem value={2}>High</MenuItem>
                <MenuItem value={3}>Medium</MenuItem>
                <MenuItem value={4}>Low</MenuItem>
                <MenuItem value={5}>Basic</MenuItem>
            </Select>
        )
    }

    function renderEnabledCell(businessUserId, businessUserRoleTypeId, enabled) {
        return (
            <Switch
                color="success"
                aria-label="Account enabled"
                checked={businessUserRoleTypeId === 1 || enabled}
                disabled={businessUserRoleTypeId === 1}
                onChange={(_, checked) => props.handleChange(businessUserId, 'enabled', checked)}
            />
        );
    }

    function renderStaffRow(rowData) {
        const {
            business_user_id: businessUserId,
            business_user_firstname: userFirstname,
            business_user_lastname: userLastname,
            business_user_role_type_id: businessUserRoleTypeId,
            staff_id: staffId,
            enabled
        } = rowData;
        const staffObj = staffId ? props.staffData.find(x => x.id === staffId) : null;
        const userName = staffObj ? `${staffObj.firstname} ${staffObj.lastname}` : `${userFirstname} ${userLastname}`;
        const userImg = staffObj ? staffObj.staff_img : rowData.business_user_img;
        const rowExpanded = openUserId === businessUserId;
        return (
            <React.Fragment>
                <TableRow
                    key={`BusinessUserID${businessUserId}`}
                    sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                    hover
                    onClick={() => window.innerWidth <= 599 && setOpenUserId(rowExpanded ? null : businessUserId)}
                >
                    <TableCell component="th" scope="row" sx={cellStyle}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <StaffDisplay
                                name={userName}
                                subtitle={businessUserRoleTypeId === 1 ? "Account holder" : "Staff member"}
                                img={userImg}
                            />
                            <Box sx={{ display: { xs: 'table-cell', sm: 'none' } }}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenUserId(rowExpanded ? null : businessUserId)}
                                >
                                    {rowExpanded ? <CaretUp/> : <CaretDown/>}
                                </IconButton>
                            </Box>
                        </Stack>
                    </TableCell>
                    <TableCell align="center" sx={{...cellStyle, ...displayCellStyle}}>
                        {renderPermissionCell(businessUserRoleTypeId, businessUserId)}
                    </TableCell>
                    <TableCell align="center" sx={{...cellStyle, ...displayCellStyle}}>
                        {renderEnabledCell(businessUserId, businessUserRoleTypeId, enabled)}
                    </TableCell>
                </TableRow>
                <TableRow sx={{ display: { xs: 'table-row', sm: 'none' } }}>
                    <TableCell sx={{ padding: 0 }} component="th" scope="row">
                        <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
                            <Stack direction="column" gap={2} sx={{ pt: 4, pb: 6, borderBottomWidth: 2, borderBottomColor: 'gray', borderBottomStyle: 'solid' }}>
                                <Box>
                                    <Typography variant="subtitle1">Permission Level</Typography>
                                    {renderPermissionCell(businessUserRoleTypeId, businessUserId)}
                                </Box>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="subtitle1">Enabled</Typography>
                                    {renderEnabledCell(businessUserId, businessUserRoleTypeId, enabled)}
                                </Stack>
                            </Stack>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                <TableRow>
                    <TableCell sx={{...headerCellStyle, fontWeight: '700', fontSize: 15 }}>User</TableCell>
                    <TableCell align="center" sx={{...headerCellStyle, ...displayCellStyle}} width={'30%'}>Permission Level</TableCell>
                    <TableCell align="center" sx={{...headerCellStyle, ...displayCellStyle}} width={'20%'}>Enabled</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {props.userData?.map(x => renderStaffRow(x) )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default StaffAccountsGrid;