import React from "react";
import Lottie from 'react-lottie';
import { Button } from 'reactstrap';
import * as Error from 'assets/animations/adhoc/error.json';

class ErrorFallback extends React.Component {

    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: Error.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            },
            width: '100%',
            height: '100%'
        };
        return (
            <div className="centered-container">
                <div className="under-construction-animation">
                    <Lottie options={defaultOptions}/>
                </div>
                <div className="under-construction-title">Oops!</div>
                <p className="under-construction-description">An unexpected error has occured. Our team has been automatically notified of this problem.</p>
                <Button color="primary" onClick={() => { window.location.href = '/' }}>Return</Button>
            </div>
        );
    }
}

export default ErrorFallback;