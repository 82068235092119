/*eslint-disable*/
import React from "react";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
    Row,
    Col,
    FormGroup,
    Card,
    CardBody,
    Input,
    Spinner,
    Button,
    InputGroup,
    CardHeader,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem
} from "reactstrap";
import Datetime from "react-datetime";
import { FiPlus, FiMenu } from "react-icons/fi";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from 'api/index';

import frequency from "constants/frequency";
import ends from "constants/ends";
import bookingTypes from "constants/bookingTypes";
import finite from "constants/finite";
import status from "constants/status";
import { CDN_URL } from "constants/urls";

import CustomerAdd from "components/Customers/CustomerAdd";

class BookingDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user_id: null,
            user_data: null,
            user_loading: false,
            user_option: null,
            locationOptions: [],
            customerAddVisible: false
        };
        this.toggleCustomerAdd = this.toggleCustomerAdd.bind(this);
        this.handleCustomerAddSubmit = this.handleCustomerAddSubmit.bind(this);
    }

    componentDidMount() {
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj,) => {
            locationOptions.push({
                id: businessLocationObj.business_location_id,
                label: businessLocationObj.business_location_name,
                value: businessLocationObj.business_location_id
            });
        });
        this.setState({ locationOptions });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.bookingUserId !== this.props.bookingUserId && Number.isInteger(this.props.bookingUserId)) {
            this.handleUserChange({ value: this.props.bookingUserId });
        }
    }

    async promiseCustomerOptions(inputValue) {
        try {
            if(inputValue.length < 3) {
                return [];
            } else {
                let searchResults = await Api.searchCustomers({ search_string: inputValue });
                let customerOptions = [];
                searchResults.data.customerData.forEach((customerObj, customerIndex) => {
                    customerOptions.push({
                        label: customerObj.user_firstname + ' ' + customerObj.user_lastname,
                        value: customerObj.user_id,
                        email: customerObj.user_email,
                        phone: customerObj.user_phone,
                        firstname: customerObj.user_firstname,
                        lastname: customerObj.user_lastname
                    });
                });
                return customerOptions;
            }
        } catch(e) {
            this.props.triggerNotification("An error occured whilst searching customers. We were unable to complete the search.", "danger", "bc", 6);
        }
    }

    formatOptionLabel({ value, label, email, phone, firstname, lastname }) {
        return (
            <div style={{ display: "flex", flexDirection: 'row' }}>
                <div className="customer-option-circle">
                    <div className="customer-option-circle-text">{firstname.charAt(0) + lastname.charAt(0)}</div>
                </div>
                <div>
                    <div>{label}</div>
                    <div className="text-muted customer-option-contact">{email}</div>
                </div>
            </div>
        );
    };

    formatStatusOptionLabel({ value, label }) {
        let circleColour = null;
        if(label === 'Booked') {
            circleColour='#1ab394';
        } else if(label === 'In-progress') {
            circleColour='#f8ac59';
        } else if(label === 'Completed') {
            circleColour='#aeaeae';
        } else if(label === 'No-show' || label === 'Cancelled') {
            circleColour='#ed5565';
        }
        return (
            <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                <div className="status-option-circle" style={{ backgroundColor: circleColour }}></div>
                <div>
                    <div>{label}</div>
                </div>
            </div>
        );
    };

    toggleCustomerAdd() {
        this.setState({ customerAddVisible: !this.state.customerAddVisible });
    }

    handleCustomerAddSubmit(customerId) {
        this.setState({ customerAddVisible: false });
        this.handleUserChange({ value: customerId });
    }

    async handleUserChange(e) {
        try {
            this.props.handleBookingDetailsChange(e.value, 'user');
            this.setState({ user_id: e.value, user_loading: true });
            let customerResponse = await Api.getCustomer({ customer_id: e.value });
            let customerData = customerResponse.data.customerData;
            if(!this.state.user_option) {
                let user_option = {
                    label: customerData.user_firstname + ' ' + customerData.user_lastname,
                    value: customerData.user_id,
                    email: customerData.user_email,
                    phone: customerData.user_phone,
                    firstname: customerData.user_firstname,
                    lastname: customerData.user_lastname
                };
                this.setState({ user_option });
            }
            this.setState({ user_data: customerData, user_loading: false });
        } catch(e) {
            this.props.triggerNotification("An error occured whilst loading the customer.", "danger", "bc", 6);
            this.setState({ user_id: null, user_loading: false, user_data: null });
            this.props.handleBookingDetailsChange(null, 'user');
        }
    }

    renderCustomerNotificationPreference() {
        if(this.state.user_data && this.state.user_data.user_notification_email === 1 && this.state.user_data.user_notification_sms === 1) {
            return('Email & SMS');
        } else if(this.state.user_data && this.state.user_data.user_notification_email === 1 && this.state.user_data.user_notification_sms === 0) {
            return('Email Only');
        } else if(this.state.user_data && this.state.user_data.user_notification_email === 0 && this.state.user_data.user_notification_sms === 1) {
            return('SMS Only');
        } else {
            return('No Notifications');
        }
    }

    renderCustomerMarketingPreference() {
        if(this.state.user_data && this.state.user_data.user_marketing_email === 1 && this.state.user_data.user_marketing_sms === 1) {
            return('Email & SMS');
        } else if(this.state.user_data && this.state.user_data.user_marketing_email === 1 && this.state.user_data.user_marketing_sms === 0) {
            return('Email Only');
        } else if(this.state.user_data && this.state.user_data.user_marketing_email === 0 && this.state.user_data.user_marketing_sms === 1) {
            return('SMS Only');
        } else {
            return('No Marketing');
        }
    }

    render() {
        return (
            <>
                <Card className="booking-info">
                    {this.props.usage === 'edit' ?
                        <CardHeader>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ fontSize: 18 }}>Edit {this.props.bookingType === "booking" ? (this.props.bookingRecurring ? "Recurring Booking" : "Booking") : "Walk-in"}</div>
                                {!this.props.bookingRecurring && !this.props.bookingRecurringConversion ?
                                    <div className="booking-details-dropdown">
                                        <UncontrolledDropdown >
                                            <DropdownToggle caret color="link" style={{ margin: 0, padding: 5 }}>
                                                <FiMenu size={20}/>
                                            </DropdownToggle>
                                            <DropdownMenu end>
                                                <DropdownItem onClick={() => this.props.handleBookingDetailsChange(null, 'payments')}>Payments</DropdownItem>
                                                <DropdownItem onClick={() => this.props.handleBookingDetailsChange(null, 'timeline')}>Timeline</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                : null}
                            </div>
                        </CardHeader>
                    : null}
                    <CardBody>
                        {this.props.usage === 'add' ?
                            <FormGroup>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={bookingTypes}
                                    placeholder={'Search booking type...'}
                                    isDisabled={this.props.usage === 'edit'}
                                    value={bookingTypes.find(x => x.value === this.props.bookingType)}
                                    onChange={(e) => this.props.handleBookingDetailsChange(e.value, 'type')}
                                    captureMenuScroll={true}
                                    controlShouldRenderValue={true}
                                />
                            </FormGroup>
                        : null}
                        {this.props.usage === 'edit' ?
                            <FormGroup>
                                <Input
                                    type="text"
                                    value={this.props.bookingUid ? 'Booking Ref: ' + this.props.bookingUid : null}
                                    placeholder="Loading..."
                                    disabled={true}
                                    onChange={(e) => this.handleChange(e, 'title')}
                                />
                            </FormGroup>
                        : null}
                        {this.props.usage === 'edit' && this.props.bookingRecurring === false ?
                            <FormGroup>
                                <label>Status</label>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={status}
                                    value={status.find(x => x.label === this.props.bookingStatus)}
                                    formatOptionLabel={this.formatStatusOptionLabel}
                                    onChange={(e) => this.props.handleBookingDetailsChange(e.label, 'status')}
                                    captureMenuScroll={true}
                                    controlShouldRenderValue={true}
                                />
                            </FormGroup>
                        : null}
                        {this.props.bookingType && this.props.bookingType === 'booking' ?
                            <div>
                                {(this.props.usage === 'add' && !this.props.bookingUserId) || this.state.user_option ?
                                    <InputGroup>
                                        <AsyncSelect
                                            className="react-select primary react-select-w-addon"
                                            classNamePrefix="react-select"
                                            cacheOptions
                                            defaultValue={this.state.user_option}
                                            loadOptions={(e) => this.promiseCustomerOptions(e)}
                                            formatOptionLabel={this.formatOptionLabel}
                                            placeholder={'Search for a customer...'}
                                            onChange={(e) => this.handleUserChange(e)}
                                        />
                                        <Button outline color={'muted'} style={{ margin: 0 }} onClick={() => this.setState({ customerAddVisible: true })}>
                                            <FiPlus size={16} color={'#1ab394'}/>
                                        </Button>
                                    </InputGroup>
                                : null}
                                {this.state.user_loading ?
                                    <div>
                                        <Spinner color="success" size="sm"/>
                                        <div className="load-customer-text">Loading Customer</div>
                                    </div>
                                : null}
                                {this.state.user_id && this.state.user_data && !this.state.user_loading ?
                                    <div>
                                        <hr/>
                                        <div className="image booking-details-business-img">
                                            <img alt="BusinessProfileImg" src={`${CDN_URL}/` + this.props.business.business_img} />
                                        </div>
                                        <div className="booking-details-avatar">
                                            <div className="booking-details-avatar-initials">{this.state.user_data ? this.state.user_data.user_firstname.charAt(0) + this.state.user_data.user_lastname.charAt(0) : '--'}</div>
                                        </div>
                                        <h5 className="title text-center" style={{ marginBottom: 0 }}>{this.state.user_data ? this.state.user_data.user_firstname + ' ' + this.state.user_data.user_lastname : '-----'}</h5>
                                        <p className="description text-center" style={{ fontWeight: 400, marginBottom: 0 }}>Customer</p>
                                        <hr/>
                                        <Row>
                                            <Col xl={6} lg={12} md={6} sm={6}>
                                                <label>Email</label>
                                                <FormGroup>
                                                    <p className="form-control-static" style={{ margin: 0 }}>
                                                        {this.state.user_data && this.state.user_data.user_email ? this.state.user_data.user_email : '-----'}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={6} lg={12} md={6} sm={6}>
                                                <label>Contact Number</label>
                                                <FormGroup>
                                                    <p className="form-control-static" style={{ margin: 0 }}>
                                                        {this.state.user_data && this.state.user_data.user_phone ? this.state.user_data.user_phone : '-----'}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={6} lg={12} md={6} sm={6}>
                                                <label>Notification Preferences</label>
                                                <FormGroup>
                                                    <p className="form-control-static" style={{ margin: 0 }}>
                                                        {this.renderCustomerNotificationPreference()}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={6} lg={12} md={6} sm={6}>
                                                <label>Marketing Preferences</label>
                                                <FormGroup>
                                                    <p className="form-control-static" style={{ margin: 0 }}>
                                                        {this.renderCustomerMarketingPreference()}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={12}>
                                                <label>Notes</label>
                                                <FormGroup>
                                                    <p className="form-control-static" style={{ margin: 0 }}>
                                                        {this.state.user_data && this.state.user_data.user_note ? this.state.user_data.user_note : '-----'}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={12}>
                                                <a
                                                    href={"/admin/customer_profile?id=" + this.state.user_data.user_id}
                                                    className="text-muted"
                                                    target="_blank"
                                                >
                                                    More details...
                                                </a>
                                            </Col>
                                        </Row>
                                    </div>
                                : null}
                            </div>
                        : null}
                        <hr/>
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <label>Location</label>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={this.state.locationOptions}
                                        value={this.state.locationOptions ? this.state.locationOptions.find(x => x.id === this.props.bookingLocationId) : null}
                                        placeholder={'Choose location...'}
                                        captureMenuScroll={true}
                                        controlShouldRenderValue={true}
                                        onChange={(e) => this.props.handleBookingDetailsChange(e.value, 'businessLocation')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <label>Booking Notes</label>
                                <FormGroup>
                                    <Input
                                        type="textarea"
                                        placeholder="Notes"
                                        style={{ padding: 10 }}
                                        value={this.props.bookingNotes}
                                        onChange={(e) => this.props.handleBookingDetailsChange(e, 'notes')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.props.bookingType && this.props.bookingType === 'booking' && (this.props.usage === 'edit' && this.props.bookingRecurring === true || this.props.usage === 'add') ?
                            <Row>
                                <Col md={6}>
                                    <label>Recurring Booking</label>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            options={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]}
                                            isDisabled={this.props.usage === 'edit'}
                                            value={this.props.bookingRecurring && this.props.bookingRecurring === true ? { label: 'Yes', value: 1 } : { label: 'No', value: 0 }}
                                            onChange={(e) => this.props.handleBookingDetailsChange(e.value, 'recurring')}
                                            captureMenuScroll={true}
                                            controlShouldRenderValue={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <label>Frequency</label>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            options={frequency}
                                            isDisabled={this.props.usage === 'add' && !this.props.bookingRecurring || this.props.usage === 'edit'}
                                            value={frequency.find(x => x.value === this.props.bookingRecurringFrequency)}
                                            onChange={(e) => this.props.handleBookingDetailsChange(e.value, 'recurringFrequency')}
                                            captureMenuScroll={true}
                                            controlShouldRenderValue={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <label>End Type</label>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            options={ends.filter(x => x.value !== 2)}
                                            isDisabled={!this.props.bookingRecurring}
                                            value={ends.find(x => x.value === this.props.bookingRecurringEnd)}
                                            onChange={(e) => this.props.handleBookingDetailsChange(e.value, 'recurringEnd')}
                                            captureMenuScroll={true}
                                            controlShouldRenderValue={true}
                                        />
                                    </FormGroup>
                                </Col>
                                {this.props.bookingRecurringEnd && this.props.bookingRecurringEnd === 2 ?
                                    <Col md={12}>
                                        <label>Ends</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                options={finite}
                                                isDisabled={!this.props.bookingRecurring}
                                                value={finite.find(x => x.value === this.props.bookingRecurringFinite)}
                                                onChange={(e) => this.props.handleBookingDetailsChange(e.value, 'recurringFinite')}
                                                captureMenuScroll={true}
                                                controlShouldRenderValue={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                : null}
                                {this.props.bookingRecurringEnd && this.props.bookingRecurringEnd === 3 ?
                                    <Col md={12}>
                                        <label>Ends</label>
                                        <FormGroup>
                                            <Datetime
                                                dateFormat={"dddd, Do MMM YYYY"}
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "Select Date", disabled: !this.props.bookingRecurring }}
                                                value={this.props.bookingRecurringEndDate}
                                                onChange={(e) => this.props.handleBookingDetailsChange(e, 'endDate')}
                                            />
                                        </FormGroup>
                                    </Col>
                                : null}
                            </Row>
                        : null}
                    </CardBody>
                </Card>
                <CustomerAdd
                    visible={this.state.customerAddVisible}
                    toggleVisible={this.toggleCustomerAdd}
                    onSubmit={this.handleCustomerAddSubmit}
                />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        businessLocation: state.businessLocation,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);