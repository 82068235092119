import React from "react";
import { Route, Routes } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";

import authRoutes from "routes/authRoutes";

class Auth extends React.Component {
  state = {
    filterColor: "yellow",
  };
  getRoutes = () => {
    return authRoutes.map((prop, key) => {
      const RouteComponent = prop.component;
      return (
        <Route
          path={prop.path}
          element={<RouteComponent/>}
          key={key}
        />
      );
    });
  };
  handleColorClick = (color) => {
    this.setState({ filterColor: color });
  };
  render() {
    return (
      <>
        <LoadingSpinner/>
        <div className="wrapper wrapper-full-page">
          <AuthNavbar {...this.props} />
          <Routes>
            {this.getRoutes()}
          </Routes>
          <Footer fluid default />
        </div>
      </>
    );
  }
}

export default Auth;
