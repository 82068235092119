import React from "react";
import moment from "moment";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    FormGroup,
    Button,
    Spinner,
    Alert
} from "reactstrap";

import Switch from "react-switch";
import Select from "react-select";
import Datetime from "react-datetime";

import time from '../../constants/time';

import Api from "api/index";

import { FiX } from 'react-icons/fi';

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

class StaffScheduleAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            selectedLocationId: null,
            startDate: moment().tz(this.props.business.timezone_name, false),
            endDate: null,
            mondayEnabled: false,
            mondayStart: null,
            mondayEnd: null,
            tuesdayEnabled: false,
            tuesdayStart: null,
            tuesdayEnd: null,
            wednesdayEnabled: false,
            wednesdayStart: null,
            wednesdayEnd: null,
            thursdayEnabled: false,
            thursdayStart: null,
            thursdayEnd: null,
            fridayEnabled: false,
            fridayStart: null,
            fridayEnd: null,
            saturdayEnabled: false,
            saturdayStart: null,
            saturdayEnd: null,
            sundayEnabled: false,
            sundayStart: null,
            sundayEnd: null,
        };
    }

    componentDidMount() {
        // Populate location options
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj,) => {
            let foundLocationMap = this.props.businessLocationStaffMap.find(x => x.staff_id === this.props.staffId && x.business_location_id === businessLocationObj.business_location_id);
            if(foundLocationMap) {
                locationOptions.push({
                    id: businessLocationObj.business_location_id,
                    label: businessLocationObj.business_location_name,
                    value: businessLocationObj.business_location_id
                });
            }
        });
        let selectedLocationId = null;
        if(locationOptions.length > 0) {
            if(this.props.locationId) {
                selectedLocationId = this.props.locationId;
            } else {
                selectedLocationId = locationOptions[0].id;
            }
        }
        this.setState({ locationOptions, selectedLocationId });
    }

    async submitSchedule() {
        if(this.state.loading) {
            return;
        }
        // Validate location
        if(!this.state.selectedLocationId) {
            this.setState({ error: 'No location selected.' });
            return;
        }
        // Validate date range
        if(!this.state.startDate || !moment.isMoment(this.state.startDate)) {
            this.setState({ error: 'A start date is required.' });
            return;
        }
        if(this.state.endDate && !moment.isMoment(this.state.endDate)) {
            this.setState({ error: 'An end date is required.' });
            return;
        }
        if(this.state.endDate && this.state.startDate.isSameOrAfter(this.state.endDate, 'day')) {
            this.setState({ error: 'The start date must be before the end date.' });
            return;
        }
        // Validate Monday
        if(this.state.mondayEnabled) {
            if(!this.state.mondayStart || !moment.isMoment(this.state.mondayStart)) {
                this.setState({ error: 'A valid start time is required on Monday.' });
                return;
            }
            if(!this.state.mondayEnd || !moment.isMoment(this.state.mondayEnd)) {
                this.setState({ error: 'A valid end time is required on Monday.' });
                return;
            }
            if(this.state.mondayStart.isSameOrAfter(this.state.mondayEnd, 'minute')) {
                this.setState({ error: 'The start time must be before the end time on Monday.' });
                return;
            }
        }
        // Validate Tuesday
        if(this.state.tuesdayEnabled) {
            if(!this.state.tuesdayStart || !moment.isMoment(this.state.tuesdayStart)) {
                this.setState({ error: 'A valid start time is required on Tuesday.' });
                return;
            }
            if(!this.state.tuesdayEnd || !moment.isMoment(this.state.tuesdayEnd)) {
                this.setState({ error: 'A valid end time is required on Tuesday.' });
                return;
            }
            if(this.state.tuesdayStart.isSameOrAfter(this.state.tuesdayEnd, 'minute')) {
                this.setState({ error: 'The start time must be before the end time on Tuesday.' });
                return;
            }
        }
        // Validate Wednesday
        if(this.state.wednesdayEnabled) {
            if(!this.state.wednesdayStart || !moment.isMoment(this.state.wednesdayStart)) {
                this.setState({ error: 'A valid start time is required on Wednesday.' });
                return;
            }
            if(!this.state.wednesdayEnd || !moment.isMoment(this.state.wednesdayEnd)) {
                this.setState({ error: 'A valid end time is required on Wednesday.' });
                return;
            }
            if(this.state.wednesdayStart.isSameOrAfter(this.state.wednesdayEnd, 'minute')) {
                this.setState({ error: 'The start time must be before the end time on Wednesday.' });
                return;
            }
        }
        // Validate Thursday
        if(this.state.thursdayEnabled) {
            if(!this.state.thursdayStart || !moment.isMoment(this.state.thursdayStart)) {
                this.setState({ error: 'A valid start time is required on Thursday.' });
                return;
            }
            if(!this.state.thursdayEnd || !moment.isMoment(this.state.thursdayEnd)) {
                this.setState({ error: 'A valid end time is required on Thursday.' });
                return;
            }
            if(this.state.thursdayStart.isSameOrAfter(this.state.thursdayEnd, 'minute')) {
                this.setState({ error: 'The start time must be before the end time on Thursday.' });
                return;
            }
        }
        // Validate Friday
        if(this.state.fridayEnabled) {
            if(!this.state.fridayStart || !moment.isMoment(this.state.fridayStart)) {
                this.setState({ error: 'A valid start time is required on Friday.' });
                return;
            }
            if(!this.state.fridayEnd || !moment.isMoment(this.state.fridayEnd)) {
                this.setState({ error: 'A valid end time is required on Friday.' });
                return;
            }
            if(this.state.fridayStart.isSameOrAfter(this.state.fridayEnd, 'minute')) {
                this.setState({ error: 'The start time must be before the end time on Friday.' });
                return;
            }
        }
        // Validate Saturday
        if(this.state.saturdayEnabled) {
            if(!this.state.saturdayStart || !moment.isMoment(this.state.saturdayStart)) {
                this.setState({ error: 'A valid start time is required on Saturday.' });
                return;
            }
            if(!this.state.saturdayEnd || !moment.isMoment(this.state.saturdayEnd)) {
                this.setState({ error: 'A valid end time is required on Saturday.' });
                return;
            }
            if(this.state.saturdayStart.isSameOrAfter(this.state.saturdayEnd, 'minute')) {
                this.setState({ error: 'The start time must be before the end time on Saturday.' });
                return;
            }
        }
        // Validate Sunday
        if(this.state.sundayEnabled) {
            if(!this.state.sundayStart || !moment.isMoment(this.state.sundayStart)) {
                this.setState({ error: 'A valid start time is required on Sunday.' });
                return;
            }
            if(!this.state.sundayEnd || !moment.isMoment(this.state.sundayEnd)) {
                this.setState({ error: 'A valid end time is required on Sunday.' });
                return;
            }
            if(this.state.sundayStart.isSameOrAfter(this.state.sundayEnd, 'minute')) {
                this.setState({ error: 'The start time must be before the end time on Sunday.' });
                return;
            }
        }
        this.setState({ loading: true, error: null });
        let data = {
            staff_id: this.props.staffId,
            business_location_id: this.state.selectedLocationId,
            schedule_start: this.state.startDate.format('YYYY-MM-DD'),
            schedule_end: this.state.endDate ? this.state.endDate.format('YYYY-MM-DD') : null,
            monday: this.state.mondayEnabled,
            monday_start: this.state.mondayEnabled ? this.state.mondayStart.format('HH:mm') : "00:00",
            monday_end: this.state.mondayEnabled ? this.state.mondayEnd.format('HH:mm') : "00:00",
            tuesday: this.state.tuesdayEnabled,
            tuesday_start: this.state.tuesdayEnabled ? this.state.tuesdayStart.format('HH:mm') : "00:00",
            tuesday_end: this.state.tuesdayEnabled ? this.state.tuesdayEnd.format('HH:mm') : "00:00",
            wednesday: this.state.wednesdayEnabled,
            wednesday_start: this.state.wednesdayEnabled ? this.state.wednesdayStart.format('HH:mm') : "00:00",
            wednesday_end: this.state.wednesdayEnabled ? this.state.wednesdayEnd.format('HH:mm') : "00:00",
            thursday: this.state.thursdayEnabled,
            thursday_start: this.state.thursdayEnabled ? this.state.thursdayStart.format('HH:mm') : "00:00",
            thursday_end: this.state.thursdayEnabled ? this.state.thursdayEnd.format('HH:mm') : "00:00",
            friday: this.state.fridayEnabled,
            friday_start: this.state.fridayEnabled ? this.state.fridayStart.format('HH:mm') : "00:00",
            friday_end: this.state.fridayEnabled ? this.state.fridayEnd.format('HH:mm') : "00:00",
            saturday: this.state.saturdayEnabled,
            saturday_start: this.state.saturdayEnabled ? this.state.saturdayStart.format('HH:mm') : "00:00",
            saturday_end: this.state.saturdayEnabled ? this.state.saturdayEnd.format('HH:mm') : "00:00",
            sunday: this.state.sundayEnabled,
            sunday_start: this.state.sundayEnabled ? this.state.sundayStart.format('HH:mm') : "00:00",
            sunday_end: this.state.sundayEnabled ? this.state.sundayEnd.format('HH:mm') : "00:00"
        };
        try {
            await Api.addStaffSchedule(data);
            this.props.triggerNotification("Staff schedule added successfully.", "success", "bc", 4);
            if(this.props.onSubmit) {
                this.props.onSubmit();
            }
        } catch(e) {
            if(e.response && e.response.data && e.response.data.message) {
                this.setState({ loading: false, error: e.response.data.message + "."});
            } else {
                this.setState({ loading: false, error: "Unable to update staff schedule. If the problem persists, please contact us at support@styler.digital." });
            }
        }
    }

    handleLocationChange(e) {
        this.setState({ selectedLocationId: e.id });
    }

    renderInput( props, openCalendar, closeCalendar ){
        function clear(){
            props.onChange({target: {value: ''}});
        }
        return (
            <div>
                <input {...props} />
                <button onClick={openCalendar}>open calendar</button>
                <button onClick={closeCalendar}>close calendar</button>
                <button onClick={clear}>clear</button>
            </div>
        );
    }

    renderEndDateInput(props,) {
        return (
            <input {...props} value={this.state.endDate ? props.value : "Ongoing"} />
        );
    }

    render() {
        let staffObj = null;
        if(this.props.staffId) {
            staffObj = this.props.staff.find(x => x.id === this.props.staffId);
        }
        return (
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Add Schedule For {staffObj ? staffObj.firstname : null}</ModalHeader>
                <ModalBody>
                    {this.state.sweetAlert}
                    <Row>
                        {this.props.businessLocation.filter(x => x.enabled === 1).length > 1 ?
                            <Col xs={12} sm={12} md={12}>
                                <label>Location</label>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={this.state.locationOptions}
                                        value={this.state.locationOptions ? this.state.locationOptions.find(x => x.id === this.state.selectedLocationId) : null}
                                        placeholder={'Choose location...'}
                                        onChange={(e) => this.handleLocationChange(e)}
                                        captureMenuScroll={true}
                                        controlShouldRenderValue={true}
                                    />
                                </FormGroup>
                            </Col>
                        : null}
                        <Col md={12} className="mb-3">
                            <label>Start & End Date</label>
                            <FormGroup className="date-group">
                                <Datetime
                                    className="date-input date-input-left"
                                    dateFormat={"ddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "Start Date", readonly: "readonly" }}
                                    value={this.state.startDate ? this.state.startDate : null}
                                    onChange={(e) => this.setState({ startDate: e })}
                                />
                                <Datetime
                                    className="date-input date-input-right"
                                    dateFormat={"ddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "End Date", readonly: "readonly" }}
                                    value={this.state.endDate ? this.state.endDate : moment()}
                                    renderInput={(e) => this.renderEndDateInput(e)}
                                    onChange={(e) => this.setState({ endDate: e })}
                                />
                                {this.state.endDate ?
                                    <div style={{ position: 'absolute', right: 25, padding: 6, cursor: 'pointer' }} onClick={() => this.setState({ endDate: null })}>
                                        <FiX size={18} color={'#ed5565'}/>
                                    </div>
                                : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Monday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ mondayEnabled: e }) }}
                                        checked={this.state.mondayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            {this.state.mondayEnabled ?
                                <FormGroup className="time-group">
                                    <Select
                                        className="react-select primary time-input time-input-left"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.mondayStart ? time.find(x => x.value === this.state.mondayStart.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ mondayStart: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                    <Select
                                        className="react-select primary time-input time-input-right"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.mondayEnd ? time.find(x => x.value === this.state.mondayEnd.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ mondayEnd: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                </FormGroup>
                            : null}
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Tuesday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ tuesdayEnabled: e }) }}
                                        checked={this.state.tuesdayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            {this.state.tuesdayEnabled ?
                                <FormGroup className="time-group">
                                    <Select
                                        className="react-select primary time-input time-input-left"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.tuesdayStart ? time.find(x => x.value === this.state.tuesdayStart.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ tuesdayStart: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                    <Select
                                        className="react-select primary time-input time-input-right"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.tuesdayEnd ? time.find(x => x.value === this.state.tuesdayEnd.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ tuesdayEnd: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                </FormGroup>
                            : null}
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Wednesday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ wednesdayEnabled: e }) }}
                                        checked={this.state.wednesdayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            {this.state.wednesdayEnabled ?
                                <FormGroup className="time-group">
                                    <Select
                                        className="react-select primary time-input time-input-left"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.wednesdayStart ? time.find(x => x.value === this.state.wednesdayStart.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ wednesdayStart: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                    <Select
                                        className="react-select primary time-input time-input-right"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.wednesdayEnd ? time.find(x => x.value === this.state.wednesdayEnd.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ wednesdayEnd: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                </FormGroup>
                            : null}
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Thursday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ thursdayEnabled: e }) }}
                                        checked={this.state.thursdayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            {this.state.thursdayEnabled ?
                                <FormGroup className="time-group">
                                    <Select
                                        className="react-select primary time-input time-input-left"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.thursdayStart ? time.find(x => x.value === this.state.thursdayStart.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ thursdayStart: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                    <Select
                                        className="react-select primary time-input time-input-right"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.thursdayEnd ? time.find(x => x.value === this.state.thursdayEnd.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ thursdayEnd: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                </FormGroup>
                            : null}
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Friday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ fridayEnabled: e }) }}
                                        checked={this.state.fridayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            {this.state.fridayEnabled ?
                                <FormGroup className="time-group">
                                    <Select
                                        className="react-select primary time-input time-input-left"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.fridayStart ? time.find(x => x.value === this.state.fridayStart.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ fridayStart: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                    <Select
                                        className="react-select primary time-input time-input-right"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.fridayEnd ? time.find(x => x.value === this.state.fridayEnd.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ fridayEnd: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                </FormGroup>
                            : null}
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Saturday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ saturdayEnabled: e }) }}
                                        checked={this.state.saturdayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            {this.state.saturdayEnabled ?
                                <FormGroup className="time-group">
                                    <Select
                                        className="react-select primary time-input time-input-left"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.saturdayStart ? time.find(x => x.value === this.state.saturdayStart.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ saturdayStart: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                    <Select
                                        className="react-select primary time-input time-input-right"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.saturdayEnd ? time.find(x => x.value === this.state.saturdayEnd.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ saturdayEnd: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                </FormGroup>
                            : null}
                            <hr/>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <div className="schedule-day-name-title">Sunday</div>
                                <div className="schedule-day-switch">
                                    <Switch
                                        onChange={(e) => { this.setState({ sundayEnabled: e }) }}
                                        checked={this.state.sundayEnabled}
                                        onColor="#1ab394"
                                        offColor="#ed5565"
                                        handleDiameter={25}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </div>
                            </FormGroup>
                            {this.state.sundayEnabled ?
                                <FormGroup className="time-group">
                                    <Select
                                        className="react-select primary time-input time-input-left"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.sundayStart ? time.find(x => x.value === this.state.sundayStart.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ sundayStart: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                    <Select
                                        className="react-select primary time-input time-input-right"
                                        classNamePrefix="react-select"
                                        options={time}
                                        value={this.state.sundayEnd ? time.find(x => x.value === this.state.sundayEnd.format('HH:mm')) : null}
                                        onChange={(e) => this.setState({ sundayEnd: moment(e.value, 'HH:mm') })}
                                        placeholder={"--:--"}
                                    />
                                </FormGroup>
                            : null}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        style={{ width: '100%' }}
                        onClick={() => this.submitSchedule()}
                    >
                        {this.state.loading ? <Spinner color="success" size="sm" /> : "Add Schedule"}
                    </Button>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error ? true : false} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        );
    }

}

function mapStateToProps(state, ownProps) {
    return {
        staff: state.staff,
        business: state.business,
        businessLocation: state.businessLocation,
        businessLocationStaffMap: state.businessLocationStaffMap
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffScheduleAdd);