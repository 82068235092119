import React from "react";
import { useNavigate, useLocation } from 'react-router-dom'; 
// Cookies
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

const withRouter = WrappedComponent => props => {
    const navigate = useNavigate();
    const location = useLocation();
    React.useEffect(() => {
        // Handle auto-redirect to login page if not logged in
        if(location.pathname.split("/")[1] === "admin" && !cookies.get('session')) {
            let redirectUrl = "/auth/login-page";
            if(location.pathname !== "/admin/home") {
                redirectUrl += `?requested_url=${encodeURIComponent(location.pathname)}`;
            }
            navigate(redirectUrl);
        }
        // Handle auto-redirect to home screen if already logged in
        if(location.pathname === "/auth/login-page" && cookies.get('session')) {
            navigate("/admin/home");
        }
    }, [location, navigate]);
    return (
        <WrappedComponent
            {...props}
            {...{ navigate, location }}
        />
    );
};

export default withRouter;