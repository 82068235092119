import * as types from "../constants/actionTypes";

export default function userReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_BUSINESS_SUCCESS:
            return action.business;
        case types.UPDATE_BUSINESS_SUCCESS:
            return action.business;
        case types.CLEAR_BUSINESS_SUCCESS:
            return [];
        default:
            return state;
    };
    
};