/*eslint-disable*/
import React from "react";
// reactstrap components
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Alert, Input, FormGroup, Spinner } from "reactstrap";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import Api from 'api/index';

class ServiceCategoryEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            categoryName: null
        };
    }

    componentDidMount() {
        let categoryName = this.props.categoryId ? this.props.serviceCategory.find(x => x.service_business_category_id === this.props.categoryId).service_business_category : '';
        this.setState({ categoryName });
    }

    async submitServiceCategory() {
        try {
            if(this.state.loading) {
                return;
            }
            if(!this.state.categoryName || this.state.categoryName.length === 0){
                this.setState({ error: 'A name is required.' });
                return;
            }
            this.setState({ loading: true, error: null });
            let that = this;
            let serviceCategoryData = {
                service_business_category_id: that.props.categoryId,
                service_business_category: that.state.categoryName
            };
            await Api.editServiceCategory(serviceCategoryData);
            this.setState({ loading: false });
            this.props.actions.loadServiceCategory();
            this.props.toggleVisible();
        } catch(err) {
            console.log(err);
            this.setState({ error: 'An unexpected error has occured.', loading: false });
        }
    }

    handleInputChange(e) {
        const inputValue = e.target.value;
        this.setState({ categoryName: inputValue });
    }

    render() {
        return (
        <>
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggleVisible} className="text-center">
            <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Edit Service Category</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Input
                        placeholder="Service Category Name"
                        onChange={(e) => this.handleInputChange(e)}
                        disabled={this.state.loading}
                        defaultValue={this.state.categoryName}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={() => this.submitServiceCategory()}
                    disabled={this.state.success}
                >
                    {this.state.loading ? <Spinner color="success" size="sm" /> : "Edit Service Category"}
                </Button>
                <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                    <span>
                        {this.state.error}
                    </span>
                </Alert>
            </ModalFooter>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        serviceCategory: state.serviceCategory
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCategoryEdit);