import * as types from "../constants/actionTypes";

export default function toDoReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_TO_DO_SUCCESS:
            return action.toDo
        case types.UPDATE_TO_DO_SUCCESS:
            return [...state.filter(x => x.id !== action.toDo.id), Object.assign({}, action.toDo)].sort((a,b) => { return a.id - b.id })
        case types.ADD_TO_DO_SUCCESS:
            return [...state, action.toDo];
        case types.DELETE_TO_DO_SUCCESS:
            return [...state.filter(x => x.id !== action.toDo.id)];
        case types.CLEAR_TO_DO_SUCCESS:
            return [];
        default:
            return state;
    };
    
};