/*eslint-disable*/
import React from "react";
import moment from "moment";
// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col,
    FormGroup,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BookingService from "components/Booking/BookingService.js";
import BookingServiceAdd from "components/Booking/BookingServiceAdd.js";
import BookingDetails from "components/Booking/BookingDetails.js";
// Modal components
import BookingPayments from "components/Booking/BookingPayments";
import BookingTimeline from "components/Booking/BookingTimeline";
// Decorators
import withRouter from "utilities/withRouter";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from '../../api/index';

import durationOptions from "constants/duration";
import currency from "constants/currency";

import { FiMenu } from 'react-icons/fi';

class BookingEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user_order_id: null,
            user_order_data: null,
            user_order_uid: null,
            bookingType: 'booking',
            status: null,
            originalStatus: null,
            user_id: null,
            business_location_id: null,
            bookingDate: moment().tz(this.props.business.timezone_name, false),
            bookingServices: [],
            notes: null,
            recurring: false,
            recurring_conversion: false,
            frequency_id: null,
            end_id: null,
            finite_id: null,
            end_date: null,
            bookingPaymentsModalVisible: false,
            bookingTimelineModalVisible: false,
            sweetAlert: null
        }
        this.addBookingService = this.addBookingService.bind(this);
        this.removeBookingService = this.removeBookingService.bind(this);
        this.handleBookingDetailsChange = this.handleBookingDetailsChange.bind(this);
        this.handleBookingServiceChange = this.handleBookingServiceChange.bind(this);
        this.toggleBookingPaymentsModal = this.toggleBookingPaymentsModal.bind(this);
        this.toggleBookingTimelineModal = this.toggleBookingTimelineModal.bind(this);
    }

    async componentDidMount() {
        this.getUrlParams();
    }

    getUrlParams() {
        try {
            const query = new URLSearchParams(window.location.search);
            // User order param
            let user_order_id = query.get("user_order_id");
            let user_order_recurring = query.get("user_order_recurring");
            let recurring_conversion = query.get("recurring_conversion");
            let recurring_conversion_date = query.get("recurring_conversion_date");
            user_order_id = parseInt(user_order_id);
            if(user_order_id && Number.isInteger(user_order_id)) {
                if(user_order_recurring === 'true') {
                    if(recurring_conversion === 'true') {
                        if(!recurring_conversion_date || !/^\d{4}-\d{2}-\d{2}$/.test(recurring_conversion_date)) {
                            this.props.navigate("/admin/calendar");
                            return;
                        }
                        this.setState({ recurring_conversion: true, bookingDate: moment(recurring_conversion_date, 'YYYY-MM-DD') });
                    } else {
                        this.setState({ recurring: true });
                    }
                }
                this.setState({ user_order_id });
                this.getBookingData(user_order_id, user_order_recurring === 'true');
            } else {
                this.props.navigate("/admin/calendar");
            }
        } catch(err) {
            console.log(err);
        }
    }

    async getBookingData(user_order_id, user_order_recurring) {
        try {
            this.props.actions.loadLoadingSpinner(true);
            let response = await Api.getBooking({ user_order_id, user_order_recurring, paid_amount: true });
            let orderData = response.data.orderData;
            let orderEventData = response.data.orderEventData;
            let bookingServices = [];
            orderEventData.forEach((orderEventObj, orderEventIndex) => {
                // Find duration id
                let bookingServiceDuration = null, durationObj = null;
                if(user_order_recurring) {
                    bookingServiceDuration = moment.duration(moment(orderEventObj.end_time, 'HH:mm:ss').diff(moment(orderEventObj.start_time, 'HH:mm:ss')));
                    durationObj = durationOptions.find(x => x.value == bookingServiceDuration.asMinutes());
                } else {
                    bookingServiceDuration = moment.duration(moment(orderEventObj.end, 'YYYY-MM-DD HH:mm:ss').diff(moment(orderEventObj.start, 'YYYY-MM-DD HH:mm:ss')));
                    durationObj = durationOptions.find(x => x.value == bookingServiceDuration.asMinutes());
                }
                // Add booking service
                bookingServices.push({
                    business_event_id: user_order_recurring ? orderEventObj.business_events_recurring_id : orderEventObj.business_event_id,
                    service_detail_id: orderEventObj.service_detail_id,
                    staff_id: orderEventObj.staff_id,
                    start_time: user_order_recurring ? moment(orderEventObj.start_time, 'HH:mm:ss') : moment(orderEventObj.start, 'YYYY-MM-DD HH:mm:ss'),
                    duration_id: durationObj ? durationObj.id : null,
                    state: 0
                });
            });
            this.setState({
                user_order_id,
                user_order_data: orderData,
                user_id: orderData.user_id,
                business_location_id: orderData.business_location_id,
                user_order_uid: user_order_recurring ? orderData.user_order_recurring_uid : orderData.user_order_uid,
                status: orderEventData[0].status,
                originalStatus: orderEventData[0].status,
                bookingDate: this.state.recurring_conversion ? this.state.bookingDate : (user_order_recurring ? moment(orderEventData[0].start_date, 'YYYY-MM-DD') : moment(orderEventData[0].start, 'YYYY-MM-DD HH:mm:ss')),
                bookingServices,
                paid: response.data.paid ? response.data.paid : null,
                notes: orderEventData[0].event_notes,
                frequency_id: orderEventData[0].business_events_recurring_frequency_id,
                end_id: user_order_recurring ? (orderEventData[0].end_date ? 3 : 1)  : null,
                end_date: user_order_recurring ? moment(orderEventData[0].end_date, 'YYYY-MM-DD') : null,
                bookingType: orderEventData[0].type === "Booking" ? "booking" : "walkin"
            });
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Booking loaded successfully", "success", "bc", 4);
        } catch(err) {
            console.log(err);
            this.props.actions.loadLoadingSpinner(false);
            if(err.response?.status === 401) {
                this.props.triggerNotification("You don't have permission to view this booking.", "danger", "bc", 4);
            } else {
                this.props.triggerNotification("An unexpected error occured whilst loading the booking. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
            }
            this.props.navigate(-1);
        }
    }

    toggleBookingPaymentsModal() {
        this.setState({ bookingPaymentsModalVisible: !this.state.bookingPaymentsModalVisible });
    }

    toggleBookingTimelineModal() {
        this.setState({ bookingTimelineModalVisible: !this.state.bookingTimelineModalVisible });
    }

    addBookingService() {
        let bookingServices = [...this.state.bookingServices], newStartTime = null, staff_id = null;
        if(bookingServices.length > 0) {
            // There are multiple services - set start time of new service
            let previousBookingService = Object.assign({}, bookingServices[bookingServices.length - 1]);
            console.log(previousBookingService);
            if(previousBookingService.start_time && previousBookingService.service_detail_id) {
                // Enough data exists to be able to pre-populate the start time
                let previousBookingServiceObj = this.props.serviceDetail.find(x => x.service_business_detail_id === previousBookingService.service_detail_id)
                if(previousBookingServiceObj) {
                    newStartTime = moment(previousBookingService.start_time).add((previousBookingServiceObj.service_business_detail_duration_a + previousBookingServiceObj.service_business_detail_duration_break + previousBookingServiceObj.service_business_detail_duration_b), 'm');
                }
            }
            if(previousBookingService.staff_id) {
                staff_id = previousBookingService.staff_id;
            }
        } else if(bookingServices.length === 0) {
            // Time param
            const query = new URLSearchParams(window.location.search);
            let start_time = query.get("time");
            if(start_time && /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(start_time)) {
                newStartTime = moment(start_time, 'HH:mm');
            }
        }
        bookingServices.push({
            business_event_id: 0,
            service_detail_id: null,
            staff_id,
            start_time: newStartTime,
            duration_id: null,
            state: 1
        });
        this.setState({ bookingServices });
    }

    removeBookingService(bookingServiceObj) {
        if(this.state.bookingServices.length === 1) {
            this.props.triggerNotification("At least one service is required.", "warning", "bc", 4);
            return;
        }
        let bookingServices  = null;
        if(bookingServiceObj.state === 1) {
            bookingServices = this.state.bookingServices.filter(x => x !== bookingServiceObj);
        } else {
            let bookingServiceIndex = this.state.bookingServices.findIndex(x => x === bookingServiceObj);
            bookingServices = [...this.state.bookingServices];
            bookingServiceObj.state = 3;
            bookingServices[bookingServiceIndex] = bookingServiceObj;
        }
        this.setState({ bookingServices });
    }

    handleBookingDetailsChange(e, name) {
        if(name === 'date') {
            this.markUnchangedServicesForEdit();
            this.setState({ bookingDate: e })
        } else if(name === 'type') {
            this.setState({ bookingType: e });
        } else if(name === 'status') {
            this.markUnchangedServicesForEdit();
            this.setState({ status: e });
        } else if(name === 'user') {
            this.markUnchangedServicesForEdit();
            this.setState({ user_id: e });
        } else if(name === 'businessLocation') {
            if(this.state.business_location_id !== e) {
                this.setState({ business_location_id: e });
                // Need to mark all unedited services as now being edited to reflect location change
                this.markUnchangedServicesForEdit();
            }
        } else if(name === 'notes') {
            this.markUnchangedServicesForEdit();
            this.setState({ notes: e.target.value });
        } else if(name === 'recurring') {
            this.setState({ recurring: e === 1 ? true : false });
        } else if(name === 'recurringFrequency') {
            this.setState({ frequency_id: e });
        } else if(name === 'recurringEnd') {
            this.markUnchangedServicesForEdit();
            this.setState({ end_id: e });
        } else if(name === 'recurringFinite') {
            this.setState({ finite_id: e });
        } else if(name === 'endDate') {
            this.markUnchangedServicesForEdit();
            this.setState({ end_date: e });
        } else if(name === 'payments') {
            this.setState({ bookingPaymentsModalVisible: true });
        } else if(name === 'timeline') {
            this.setState({ bookingTimelineModalVisible: true });
        }
    }

    markUnchangedServicesForEdit() {
        // This function marks unchanged booking services for edit where a universal change is applied.
        // E.g. status, date, user, notes, recurringEnd, endDate
        let bookingServices = [...this.state.bookingServices];
        bookingServices.forEach((bookingServiceObj, bookingServiceIndex) => {
            if(bookingServiceObj.state === 0) {
                bookingServices[bookingServiceIndex].state = 2;
            }
        });
        this.setState({ bookingServices });
    }

    async handleEditBooking() {
        if(this.state.status !== this.state.originalStatus && (this.state.status === 'Cancelled' || this.state.status === 'No-show')) {
            if(this.state.status === 'Cancelled' && this.props.business.cancellation_fee_enabled === 1 && this.props.business.cancellation_fee_percentage > 0) {
                this.handleCancellationFee();
            } else if(this.state.status === 'No-show' && this.props.business.no_show_fee_enabled === 1 && this.props.business.no_show_fee_percentage > 0) {
                this.handleNoShowFee();
            } else {
                this.editBooking(false);
            }
        } else {
            this.editBooking(false);
        }
    }

    handleCancellationFee() {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        let total = 0;
        {this.state.bookingServices.map((bookingServiceObj, bookingServiceIndex) => {
            let serviceDetailObj = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingServiceObj.service_detail_id);
            if(!serviceDetailObj) {
                return null;
            }
            total = total + serviceDetailObj.service_business_detail_price;
        })}
        let feeAmount = Math.round(total * (this.props.business.cancellation_fee_percentage / 100));
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    title="Late Cancellation Fee"
                    onConfirm={() => this.editBooking(true)}
                    onCancel={() => this.editBooking(false)}
                    confirmBtnBsStyle="warning"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Would you like to charge the customer a {this.props.business.cancellation_fee_percentage}% ({currencyObj ? currencyObj.symbol : '£'}{Number(feeAmount / 100).toFixed(2)}) late cancellation fee?
                </SweetAlert>
            ),
        });
    }

    handleNoShowFee() {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        let total = 0;
        {this.state.bookingServices.map((bookingServiceObj, bookingServiceIndex) => {
            let serviceDetailObj = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingServiceObj.service_detail_id);
            if(!serviceDetailObj) {
                return null;
            }
            total = total + serviceDetailObj.service_business_detail_price;
        })}
        let feeAmount = Math.round(total * (this.props.business.no_show_fee_percentage / 100));
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    title="No-show Fee"
                    onConfirm={() => this.editBooking(true)}
                    onCancel={() => this.editBooking(false)}
                    confirmBtnBsStyle="warning"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Would you like to charge the customer a {this.props.business.no_show_fee_percentage}% ({currencyObj ? currencyObj.symbol : '£'}{Number(feeAmount / 100).toFixed(2)}) no-show fee?
                </SweetAlert>
            ),
        });
    }

    async editBooking(bookingFee = false) {
        this.setState({ sweetAlert: null });
        // Check date
        if(!this.state.bookingDate || !moment.isMoment(this.state.bookingDate)) {
            this.props.triggerNotification("No date selected", "danger", "bc", 4);
            return;
        }
        // Check type
        if(!this.state.bookingType || (this.state.bookingType !== 'booking' && this.state.bookingType !== 'walkin')){
            this.props.triggerNotification("Invalid booking type", "danger", "bc", 4);
            return;
        }
        // Validate extra booking fields
        if(this.state.bookingType === 'booking') {
            // Check user
            if(!this.state.user_id) {
                this.props.triggerNotification("No customer selected", "danger", "bc", 4);
                return;
            }
            if(this.state.recurring && !this.state.recurring_conversion) {
                // Check frequency
                if(!this.state.frequency_id) {
                    this.props.triggerNotification("A frequency is required for a recurring booking", "danger", "bc", 4);
                    return;
                }
                // Check end type
                if(!this.state.end_id) {
                    this.props.triggerNotification("An end type is required for a recurring booking", "danger", "bc", 4);
                    return;
                }
                // Check end selection
                if(this.state.end_id === 2 && !this.state.finite_id) {
                    this.props.triggerNotification("Please select the number of times the recurring booking should occur", "danger", "bc", 4);
                    return;
                } else if(this.state.end_id === 3 && !this.state.end_date) {
                    this.props.triggerNotification("Please select the end date of the recurring booking", "danger", "bc", 4);
                    return;
                } else if(this.state.end_id === 3 && this.state.end_date && this.state.bookingDate > this.state.end_date) {
                    this.props.triggerNotification("The end date must be after the start date", "danger", "bc", 4);
                    return;
                }
            }
        }
        // Validate business location
        if(!this.state.business_location_id) {
            this.props.triggerNotification("No business location is selected", "danger", "bc", 4);
            return;
        }
        // Validate booking services
        if(!Array.isArray(this.state.bookingServices) || this.state.bookingServices.length === 0) {
            this.props.triggerNotification("At least one service is required", "danger", "bc", 4);
            return;
        }
        let bookingServiceError = false;
        for (let i = 0; i < this.state.bookingServices.length; i++) {
            let bookingService = this.state.bookingServices[i];
            // Check service is selected
            if(!bookingService.service_detail_id || !Number.isInteger(bookingService.service_detail_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("No service selected on service " + (i + 1), "danger", "bc", 4);
                break;
            }
            // Check service is available at this location
            let serviceBusinessDetailData = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingService.service_detail_id);
            if(!this.props.serviceLocationMap.find(x => x.service_business_id === serviceBusinessDetailData.service_business_id && x.business_location_id === this.state.business_location_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("Service " + (i + 1) + " is not available at this location", "danger", "bc", 4);
                break;
            }
            // Check staff member is selected
            if(!bookingService.staff_id || !Number.isInteger(bookingService.staff_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("No staff member selected on service " + (i + 1), "danger", "bc", 4);
                break;
            }
            // Check staff member works at this location
            if(!this.props.businessLocationStaffMap.find(x => x.staff_id === bookingService.staff_id && x.business_location_id === this.state.business_location_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("The staff member on service " + (i + 1) + " does not work at this location", "danger", "bc", 4);
                break;   
            }
            // Check start time is selected
            if(!bookingService.start_time || !moment.isMoment(bookingService.start_time)) {
                bookingServiceError = true;
                this.props.triggerNotification("No start time selected on service " + (i + 1), "danger", "bc", 4);
                break;
            }
            // Check duration is selected
            if(!bookingService.duration_id || !Number.isInteger(bookingService.duration_id)) {
                bookingServiceError = true;
                this.props.triggerNotification("No duration selected on service " + (i + 1), "danger", "bc", 4);
                break;
            }
        }
        if(bookingServiceError === true) {
            return;
        }
        // Inputs valid - submit booking
        this.props.actions.loadLoadingSpinner(true);
        // Calculate end date if necessary
        let end_date = null;
        if(this.state.end_id === 3){
            end_date = this.state.end_date.format('YYYY-MM-DD');
        }
        let bookingServices = [];
        this.state.bookingServices.forEach((bookingServiceObj, bookingServiceIndex) => {
            let durationObj = durationOptions.find(x => x.id === bookingServiceObj.duration_id);
            let end_time = moment(bookingServiceObj.start_time).add(durationObj.value, 'm').format('HH:mm');
            bookingServices.push({
                business_event_id: bookingServiceObj.business_event_id,
                service_detail_id: bookingServiceObj.service_detail_id,
                staff_id: bookingServiceObj.staff_id,
                start_time: bookingServiceObj.start_time.format('HH:mm'),
                end_time: end_time,
                state: bookingServiceObj.state
            });
        });
        let data = {
            type: this.state.bookingType,
            user_id: this.state.bookingType === "booking" ? this.state.user_id : null,
            date: this.state.bookingDate.format('YYYY-MM-DD'),
            business_location_id: this.state.business_location_id,
            notes: this.state.notes,
            recurring: this.state.recurring_conversion ? true : this.state.recurring,
            recurring_frequency_id: this.state.frequency_id,
            recurring_end_date: end_date,
            recurring_conversion: this.state.recurring_conversion,
            services: bookingServices,
            status: this.state.status,
            user_order_id: this.state.user_order_id,
            booking_fee: bookingFee
        };
        try {
            let response = await Api.updateBooking(data);
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Booking updated successfully", "success", "bc", 4);
            if(bookingFee === true) {
                if(response.data && response.data.feeData && response.data.feeData.feeCharged === true) {
                    this.props.triggerNotification("Booking fee processed successfully.", "success", "bc", 4);
                } else {
                    if(response.data.feeData.message) {
                        this.props.triggerNotification("Booking fee failed. " + response.data.feeData.message, "danger", "bc", 8);
                    }
                }
            }
            this.props.navigate("/admin/calendar?date=" + this.state.bookingDate.format('YYYY-MM-DD') + "&time=" + this.state.bookingServices[0].start_time.format('HH:mm'));
        } catch(e) {
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("An unexpected error occured whilst trying to add the booking. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
        }
    }

    handleBookingServiceChange(e, name, index) {
        let bookingServices = [...this.state.bookingServices];
        if(name === 'service') {
            bookingServices[index].service_detail_id = e.value;
            // Attribute correct duration
            let bookingServiceData = this.props.serviceDetail.find(x => x.service_business_detail_id === e.value);
            if(bookingServiceData) {
                let durationOption = durationOptions.find(x => x.value === (bookingServiceData.service_business_detail_duration_a + bookingServiceData.service_business_detail_duration_break + bookingServiceData.service_business_detail_duration_b));
                bookingServices[index].duration_id = durationOption.id;
            }
        } else if(name === 'staff') {
            bookingServices[index].staff_id = e.value;
        } else if(name === 'startTime') {
            bookingServices[index].start_time = e;
        } else if(name === 'duration') {
            bookingServices[index].duration_id = e.id;
        }
        if(bookingServices[index].state === 0) {
            bookingServices[index].state = 2;
        }
        this.setState({ bookingServices });
    }

    renderTotalRow() {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        let total = 0;
        const paid = this.state.paid ? this.state.paid : 0;
        this.state.bookingServices.forEach((bookingServiceObj, bookingServiceIndex) => {
            let bookingServiceData = this.props.serviceDetail.find(x => x.service_business_detail_id === bookingServiceObj.service_detail_id);
            if(bookingServiceData) {
                // Total
                total += bookingServiceData.service_business_detail_price;
            }
        });
        return (
            <div className="total-container">
                <Row>
                    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                        <div className="total-container-row">
                            <div className="text-bold">Total:</div>
                            <div>{total && total > 0 ? (currencyObj ? currencyObj.symbol : '£') + Number(total / 100).toFixed(2) : 'FREE'}</div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                        <div className="total-container-row" style={{ cursor: 'pointer' }} onClick={() => this.setState({ bookingPaymentsModalVisible: true })}>
                            <div className="text-bold">Paid:</div>
                            <div>{this.state.paid > 0 ? (currencyObj ? currencyObj.symbol : '£') + Number(paid / 100).toFixed(2) : 'N/A'}</div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                        <div className="total-container-row">
                            <div className="text-bold">Due:</div>
                            <div>{total > 0 ? (currencyObj ? currencyObj.symbol : '£') + Number((total - paid) / 100).toFixed(2) : 'FREE'}</div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                        <Button
                            color="primary"
                            style={{ width: '100%', margin: 0 }}
                            onClick={() => this.handleEditBooking()}
                        >
                            Save Changes
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        return (
        <>
            <PanelHeader size="sm"/>
            <div className="content" style={{ paddingBottom: 0 }}>
                <Row>
                    <Col lg={4} md={12} sm={12} xs={12}>
                        <BookingDetails
                            handleBookingDetailsChange={this.handleBookingDetailsChange}
                            bookingUid={this.state.user_order_uid}
                            bookingType={this.state.bookingType}
                            bookingStatus={this.state.status}
                            bookingUserId={this.state.user_id}
                            bookingLocationId={this.state.business_location_id}
                            bookingNotes={this.state.notes}
                            bookingRecurring={this.state.recurring}
                            bookingRecurringConversion={this.state.recurring_conversion}
                            bookingRecurringFrequency={this.state.frequency_id}
                            bookingRecurringEnd={this.state.end_id}
                            bookingRecurringFinite={this.state.finite_id}
                            bookingRecurringEndDate={this.state.end_date}
                            triggerNotification={this.props.triggerNotification}
                            usage={'edit'}
                        />
                    </Col>
                    <Col lg={7} md={12} sm={12} xs={12} className="ms-auto me-auto">
                        <div style={{ display: 'flex' }}>
                            <Card className="booking-date-card">
                                <CardBody>
                                    <Datetime
                                        dateFormat={"dddd, Do MMM YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: "Select Date", style: { border: 0, fontSize: '1.2em' } }}
                                        value={this.state.bookingDate ? this.state.bookingDate : moment().tz(this.props.business.timezone_name, false)}
                                        onChange={(e) => this.handleBookingDetailsChange(e, 'date')}
                                    />
                                </CardBody>
                            </Card>
                            {!this.state.recurring && !this.state.recurring_conversion ?
                                <div>
                                    <UncontrolledDropdown style={{ height: '100%' }}>
                                        <DropdownToggle caret className="booking-options-dropdown">
                                            <FiMenu size={20}/>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            <DropdownItem onClick={() => this.setState({ bookingPaymentsModalVisible: true })}>Payments</DropdownItem>
                                            <DropdownItem onClick={() => this.setState({ bookingTimelineModalVisible: true })}>Timeline</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            : null}
                        </div>
                        <Card className="card-timeline card-plain">
                            <CardBody style={{ padding: 0 }}>
                                <ul className="timeline timeline-simple timeline-booking">
                                    {this.state.bookingServices.filter(x => x.state !== 3).map((bookingServiceObj, bookingServiceIndex) => {
                                        return (
                                            <BookingService
                                                index={bookingServiceIndex}
                                                removeBookingService={this.removeBookingService}
                                                handleBookingServiceChange={this.handleBookingServiceChange}
                                                bookingService={bookingServiceObj}
                                                serviceId={bookingServiceObj.service_detail_id}
                                                staffId={bookingServiceObj.staff_id}
                                                locationId={this.state.business_location_id}
                                                startTime={bookingServiceObj.start_time}
                                                durationId={bookingServiceObj.duration_id}
                                            />
                                        );
                                    })}
                                    <BookingServiceAdd addBookingService={this.addBookingService}/>
                                </ul>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                {this.renderTotalRow()}
                            </Col>
                            {!this.state.recurring && !this.state.recurring_conversion ?
                                <Col md={12}>
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => this.setState({ bookingPaymentsModalVisible: true })}
                                    >
                                        View Booking Payments
                                    </Button>
                                </Col>
                            : null}
                        </Row>
                    </Col>
                </Row>
            </div>
            {this.state.bookingPaymentsModalVisible ?
                <BookingPayments
                    visible={this.state.bookingPaymentsModalVisible}
                    toggleVisible={this.toggleBookingPaymentsModal}
                    userOrderId={this.state.user_order_id}
                    currencySymbol={currencyObj ? currencyObj.symbol : '£'}
                />
            : null}
            {this.state.bookingTimelineModalVisible ?
                <BookingTimeline
                    visible={this.state.bookingTimelineModalVisible}
                    toggleVisible={this.toggleBookingTimelineModal}
                    userOrderData={this.state.user_order_data}
                    bookingServices={this.state.bookingServices}
                    status={this.state.status}
                    bookingType={this.state.bookingType}
                />
            : null}
            {this.state.sweetAlert}
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        businessLocation: state.businessLocation,
        businessLocationStaffMap: state.businessLocationStaffMap,
        serviceDetail: state.serviceDetail,
        serviceLocationMap: state.serviceLocationMap
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingEdit));