import * as types from "../constants/actionTypes";

export default function calendarSettingsReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_CALENDAR_SETTINGS_SUCCESS:
            return action.calendar_settings;
        default:
            return state;
    };
    
};