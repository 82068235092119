import React from "react";

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { FiEdit2, FiMoreHorizontal } from 'react-icons/fi';

import Api from '../../api/index';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ServiceCategoryAdd from "components/Services/ServiceCategoryAdd.js";
import ServiceCategoryEdit from "components/Services/ServiceCategoryEdit.js";
// constants
import currency from "constants/currency";
// Decorators
import withRouter from "utilities/withRouter";

class Services extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            serviceCategoryAddVisible: false,
            serviceCategoryEditVisible: false,
            serviceCategoryEditId: null,
            alert : null
        };
        this.toggleServiceCategoryAddVisible = this.toggleServiceCategoryAddVisible.bind(this);
        this.toggleServiceCategoryEditVisible = this.toggleServiceCategoryEditVisible.bind(this);
    }

    componentDidMount() {
        this.props.actions.loadServiceCategory();
        this.props.actions.loadService();
    }

    toggleServiceCategoryAddVisible() {
        this.setState({ serviceCategoryAddVisible: !this.state.serviceCategoryAddVisible });
    }

    toggleServiceCategoryEditVisible() {
        this.setState({ serviceCategoryEditVisible: !this.state.serviceCategoryEditVisible, serviceCategoryEditId: this.state.serviceCategoryEditId ? null : this.state.serviceCategoryEditId });
    }

    deleteServiceCategory(service_business_category_id) {
        let categoryServices = this.props.service.filter(x => x.service_business_category_id === service_business_category_id && x.enabled === 1);
        console.log(categoryServices);
        if(categoryServices.length > 0){
            this.props.triggerNotification("Please reassign the services to another category before deleting this category.", "danger", "bc", 8);
            return;
        }
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    title="Are you sure?"
                    onConfirm={() => this.confirmDeleteServiceCategory(service_business_category_id)}
                    onCancel={() => this.setState({ alert: null })}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Are you sure that you want to delete this service category?
                </SweetAlert>
            )
        })
    }

    async confirmDeleteServiceCategory(service_business_category_id) {
        try {
            this.props.actions.loadLoadingSpinner(true);
            this.setState({ alert: null });
            let serviceCategoryData = {
                service_business_category_id
            };
            await Api.deleteServiceCategory(serviceCategoryData);
            this.props.actions.loadLoadingSpinner(false);
            this.props.actions.loadServiceCategory();
            this.props.triggerNotification("Service category deleted.", "success", "bc", 4);
        } catch(err) {
            console.log(err);
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("An unexpected error occured. Please contact Support if the problem persists.", "danger", "bc", 8);
            return;
        }
    }

    render() {
        let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        // Access permissions
        const addPermission = this.props.userRole?.service?.create === true;
        const editPermission = this.props.userRole?.service?.update === true;
        const deletePermission = this.props.userRole?.service?.delete === true;
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {this.state.alert}
                <Row>
                    <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                    {this.props.serviceCategory && this.props.serviceCategory.length > 0 ?
                        this.props.serviceCategory.map((categoryObj, categoryIndex) => {
                        let categoryServices = this.props.service.filter(x => x.service_business_category_id === categoryObj.service_business_category_id && x.enabled === 1);
                        return (
                            <Card key={'serviceCategory' + categoryIndex}>
                                <CardHeader className="service-category-container">
                                    <CardTitle tag="h4" className="service-category-title">{categoryObj.service_business_category}</CardTitle>
                                    {(addPermission || editPermission || deletePermission) && (
                                        <div style={{ margin: 'auto 0px' }}>
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    color="primary"
                                                    className="btn-icon"
                                                    size="sm"
                                                    id={'addCategoryServiceBtn' + categoryObj.service_business_category_id}
                                                    style={{ margin: 0 }}
                                                    outline
                                                >
                                                    <FiMoreHorizontal style={{ marginBottom: 3 }}/>
                                                </DropdownToggle>
                                                <DropdownMenu end>
                                                    {addPermission && <DropdownItem onClick={() => this.props.navigate('/admin/add_service?service_business_category_id=' + categoryObj.service_business_category_id)}>Add Service</DropdownItem>}
                                                    {addPermission && <DropdownItem divider />}
                                                    {editPermission && <DropdownItem onClick={() => this.setState({ serviceCategoryEditVisible: true, serviceCategoryEditId: categoryObj.service_business_category_id })}>Edit Category</DropdownItem>}
                                                    {deletePermission && <DropdownItem onClick={() => this.deleteServiceCategory(categoryObj.service_business_category_id)} className="text-danger">Delete Category</DropdownItem>}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    )}
                                </CardHeader>
                                <CardBody>
                                    <div className="service-container">
                                        {categoryServices && categoryServices.length > 0 ?
                                            categoryServices.map((serviceObj, serviceIndex) => {
                                                let serviceDetail = this.props.serviceDetail.filter(x => x.service_business_id === serviceObj.service_id && x.service_business_detail_enabled === 1);
                                                return (
                                                    <div className="service-row-container" key={'serviceRow' + serviceIndex}>
                                                        <div className="service-row-flex-container">
                                                            <div className={"service-row-title" + (serviceDetail.length === 1 ? " service-row-title-info" : " service-row-info")}>{serviceObj.service_name.replace('%comma%', ',').replace('%apostrophe%', "'")}</div>
                                                            <div className="service-row-muted service-row-title-info">
                                                                {serviceDetail.length === 1 ? (serviceDetail[0].service_business_detail_split === 1 ? serviceDetail[0].service_business_detail_duration_a + serviceDetail[0].service_business_detail_duration_break + serviceDetail[0].service_business_detail_duration_b: serviceDetail[0].service_business_detail_duration_a) + ' mins' : null} 
                                                            </div>
                                                            <div className="service-row-muted service-row-title-info">{serviceDetail.length === 1 ? (serviceDetail[0].service_business_detail_poa ? 'POA' : (currencyObj ? currencyObj.symbol : '£') + Number(serviceDetail[0].service_business_detail_price / 100).toFixed(2)) : null}</div>
                                                            <div>
                                                                <Button
                                                                    className="btn-icon"
                                                                    color="primary"
                                                                    size="sm"
                                                                    id={'editServiceBtn' + serviceObj.service_id}
                                                                    style={{ margin: 0 }}
                                                                    onClick={() => this.props.navigate('/admin/edit_service?service_business_id=' + serviceObj.service_id)}
                                                                >
                                                                    <FiEdit2 style={{ marginBottom: 3 }}/>
                                                                    <UncontrolledTooltip
                                                                        delay={0}
                                                                        target={'editServiceBtn' + serviceObj.service_id}
                                                                    >
                                                                        Edit
                                                                    </UncontrolledTooltip>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {serviceDetail.length > 1 ? serviceDetail.map((serviceDetailObj, serviceDetailIndex) => {
                                                            return(
                                                                <div className="service-row-flex-container service-row-muted">
                                                                    <div className="service-row-tier-item service-row-tier-title">{serviceDetailObj.service_business_detail_name.replace('%comma%', ',').replace('%apostrophe%', "'")}</div>
                                                                    <div className="service-row-tier-item">
                                                                        {serviceDetailObj.service_business_detail_split === 1 ? serviceDetailObj.service_business_detail_duration_a + serviceDetailObj.service_business_detail_duration_break + serviceDetailObj.service_business_detail_duration_b: serviceDetailObj.service_business_detail_duration_a} mins
                                                                    </div>
                                                                    <div className="service-row-tier-item">{serviceDetailObj.service_business_detail_poa === 1 ? 'POA' : (currencyObj ? currencyObj.symbol : '£') + Number(serviceDetailObj.service_business_detail_price / 100).toFixed(2)}</div>
                                                                    <div className="service-row-tier-item" style={{ minWidth: 30 }}></div>
                                                                </div>
                                                            );
                                                        }) : null}
                                                    </div>
                                                );
                                            }) :
                                            <div className="text-center"> 
                                                <p style={{ marginBottom: 0, marginTop: 10 }}>There are no services assigned to this category.</p>
                                                <Button color="primary" onClick={() => this.props.navigate('/admin/add_service?service_business_category_id=' + categoryObj.service_business_category_id)} size="sm" disabled={!addPermission}>
                                                    Add Service
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        );
                    }) :
                        <Card>
                            <CardBody className="text-center">
                                <h2 style={{ marginBottom: 15 }}><i className="now-ui-icons design_bullet-list-67" /></h2>
                                <h5>Get started by adding your first service category</h5>
                                {addPermission && (
                                    <Button color="primary" onClick={() => this.toggleServiceCategoryAddVisible()}>
                                        Add Service Category
                                    </Button>
                                )}
                            </CardBody>
                        </Card>
                    }
                    </Col>
                </Row>
                {this.props.serviceCategory && this.props.serviceCategory.length > 0 ? 
                    <Row>
                        <Col md={12} className="ms-auto me-auto text-center">
                            {addPermission && (
                                <Button color="primary" className="btn-round" outline onClick={() => this.toggleServiceCategoryAddVisible()}>
                                    <i className="now-ui-icons ui-1_simple-add" /> Add Service Category
                                </Button>
                            )}
                        </Col>
                    </Row>
                : null}
                <ServiceCategoryAdd isOpen={this.state.serviceCategoryAddVisible} toggleVisible={this.toggleServiceCategoryAddVisible} />
                {this.state.serviceCategoryEditId ? <ServiceCategoryEdit isOpen={this.state.serviceCategoryEditVisible} toggleVisible={this.toggleServiceCategoryEditVisible} categoryId={this.state.serviceCategoryEditId} /> : null}
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        user: state.user,
        userRole: state.userRole,
        serviceCategory: state.serviceCategory,
        service: state.service,
        serviceDetail: state.serviceDetail
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Services));