import React from "react";
// reactstrap components
import {
    Row,
    Col,
    Button
} from "reactstrap";
import { motion } from "framer-motion";
import CountUp from 'react-countup';
import { FiImage, FiPlus } from 'react-icons/fi';

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { CDN_URL } from "constants/urls";
import withRouter from "utilities/withRouter";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ImageCarousel from "components/ImageCarousel/ImageCarousel.js";
import Api from '../../api/index';

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            galleryData: [],
            galleryDataPage: 1,
            gallerySelectedImageIndex: 2,
            galleryCarouselVisible: false
        };
        this.toggleGalleryCarouselVisible = this.toggleGalleryCarouselVisible.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
    }

    componentDidMount() {
        this.getGalleryData();
    }

    async getGalleryData() {
        try {
            this.props.actions.loadLoadingSpinner(true);
            let response = await Api.getGalleryImages();
            let galleryData = response.data.galleryData;
            this.setState({ galleryData });
            this.props.actions.loadLoadingSpinner(false);
        } catch(err) {
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("An unexpected error occured whilst loading your gallery images. If the problem persists, please contact us at support@styler.digital.", "danger", "bc", 4);
        }
    }

    loadNextGalleryDataPage() {
        let nextPage = this.state.galleryDataPage + 1;
        this.setState({ galleryDataPage: nextPage })
    }

    toggleGalleryCarouselVisible() {
        this.setState({ galleryCarouselVisible: !this.state.galleryCarouselVisible })
    }

    handleDeleteImage(galleryImageId) {
        let galleryData = [...this.state.galleryData];
        this.setState({ galleryCarouselVisible: !this.state.galleryCarouselVisible, galleryData: galleryData.filter(x => x.business_gallery_id !== galleryImageId) });
    }

    renderGalleryCard(galleryObj, galleryIndex) {
        const galleryCardAnim = {
            hidden: { y: 30, opacity: 0 },
            visible: {
                y: 0,
                opacity: 1,
                delay: galleryIndex
            },
        };
        return (
            <motion.div variants={galleryCardAnim} initial="hidden" animate="visible" transition={{ delay: Number(galleryIndex / 20) }}>
                <div
                    className="gallery-image-container"
                    onClick={() => this.setState({ galleryCarouselVisible: true, gallerySelectedImageIndex: galleryIndex })}
                    style={{ backgroundImage: `url(${CDN_URL}/` + galleryObj.business_gallery_img + ")" }}
                />
            </motion.div>
        );
    }

    render() {
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content" style={{ marginTop: -55 }}>
                <Row>
                    <Col xs={12} lg={10} xl={8} className="ms-auto me-auto" style={{ marginBottom: 30 }}>
                        <div className="staff-header-row">
                            <div>
                                <div style={{ fontSize: 20, lineHeight: 3, color: this.props.business.hub_header_gradient_start_text ? this.props.business.hub_header_gradient_start_text : '#ffffff' }}>
                                    {this.state.galleryData.length > 0 ?
                                        <CountUp start={0} end={this.state.galleryData.length} duration={1}/>
                                    : 0} Images
                                </div>
                            </div>
                            {this.props.userRole.gallery?.create === true && (
                                <div>
                                    <Button
                                        outline
                                        color="default"
                                        style={{ color: this.props.business.hub_header_gradient_end_text ? this.props.business.hub_header_gradient_end_text : '#ffffff' }}
                                        onClick={() => this.props.navigate("/admin/add_gallery_image")}
                                    >
                                        <FiPlus size={16} color={'#1ab394'}/> Add Image
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                        {this.state.galleryData && this.state.galleryData.length > 0 ?
                            <Row>
                                {this.state.galleryData.slice(0, 16 * this.state.galleryDataPage).map((galleryObj, galleryIndex) => {
                                    return (
                                        <Col lg={3} md={3} sm={4} xs={12}>
                                            {this.renderGalleryCard(galleryObj, galleryIndex)}
                                        </Col>
                                    );
                                })}
                            </Row>
                        :
                            <Row>
                                <Col md={12}>
                                    <div className="gallery-empty-container">
                                        <div className="centered-container text-center">
                                            <div style={{ marginBottom: 15 }}>
                                                <FiImage size={40}/>
                                            </div>
                                            <h5>Your gallery is empty</h5>
                                            <Button color="primary" onClick={() => this.props.navigate("/admin/add_gallery_image")}>
                                                Add Gallery Image
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {this.state.galleryData && this.state.galleryData.length > this.state.galleryDataPage * 16 ?
                            <Row>
                                <Col md={12} className="ms-auto me-auto text-center">
                                    <Button color="primary" className="btn-round" outline style={{ margin: 0 }} onClick={() => this.loadNextGalleryDataPage()}>Load more...</Button>
                                </Col>
                            </Row>
                        : null}
                    </Col>
                </Row>
            </div>
            <ImageCarousel
                visible={this.state.galleryCarouselVisible}
                toggleVisible={this.toggleGalleryCarouselVisible}
                submitDeleteImage={this.handleDeleteImage}
                imageData={this.state.galleryData}
                selectedIndex={this.state.gallerySelectedImageIndex}
                navigate={this.props.navigate}
                triggerNotification={this.props.triggerNotification}
            />
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Gallery));