import { useState } from 'react';
// MUI components
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
// Firebase
import { getAuth, signInWithEmailAndPassword, multiFactor, TotpMultiFactorGenerator } from "firebase/auth";
import { firebaseApp } from 'firebaseProvider/config';
// Components
import Modal from "components/Material/Modal";
import MFAStepAuth from './MFAStepAuth';
import MFAStepApp from './MFAStepApp';
import MFAStepEnroll from './MFAStepEnroll';
import MFAStepConfirm from './MFAStepConfirm';
import MFAStepComplete from './MFAStepComplete';

function MFAWizard(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [totpUri, setTotpUri] = useState(null);
    const [totpSecret, setTotpSecret] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [otp, setOtp] = useState('');

    const confirmPassword = async (e)  => {
        const auth = getAuth(firebaseApp);
        try {
            const { user } = await signInWithEmailAndPassword(auth, props.user.email, password);
            setPassword(null);
            setUser(user);
            return true;
        } catch(err) {
            switch(err.code) {
                case 'auth/missing-password':
                    setError('Your password is required');
                    break;
                case 'auth/invalid-email':
                    setError('Invalid email address');
                    break;
                case 'auth/user-disabled':
                    setError('Account disabled');
                    break;
                case 'auth/wrong-password':
                    setError('Incorrect password');
                    break;
                case 'auth/too-many-requests':
                    setError('Too many unsuccessful login attempts. Please try again later.')
                default:
                    console.log(err);
                    setError("Something went wrong - we weren't able to confirm your password.");
                    break;
            }
        }
    }

    const generateTotpSecret = async () => {
        // Generate a TOTP secret.
        const multiFactorSession = await multiFactor(user).getSession();
        const totpSecret = await TotpMultiFactorGenerator.generateSecret(
            multiFactorSession
        );
        const totpUri = totpSecret.generateQrCodeUrl(
            user.email,
            "Styler Hub"
        );
        setTotpUri(totpUri);
        setTotpSecret(totpSecret);
    }

    const confirmOtp = async (finalOtp) => {
        try {
            const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
                totpSecret,
                finalOtp
            );
            await multiFactor(user).enroll(multiFactorAssertion, "AuthenticatorTotp");
            return true;
        } catch(e) {
            setError('Invalid or expired code.');
            return false;
        }
    }

    const nextHandler = async (e) => {
        setLoading(true);
        let valid;
        switch (activeStep) {
            case 0:
                valid = await confirmPassword();
                break;
            case 1:
                await generateTotpSecret();
                valid = true;
                break;
            case 2:
                valid = true;
                break;
            case 3:
                valid = await confirmOtp(e);
                break;
            default:
                break;
        }

        setLoading(false);
        if (!valid) return;
        if(error) setError(null);
        setActiveStep(activeStep + 1);
    }

    const steps = [
        {
            label: 'Begin',
            stepContent: () => <MFAStepAuth password={password} setPassword={setPassword} />
        },
        {
            label: 'Download',
            stepContent: () => <MFAStepApp/>
        },
        {
            label: 'Enroll',
            stepContent: () => <MFAStepEnroll totpSecret={totpSecret.secretKey} totpUri={totpUri} />
        },
        {
            label: 'Confirm',
            stepContent: () => <MFAStepConfirm otp={otp} setOtp={setOtp} nextHandler={nextHandler}/>
        },
        {
            label: 'Done',
            stepContent: () => <MFAStepComplete/>
        }
    ];
    
    return (
        <Modal
            open={props.isVisible}
            onClose={props.setVisible}
            fullWidth
            maxWidth="sm"
        >
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((stepObj, index) => {
                         return (
                            <Step key={stepObj.label} completed={index <= activeStep}>
                                <StepLabel>{stepObj.label}</StepLabel>
                            </Step>
                         )
                    })}
                </Stepper>
                <hr/>
                <Box sx={{ minHeight: 400 }}>
                    {steps[activeStep].stepContent()}
                    {error && <Box sx={{ mt: 2 }}><Alert severity="error">{error}</Alert></Box>}
                </Box>
                {activeStep !== (steps.length - 1) && (
                    <>
                        <hr/>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                {activeStep > 1 && activeStep && <Button disabled={loading} variant="contained" onClick={() => setActiveStep(activeStep - 1)}>Previous</Button>}
                            </div>
                            <div>
                                {activeStep < (steps.length - 2) && <LoadingButton variant="contained" color="primary" loading={loading} onClick={nextHandler}>Next</LoadingButton>}
                            </div>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    )
};

export default MFAWizard;