import * as types from "../constants/actionTypes";

export default function productReducer(state = [], action) {

    switch (action.type) {
        case types.LOAD_PRODUCT_SUCCESS:
            return action.product;
        default:
            return state;
    };
    
};