const bookingCancellationPeriod = [
    {
        id: 1,
        label: "Anytime",
        value: 0
    },
    {
        id: 2,
        label: "30 minutes in advance",
        value: 0.5
    },
    {
        id: 3,
        label: "1 hour in advance",
        value: 1
    },
    {
        id: 4,
        label: "2 hours in advance",
        value: 2
    },
    {
        id: 5,
        label: "3 hours in advance",
        value: 3
    },
    {
        id: 6,
        label: "4 hours in advance",
        value: 4
    },
    {
        id: 7,
        label: "5 hours in advance",
        value: 5
    },
    {
        id: 8,
        label: "6 hours in advance",
        value: 6
    },
    {
        id: 9,
        label: "7 hours in advance",
        value: 7
    },
    {
        id: 10,
        label: "8 hours in advance",
        value: 8
    },
    {
        id: 11,
        label: "9 hours in advance",
        value: 9
    },
    {
        id: 12,
        label: "10 hours in advance",
        value: 10
    },
    {
        id: 13,
        label: "11 hours in advance",
        value: 11
    },
    {
        id: 14,
        label: "12 hours in advance",
        value: 12
    },
    {
        id: 15,
        label: "1 day in advance",
        value: 24
    },
    {
        id: 16,
        label: "2 days in advance",
        value: 48
    },
    {
        id: 17,
        label: "3 days in advance",
        value: 72
    },
    {
        id: 18,
        label: "4 days in advance",
        value: 96
    },
    {
        id: 19,
        label: "5 days in advance",
        value: 120
    },
    {
        id: 20,
        label: "6 days in advance",
        value: 144
    },
    {
        id: 21,
        label: "7 days in advance",
        value: 168
    }
];

export default bookingCancellationPeriod;