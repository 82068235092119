const finite = [
    {
        id: 1,
        label: "After 2 Times",
        value: 2
    },
    {
        id: 2,
        label: "After 3 Times",
        value: 3
    },
    {
        id: 3,
        label: "After 4 Times",
        value: 4
    },
    {
        id: 4,
        label: "After 5 Times",
        value: 5
    },
    {
        id: 5,
        label: "After 6 Times",
        value: 6
    },
    {
        id: 6,
        label: "After 7 Times",
        value: 7
    },
    {
        id: 7,
        label: "After 8 Times",
        value: 8
    },
    {
        id: 8,
        label: "After 9 Times",
        value: 9
    },
    {
        id: 9,
        label: "After 10 Times",
        value: 10
    },
    {
        id: 10,
        label: "After 11 Times",
        value: 11
    },
    {
        id: 11,
        label: "After 12 Times",
        value: 12
    },
    {
        id: 12,
        label: "After 13 Times",
        value: 13
    },
    {
        id: 13,
        label: "After 14 Times",
        value: 14
    },
    {
        id: 14,
        label: "After 15 Times",
        value: 15
    },
    {
        id: 15,
        label: "After 16 Times",
        value: 16
    },
    {
        id: 16,
        label: "After 17 Times",
        value: 17
    },
    {
        id: 17,
        label: "After 18 Times",
        value: 18
    },
    {
        id: 18,
        label: "After 19 Times",
        value: 19
    },
    {
        id: 19,
        label: "After 20 Times",
        value: 20
    },
    {
        id: 20,
        label: "After 21 Times",
        value: 21
    },
    {
        id: 21,
        label: "After 22 Times",
        value: 22
    },
    {
        id: 22,
        label: "After 23 Times",
        value: 23
    },
    {
        id: 23,
        label: "After 24 Times",
        value: 24
    },
    {
        id: 24,
        label: "After 25 Times",
        value: 25
    },
    {
        id: 25,
        label: "After 26 Times",
        value: 26
    },
    {
        id: 26,
        label: "After 27 Times",
        value: 27
    },
    {
        id: 27,
        label: "After 28 Times",
        value: 28
    },
    {
        id: 28,
        label: "After 29 Times",
        value: 29
    },
    {
        id: 29,
        label: "After 30 Times",
        value: 30
    },
    {
        id: 30,
        label: "After 31 Times",
        value: 31
    },
    {
        id: 31,
        label: "After 32 Times",
        value: 32
    },
    {
        id: 32,
        label: "After 33 Times",
        value: 33
    },
    {
        id: 33,
        label: "After 34 Times",
        value: 34
    },
    {
        id: 34,
        label: "After 35 Times",
        value: 35
    },
    {
        id: 35,
        label: "After 36 Times",
        value: 36
    },
    {
        id: 36,
        label: "After 37 Times",
        value: 37
    },
    {
        id: 37,
        label: "After 38 Times",
        value: 38
    },
    {
        id: 38,
        label: "After 39 Times",
        value: 39
    },
    {
        id: 39,
        label: "After 40 Times",
        value: 40
    },
  ];

export default finite;