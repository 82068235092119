import * as types from "../constants/actionTypes";

export default function userRoleReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_USER_ROLE_SUCCESS:
            return action.userRole;
        case types.UPDATE_USER_ROLE_SUCCESS:
            return action.userRole;
        case types.CLEAR_USER_ROLE_SUCCESS:
            return [];
        default:
            return state;
    };
    
};