/*eslint-disable*/
import React from "react";

// reactstrap components
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Checkbox from '@mui/material/Checkbox';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import { CDN_URL } from "constants/urls";

class ServiceTierStaffPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            task: null
        };
    }

    render() {
        return (
        <>
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible} className="text-center">
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Select Tier Staff</ModalHeader>
                <ModalBody>
                    {this.props.staff.map((staffObj, staffIndex) => {
                        let staffChecked = false;
                        if(this.props.serviceDetailStaff.find(x => x.staff_id === staffObj.id)){
                            staffChecked = true;
                        }
                        return (
                            <div className="tier-staff-row" key={'ServiceTierStaffPicker' + staffIndex}>
                                <div style={{ marginLeft: 10 }}>
                                    <img className="tier-staff-img" src={`${CDN_URL}/` + staffObj.staff_img}/>
                                    <div className="tier-staff-name">{staffObj.firstname + ' ' + staffObj.lastname}</div>
                                </div>
                                <Checkbox defaultChecked={staffChecked} onChange={(e) => this.props.handleTierStaffChange(staffObj.id, e.target.checked)} />
                            </div>
                        );
                    })}
                </ModalBody>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        staff: state.staff
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTierStaffPicker);