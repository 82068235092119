import { Fragment } from 'react';
// MUI components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// OTP input
import { MuiOtpInput } from 'mui-one-time-password-input';

function MFAStepConfirm(props) {

    return (
        <Fragment>
            <Typography variant="body1">
                Enter the one-time password displayed on the authenticator app to finalise {props.unenrollment && 'un'}enrollment. 
            </Typography>
            <Box sx={{ mt: 3 }}>
                <MuiOtpInput
                    length={6}
                    onChange={props.setOtp}
                    value={props.otp}
                    onComplete={props.nextHandler}
                />
            </Box>
        </Fragment>
    );
};

export default MFAStepConfirm;