/*eslint-disable*/
import React from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalHeader,
    Input,
    FormGroup,
    Row,
    Col,
    Label,
    Button,
    Alert,
    Spinner
} from "reactstrap";

// react plugin used to create datetimepicker
import Datetime from "react-datetime";
import Select from "react-select";

import moment from 'moment';

import Api from 'api/index';
import ModalFooter from "reactstrap/lib/ModalFooter";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import frequency from '../../constants/frequency';
import ends from '../../constants/ends';
import finite from '../../constants/finite';
import time from '../../constants/time';
import { CDN_URL } from "constants/urls";

class BreakAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            staffOptions: [],
            locationOptions: [],
            breakId: null,
            title: null,
            date: null,
            start: null,
            end: null,
            staffId: null,
            recurring: false,
            frequencyId: null,
            endId: null,
            finiteId: null,
            endDate: null,
            loading: false,
            success: false,
            error: null
        };
    }

    componentDidMount() {
        // Populate staff options
        let staffOptions = [];
        this.props.staff.forEach((staffObj, staffIndex) => {
            let foundLocationMap = this.props.businessLocationStaffMap.find(x => x.staff_id === staffObj.id && x.business_location_id === this.props.locationId);
            if(foundLocationMap) {
                console.log(foundLocationMap);
                staffOptions.push({
                    id: staffIndex,
                    name: staffObj.firstname + ' ' + staffObj.lastname,
                    label: <div><img className="staff-select-img" src={`${CDN_URL}/` + staffObj.staff_img} alt="StaffImg"/> {staffObj.firstname + ' ' + staffObj.lastname}</div>,
                    value: staffObj.id
                });
            }
        });
        // Populate location options
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj,) => {
            let foundLocationMap = this.props.businessLocationStaffMap.find(x => x.staff_id === this.props.staffId && x.business_location_id === businessLocationObj.business_location_id);
            if(foundLocationMap) {
                locationOptions.push({
                    id: businessLocationObj.business_location_id,
                    label: businessLocationObj.business_location_name,
                    value: businessLocationObj.business_location_id
                });
            }
        });
        // Set initial state
        this.setState({
            staffOptions,
            date: this.props.date ? this.props.date : null,
            start: this.props.time ? moment(this.props.time.format('HH:mm'), 'HH:mm') : null,
            end: this.props.time ? moment(moment(this.props.time).add(30, 'minutes').format('HH:mm'), 'HH:mm') : null,
            staffId: this.props.staffId ? this.props.staffId : null,
            locationOptions
        });
    }

    handleChange(e, name) {
        if(name === 'title') {
            this.setState({ title: e.target.value });
        } else if(name === 'date') {
            this.setState({ date: e });
        } else if(name === 'start') {
            this.setState({ start: e });
        } else if(name === 'end') {
            this.setState({ end: e });
        } else if(name === 'staff') {
            this.setState({ staffId: e.value });
        } else if(name === 'recurring') {
            this.setState({ recurring: e.target.checked });
        } else if(name === 'frequency') {
            this.setState({ frequencyId: e.value });
        } else if(name === 'endType') {
            this.setState({ endId: e.value });
        } else if(name === 'finite') {
            this.setState({ finiteId: e.value });
        } else if(name === 'endDate') {
            this.setState({ endDate: e });
        }
    }

    async submitBreak() {
        if(this.state.loading) {
            return;
        }
        if(!this.state.title || this.state.title.length === 0) {
            this.setState({ error: 'A title is required.' });
            return;
        }
        if(!this.state.date || !moment.isMoment(this.state.date)) {
            this.setState({ error: 'A date is required.' });
            return;
        }
        if(!this.state.start || !moment.isMoment(this.state.start)) {
            this.setState({ error: 'A start time is required.' });
            return;
        }
        if(!this.state.end || !moment.isMoment(this.state.end)) {
            this.setState({ error: 'An end time is required.' });
            return;
        }
        if(moment(this.state.start).isSameOrAfter(this.state.end, 'minute')) {
            this.setState({ error: 'The start time must be before the end time.' });
            return;
        }
        if(!this.state.staffId) {
            this.setState({ error: 'A staff member is required.' });
            return;
        }
        if(!this.props.businessLocationStaffMap.find(x => x.staff_id === this.state.staffId && x.business_location_id === this.props.locationId)) {
            this.setState({ error: 'The selected staff member does not work at this location.' });
            return;
        }
        if(this.state.recurring) {
            if(!this.state.frequencyId) {
                this.setState({ error: 'A frequency is required.' });
                return;
            }
            if(!this.state.endId) {
                this.setState({ error: 'An end type is required.' });
                return;
            }
            if(this.state.endId === 2 && !this.state.finiteId) {
                this.setState({ error: 'No finite count selected' });
                return;
            }
            if(this.state.endId === 3 && (!this.state.endDate || !moment.isMoment(this.state.endDate))) {
                this.setState({ error: 'No end date selected' });
                return;
            }
            if(this.state.endId === 3 && moment(this.state.endDate).isSameOrBefore(this.state.date, 'day')) {
                this.setState({ error: 'The end date must be after the start date.' });
                return;
            }
        }

        // If recurring finite option is selected calculate end date
        let recurringEndDate, finiteObj, frequencyObj;
        if(this.state.recurring && this.state.endId === 2){
            recurringEndDate = moment(this.state.date);
            finiteObj = finite.find(x => x.id === this.state.finiteId);
            frequencyObj = frequency.find(x => x.id === this.state.frequencyId);
            var i;
            for (i = 1; i <= finiteObj.value; i++) {
                recurringEndDate = recurringEndDate.add(frequencyObj.frequency_magnitude, frequencyObj.frequency_unit);
            }
            recurringEndDate = recurringEndDate.format('YYYY-MM-DD');
        } else if(this.state.recurring && this.state.endId === 3) {
            recurringEndDate = this.state.endDate.format('YYYY-MM-DD')
        } else {
            recurringEndDate = null;
        }
        this.setState({ loading: true, error: null });
        try {
            let data = {
                title: this.state.title,
                date: this.state.date.format('YYYY-MM-DD'),
                start_time: this.state.start.format('HH:mm'),
                end_time: this.state.end.format('HH:mm'),
                staff_id: this.state.staffId,
                business_location_id: this.props.locationId,
                recurring: this.state.recurring,
                recurring_frequency_id: this.state.frequencyId,
                recurring_end_id: this.state.endId,
                recurring_end_date: recurringEndDate
            };
            let response = await Api.addBreak(data);
            this.props.triggerNotification("Break added successfully.", "success", "bc", 4);
            this.props.toggleVisible();
            if(this.props.onSubmit) {
                const breakState = Object.assign({}, this.state);
                breakState.breakId = response.data.breakId
                this.props.onSubmit(breakState);
            }
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    customStaffOptionFilter(option, searchText) {
        if(option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
        <>
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Add Break</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} sm={12}>
                            <label>Title <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Input
                                    type="text"
                                    placeholder="e.g. Lunch"
                                    onChange={(e) => this.handleChange(e, 'title')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Date <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Datetime
                                    dateFormat={"dddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "Select Date" }}
                                    value={this.state.date ? this.state.date : this.props.date}
                                    onChange={(e) => this.handleChange(e, 'date')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Start & End <span className="text-danger">*</span></label>
                            <FormGroup className="time-group">
                                <Select
                                    className="react-select primary time-input time-input-left"
                                    classNamePrefix="react-select"
                                    options={time}
                                    value={this.state.start ? time.find(x => x.value === this.state.start.format('HH:mm')) : null}
                                    onChange={(e) => this.handleChange(moment(e.value, 'HH:mm'), 'start')}
                                    placeholder={"--:--"}
                                />
                                <Select
                                    className="react-select primary time-input time-input-right"
                                    classNamePrefix="react-select"
                                    options={time}
                                    value={this.state.end ? time.find(x => x.value === this.state.end.format('HH:mm')) : null}
                                    onChange={(e) => this.handleChange(moment(e.value, 'HH:mm'), 'end')}
                                    placeholder={"--:--"}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Staff <span className="text-danger">*</span></label>
                            <FormGroup>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={this.state.staffOptions}
                                    value={this.state.staffOptions.find(x => x.value === this.state.staffId)}
                                    filterOption={this.customStaffOptionFilter}
                                    onChange={(e) => this.handleChange(e, 'staff')}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={12} md={12}>
                            <label>Location</label>
                            <FormGroup>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    options={this.state.locationOptions}
                                    value={this.state.locationOptions ? this.state.locationOptions.find(x => x.id === this.props.locationId) : null}
                                    placeholder={'Choose location...'}
                                    captureMenuScroll={true}
                                    isDisabled
                                    controlShouldRenderValue={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr style={{ marginLeft: 0, marginRight: 0 }} />
                    <Row>
                        <Col md={12}>
                            <FormGroup check style={{ marginTop: 0, marginBottom: 10 }}>
                                <Label check>
                                    <Input type="checkbox" defaultChecked={this.state.recurring} onClick={(e) => this.handleChange(e, 'recurring')} />
                                    <span className="form-check-sign" />
                                    Recurring Event
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.state.recurring ?
                        <Row>
                            <Col md={12}>
                                <label>Frequency <span className="text-danger">*</span></label>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={frequency}
                                        defaultValue={frequency.find(x => x.value === this.state.frequencyId)}
                                        onChange={(e) => this.handleChange(e, 'frequency')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <label>End Type <span className="text-danger">*</span></label>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={ends}
                                        defaultValue={ends.find(x => x.value === this.state.endId)}
                                        onChange={(e) => this.handleChange(e, 'endType')}
                                    />
                                </FormGroup>
                            </Col>
                            {this.state.endId === 2 ?
                                <Col md={12}>
                                    <label>Ends <span className="text-danger">*</span></label>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            options={finite}
                                            defaultValue={finite.find(x => x.value === this.state.finiteId)}
                                            onChange={(e) => this.handleChange(e, 'finite')}
                                        />
                                    </FormGroup>
                                </Col>
                            : null}
                            {this.state.endId === 3 ?
                                <Col md={12}>
                                    <label>Ends <span className="text-danger">*</span></label>
                                    <FormGroup>
                                        <Datetime
                                            dateFormat={"dddd, Do MMM YYYY"}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: "Select End Date" }}
                                            value={this.state.endDate}
                                            onChange={(e) => this.handleChange(e, 'endDate')}
                                        />
                                    </FormGroup>
                                </Col>
                            : null}
                        </Row>
                    : null}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        style={{ width: '100%' }}
                        onClick={() => this.submitBreak()}
                        disabled={this.state.success}
                    >
                        {this.state.loading ? <Spinner color="success" size="sm" /> : "Add Break"}
                    </Button>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        staff: state.staff,
        businessLocation: state.businessLocation,
        businessLocationStaffMap: state.businessLocationStaffMap
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakAdd);