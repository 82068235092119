import * as types from "../constants/actionTypes";

export default function settingsReducer(state = [], action) {
  
    switch (action.type) {
        case types.LOAD_SETTINGS_SUCCESS:
            return action.settings;
        default:
            return state;
    };
    
};