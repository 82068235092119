import React from "react";
import moment from "moment";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  Spinner,
  Table,
  FormText
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import Api from '../../api/index';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { FiArrowRight, FiMail, FiPhone, FiRepeat } from 'react-icons/fi';
// Constants
import frequency from "constants/frequency";
import currency from "constants/currency";
// Decorators
import withRouter from "utilities/withRouter";

class Search extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            searchString: null,
            customerData: [],
            bookingData: [],
            recurringBookingData: [],
            customersLoading: false,
            bookingsLoading: false
        };
    }

    componentDidMount() {
        this.getSearchQuery();
    }

    getSearchQuery() {
        try {
            const query = new URLSearchParams(window.location.search);
            let searchString = query.get("query");
            if(searchString && searchString !== this.state.searchString) {
                this.setState({ searchString });
                this.searchCustomers(searchString);
                if(searchString.length <= 8) {
                    // Greater than 8 characters implies customer name
                    this.searchBookings(searchString);
                }
            }
        } catch(e) {
            console.log('Unable to apply URL query string');
        }
    }
    
    async searchBookings(searchString) {
        this.setState({ bookingsLoading: true, bookingData: [], recurringBookingData: [] });
        try {
            let response = await Api.searchBookings({ search_string: searchString });
            this.setState({ bookingData: response.data.bookingData, recurringBookingData: response.data.recurringBookingData, bookingsLoading: false });
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst trying to retrieve your booking search results.", "danger", "bc", 4);
            this.setState({ bookingsLoading: false, bookingData: [], recurringBookingData: [] });
        }
    }

    async searchCustomers(searchString) {
        this.setState({ customersLoading: true, customerData: [] });
        try {
            let searchResults = await Api.searchCustomers({ search_string: searchString });
            this.setState({ customerData: searchResults.data.customerData, customersLoading: false });
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst trying to retrieve your customer search results.", "danger", "bc", 4);
            this.setState({ customersLoading: false, customerData: [] });
        }
    }

    async handleSearchChange(e) {
        let searchString = e.target.value;
        if(searchString && searchString !== this.state.searchString && searchString.length > 2) {
            try {
                this.searchCustomers(searchString);
                if(searchString.length <= 8) {
                    // Greater than 8 characters implies customer name
                    this.searchBookings(searchString);
                }
            } catch(err) {
                console.log(err);
                this.setState({ customersLoading: false, bookingsLoading: false, customerData: [], bookingData: [] });
                this.props.triggerNotification("An unexpected error occured while fetching your search results. Please contact Support if the problem persists.", "danger", "bc", 8);
                return;
            }
        }
        this.setState({ searchString });
    }

    renderCustomerResults() {
        if(this.state.customersLoading) {
            return (
                <div className="text-center">
                    <Spinner color="primary" size="lg"/>
                </div>
            );
        } else {
            if(this.state.customerData && this.state.customerData.length > 0) {
                return (
                    <div>
                        <Table responsive style={{ marginBottom: 0, paddingBottom: 0 }}>
                            <tbody>
                                {this.state.customerData.map((customerObj, customerIndex) => {
                                    return (
                                        <tr key={'customerRow' + customerObj.user_id} className="customer-row">
                                            <td style={customerIndex === 0 ? { borderTop: 'none' } : null}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="customer-circle">{customerObj.user_firstname.charAt(0) + customerObj.user_lastname.charAt(0)}</div>
                                                    <div className="customer-name-container">
                                                        <div className="customer-name">{customerObj.user_firstname + ' ' + customerObj.user_lastname}</div>
                                                        <div className="customer-info-mini"><FiMail style={{ marginRight: 5 }} /> {customerObj.user_email}</div>
                                                        <div className="customer-info-mini"><FiPhone style={{ marginRight: 5 }} /> {customerObj.user_phone}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="customer-info" style={customerIndex === 0 ? { borderTop: 'none' } : null}>
                                                <FiMail style={{ marginRight: 5 }} /> {customerObj.user_email ? customerObj.user_email : '----------------'}
                                            </td>
                                            <td className="customer-info" style={customerIndex === 0 ? { borderTop: 'none' } : null}>
                                                <FiPhone style={{ marginRight: 5 }} /> {customerObj.user_phone ? customerObj.user_phone : '-------------'}
                                            </td>
                                            <td style={customerIndex === 0 ? { borderTop: 'none' } : null}>
                                                <Button
                                                    className="btn-icon pull-right"
                                                    color="primary"
                                                    size="sm"
                                                    id={'editCustomerBtn0'}
                                                    style={{ margin: 0 }}
                                                    onClick={() => this.props.navigate('/admin/customer_profile?id=' + customerObj.user_id)}
                                                >
                                                    <FiArrowRight style={{ marginBottom: 3 }}/>
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                );
            } else {
                return <div className="text-center text-muted">No customers found</div>;
            }
        }
    }

    handleBookingClick(user_order_id, user_order_recurring) {
        if(user_order_recurring) {
            this.props.navigate("/admin/edit_booking?user_order_id=" + user_order_id + "&user_order_recurring=true");
        } else {
            this.props.navigate("/admin/edit_booking?user_order_id=" + user_order_id);
        }
    }
    
    renderBookingResults() {
        if(this.state.bookingsLoading) {
            return (
                <div className="text-center">
                    <Spinner color="primary" size="lg"/>
                </div>
            );
        } else {
            if((this.state.bookingData && this.state.bookingData.length > 0) || (this.state.recurringBookingData && this.state.recurringBookingData.length > 0)) {
                let currencyObj = currency.find(x => x.id === this.props.business.currency_id);
                return (
                    <div>
                        {this.state.recurringBookingData.map((userOrderObj, userOrderIndex) => {
                            let orderEvents = userOrderObj.order_events;
                            let total = 0;
                            let frequencyObj = frequency.find(x => x.id === orderEvents[0].business_events_recurring_frequency_id);
                            return (
                                <div className="customer-profile-booking-container" onClick={() => this.handleBookingClick(userOrderObj.user_order_recurring_id, true)}>
                                    <div className="customer-profile-booking-info">
                                        <strong>{moment(orderEvents[0].start_time, 'HH:mm:ss').format('HH:mm')} - {moment(orderEvents[orderEvents.length - 1].end_time, 'HH:mm:ss').format('HH:mm')}</strong>
                                        <div className="pull-right"><FiRepeat size={18}/></div>
                                    </div>
                                    <div className="customer-profile-booking-info">Customer: <strong>{userOrderObj.user_firstname ? userOrderObj.user_firstname + ' ' + userOrderObj.user_lastname : null}</strong></div>
                                    <div className="customer-profile-booking-info">Start date: {moment(orderEvents[0].start_date, 'YYYY-MM-DD').format('ddd Do MMM YYYY')}</div>
                                    <div className="customer-profile-booking-info">End date: {orderEvents[0].end_date ? moment(orderEvents[0].end_date, 'YYYY-MM-DD').format('ddd Do MMM YYYY') : '------'}</div>
                                    <div className="customer-profile-booking-info">Occurs: {frequencyObj ? frequencyObj.label : null}</div>
                                    <div className="customer-profile-booking-info">Booking Ref: {userOrderObj.user_order_recurring_uid}</div>
                                    <div>
                                        {orderEvents.map((userOrderEventObj, userOrderEventIndex) => {
                                            var E_start = moment(userOrderEventObj.start_time, 'HH:mm:ss');
                                            var E_end = moment(userOrderEventObj.end_time, 'HH:mm:ss');
                                            let staffObj = this.props.staff.find(x => x.id === userOrderEventObj.staff_id);
                                            let serviceDetailObj = this.props.serviceDetail.find(x => x.service_business_detail_id === userOrderEventObj.service_detail_id);
                                            let serviceObj;
                                            if(serviceDetailObj) {
                                                total = total + Number(serviceDetailObj.service_business_detail_price);
                                                serviceObj = this.props.service.find(x => x.service_id === serviceDetailObj.service_business_id);
                                            }
                                            return (
                                                <div className="customer-profile-booking-service-container" style={orderEvents.length === 1 ? { marginBottom: 0 } : null}>
                                                    <div className="customer-profile-booking-service">
                                                        {serviceObj.service_name ? serviceObj.service_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null} ({serviceDetailObj.service_business_detail_name ? serviceDetailObj.service_business_detail_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null})
                                                        <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(serviceDetailObj.service_business_detail_price / 100).toFixed(2)}</div>
                                                    </div>
                                                    <div className="customer-profile-booking-service-info">{E_end.diff(E_start, 'minutes')} mins with {staffObj ? staffObj.firstname + ' ' + staffObj.lastname : null}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {orderEvents && orderEvents.length > 1 ?
                                        <div>
                                            <hr/>
                                            <div className="customer-profile-booking-total">
                                                Total
                                                <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(total / 100).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            )
                        })}
                        {this.state.bookingData.map((userOrderObj, userOrderIndex) => {
                            let orderEvents = userOrderObj.order_events;
                            let total = 0;
                            let cancelled = orderEvents[0].status === 'Cancelled';
                            let noShow = orderEvents[0].status === 'No-show';
                            let startMoment = moment(orderEvents[0].start, 'YYYY-MM-DD HH:mm:ss');
                            return (
                                <div className="customer-profile-booking-container" style={cancelled || noShow ? { borderLeftColor: '#ed5565' } : null} onClick={() => this.handleBookingClick(userOrderObj.user_order_id, false)}>
                                    <div className="customer-profile-booking-info">
                                        {startMoment.format('HH:mm, ddd Do MMM YYYY')} {cancelled ? <div className="customer-profile-booking-cancelled">CANCELLED</div> : null}{noShow ? <div className="customer-profile-booking-cancelled">NO-SHOW</div> : null}
                                    </div>
                                    <div className="customer-profile-booking-info">Customer: <strong>{userOrderObj.user_firstname ? userOrderObj.user_firstname + ' ' + userOrderObj.user_lastname : null}</strong></div>
                                    <div className="customer-profile-booking-info">Booking Ref: {userOrderObj.user_order_uid}</div>
                                    <div>
                                        {orderEvents.map((userOrderEventObj, userOrderEventIndex) => {
                                            var E_start = moment(userOrderEventObj.start, 'YYYY-MM-DD HH:mm:ss');
                                            var E_end = moment(userOrderEventObj.end, 'YYYY-MM-DD HH:mm:ss');
                                            let staffObj = this.props.staff.find(x => x.id === userOrderEventObj.staff_id);
                                            let serviceDetailObj = this.props.serviceDetail.find(x => x.service_business_detail_id === userOrderEventObj.service_detail_id);
                                            let serviceObj;
                                            if(serviceDetailObj) {
                                                total = total + Number(serviceDetailObj.service_business_detail_price);
                                                serviceObj = this.props.service.find(x => x.service_id === serviceDetailObj.service_business_id);
                                            }
                                            return (
                                                <div className="customer-profile-booking-service-container" style={orderEvents.length === 1 ? { marginBottom: 0 } : null}>
                                                    <div className="customer-profile-booking-service">
                                                        {serviceObj ? serviceObj.service_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null} ({serviceDetailObj.service_business_detail_name ? serviceDetailObj.service_business_detail_name.replace('%comma%', ',').replace('%apostrophe%', "'") : null})
                                                        <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(serviceDetailObj.service_business_detail_price / 100).toFixed(2)}</div>
                                                    </div>
                                                    <div className="customer-profile-booking-service-info">{E_end.diff(E_start, 'minutes')} mins with {staffObj ? staffObj.firstname + ' ' + staffObj.lastname : null}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {orderEvents && orderEvents.length > 1 ?
                                        <div>
                                            <hr/>
                                            <div className="customer-profile-booking-total">
                                                Total
                                                <div className="pull-right">{currencyObj ? currencyObj.symbol : '£'}{Number(total / 100).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            )
                        })}
                    </div>
                );
            } else {
                return <div className="text-center text-muted">No bookings found</div>;
            }
        }
    }

    render() {
        return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <Card style={{ marginBottom: 5 }}>
                            <CardBody style={{ padding: '10px 15px' }}>
                                <Row>
                                    <Col md={12}>
                                        <Input
                                            type="text"
                                            placeholder="System Search..."
                                            value={this.state.searchString ? this.state.searchString : ''}
                                            onChange={(e) => this.handleSearchChange(e)}
                                            style={{ fontSize: 16, border: 'none' }}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <FormText color="muted" style={{ marginBottom: 10 }}>
                            Search for a customer name or a booking reference.
                        </FormText>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <h4 className="mb-3 mt-0">Bookings</h4>
                        <Card>
                            <CardBody>
                                {this.renderBookingResults()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <h4 className="mb-3 mt-0">Customers</h4>
                        <Card>
                            <CardBody>
                                {this.renderCustomerResults()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        service: state.service,
        serviceDetail: state.serviceDetail,
        staff: state.staff
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));