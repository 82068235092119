/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse, Button } from "reactstrap";
// Icons
import { FiLogOut, FiUser, FiCreditCard, FiHeart } from 'react-icons/fi';

// core components
import logo from "assets/img/styler_icon_secondary.svg";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import withRouter from "utilities/withRouter";

import { CDN_URL } from "constants/urls";

import SignOutModal from '../SignOutModal/SignOutModal';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      ...this.getCollapseStates(props.routes),
      signOutModalOpen: false
    };
    this.sidebar = React.createRef();
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    // to stop the warning of calling setState of unmounted component
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.filter(x => x.onSideMenu === true).map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.filter(x => x.onSideMenu === true).map((prop, key) => {
      // Check business access permission
      if(Array.isArray(prop.businessAccountAccess) && !prop.businessAccountAccess.includes(this.props.business.business_account_type_id)) {
        return null;
      }
      // Check staff access permission
      if(!this.props.user) {
        return null;
      }
      // Render link
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.path)} key={key}>
          <NavLink to={prop.path}>
            {prop.icon !== undefined ? (
              <div style={{ display: 'flex' }}>
                {typeof prop.icon === "string" ? (
                  <i className={prop.icon} />
                ) : (
                  <span style={{ minWidth: 32, marginRight: 15 }}>{prop.icon}</span>
                )}
                <p>{prop.name}</p>
              </div>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  toggleSignOutModal = () => {
    this.setState({ signOutModalOpen: !this.state.signOutModalOpen });
  }

  render() {
    return (
      <>
        <div className="sidebar" data-color={this.props.backgroundColor}>
          <div className="logo">
            <a
              href="https://styler.digital"
              className="simple-text logo-mini"
              target="_blank"
            >
              <div className="logo-img">
                <img src={logo} alt="Styler logo" />
              </div>
            </a>
            <a
              href="https://styler.digital"
              className="simple-text logo-normal"
              target="_blank"
            >
              Styler <div style={{ color: '#ff2b54', display: 'inline-block' }}>Hub</div>
            </a>
            <div className="navbar-minimize">
              <Button
                outline
                className="btn-round btn-icon"
                color="neutral"
                id="minimizeSidebar"
                onClick={() => this.props.minimizeSidebar()}
              >
                <i className="now-ui-icons arrows-1_minimal-left visible-on-sidebar-regular" />
                <i className="now-ui-icons arrows-1_minimal-right visible-on-sidebar-mini" />
              </Button>
            </div>
          </div>

          <div className="sidebar-wrapper" ref={this.sidebar}>
            <div className="user">
              <div className="photo">
                <img src={`${CDN_URL}/` + this.props.user.user_img} alt="Avatar" />
              </div>
              <div className="info">
                <a
                  data-toggle="collapse"
                  aria-expanded={this.state.openAvatar}
                  onClick={() =>
                    this.setState({ openAvatar: !this.state.openAvatar })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <span>
                    <div>
                      {this.props.user ? `${this.props.user.user_firstname} ${this.props.user.user_lastname}` : 'Loading...'}
                    </div>
                    <b className="caret" />
                  </span>
                </a>
                <Collapse isOpen={this.state.openAvatar}>
                  <ul className="nav">
                    <li>
                      <NavLink to={'account'}>
                        <span className="sidebar-mini-icon"><FiUser size={15}/></span>
                        <span className="sidebar-normal">Account</span>
                      </NavLink>
                    </li>
                    {this.props.userRole?.billing?.read === true  && (
                      <li>
                        <NavLink to={'billing'}>
                          <span className="sidebar-mini-icon"><FiCreditCard size={15}/></span>
                          <span className="sidebar-normal">Billing</span>
                        </NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink to={'referrals'}>
                        <span className="sidebar-mini-icon"><FiHeart size={15}/></span>
                        <span className="sidebar-normal">Referrals</span>
                      </NavLink>
                    </li>
                    <li>
                      <a onClick={(e) => this.toggleSignOutModal()}>
                        <span className="sidebar-mini-icon"><FiLogOut size={15}/></span>
                        <span className="sidebar-normal">Sign Out</span>
                      </a>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </div>
            <Nav>{this.createLinks(this.props.routes)}</Nav>
          </div>
          <SignOutModal
            open={this.state.signOutModalOpen}
            toggle={this.toggleSignOutModal}
            {...this.props}
          />
        </div>
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "blue",
  minimizeSidebar: () => {},
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    "blue",
    "yellow",
    "green",
    "orange",
    "red",
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userRole: state.userRole,
    business: state.business
  }
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
