/*eslint-disable*/
import React from "react";
import moment from "moment";
import 'moment-timezone';
import SweetAlert from "react-bootstrap-sweetalert";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
// reactstrap components
import {
    Modal,
    ModalBody,
    ModalHeader,
    Button,
    Alert,
    Spinner,
    ModalFooter,
    Table
} from "reactstrap";
import Api from 'api/index';

class BookingPayments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sweetAlert: null,
            paymentsData: null,
            error: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.getBookingPayments();
    }

    async getBookingPayments() {
        try {
            this.setState({ loading: true });
            let response = await Api.getBookingPayments({ user_order_id: this.props.userOrderId });
            this.setState({ paymentsData: response.data.orderPaymentsData, loading: false });
        } catch(e) {
            this.setState({ loading: false, error: 'An error occured whilst trying to load the payments associated with this booking.' });
        }
    }

    triggerRefundPayment(user_payment_id) {
        if(this.state.loading) {
            return;
        }
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    title="Are you sure?"
                    onConfirm={() => this.confirmRefundPayment(user_payment_id)}
                    onCancel={() => this.cancelRefundPayment()}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Are you sure that you want to refund this payment? You cannot undo this action.
                </SweetAlert>
            ),
        });
    }

    async confirmRefundPayment(user_payment_id) {
        this.setState({ sweetAlert: null });
        try {
            this.setState({ loading: true });
            await Api.refundBookingPayment({ user_payment_id });
            let paymentsData = [...this.state.paymentsData];
            let paymentIndex = paymentsData.findIndex(x => x.user_payment_id === user_payment_id);
            paymentsData[paymentIndex].user_payment_status_id = 8;
            paymentsData[paymentIndex].user_payment_status = "Refunded";
            this.setState({ loading: false, paymentsData });
        } catch(e) {
            if(e.response && e.response.data && e.response.data.message) {
                this.setState({ loading: false, error: 'Stripe Error: ' + e.response.data.message });
            } else {
                this.setState({ loading: false, error: 'An error occured whilst trying to refund this payment.' });
            }
        }
    }

    cancelRefundPayment() {
        this.setState({ sweetAlert: false });
    }

    render() {
        return (
        <>
            {this.state.sweetAlert}
            <Modal size={"lg"} isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">Booking Payments</ModalHeader>
                <ModalBody>
                    {this.state.loading ? <div className="text-center"><Spinner color="success"/></div> : null}
                    {this.state.paymentsData && this.state.paymentsData.length > 0 ?
                        <Table responsive>
                            <tbody>
                                {this.state.paymentsData.map((paymentObj, paymentIndex) => {
                                    let circleColour = null;
                                    if(paymentObj.user_payment_status_id === 1) {
                                        circleColour = "#1ab394";
                                    } else if(paymentObj.user_payment_status_id === 2 || paymentObj.user_payment_status_id === 3) {
                                        circleColour = "#ed5565";
                                    } else if(paymentObj.user_payment_status_id === 8) {
                                        circleColour='#aeaeae';
                                    } else {
                                        circleColour='#f8ac59';
                                    }
                                    return (
                                        <tr>
                                            <td style={{ whiteSpace: 'nowrap' }}>{paymentObj.user_payment_type ? paymentObj.user_payment_type : null}</td>
                                            <td class="font-weight-bold">{paymentObj.user_payment_amount ? (this.props.currencySymbol) + Number(paymentObj.user_payment_amount / 100).toFixed(2) : "--.--"}</td>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                {paymentObj.user_payment_created ? (
                                                    this.props.business && this.props.business.timezone_name ? moment.utc(paymentObj.user_payment_created, 'YYYY-MM-DD HH:mm:ss').tz(this.props.business.timezone_name, false).format("Do MMM YY, HH:mm") : moment(paymentObj.user_payment_created, 'YYYY-MM-DD HH:mm:ss').format("Do MMM YY, HH:mm")
                                                ) : ( "--.--" )}</td>
                                            <td>
                                                {paymentObj.user_payment_status ?
                                                    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                                        <div className="status-option-circle" style={{ backgroundColor: circleColour }}></div>
                                                        {paymentObj.user_payment_status}
                                                    </div>
                                                : null}
                                            </td>
                                            <td>
                                                {paymentObj.user_payment_status_id === 1 ?
                                                    <Button color="primary" className="btn-round pull-right" onClick={() => this.triggerRefundPayment(paymentObj.user_payment_id)}>
                                                        Refund
                                                    </Button>
                                                : null}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    : <div className="text-center">{this.state.loading === false ? "No payments to display" : null}</div>}
                </ModalBody>
                <ModalFooter>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingPayments);