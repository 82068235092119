import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

class PanelHeader extends React.Component {
  render() {
    return (
      <div
        className={
          "panel-header " +
          (this.props.size !== undefined
            ? "panel-header-" + this.props.size
            : "") +
          (this.props.minimised ? " panel-header-minimised" : "")
        }
        style={this.props.business.hub_header_gradient_start && this.props.business.hub_header_gradient_end ?
          { background: 'linear-gradient(160deg, ' + this.props.business.hub_header_gradient_start + ' 0%, ' + this.props.business.hub_header_gradient_end + ' 100%)' }
        : null}
      >
        {this.props.content}
      </div>
    );
  }
}

PanelHeader.defaultProps = {
  size: undefined,
  content: null,
  minimised: false
};

PanelHeader.propTypes = {
  // size of the panel header
  size: PropTypes.oneOf(["sm", "md", "lg", undefined]),
  // content
  content: PropTypes.node,
  // minimised on small screens
  minimised: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  return {
      business: state.business
  };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelHeader);
