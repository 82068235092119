const duration = [
    {
        id: 1,
        label: "5 mins",
        value: 5
    },
    {
        id: 2,
        label: "10 mins",
        value: 10
    },
    {
        id: 3,
        label: "15 mins",
        value: 15
    },
    {
        id: 4,
        label: "20 mins",
        value: 20
    },
    {
        id: 5,
        label: "25 mins",
        value: 25
    },
    {
        id: 6,
        label: "30 mins",
        value: 30
    },
    {
        id: 7,
        label: "35 mins",
        value: 35
    },
    {
        id: 8,
        label: "40 mins",
        value: 40
    },
    {
        id: 9,
        label: "45 mins",
        value: 45
    },
    {
        id: 10,
        label: "50 mins",
        value: 50
    },
    {
        id: 11,
        label: "55 mins",
        value: 55
    },
    {
        id: 12,
        label: "1 hr",
        value: 60
    },
    {
        id: 13,
        label: "1 hr 5 mins",
        value: 65
    },
    {
        id: 14,
        label: "1 hr 10 mins",
        value: 70
    },
    {
        id: 15,
        label: "1 hr 15 mins",
        value: 75
    },
    {
        id: 16,
        label: "1 hr 20 mins",
        value: 80
    },
    {
        id: 17,
        label: "1 hr 25 mins",
        value: 85
    },
    {
        id: 18,
        label: "1 hr 30 mins",
        value: 90
    },
    {
        id: 19,
        label: "1 hr 35 mins",
        value: 95
    },
    {
        id: 20,
        label: "1 hr 40 mins",
        value: 100
    },
    {
        id: 21,
        label: "1 hr 45 mins",
        value: 105
    },
    {
        id: 22,
        label: "1 hr 50 mins",
        value: 110
    },
    {
        id: 23,
        label: "1 hr 55 mins",
        value: 115
    },
    {
        id: 24,
        label: "2 hrs",
        value: 120
    },
    {
        id: 25,
        label: "2 hrs 5 mins",
        value: 125
    },
    {
        id: 26,
        label: "2 hrs 10 mins",
        value: 130
    },
    {
        id: 27,
        label: "2 hrs 15 mins",
        value: 135
    },
    {
        id: 28,
        label: "2 hrs 20 mins",
        value: 140
    },
    {
        id: 29,
        label: "2 hrs 25 mins",
        value: 145
    },
    {
        id: 30,
        label: "2 hrs 30 mins",
        value: 150
    },
    {
        id: 31,
        label: "2 hrs 35 mins",
        value: 155
    },
    {
        id: 32,
        label: "2 hrs 40 mins",
        value: 160
    },
    {
        id: 33,
        label: "2 hrs 45 mins",
        value: 165
    },
    {
        id: 34,
        label: "2 hrs 50 mins",
        value: 170
    },
    {
        id: 35,
        label: "2 hr 55 mins",
        value: 175
    },
    {
        id: 36,
        label: "3 hrs",
        value: 180
    },
    {
        id: 37,
        label: "3 hrs 5 mins",
        value: 185
    },
    {
        id: 38,
        label: "3 hrs 10 mins",
        value: 190
    },
    {
        id: 39,
        label: "3 hrs 15 mins",
        value: 195
    },
    {
        id: 40,
        label: "3 hrs 20 mins",
        value: 200
    },
    {
        id: 41,
        label: "3 hrs 25 mins",
        value: 205
    },
    {
        id: 42,
        label: "3 hrs 30 mins",
        value: 210
    },
    {
        id: 43,
        label: "3 hrs 35 mins",
        value: 215
    },
    {
        id: 44,
        label: "3 hrs 40 mins",
        value: 220
    },
    {
        id: 45,
        label: "3 hrs 45 mins",
        value: 225
    },
    {
        id: 46,
        label: "3 hrs 50 mins",
        value: 230
    },
    {
        id: 47,
        label: "3 hrs 55 mins",
        value: 235
    },
    {
        id: 48,
        label: "4 hrs",
        value: 240
    },
    {
        id: 49,
        label: "4 hrs 5 mins",
        value: 245
    },
    {
        id: 50,
        label: "4 hrs 10 mins",
        value: 250
    },
    {
        id: 51,
        label: "4 hrs 15 mins",
        value: 255
    },
    {
        id: 52,
        label: "4 hrs 20 mins",
        value: 260
    },
    {
        id: 53,
        label: "4 hrs 25 mins",
        value: 265
    },
    {
        id: 54,
        label: "4 hrs 30 mins",
        value: 270
    },
    {
        id: 55,
        label: "4 hrs 35 mins",
        value: 275
    },
    {
        id: 56,
        label: "4 hrs 40 mins",
        value: 280
    },
    {
        id: 57,
        label: "4 hrs 45 mins",
        value: 285
    },
    {
        id: 58,
        label: "4 hrs 50 mins",
        value: 290
    },
    {
        id: 59,
        label: "4 hrs 55 mins",
        value: 295
    },
    {
        id: 60,
        label: "5 hrs",
        value: 300
    },
    {
        id: 61,
        label: "5 hrs 5 mins",
        value: 305
    },
    {
        id: 62,
        label: "5 hrs 10 mins",
        value: 310
    },
    {
        id: 63,
        label: "5 hrs 15 mins",
        value: 315
    },
    {
        id: 64,
        label: "5 hrs 20 mins",
        value: 320
    },
    {
        id: 65,
        label: "5 hrs 25 mins",
        value: 325
    },
    {
        id: 66,
        label: "5 hrs 30 mins",
        value: 330
    },
    {
        id: 67,
        label: "5 hrs 35 mins",
        value: 335
    },
    {
        id: 68,
        label: "5 hrs 40 mins",
        value: 340
    },
    {
        id: 69,
        label: "5 hrs 45 mins",
        value: 345
    },
    {
        id: 70,
        label: "5 hrs 50 mins",
        value: 350
    },
    {
        id: 71,
        label: "5 hrs 55 mins",
        value: 355
    },
    {
        id: 72,
        label: "6 hrs",
        value: 360
    }
];

export default duration;