import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    FormText,
    Input
} from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";
// API
import Api from 'api/index';
// Constants
import bookingAdvanceMinimumPeriod from "constants/bookingAdvanceMinimumPeriod";
import bookingAdvanceMaximumPeriod from "constants/bookingAdvanceMaximumPeriod";
import bookingCancellationPeriod from "constants/bookingCancellationPeriod";
import duration from "constants/duration";
import withRouter from "utilities/withRouter";
// Decorators

class BookingPreferences extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingEnabled: true,
            bookingAdvance: null,
            bookingAdvanceMinimum: null,
            bookingInterval: null,
            bookingCancellation: null,
            bookingImportantInformation: null
        };
    }

    async componentDidMount() {
        this.getBookingPreferences();
    }

    async getBookingPreferences() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let response = await Api.getBookingPreferences();
            let bookingPreferencesData = response.data.bookingPreferencesData;
            this.setState({
                bookingEnabled: bookingPreferencesData.enable_booking === 1 ? true : false,
                bookingAdvanceMinimum: bookingPreferencesData.advance_booking_minimum,
                bookingAdvance: bookingPreferencesData.advance_booking,
                bookingInterval: bookingPreferencesData.slot_interval,
                bookingCancellation: bookingPreferencesData.cancellation_period,
                bookingImportantInformation: bookingPreferencesData.booking_notice
            });
            this.props.actions.loadLoadingSpinner(false);
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured whilst loading your booking preferences.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
            this.props.navigate("/admin/settings");
        }
    }

    async submitBookingPreferences() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            await Api.updateBookingPreferences({
                enable_booking: this.state.bookingEnabled,
                advance_booking_minimum: this.state.bookingAdvanceMinimum,
                advance_booking: this.state.bookingAdvance,
                slot_interval: this.state.bookingInterval,
                cancellation_period: this.state.bookingCancellation,
                booking_notice: this.state.bookingImportantInformation
            });
            this.props.triggerNotification("Booking preferences updated successfully.", "success", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
            this.props.navigate('/admin/settings');
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while updating your booking preferences.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    handleChange(e, name) {
        if(name === "bookingEnabled") {
            this.setState({ bookingEnabled: e });
        } else if(name === "bookingAdvance") {
            this.setState({ bookingAdvance: e.value });
        } else if(name === "bookingAdvanceMinimum") {
            this.setState({ bookingAdvanceMinimum: e.value });
        } else if(name === "bookingInterval") {
            this.setState({ bookingInterval: e.value });
        } else if(name === "bookingCancellation") {
            this.setState({ bookingCancellation: e.value });
        } else if(name === "bookingImportantInformation") {
            this.setState({ bookingImportantInformation: e.target.value });
        }
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Booking Preferences</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <Card style={{ marginBottom: 0 }}>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12} className="mb-2">
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>Bookings Enabled</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'bookingEnabled') }}
                                                checked={this.state.bookingEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                        <FormText color="muted" style={{ marginTop: '1rem' }}>
                                            Disabling online bookings will suspend the ability for your customer to make bookings via any app or widget. You will still be able to make bookings from the Hub.
                                            You may enable online booking functionality again at any time.
                                        </FormText>
                                        <hr/>
                                    </Col>
                                    <Col md={12}>
                                        <label>How far in advance can a customer book?</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                options={bookingAdvanceMaximumPeriod}
                                                value={bookingAdvanceMaximumPeriod.find(x => x.value === this.state.bookingAdvance)}
                                                onChange={(value) => this.handleChange(value, 'bookingAdvance')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Minimum booking notice period</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                options={bookingAdvanceMinimumPeriod}
                                                value={bookingAdvanceMinimumPeriod.find(x => x.value === this.state.bookingAdvanceMinimum)}
                                                onChange={(value) => this.handleChange(value, 'bookingAdvanceMinimum')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Interval between booking slots</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                options={duration.filter(x => x.value <= 180)}
                                                value={duration.filter(x => x.value <= 180).find(x => x.value === this.state.bookingInterval)}
                                                onChange={(value) => this.handleChange(value, 'bookingInterval')}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <hr/>
                                        <label>Cancellation Policy</label>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                options={bookingCancellationPeriod}
                                                value={bookingCancellationPeriod.find(x => x.value === this.state.bookingCancellation)}
                                                onChange={(value) => this.handleChange(value, 'bookingCancellation')}
                                            />
                                        </FormGroup>
                                        <FormText>
                                            The cancellation policy allows you to set how far in advance of the booking time customers can cancel or modify their booking.
                                        </FormText>
                                    </Col>
                                    <Col md={12}>
                                        <hr/>
                                        <label>Important Information</label>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                placeholder=""
                                                style={{ padding: 10 }}
                                                value={this.state.bookingImportantInformation}
                                                onChange={(e) => this.handleChange(e, 'bookingImportantInformation')}
                                            />
                                        </FormGroup>
                                        <FormText>
                                            The information declared here will be shown to clients on all booking confirmation and update emails.
                                        </FormText>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <div className="action-button-row">
                                    <Button color="primary" onClick={() => this.submitBookingPreferences()}>
                                        Save Changes
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingPreferences));