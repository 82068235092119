const time = [
    {
        id: 1,
        label: "00:00",
        value: "00:00"
    },
    {
        id: 2,
        label: "00:05",
        value: "00:05"
    },
    {
        id: 3,
        label: "00:10",
        value: "00:10"
    },
    {
        id: 4,
        label: "00:15",
        value: "00:15"
    },
    {
        id: 5,
        label: "00:20",
        value: "00:20"
    },
    {
        id: 6,
        label: "00:25",
        value: "00:25"
    },
    {
        id: 7,
        label: "00:30",
        value: "00:30"
    },
    {
        id: 8,
        label: "00:35",
        value: "00:35"
    },
    {
        id: 9,
        label: "00:40",
        value: "00:40"
    },
    {
        id: 10,
        label: "00:45",
        value: "00:45"
    },
    {
        id: 11,
        label: "00:50",
        value: "00:50"
    },
    {
        id: 12,
        label: "00:55",
        value: "00:55"
    },
    {
        id: 13,
        label: "01:00",
        value: "01:00"
    },
    {
        id: 14,
        label: "01:05",
        value: "01:05"
    },
    {
        id: 15,
        label: "01:10",
        value: "01:10"
    },
    {
        id: 16,
        label: "01:15",
        value: "01:15"
    },
    {
        id: 17,
        label: "01:20",
        value: "01:20"
    },
    {
        id: 18,
        label: "01:25",
        value: "01:25"
    },
    {
        id: 19,
        label: "01:30",
        value: "01:30"
    },
    {
        id: 20,
        label: "01:35",
        value: "01:35"
    },
    {
        id: 21,
        label: "01:40",
        value: "01:40"
    },
    {
        id: 22,
        label: "01:45",
        value: "01:45"
    },
    {
        id: 23,
        label: "01:50",
        value: "01:50"
    },
    {
        id: 24,
        label: "01:55",
        value: "01:55"
    },
    {
        id: 25,
        label: "02:00",
        value: "02:00"
    },
    {
        id: 26,
        label: "02:05",
        value: "02:05"
    },
    {
        id: 27,
        label: "02:10",
        value: "02:10"
    },
    {
        id: 28,
        label: "02:15",
        value: "02:15"
    },
    {
        id: 29,
        label: "02:20",
        value: "02:20"
    },
    {
        id: 30,
        label: "02:25",
        value: "02:25"
    },
    {
        id: 31,
        label: "02:30",
        value: "02:30"
    },
    {
        id: 32,
        label: "02:35",
        value: "02:35"
    },
    {
        id: 33,
        label: "02:40",
        value: "02:40"
    },
    {
        id: 34,
        label: "02:45",
        value: "02:45"
    },
    {
        id: 35,
        label: "02:50",
        value: "02:50"
    },
    {
        id: 36,
        label: "02:55",
        value: "02:55"
    },
    {
        id: 37,
        label: "03:00",
        value: "03:00"
    },
    {
        id: 38,
        label: "03:05",
        value: "03:05"
    },
    {
        id: 39,
        label: "03:10",
        value: "03:10"
    },
    {
        id: 40,
        label: "03:15",
        value: "03:15"
    },
    {
        id: 41,
        label: "03:20",
        value: "03:20"
    },
    {
        id: 42,
        label: "03:25",
        value: "03:25"
    },
    {
        id: 43,
        label: "03:30",
        value: "03:30"
    },
    {
        id: 44,
        label: "03:35",
        value: "03:35"
    },
    {
        id: 45,
        label: "03:40",
        value: "03:40"
    },
    {
        id: 46,
        label: "03:45",
        value: "03:45"
    },
    {
        id: 47,
        label: "03:50",
        value: "03:50"
    },
    {
        id: 48,
        label: "03:55",
        value: "03:55"
    },
    {
        id: 49,
        label: "04:00",
        value: "04:00"
    },
    {
        id: 50,
        label: "04:05",
        value: "04:05"
    },
    {
        id: 51,
        label: "04:10",
        value: "04:10"
    },
    {
        id: 52,
        label: "04:15",
        value: "04:15"
    },
    {
        id: 53,
        label: "04:20",
        value: "04:20"
    },
    {
        id: 54,
        label: "04:25",
        value: "04:25"
    },
    {
        id: 55,
        label: "04:30",
        value: "04:30"
    },
    {
        id: 56,
        label: "04:35",
        value: "04:35"
    },
    {
        id: 57,
        label: "04:40",
        value: "04:40"
    },
    {
        id: 58,
        label: "04:45",
        value: "04:45"
    },
    {
        id: 59,
        label: "04:50",
        value: "04:50"
    },
    {
        id: 60,
        label: "04:55",
        value: "04:55"
    },
    {
        id: 61,
        label: "05:00",
        value: "05:00"
    },
    {
        id: 62,
        label: "05:05",
        value: "05:05"
    },
    {
        id: 63,
        label: "05:10",
        value: "05:10"
    },
    {
        id: 64,
        label: "05:15",
        value: "05:15"
    },
    {
        id: 65,
        label: "05:20",
        value: "05:20"
    },
    {
        id: 66,
        label: "05:25",
        value: "05:25"
    },
    {
        id: 67,
        label: "05:30",
        value: "05:30"
    },
    {
        id: 68,
        label: "05:35",
        value: "05:35"
    },
    {
        id: 69,
        label: "05:40",
        value: "05:40"
    },
    {
        id: 70,
        label: "05:45",
        value: "05:45"
    },
    {
        id: 71,
        label: "05:50",
        value: "05:50"
    },
    {
        id: 72,
        label: "05:55",
        value: "05:55"
    },
    {
        id: 73,
        label: "06:00",
        value: "06:00"
    },
    {
        id: 74,
        label: "06:05",
        value: "06:05"
    },
    {
        id: 75,
        label: "06:10",
        value: "06:10"
    },
    {
        id: 76,
        label: "06:15",
        value: "06:15"
    },
    {
        id: 77,
        label: "06:20",
        value: "06:20"
    },
    {
        id: 78,
        label: "06:25",
        value: "06:25"
    },
    {
        id: 79,
        label: "06:30",
        value: "06:30"
    },
    {
        id: 80,
        label: "06:35",
        value: "06:35"
    },
    {
        id: 81,
        label: "06:40",
        value: "06:40"
    },
    {
        id: 82,
        label: "06:45",
        value: "06:45"
    },
    {
        id: 83,
        label: "06:50",
        value: "06:50"
    },
    {
        id: 84,
        label: "06:55",
        value: "06:55"
    },
    {
        id: 85,
        label: "07:00",
        value: "07:00"
    },
    {
        id: 86,
        label: "07:05",
        value: "07:05"
    },
    {
        id: 87,
        label: "07:10",
        value: "07:10"
    },
    {
        id: 88,
        label: "07:15",
        value: "07:15"
    },
    {
        id: 89,
        label: "07:20",
        value: "07:20"
    },
    {
        id: 90,
        label: "07:25",
        value: "07:25"
    },
    {
        id: 91,
        label: "07:30",
        value: "07:30"
    },
    {
        id: 92,
        label: "07:35",
        value: "07:35"
    },
    {
        id: 93,
        label: "07:40",
        value: "07:40"
    },
    {
        id: 94,
        label: "07:45",
        value: "07:45"
    },
    {
        id: 95,
        label: "07:50",
        value: "07:50"
    },
    {
        id: 96,
        label: "07:55",
        value: "07:55"
    },
    {
        id: 97,
        label: "08:00",
        value: "08:00"
    },
    {
        id: 98,
        label: "08:05",
        value: "08:05"
    },
    {
        id: 99,
        label: "08:10",
        value: "08:10"
    },
    {
        id: 100,
        label: "08:15",
        value: "08:15"
    },
    {
        id: 101,
        label: "08:20",
        value: "08:20"
    },
    {
        id: 102,
        label: "08:25",
        value: "08:25"
    },
    {
        id: 103,
        label: "08:30",
        value: "08:30"
    },
    {
        id: 104,
        label: "08:35",
        value: "08:35"
    },
    {
        id: 105,
        label: "08:40",
        value: "08:40"
    },
    {
        id: 106,
        label: "08:45",
        value: "08:45"
    },
    {
        id: 107,
        label: "08:50",
        value: "08:50"
    },
    {
        id: 108,
        label: "08:55",
        value: "08:55"
    },
    {
        id: 109,
        label: "09:00",
        value: "09:00"
    },
    {
        id: 110,
        label: "09:05",
        value: "09:05"
    },
    {
        id: 111,
        label: "09:10",
        value: "09:10"
    },
    {
        id: 112,
        label: "09:15",
        value: "09:15"
    },
    {
        id: 113,
        label: "09:20",
        value: "09:20"
    },
    {
        id: 114,
        label: "09:25",
        value: "09:25"
    },
    {
        id: 115,
        label: "09:30",
        value: "09:30"
    },
    {
        id: 116,
        label: "09:35",
        value: "09:35"
    },
    {
        id: 117,
        label: "09:40",
        value: "09:40"
    },
    {
        id: 118,
        label: "09:45",
        value: "09:45"
    },
    {
        id: 119,
        label: "09:50",
        value: "09:50"
    },
    {
        id: 120,
        label: "09:55",
        value: "09:55"
    },
    {
        id: 121,
        label: "10:00",
        value: "10:00"
    },
    {
        id: 122,
        label: "10:05",
        value: "10:05"
    },
    {
        id: 123,
        label: "10:10",
        value: "10:10"
    },
    {
        id: 124,
        label: "10:15",
        value: "10:15"
    },
    {
        id: 125,
        label: "10:20",
        value: "10:20"
    },
    {
        id: 126,
        label: "10:25",
        value: "10:25"
    },
    {
        id: 127,
        label: "10:30",
        value: "10:30"
    },
    {
        id: 128,
        label: "10:35",
        value: "10:35"
    },
    {
        id: 129,
        label: "10:40",
        value: "10:40"
    },
    {
        id: 130,
        label: "10:45",
        value: "10:45"
    },
    {
        id: 131,
        label: "10:50",
        value: "10:50"
    },
    {
        id: 132,
        label: "10:55",
        value: "10:55"
    },
    {
        id: 133,
        label: "11:00",
        value: "11:00"
    },
    {
        id: 134,
        label: "11:05",
        value: "11:05"
    },
    {
        id: 135,
        label: "11:10",
        value: "11:10"
    },
    {
        id: 136,
        label: "11:15",
        value: "11:15"
    },
    {
        id: 137,
        label: "11:20",
        value: "11:20"
    },
    {
        id: 138,
        label: "11:25",
        value: "11:25"
    },
    {
        id: 139,
        label: "11:30",
        value: "11:30"
    },
    {
        id: 140,
        label: "11:35",
        value: "11:35"
    },
    {
        id: 141,
        label: "11:40",
        value: "11:40"
    },
    {
        id: 142,
        label: "11:45",
        value: "11:45"
    },
    {
        id: 143,
        label: "11:50",
        value: "11:50"
    },
    {
        id: 144,
        label: "11:55",
        value: "11:55"
    },
    {
        id: 145,
        label: "12:00",
        value: "12:00"
    },
    {
        id: 146,
        label: "12:05",
        value: "12:05"
    },
    {
        id: 147,
        label: "12:10",
        value: "12:10"
    },
    {
        id: 148,
        label: "12:15",
        value: "12:15"
    },
    {
        id: 149,
        label: "12:20",
        value: "12:20"
    },
    {
        id: 150,
        label: "12:25",
        value: "12:25"
    },
    {
        id: 151,
        label: "12:30",
        value: "12:30"
    },
    {
        id: 152,
        label: "12:35",
        value: "12:35"
    },
    {
        id: 153,
        label: "12:40",
        value: "12:40"
    },
    {
        id: 154,
        label: "12:45",
        value: "12:45"
    },
    {
        id: 155,
        label: "12:50",
        value: "12:50"
    },
    {
        id: 156,
        label: "12:55",
        value: "12:55"
    },
    {
        id: 157,
        label: "13:00",
        value: "13:00"
    },
    {
        id: 158,
        label: "13:05",
        value: "13:05"
    },
    {
        id: 159,
        label: "13:10",
        value: "13:10"
    },
    {
        id: 160,
        label: "13:15",
        value: "13:15"
    },
    {
        id: 161,
        label: "13:20",
        value: "13:20"
    },
    {
        id: 162,
        label: "13:25",
        value: "13:25"
    },
    {
        id: 163,
        label: "13:30",
        value: "13:30"
    },
    {
        id: 164,
        label: "13:35",
        value: "13:35"
    },
    {
        id: 165,
        label: "13:40",
        value: "13:40"
    },
    {
        id: 166,
        label: "13:45",
        value: "13:45"
    },
    {
        id: 167,
        label: "13:50",
        value: "13:50"
    },
    {
        id: 168,
        label: "13:55",
        value: "13:55"
    },
    {
        id: 169,
        label: "14:00",
        value: "14:00"
    },
    {
        id: 170,
        label: "14:05",
        value: "14:05"
    },
    {
        id: 171,
        label: "14:10",
        value: "14:10"
    },
    {
        id: 172,
        label: "14:15",
        value: "14:15"
    },
    {
        id: 173,
        label: "14:20",
        value: "14:20"
    },
    {
        id: 174,
        label: "14:25",
        value: "14:25"
    },
    {
        id: 175,
        label: "14:30",
        value: "14:30"
    },
    {
        id: 176,
        label: "14:35",
        value: "14:35"
    },
    {
        id: 177,
        label: "14:40",
        value: "14:40"
    },
    {
        id: 178,
        label: "14:45",
        value: "14:45"
    },
    {
        id: 179,
        label: "14:50",
        value: "14:50"
    },
    {
        id: 180,
        label: "14:55",
        value: "14:55"
    },
    {
        id: 181,
        label: "15:00",
        value: "15:00"
    },
    {
        id: 182,
        label: "15:05",
        value: "15:05"
    },
    {
        id: 183,
        label: "15:10",
        value: "15:10"
    },
    {
        id: 184,
        label: "15:15",
        value: "15:15"
    },
    {
        id: 185,
        label: "15:20",
        value: "15:20"
    },
    {
        id: 186,
        label: "15:25",
        value: "15:25"
    },
    {
        id: 187,
        label: "15:30",
        value: "15:30"
    },
    {
        id: 188,
        label: "15:35",
        value: "15:35"
    },
    {
        id: 189,
        label: "15:40",
        value: "15:40"
    },
    {
        id: 190,
        label: "15:45",
        value: "15:45"
    },
    {
        id: 191,
        label: "15:50",
        value: "15:50"
    },
    {
        id: 192,
        label: "15:55",
        value: "15:55"
    },
    {
        id: 193,
        label: "16:00",
        value: "16:00"
    },
    {
        id: 194,
        label: "16:05",
        value: "16:05"
    },
    {
        id: 195,
        label: "16:10",
        value: "16:10"
    },
    {
        id: 196,
        label: "16:15",
        value: "16:15"
    },
    {
        id: 197,
        label: "16:20",
        value: "16:20"
    },
    {
        id: 198,
        label: "16:25",
        value: "16:25"
    },
    {
        id: 199,
        label: "16:30",
        value: "16:30"
    },
    {
        id: 200,
        label: "16:35",
        value: "16:35"
    },
    {
        id: 201,
        label: "16:40",
        value: "16:40"
    },
    {
        id: 202,
        label: "16:45",
        value: "16:45"
    },
    {
        id: 203,
        label: "16:50",
        value: "16:50"
    },
    {
        id: 204,
        label: "16:55",
        value: "16:55"
    },
    {
        id: 205,
        label: "17:00",
        value: "17:00"
    },
    {
        id: 206,
        label: "17:05",
        value: "17:05"
    },
    {
        id: 207,
        label: "17:10",
        value: "17:10"
    },
    {
        id: 208,
        label: "17:15",
        value: "17:15"
    },
    {
        id: 209,
        label: "17:20",
        value: "17:20"
    },
    {
        id: 210,
        label: "17:25",
        value: "17:25"
    },
    {
        id: 211,
        label: "17:30",
        value: "17:30"
    },
    {
        id: 212,
        label: "17:35",
        value: "17:35"
    },
    {
        id: 213,
        label: "17:40",
        value: "17:40"
    },
    {
        id: 214,
        label: "17:45",
        value: "17:45"
    },
    {
        id: 215,
        label: "17:50",
        value: "17:50"
    },
    {
        id: 216,
        label: "17:55",
        value: "17:55"
    },
    {
        id: 217,
        label: "18:00",
        value: "18:00"
    },
    {
        id: 218,
        label: "18:05",
        value: "18:05"
    },
    {
        id: 219,
        label: "18:10",
        value: "18:10"
    },
    {
        id: 220,
        label: "18:15",
        value: "18:15"
    },
    {
        id: 221,
        label: "18:20",
        value: "18:20"
    },
    {
        id: 222,
        label: "18:25",
        value: "18:25"
    },
    {
        id: 223,
        label: "18:30",
        value: "18:30"
    },
    {
        id: 224,
        label: "18:35",
        value: "18:35"
    },
    {
        id: 225,
        label: "18:40",
        value: "18:40"
    },
    {
        id: 226,
        label: "18:45",
        value: "18:45"
    },
    {
        id: 227,
        label: "18:50",
        value: "18:50"
    },
    {
        id: 228,
        label: "18:55",
        value: "18:55"
    },
    {
        id: 229,
        label: "19:00",
        value: "19:00"
    },
    {
        id: 230,
        label: "19:05",
        value: "19:05"
    },
    {
        id: 231,
        label: "19:10",
        value: "19:10"
    },
    {
        id: 232,
        label: "19:15",
        value: "19:15"
    },
    {
        id: 233,
        label: "19:20",
        value: "19:20"
    },
    {
        id: 234,
        label: "19:25",
        value: "19:25"
    },
    {
        id: 235,
        label: "19:30",
        value: "19:30"
    },
    {
        id: 236,
        label: "19:35",
        value: "19:35"
    },
    {
        id: 237,
        label: "19:40",
        value: "19:40"
    },
    {
        id: 238,
        label: "19:45",
        value: "19:45"
    },
    {
        id: 239,
        label: "19:50",
        value: "19:50"
    },
    {
        id: 240,
        label: "19:55",
        value: "19:55"
    },
    {
        id: 241,
        label: "20:00",
        value: "20:00"
    },
    {
        id: 242,
        label: "20:05",
        value: "20:05"
    },
    {
        id: 243,
        label: "20:10",
        value: "20:10"
    },
    {
        id: 244,
        label: "20:15",
        value: "20:15"
    },
    {
        id: 245,
        label: "20:20",
        value: "20:20"
    },
    {
        id: 246,
        label: "20:25",
        value: "20:25"
    },
    {
        id: 247,
        label: "20:30",
        value: "20:30"
    },
    {
        id: 248,
        label: "20:35",
        value: "20:35"
    },
    {
        id: 249,
        label: "20:40",
        value: "20:40"
    },
    {
        id: 250,
        label: "20:45",
        value: "20:45"
    },
    {
        id: 251,
        label: "20:50",
        value: "20:50"
    },
    {
        id: 252,
        label: "20:55",
        value: "20:55"
    },
    {
        id: 253,
        label: "21:00",
        value: "21:00"
    },
    {
        id: 254,
        label: "21:05",
        value: "21:05"
    },
    {
        id: 255,
        label: "21:10",
        value: "21:10"
    },
    {
        id: 256,
        label: "21:15",
        value: "21:15"
    },
    {
        id: 257,
        label: "21:20",
        value: "21:20"
    },
    {
        id: 258,
        label: "21:25",
        value: "21:25"
    },
    {
        id: 259,
        label: "21:30",
        value: "21:30"
    },
    {
        id: 260,
        label: "21:35",
        value: "21:35"
    },
    {
        id: 261,
        label: "21:40",
        value: "21:40"
    },
    {
        id: 262,
        label: "21:45",
        value: "21:45"
    },
    {
        id: 263,
        label: "21:50",
        value: "21:50"
    },
    {
        id: 264,
        label: "21:55",
        value: "21:55"
    },
    {
        id: 265,
        label: "22:00",
        value: "22:00"
    },
    {
        id: 266,
        label: "22:05",
        value: "22:05"
    },
    {
        id: 267,
        label: "22:10",
        value: "22:10"
    },
    {
        id: 268,
        label: "22:15",
        value: "22:15"
    },
    {
        id: 269,
        label: "22:20",
        value: "22:20"
    },
    {
        id: 270,
        label: "22:25",
        value: "22:25"
    },
    {
        id: 271,
        label: "22:30",
        value: "22:30"
    },
    {
        id: 272,
        label: "22:35",
        value: "22:35"
    },
    {
        id: 273,
        label: "22:40",
        value: "22:40"
    },
    {
        id: 274,
        label: "22:45",
        value: "22:45"
    },
    {
        id: 275,
        label: "22:50",
        value: "22:50"
    },
    {
        id: 276,
        label: "22:55",
        value: "22:55"
    },
    {
        id: 277,
        label: "23:00",
        value: "23:00"
    },
    {
        id: 278,
        label: "23:05",
        value: "23:05"
    },
    {
        id: 279,
        label: "23:10",
        value: "23:10"
    },
    {
        id: 280,
        label: "23:15",
        value: "23:15"
    },
    {
        id: 281,
        label: "23:20",
        value: "23:20"
    },
    {
        id: 282,
        label: "23:25",
        value: "23:25"
    },
    {
        id: 283,
        label: "23:30",
        value: "23:30"
    },
    {
        id: 284,
        label: "23:35",
        value: "23:35"
    },
    {
        id: 285,
        label: "23:40",
        value: "23:40"
    },
    {
        id: 286,
        label: "23:45",
        value: "23:45"
    },
    {
        id: 287,
        label: "23:50",
        value: "23:50"
    },
    {
        id: 288,
        label: "23:55",
        value: "23:55"
    },
    {
        id: 61,
        label: "05:00",
        value: "05:00"
    }
];

export default time;