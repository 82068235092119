/*eslint-disable*/
import React from "react";

// reactstrap components
import {
    Modal,
    ModalBody,
    ModalHeader,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

import { FiPlus } from 'react-icons/fi';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

import { CDN_URL } from "constants/urls";

class CalendarResourceOptions extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        let staffObj = this.props.staff.find(x => x.id === this.props.resourceId);
        const createBookingAccess = this.props.userRole.booking.create === true;
        const createBreakAccess = this.props.userRole.break.create === true;
        const hoursUpdateAccess = this.props.userRole.hours.update === true;
        return (
        <>
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible} className="text-center">
                <ModalHeader
                    className="justify-content-center uppercase booking-options-modal-header"
                    toggle={() => this.props.toggleVisible()}
                >
                    <div className="image">
                        <img alt="BusinessProfileImg" src={`${CDN_URL}/` + this.props.business.business_img} />
                    </div>
                    <div className="author">
                        <div className="customer-profile-avatar" style={{ backgroundColor: '#ffffff' }}>
                            <img className="img img-raised img-round" alt="StaffImg" src={`${CDN_URL}/` + staffObj.staff_img} />
                        </div>
                        <h5 className="title booking-options-modal-header-title">{staffObj ? staffObj.firstname + ' ' + staffObj.lastname : null}</h5>
                        <p className="description booking-options-modal-header-description">{staffObj ? staffObj.position : null}</p>
                    </div>
                </ModalHeader>
                <ModalBody className="calendar-options-modal-body">
                    {(createBreakAccess || createBookingAccess) && (
                        <UncontrolledDropdown>
                            <DropdownToggle block className="btn-block" color="primary">
                                <FiPlus size={16} color={'#1ab394'}/> New Event
                            </DropdownToggle>
                            <DropdownMenu end>
                                {this.props.userRole.booking.create === true && (<DropdownItem onClick={() => this.props.handleOption('booking', this.props.resourceId)}>Booking</DropdownItem>)}
                                {this.props.userRole.booking.create === true && (<DropdownItem onClick={() => this.props.handleOption('walk-in', this.props.resourceId)}>Walk-in</DropdownItem>)}
                                {this.props.userRole.break.create === true && (<DropdownItem onClick={() => this.props.handleOption('break', this.props.resourceId)}>Break</DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}
                    <h6 className="text-muted mt-3">Options</h6>
                    <UncontrolledDropdown>
                        <DropdownToggle block className="btn-block" color="primary" outline caret>
                            Calendar View
                        </DropdownToggle>
                        <DropdownMenu end>
                            <DropdownItem onClick={() => this.props.handleOption('dayView', this.props.resourceId)}>Day</DropdownItem>
                            <DropdownItem onClick={() => this.props.handleOption('threeDayView', this.props.resourceId)}>3 day view</DropdownItem>
                            <DropdownItem onClick={() => this.props.handleOption('weekView', this.props.resourceId)}>Week</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {this.props.calendarView === 'resourceTimeGridDay' && hoursUpdateAccess && (
                        <Button block className="btn-block" color="primary" outline onClick={() => this.props.handleOption('workingHours', this.props.resourceId)}>Edit working hours</Button>
                    )}
                </ModalBody>
            </Modal>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        staff: state.staff,
        business: state.business,
        userRole: state.userRole
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(CalendarResourceOptions);