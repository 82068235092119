import React from 'react';
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name) {
  const avatarBgColor = stringToColor(name);
  const nameSplit = name.split(' ')?.filter(x => x !== '');
  return {
    sx: {
      bgcolor: avatarBgColor
    },
    children: `${nameSplit[0][0]}${nameSplit[nameSplit.length - 1][0]}`,
  };
}

function checkImagePath(imgPath) {
  if(imgPath && imgPath.includes("default")) {
    return null;
  }
  return imgPath;
}

export default function UserAvatar(props) {
  const { name, imgPath, ...rest } = props;
  
  if(checkImagePath(imgPath)) {
    return (<Avatar alt={name} src={imgPath} {...rest} />);
  }
  return (<Avatar alt={name} {...stringAvatar(name)} {...rest} />);
}