import * as React from 'react';
// Mui components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomModal = (props) => {
    const { open, onClose, fullScreen, fullWidth, maxWidth, children, ...rest } = props;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            fullWidth={fullWidth}
            maxWidth={maxWidth ? maxWidth : undefined}
            fullScreen={fullScreen}
            {...rest}
        >
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
};

export default CustomModal;