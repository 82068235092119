import React from "react";

import moment from "moment";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    FormGroup,
    Input,
    Button,
    Spinner,
    Alert,
    InputGroup
} from "reactstrap";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import Datetime from "react-datetime";
import { FiTrash } from 'react-icons/fi';

import time from '../../constants/time';

import Api from "api/index";

class WaitListModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deleteLoading: false,
            error: null,
            name: null,
            userOption: null,
            date: moment(),
            startTime: null,
            endTime: null
        };
    }

    componentDidMount() {
        const { userWaitListData, date } = this.props;
        let newState = { date };
        if(userWaitListData) {
            newState.startTime = moment(userWaitListData.start_time, 'HH:mm');
            newState.endTime = moment(userWaitListData.end_time, 'HH:mm');
        }
        this.setState(newState);
    }

    handleChange(e, name) {
        this.setState({ [name]: e });
    }

    async submitWaitList() {
        if(this.state.loading) {
            return;
        }
        const { userWaitListData } = this.props;
        // Validate name
        if(!this.state.userOption && !userWaitListData) {
            this.setState({ error: 'A customer is required.' });
            return;
        }
        // Validate date
        if(!this.state.date || !moment.isMoment(this.state.date)) {
            this.setState({ error: 'A date is required.' });
            return;
        }
        // Validate times
        if(!this.state.startTime || !moment.isMoment(this.state.startTime)) {
            this.setState({ error: 'A start time is required.' });
            return;
        }
        if(!this.state.endTime || !moment.isMoment(this.state.endTime)) {
            this.setState({ error: 'An end time is required.' });
            return;
        }
        if(moment(this.state.startTime).isSameOrAfter(this.state.endTime, 'minute')) {
            this.setState({ error: 'The start time must be before the end time.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let dateStr = this.state.date.format('YYYY-MM-DD');
            let data = {
                user_id: userWaitListData ? userWaitListData.user_id : this.state.userOption.value,
                date: this.state.date.format('YYYY-MM-DD'),
                start_time: this.state.startTime.format('HH:mm'),
                end_time: this.state.endTime.format('HH:mm')
            };
            if(userWaitListData) {
                data.user_wait_list_id = userWaitListData.user_wait_list_id;
            }
            await Api.postWaitList(data);
            this.props.triggerNotification("Wait list updated successfully.", "success", "bc", 4);
            if(this.props.onSubmit) {
                this.props.onSubmit(dateStr);
            }
        } catch(e) {
            console.log(e);
            if(e.response && e.response.data && e.response.data.message) {
                this.setState({ loading: false, error: e.response.data.message + "."});
            } else {
                this.setState({ loading: false, error: "Unable to update wait list. If the problem persists, please contact us at support@styler.digital." });
            }
        }
    }

    async deleteWaitList() {
        if(this.state.loading || this.state.deleteLoading) {
            return;
        }
        this.setState({ deleteLoading: true, error: null });
        try {
            const { userWaitListData, triggerNotification, date } = this.props;
            await Api.deleteWaitList(userWaitListData.user_wait_list_id);
            triggerNotification("Wait list entry deleted successfully.", "success", "bc", 4);
            if(this.props.onSubmit) {
                this.props.onSubmit(date.format('YYYY-MM-DD'));
            }
        } catch(err) {
            console.log(err);
            this.setState({ deleteLoading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    renderEndDateInput(props,) {
        return (
            <input {...props} value={this.state.endDate ? props.value : "Ongoing"} />
        );
    }

    async promiseCustomerOptions(inputValue) {
        try {
            if(inputValue.length < 3) {
                return [];
            } else {
                let searchResults = await Api.searchCustomers({ search_string: inputValue });
                let customerOptions = [];
                searchResults.data.customerData.forEach((customerObj, customerIndex) => {
                    customerOptions.push({
                        label: customerObj.user_firstname + ' ' + customerObj.user_lastname,
                        value: customerObj.user_id,
                        email: customerObj.user_email,
                        phone: customerObj.user_phone,
                        firstname: customerObj.user_firstname,
                        lastname: customerObj.user_lastname
                    });
                });
                return customerOptions;
            }
        } catch(e) {
            this.props.triggerNotification("An error occured whilst searching customers. We were unable to complete the search.", "danger", "bc", 6);
        }
    }

    formatOptionLabel({ label, email, firstname, lastname }) {
        return (
            <div style={{ display: "flex", flexDirection: 'row' }}>
                <div className="customer-option-circle">
                    <div className="customer-option-circle-text">{firstname.charAt(0) + lastname.charAt(0)}</div>
                </div>
                <div>
                    <div>{label}</div>
                    <div className="text-muted customer-option-contact" style={{ marginTop: -7 }}>{email}</div>
                </div>
            </div>
        );
    };

    renderButtonContent() {
        if(this.state.loading) {
            return <Spinner color="success" size="sm" />;
        }
        return this.props.userWaitListData ? "Update" : "Add"
    }

    render() {
        const { visible, toggleVisible, userWaitListData } = this.props;
        return (
            <Modal isOpen={visible} toggle={toggleVisible}>
                <ModalHeader
                    className="justify-content-center uppercase title"
                    toggle={toggleVisible}
                    tag="h4"
                >
                    {userWaitListData ? "Update" : "Add to"} Wait List
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <label>Customer <span className="text-danger">*</span></label>
                            <InputGroup>
                                {userWaitListData ? (
                                    <Input
                                        type="text"
                                        value={userWaitListData.user_name}
                                        placeholder="Loading..."
                                        disabled={true}
                                        onChange={(e) => this.handleChange(e, 'title')}
                                    />
                                ) : (
                                    <AsyncSelect
                                        className="react-select primary react-select-w-addon"
                                        classNamePrefix="react-select"
                                        cacheOptions
                                        defaultValue={this.state.userOption}
                                        loadOptions={(e) => this.promiseCustomerOptions(e)}
                                        formatOptionLabel={this.formatOptionLabel}
                                        placeholder={'Search for a customer...'}
                                        onChange={(e) => this.handleChange(e, 'userOption')}
                                    />
                                )}
                            </InputGroup>
                        </Col>
                        <Col md={12}>
                            <label>Date <span className="text-danger">*</span></label>
                            <FormGroup className="date-group">
                                <Datetime
                                    className="date-input"
                                    dateFormat={"ddd, Do MMM YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "Date", readonly: "readonly" }}
                                    value={this.state.date ? this.state.date : null}
                                    onChange={(e) => this.handleChange(e, 'date')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} sm={12}>
                            <label>Start & End Time <span className="text-danger">*</span></label>
                            <FormGroup className="time-group">
                                <Select
                                    className="react-select primary time-input time-input-left"
                                    classNamePrefix="react-select"
                                    options={time}
                                    value={this.state.startTime ? time.find(x => x.value === this.state.startTime.format('HH:mm')) : null}
                                    onChange={(e) => this.handleChange(moment(e.value, 'HH:mm'), 'startTime')}
                                    placeholder={"--:--"}
                                />
                                <Select
                                    className="react-select primary time-input time-input-right"
                                    classNamePrefix="react-select"
                                    options={time}
                                    value={this.state.endTime ? time.find(x => x.value === this.state.endTime.format('HH:mm')) : null}
                                    onChange={(e) => this.handleChange(moment(e.value, 'HH:mm'), 'endTime')}
                                    placeholder={"--:--"}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="modal-split-footer">
                        <Button
                            color="primary"
                            style={{ width: '100%' }}
                            onClick={() => this.submitWaitList()}
                        >
                            {this.renderButtonContent()}
                        </Button>
                        {userWaitListData ? (
                            <Button
                                color="danger"
                                className="modal-split-footer-right-button"
                                onClick={() => this.deleteWaitList()}
                            >
                                {this.state.deleteLoading ? <Spinner color="light" size="sm" /> : <FiTrash size={15}/>}
                            </Button>
                        ) : null}
                    </div>
                    <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error ? true : false} toggle={() => this.setState({ error: null })}>
                        <span>
                            {this.state.error}
                        </span>
                    </Alert>
                </ModalFooter>
            </Modal>
        );
    }
}

export default WaitListModal;