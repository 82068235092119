import React from "react";
import moment from "moment";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Card,
    CardHeader,
    Collapse,
    CardBody,
    Spinner,
    Button,
    FormGroup
} from "reactstrap";
import Select from 'react-select';

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import { FiArrowRight, FiPlus } from "react-icons/fi";
import Api from "api";

class StaffSchedules extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            openedCollapses: ["collapseTwo"],
            scheduleData: [],
            conflictingSchedules: [],
            upcomingSchedules: [],
            previousSchedules: [],
            locationOptions: [],
            selectedLocationId: null,
        };
    }

    componentDidMount() {
        // Populate location options
        let locationOptions = [];
        this.props.businessLocation.filter(x => x.enabled === 1).forEach((businessLocationObj, businessLocationIndex) => {
            let foundLocationMap = this.props.businessLocationStaffMap.find(x => x.staff_id === this.props.staffId && x.business_location_id === businessLocationObj.business_location_id);
            if(foundLocationMap) {
                locationOptions.push({
                    id: businessLocationObj.business_location_id,
                    label: businessLocationObj.business_location_name,
                    value: businessLocationObj.business_location_id
                });
            }
        });
        let selectedLocationId = null;
        if(locationOptions.length > 0) {
            if(this.props.locationId) {
                selectedLocationId = this.props.locationId;
            } else {
                selectedLocationId = locationOptions[0].id;
            }
        }
        this.setState({ locationOptions, selectedLocationId });
        this.getStaffSchedules(selectedLocationId);
    }

    async getStaffSchedules(locationId) {
        if(!locationId) {
            return;
        }
        this.setState({ loading: true });
        try {
            let response = await Api.getStaffSchedules({ staff_id: this.props.staffId });
            let scheduleData = response.data.scheduleData;
            let conflictingSchedules = [], upcomingSchedules = [], previousSchedules = [];
            let now = moment().tz(this.props.business.timezone_name, false);
            scheduleData.filter(x => x.business_location_id === locationId).forEach(scheduleObj => {
                // Detect schedule conflict
                let filteredSchedules = scheduleData.filter(x => x.staff_schedule_id !== scheduleObj.staff_schedule_id && x.business_location_id === scheduleObj.business_location_id);
                let foundSchedule = null;
                if(!scheduleObj.staff_schedule_end) {
                    foundSchedule = filteredSchedules.find(x =>
                        (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                        !x.staff_schedule_end ||
                        moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day')
                    );
                } else {
                    foundSchedule = filteredSchedules.find(x =>
                        (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                        (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                        (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                        (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                        (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day') && !x.staff_schedule_end)
                    );
                }
                if(foundSchedule) {
                    conflictingSchedules.push(scheduleObj);
                } else {
                    // Classify schedule
                    if(scheduleObj.staff_schedule_end) {
                        let scheduleStart = moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss');
                        let scheduleEnd = moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss');
                        if(scheduleStart.isAfter(now, 'day')) {
                            upcomingSchedules.push(scheduleObj);
                        } else if(scheduleStart.isBefore(now, 'day') && scheduleEnd.isAfter(now, 'day')) {
                            upcomingSchedules.push(scheduleObj);
                        } else {
                            previousSchedules.push(scheduleObj);
                        }
                    } else {
                        upcomingSchedules.push(scheduleObj);
                    }
                }
            });
            this.setState({ loading: false, scheduleData, conflictingSchedules, upcomingSchedules, previousSchedules });
        } catch(e) {
            this.setState({ loading: false });
        }
    }

    sortSchedules(locationId) {
        if(!this.state.scheduleData) {
            return;
        }
        this.setState({ loading: true });
        let scheduleData = this.state.scheduleData;
        let conflictingSchedules = [], upcomingSchedules = [], previousSchedules = [];
        let now = moment().tz(this.props.business.timezone_name, false);
        scheduleData.filter(x => x.business_location_id === locationId).forEach(scheduleObj => {
            // Detect schedule conflict
            let filteredSchedules = scheduleData.filter(x => x.staff_schedule_id !== scheduleObj.staff_schedule_id && x.business_location_id === scheduleObj.business_location_id);
            let foundSchedule = null;
            if(!scheduleObj.staff_schedule_end) {
                foundSchedule = filteredSchedules.find(x =>
                    (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                    !x.staff_schedule_end ||
                    moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day')
                );
            } else {
                foundSchedule = filteredSchedules.find(x =>
                    (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                    (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                    (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                    (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day') && moment(x.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day')) ||
                    (moment(x.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss'), 'day') && !x.staff_schedule_end)
                );
            }
            if(foundSchedule) {
                conflictingSchedules.push(scheduleObj);
            } else {
                // Classify schedule
                if(scheduleObj.staff_schedule_end) {
                    let scheduleStart = moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss');
                    let scheduleEnd = moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss');
                    if(scheduleStart.isAfter(now, 'day')) {
                        upcomingSchedules.push(scheduleObj);
                    } else if(scheduleStart.isBefore(now, 'day') && scheduleEnd.isAfter(now, 'day')) {
                        upcomingSchedules.push(scheduleObj);
                    } else {
                        previousSchedules.push(scheduleObj);
                    }
                } else {
                    upcomingSchedules.push(scheduleObj);
                }
            }
        });
        this.setState({ loading: false, conflictingSchedules, upcomingSchedules, previousSchedules });
    }

    collapsesToggle = (collapse) => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
          this.setState({
            openedCollapses: [],
          });
        } else {
          this.setState({
            openedCollapses: [collapse],
          });
        }
    };

    handleAddScheduleClick() {
        this.props.toggleAddVisible(this.state.selectedLocationId);
        this.props.toggleVisible();
    }

    handleLocationChange(e) {
        console.log(e.id);
        if(e.id === this.state.selectedLocationId) {
            return;
        }
        this.setState({ selectedLocationId: e.id });
        this.sortSchedules(e.id);
    }

    render() {
        let staffObj = this.props.staff.find(x => x.id === this.props.staffId);
        return (
            <Modal isOpen={this.props.visible} toggle={this.props.toggleVisible}>
                <ModalHeader className="justify-content-center uppercase title" toggle={this.props.toggleVisible} tag="h4">{staffObj ? staffObj.firstname + "'s" : null} Schedules</ModalHeader>
                <ModalBody>
                    {this.props.businessLocation && this.props.businessLocation.filter(x => x.enabled === 1).length > 1 ?
                        <Row>
                            <Col md={12}>
                                <h6>Location</h6>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <FormGroup>
                                    <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={this.state.locationOptions}
                                        value={this.state.locationOptions ? this.state.locationOptions.find(x => x.id === this.state.selectedLocationId) : null}
                                        placeholder={'Choose location...'}
                                        onChange={(e) => this.handleLocationChange(e)}
                                        captureMenuScroll={true}
                                        controlShouldRenderValue={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    : null}
                    <Row>
                        {(this.props.userRole.hours?.update_all === true || (this.props.userRole.hours?.update === true && this.props.user.staff_id === this.props.staffId)) && (
                            <Col md={12}>
                                <Button color="primary" block onClick={() => this.handleAddScheduleClick()}>
                                    <FiPlus size={16} color={'#1ab394'}/> Add new schedule
                                </Button>
                                <hr/>
                            </Col>
                        )}
                        <Col md={12}>
                            {this.state.previousSchedules.length === 0 && this.state.upcomingSchedules.length === 0 && this.state.conflictingSchedules.length === 0 && !this.state.loading ?
                                <div className="text-center" style={{ marginBottom: 25 }}>No schedules to display</div>
                            : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {this.state.loading ?
                                <div className="text-center" style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Spinner size="lg" color="success"/>
                                </div>
                            : null}
                            <div
                                aria-multiselectable={true}
                                className="card-collapse"
                                id="accordion"
                                role="tablist"
                            >
                                {this.state.conflictingSchedules && this.state.conflictingSchedules.length > 0 && !this.state.loading ?
                                    <Card className="card-plain">
                                        <CardHeader role="tab" className="schedule-category-header schedule-category-header-conflict">
                                            <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "collapseOne"
                                            )}
                                            href="#pablo"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.collapsesToggle("collapseOne");
                                            }}
                                            >
                                            Conflicting Schedules{" "}
                                            <i className="now-ui-icons arrows-1_minimal-down" />
                                            </a>
                                        </CardHeader>
                                        <Collapse
                                            role="tabpanel"
                                            isOpen={this.state.openedCollapses.includes(
                                            "collapseOne"
                                            )}
                                        >
                                            <CardBody className="schedule-category-body">
                                                <div>
                                                    {this.state.conflictingSchedules.map(scheduleObj => {
                                                        return (
                                                            <div className="schedule-container" onClick={() => this.props.onSubmit(scheduleObj.staff_schedule_id, this.state.selectedLocationId)}>
                                                                <div>{moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY')}<FiArrowRight style={{ marginBottom: 3, marginLeft: 10, marginRight: 10 }}/>{scheduleObj.staff_schedule_end ? moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY') : "Ongoing"}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                : null}
                                {this.state.upcomingSchedules && this.state.upcomingSchedules.length > 0 && !this.state.loading ?
                                    <Card className="card-plain">
                                        <CardHeader role="tab" className="schedule-category-header schedule-category-header-primary">
                                            <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "collapseTwo"
                                            )}
                                            href="#pablo"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.collapsesToggle("collapseTwo");
                                            }}
                                            >
                                            Upcoming Schedules{" "}
                                            <i className="now-ui-icons arrows-1_minimal-down" />
                                            </a>
                                        </CardHeader>
                                        <Collapse
                                            role="tabpanel"
                                            isOpen={this.state.openedCollapses.includes(
                                            "collapseTwo"
                                            )}
                                        >
                                            <CardBody className="schedule-category-body">
                                                <div>
                                                    {this.state.upcomingSchedules.map(scheduleObj => {
                                                        return (
                                                            <div className="schedule-container" onClick={() => this.props.onSubmit(scheduleObj.staff_schedule_id, this.state.selectedLocationId)}>
                                                                <div>{moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY')}<FiArrowRight style={{ marginBottom: 3, marginLeft: 10, marginRight: 10 }}/>{scheduleObj.staff_schedule_end ? moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY') : "Ongoing"}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                : null}
                                {this.state.previousSchedules && this.state.previousSchedules.length > 0 && !this.state.loading ?
                                    <Card className="card-plain">
                                        <CardHeader role="tab" className="schedule-category-header schedule-category-header-primary">
                                            <a
                                            aria-expanded={this.state.openedCollapses.includes(
                                                "collapseThree"
                                            )}
                                            href="#pablo"
                                            data-parent="#accordion"
                                            data-toggle="collapse"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.collapsesToggle("collapseThree");
                                            }}
                                            >
                                            Previous Schedules{" "}
                                            <i className="now-ui-icons arrows-1_minimal-down" />
                                            </a>
                                        </CardHeader>
                                        <Collapse
                                            role="tabpanel"
                                            isOpen={this.state.openedCollapses.includes(
                                            "collapseThree"
                                            )}
                                        >
                                            <CardBody className="schedule-category-body">
                                                <div>
                                                    {this.state.previousSchedules.map(scheduleObj => {
                                                        return (
                                                            <div className="schedule-container" onClick={() => this.props.onSubmit(scheduleObj.staff_schedule_id, this.state.selectedLocationId)}>
                                                                <div>{moment(scheduleObj.staff_schedule_start, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY')}<FiArrowRight style={{ marginBottom: 3, marginLeft: 10, marginRight: 10 }}/>{scheduleObj.staff_schedule_end ? moment(scheduleObj.staff_schedule_end, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM YYYY') : "Ongoing"}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                : null}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        businessLocation: state.businessLocation,
        businessLocationStaffMap: state.businessLocationStaffMap,
        user: state.user,
        userRole: state.userRole
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffSchedules);