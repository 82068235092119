import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    FormText
} from "reactstrap";
import Switch from "react-switch";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from "draft-convert";

import Api from 'api/index';
import withRouter from "utilities/withRouter";

class BookingPreferences extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingTermsEnabled: false,
            bookingTermsEditorState: EditorState.createEmpty()
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    async componentDidMount() {
        this.getBookingTerms();
    }

    async getBookingTerms() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let response = await Api.getBookingTerms();
            let bookingTermsData = response.data.bookingTermsData;
            // Generate terms state
            let bookingTermsEditorState = null;
            if(bookingTermsData.booking_terms && bookingTermsData.booking_terms.length > 0) {
                const blocksFromHTML = convertFromHTML(bookingTermsData.booking_terms);
                const state = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap,
                );
                bookingTermsEditorState = EditorState.createWithContent(state);
            } else {
                bookingTermsEditorState = EditorState.createEmpty();
            }
            this.setState({
                bookingTermsEnabled: bookingTermsData.booking_terms_enabled === 1 ? true : false,
                bookingTermsEditorState
            });
            this.props.actions.loadLoadingSpinner(false);
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured whilst loading your booking terms.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
            this.props.navigate("/admin/settings");
        }
    }

    async submitBookingTerms() {
        this.props.actions.loadLoadingSpinner(true);
        try {
            let bookingTerms = convertToHTML(this.state.bookingTermsEditorState.getCurrentContent());
            await Api.updateBookingTerms({
                booking_terms_enabled: this.state.bookingTermsEnabled,
                booking_terms: bookingTerms
            });
            this.props.triggerNotification("Booking terms updated successfully.", "success", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
            this.props.navigate('/admin/settings');
        } catch(e) {
            this.props.triggerNotification("An unexpected error occured while updating your booking terms.", "danger", "bc", 4);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    handleChange(e, name) {
        if(name === "bookingTermsEnabled") {
            this.setState({ bookingTermsEnabled: e });
        } else if(name === "bookingTerms") {
            this.setState({ bookingTermsEditorState: e });
        }
    }

    handleEditorChange(editorState) {
        this.handleChange(editorState, "bookingTerms");
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Booking T&Cs</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} lg={10} xl={6} className="ms-auto me-auto">
                        <Card style={{ marginBottom: 0 }}>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12} className="mb-2">
                                        <FormGroup>
                                            <p className="react-switch-label" style={{ fontSize: '1.2em', paddingTop: 0, color: '#0a122b' }}>Booking Terms & Conditions</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'bookingTermsEnabled') }}
                                                checked={this.state.bookingTermsEnabled}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                            />
                                        </FormGroup>
                                        <FormText color="muted" style={{ marginTop: '1rem' }}>
                                            Enabling Booking Terms & Conditions allows you to define your own terms and conditions which will be shown to customers before they book.
                                            Customers will be required to agree to these terms before being able to book with you.
                                        </FormText>
                                    </Col>
                                    <Col md={12}>
                                        <div style={{ minHeight: '6em' }} onClick={this.focusEditor}>
                                            <RichTextEditor editorState={this.state.bookingTermsEditorState} handleChange={this.handleEditorChange}/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <div className="action-button-row">
                                    <Button color="primary" onClick={() => this.submitBookingTerms()}>
                                        Save Changes
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingPreferences));