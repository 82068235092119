import React from "react";
import Lottie from 'react-lottie';
import * as AccessDeniedAnim from 'assets/animations/adhoc/accessDenied.json';
import PanelHeader from "components/PanelHeader/PanelHeader";
// Material components
import Button from '@mui/material/Button';
// Utilities
import withRouter from "utilities/withRouter";

class AccessDenied extends React.Component {

    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: AccessDeniedAnim.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            },
            width: '100%',
            height: '100%'
        };
        return (
            <>
                <PanelHeader size="sm" />
                <div className="content" style={{ display: 'flex' }}>
                    <div className="centered-container">
                        <div className="under-construction-animation" style={{ height: 150, width: 150 }}>
                            <Lottie options={defaultOptions}/>
                        </div>
                        <div className="under-construction-title">Access Denied</div>
                        <p className="under-construction-description" style={{ fontWeight: '700' }}>You do not have the required account privileges to access this information.</p>
                        <p className="under-construction-description">If you think this is incorrect, please contact your system admin.</p>
                        <Button variant="contained" onClick={() => this.props.navigate(-1)}>Go back</Button>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(AccessDenied);