import React from "react";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Badge
} from "reactstrap";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import Api from 'api/index';

import { FiPlus } from 'react-icons/fi';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import DatePicker from "components/DatePicker/DatePicker";
import ReactTable from "components/ReactTable/ReactTable";
import BusinessClosedDateAdd from "components/Business/BusinessClosedDateAdd";
import BusinessClosedDateEdit from "components/Business/BusinessClosedDateEdit";

class BusinessClosedDates extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            datePickerModalVisible: false,
            year: moment().year(),
            closedDatesData: [],
            businessClosedDateAddModalVisible: false,
            businessClosedDateEditModalVisible: false
        };
        this.toggleDatePickerModal = this.toggleDatePickerModal.bind(this);
    }

    componentDidMount() {
        this.getClosedDates(moment().year());
    }

    async getClosedDates(year) {
        this.setState({ loading: true, closedDatesData: [] });
        try {
            let response = await Api.getBusinessClosedDates({ year });
            this.setState({ loading: false, closedDatesData: response.data.businessClosedDatesData });
        } catch(e) {
            this.setState({ loading: false });
            this.props.triggerNotification("An unexpected error occured whilst loading your business closed dates.", "danger", "bc", 4);
        }
    }

    toggleDatePickerModal = () => {
        this.setState({
            datePickerModalVisible: !this.state.datePickerModalVisible
        });
    };

    toggleBusinessClosedDateAddModal = () => {
        this.setState({ businessClosedDateAddModalVisible: !this.state.businessClosedDateAddModalVisible })
    };

    toggleBusinessClosedDateEditModal = () => {
        this.setState({ businessClosedDateEditModalVisible: !this.state.businessClosedDateEditModalVisible })
    };

    handleBusinessClosedDateAddSubmit = () => {
        this.setState({ businessClosedDateAddModalVisible: false });
        this.getClosedDates(this.state.year);
    };

    handleBusinessClosedDateEditSubmit = () => {
        this.setState({ businessClosedDateEditModalVisible: false });
        this.getClosedDates(this.state.year);
    };

    handlePreviousYear() {
        let year = this.state.year - 1;
        this.setState({ year });
        this.getClosedDates(year);
    }

    handleNextYear() {
        let year = this.state.year + 1;
        this.setState({ year });
        this.getClosedDates(year);
    }

    renderHeader() {
        return (
            <div className="mb-3 calendar-header-container" style={{ padding: 0 }}>
                <div className="calendar-header-item calendar-header-item-2">
                    <div className="calendar-header-title-container" style={{ margin: 0 }}>
                        <Button color="primary" className="calendar-header-title-button" onClick={() => this.handlePreviousYear()}>
                            <span className="btn-label">
                            <i className="now-ui-icons arrows-1_minimal-left" />
                            </span>
                        </Button>
                        <div className="calendar-header-title" onClick={this.toggleDatePickerModal}>
                            {this.state.year}
                        </div>
                        <Button color="primary" className="calendar-header-title-button" onClick={() => this.handleNextYear()}>
                            <span className="btn-label">
                            <i className="now-ui-icons arrows-1_minimal-right" />
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="calendar-header-item calendar-header-item-3">
                    <Button color="primary" className="calendar-header-button pull-right" style={{ marginLeft: 3 }} onClick={this.toggleBusinessClosedDateAddModal}>
                        <FiPlus size={16} color={'#1ab394'}/> Add
                    </Button>
                </div>
                <div className="calendar-header-item calendar-header-item-4">
                    <Button
                        color="primary"
                        className="calendar-header-button"
                        style={{ marginTop: 0, marginBottom: 0, marginLeft: 5 }}
                        onClick={this.toggleBusinessClosedDateAddModal}
                    >
                        <FiPlus size={16} color={'#1ab394'}/>
                    </Button>
                </div>
            </div>
        );
    }

    submitDatePickerChange = (e) => {
        let year = e.year();
        this.setState({ year, datePickerModalVisible: false });
        this.getClosedDates(year);
    }

    renderClosedDateNameCell(props, breakLayoutWidth) {
        if(window.innerWidth > breakLayoutWidth) {
            return (
                <span className="text-bold">{props.value ? props.value : 'N/A'}</span>
            );
        } else {
            let isSameYear = moment(props.row.original.start_date, 'YYYY-MM-DD').isSame(moment(props.row.original.end_date, 'YYYY-MM-DD'), 'year');
            return (
                <div>
                    <div className="product-name-cell-title">{props.value ? props.value : 'N/A'}</div>
                    <div className="product-minimised-data">Start: {moment(props.row.original.start_date, 'YYYY-MM-DD HH:mm:ss').format(isSameYear ? 'ddd D MMM' : 'ddd D MMM YY')}</div>
                    <div className="product-minimised-data">End: {moment(props.row.original.end_date, 'YYYY-MM-DD HH:mm:ss').format(isSameYear ? 'ddd D MMM' : 'ddd D MMM YY')}</div>
                </div>
            );
        }
    }

    renderClosedDateColumn(props) {
        let isSameYear = moment(props.row.original.start_date, 'YYYY-MM-DD').isSame(moment(props.row.original.end_date, 'YYYY-MM-DD'), 'year');
        return (
            <span>{moment(props.value, 'YYYY-MM-DD HH:mm:ss').format(isSameYear ? 'ddd D MMM' : 'ddd D MMM YY')}</span>
        );
    }

    getTableColumns() {
        let breakLayoutWidth = 540;
        let tableColumns = [
            {
                Header: <span>Name</span>,
                accessor: "business_closed_date_name",
                disableFilters: true,
                disableSortBy: window.innerWidth <= breakLayoutWidth,
                Cell: props => this.renderClosedDateNameCell(props, breakLayoutWidth)
            }
        ];
        if(window.innerWidth > breakLayoutWidth) {
            tableColumns.push(
                {
                    Header: <span>Start Date</span>,
                    accessor: "start_date",
                    disableFilters: true,
                    disableSortBy: window.innerWidth <= breakLayoutWidth,
                    Cell: props => this.renderClosedDateColumn(props)
                },
                {
                    Header: <span>End Date</span>,
                    accessor: "end_date",
                    disableFilters: true,
                    disableSortBy: window.innerWidth <= breakLayoutWidth,
                    Cell: props => this.renderClosedDateColumn(props)
                }
            );
        }
        tableColumns.push(
            {
                Header: <span className="pull-right">Status</span>,
                accessor: "open",
                disableFilters: true,
                disableSortBy: true,
                Cell: props => <span className="pull-right">{props.value === 1 ? <Badge color="success">Open</Badge> : <Badge color="danger">Closed</Badge> }</span>
            }
        );
        return tableColumns;
    }

    handleRowClick = (rowData) => {
        this.setState({ businessClosedDateEditModalVisible: true, businessClosedDateId: rowData.business_closed_date_id });
    }

    renderDatePickerModal() {
        return (
            <DatePicker
                visible={this.state.datePickerModalVisible}
                curDate={moment(this.state.year, 'YYYY')}
                submitDate={this.submitDatePickerChange}
                toggleVisible={this.toggleDatePickerModal}
                timezoneName={this.props.business.timezone_name}
                initialView={'years'}
            />
        );
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Closed Dates</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={12} md={10} lg={10} xl={7} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        {this.renderHeader()}
                                    </Col>
                                    <Col md={12}>
                                        <div>
                                            <ReactTable
                                                data={this.state.closedDatesData}
                                                pagination={true}
                                                masterClassName="products-table"
                                                striped={false}
                                                columns={this.getTableColumns()}
                                                rowClick={this.handleRowClick}
                                                searchPlaceholder={'Filter closed dates...'}
                                                noDataPlaceholder={'No closed dates found'}
                                                loading={this.state.loading}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {this.renderDatePickerModal()}
            {this.state.businessClosedDateAddModalVisible ?
                <BusinessClosedDateAdd
                    visible={this.state.businessClosedDateAddModalVisible}
                    toggleVisible={this.toggleBusinessClosedDateAddModal}
                    onSubmit={this.handleBusinessClosedDateAddSubmit}
                    triggerNotification={this.props.triggerNotification}
                />
            : null}
            {this.state.businessClosedDateEditModalVisible ?
                <BusinessClosedDateEdit
                    visible={this.state.businessClosedDateEditModalVisible}
                    toggleVisible={this.toggleBusinessClosedDateEditModal}
                    onSubmit={this.handleBusinessClosedDateEditSubmit}
                    triggerNotification={this.props.triggerNotification}
                    businessClosedDateId={this.state.businessClosedDateId}
                />
            : null}
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        user: state.user
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessClosedDates);