import React from "react";

// reactstrap components
import {
    Card,
    CardTitle,
    CardBody,
    Col,
    Button,
    Tooltip,
} from "reactstrap";

import { FiMail, FiMessageSquare, FiPhone } from 'react-icons/fi';

import { CDN_URL } from "constants/urls";

class StaffCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            callTooltipVisible: false,
            smsTooltipVisible: false,
            emailTooltipVisible: false
        };
        this.handleStaffCardClick = this.handleStaffCardClick.bind(this);
        this.handleStaffCallClick = this.handleStaffCallClick.bind(this);
        this.handleStaffSmsClick = this.handleStaffSmsClick.bind(this);
        this.handleStaffEmailClick = this.handleStaffEmailClick.bind(this);
    }

    handleStaffCardClick(e) {
        if(e.defaultPrevented) return
        this.props.navigate('/admin/edit_staff?id=' + this.props.staffObj.id);
    }

    handleStaffCallClick(e) {
        e.preventDefault();
        if(this.props.staffObj && this.props.staffObj.staff_mobile_no) {
            window.open(`tel:${this.props.staffObj.staff_mobile_no}`, '_self');
        }
    }

    handleStaffSmsClick(e) {
        e.preventDefault();
        if(this.props.staffObj && this.props.staffObj.staff_mobile_no) {
            window.open(`sms:${this.props.staffObj.staff_mobile_no}`, '_self');
        }
    }

    handleStaffEmailClick(e) {
        e.preventDefault();
        if(this.props.staffObj && this.props.staffObj.staff_email) {
            window.open(`mailto:${this.props.staffObj.staff_email}`, '_self')
        }
    }

    render() {
        if(!this.props.staffObj) {
            return null;
        }
        let staffObj = this.props.staffObj;
        return (
            <Col xl={3} lg={4} md={4} sm={6} xs={12}>
                <Card className="card-profile staff-card" onClick={this.handleStaffCardClick}>
                    <CardBody>
                        <div className="card-avatar">
                            <img
                            alt="..."
                            className="img img-raised"
                            src={`${CDN_URL}/` + staffObj.staff_img}
                            ></img>
                        </div>
                        <CardTitle tag="h4">{staffObj ? staffObj.firstname + ' ' + staffObj.lastname : null}</CardTitle>
                        <p className="card-description">{staffObj ? staffObj.position : null}</p>
                        <div className="customer-profile-buttons">
                            {staffObj && staffObj.staff_mobile_no ?
                                <div style={{ display: 'inline-flex' }}>
                                    <Button id={"callButton" + staffObj.id} color="muted" className="customer-profile-button btn-icon" onClick={this.handleStaffCallClick}><FiPhone style={{ marginBottom: 3 }} /></Button>
                                    <Tooltip placement="bottom" isOpen={this.state.callTooltipVisible} target={"callButton" + staffObj.id} toggle={() => this.setState({ callTooltipVisible: !this.state.callTooltipVisible })}>
                                        Call: {staffObj.staff_mobile_no}
                                    </Tooltip>
                                    <Button id={"smsButton" + staffObj.id} color="muted" className="customer-profile-button btn-icon" onClick={this.handleStaffSmsClick}><FiMessageSquare style={{ marginBottom: 3 }} /></Button>
                                    <Tooltip placement="bottom" isOpen={this.state.smsTooltipVisible} target={"smsButton" + staffObj.id} toggle={(e) => this.setState({ smsTooltipVisible: !this.state.smsTooltipVisible })}>
                                        SMS: {staffObj.staff_mobile_no}
                                    </Tooltip>
                                </div>
                            : null}
                            {staffObj && staffObj.staff_email ?
                                <div style={{ display: 'inline-flex' }}>
                                    <Button id={"emailButton" + staffObj.id} color="muted" className="customer-profile-button btn-icon" onClick={this.handleStaffEmailClick}><FiMail style={{ marginBottom: 3 }} /></Button>
                                    <Tooltip placement="bottom" isOpen={this.state.emailTooltipVisible} target={"emailButton" + staffObj.id} toggle={() => this.setState({ emailTooltipVisible: !this.state.emailTooltipVisible })}>
                                        Email: {staffObj.staff_email}
                                    </Tooltip>
                                </div>
                            : null}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        );
    }

}

export default StaffCard;