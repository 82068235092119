import React from "react";
// MUI components
import {
    Box,
    Button,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    Checkbox,
    CircularProgress
} from "@mui/material";
// Sweetalert
import Swal from 'sweetalert2';
// Core components
import Modal from "components/Material/Modal";
import UserAvatar from "components/Material/UserAvatar";
// API
import Api from 'api/index';

export default function CustomerMerger(props) {

    const [loading, setLoading] = React.useState(true);
    const [userMergePage, setUserMergePage]= React.useState(0);
    const [userMergeData, setUserMergeData] = React.useState(null);
    const [loadMore, setloadMore] = React.useState(true);
    const [selectedUserMerges, setSelectedUserMerges] = React.useState([]);
    const [collapsed, setCollapsed] = React.useState(false);

    React.useEffect(() => {
        loadUserMergeData(0);
        setCollapsed(window.innerWidth < 800);
    }, []);

    const loadUserMergeData = async(page) => {
        try {
            if(!loading) setLoading(true);
            const { data } = await Api.getUserMerge(page);
            setUserMergeData(data.userMergeData);
            if(data.userMergeData?.length < 50) {
                setloadMore(false);
            }
            setLoading(false);
            if(userMergePage !== page) {
                setUserMergePage(page);
            }
        } catch(err) {
            props.triggerNotification("An unexpected error occured. Please contact Support if the problem persists.", "danger", "bc", 8);
            console.log(err);
            props.onClose();
        }
    }

    const submitUserMerge = async (mergeAll=false) => {
        if(loading) return;
        // Confirm merge before proceeding
        const { isDismissed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'We strongly recommend you review all customer merge suggestions before proceeding. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#f44336',
            target: document.getElementById('customer-merger-dialog')
        });
        if(isDismissed) return;
        setLoading(true);
        // Request merge
        try {
            await Api.postUserMerge({
                merge_all: mergeAll,
                user_merge_ids: selectedUserMerges
            });
        } catch(err) {
            props.triggerNotification("An unexpected error occured. Please contact Support if the problem persists.", "danger", "bc", 8);
            console.log(err);
        }
        props.triggerNotification("User merge completed successfully.", "success", "bc", 8);
        props.onClose();
    }

    const renderTableHeader = () => {
        if(collapsed) return;
        return (
            <TableHead>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                {!collapsed && <TableCell>Email</TableCell>}
                {!collapsed && <TableCell>Contact Number</TableCell>}
                {!collapsed && <TableCell>Created By</TableCell>}
            </TableHead>
        );
    };

    const handleRowClick = (userMergeId) => {
        const foundIndex = selectedUserMerges.indexOf(userMergeId);
        const updatedSelectedUserMerges = [...selectedUserMerges];
        if(foundIndex > -1) {
            updatedSelectedUserMerges.splice(foundIndex, 1);
        } else {
            updatedSelectedUserMerges.push(userMergeId);
        }
        setSelectedUserMerges(updatedSelectedUserMerges);
    }

    const contentPlaceholder = (x) => x ? x : '--------';

    const renderNameCell = (rowData) => {
        const fromName = `${rowData.merge_from_user_firstname} ${rowData.merge_from_user_lastname}`;
        const toName = `${rowData.merge_to_user_firstname} ${rowData.merge_to_user_lastname}`;
        const containerSx = { display: 'flex', alignItems: 'center', gap: 2 };
        const avatarSx = { height: 30, width: 30, fontSize: 13 }
        const collapsed = window.innerWidth < 800;
        return (
            <TableCell sx={collapsed && { pl: 0.5, pr: 0 }}>
                <Box sx={containerSx}>
                    <UserAvatar
                        name={`${rowData.merge_from_user_firstname} ${rowData.merge_from_user_lastname}`}
                        style={avatarSx}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {fromName}
                        {collapsed && (
                            <>
                                <Typography variant="caption">{contentPlaceholder(rowData.merge_from_user_email)}</Typography>
                                <Typography variant="caption">{contentPlaceholder(rowData.merge_from_user_phone)}</Typography>
                            </>
                        )}
                    </Box>
                </Box>
                <Divider sx={{ my: 0.5 }} />
                <Box sx={containerSx}>
                    <UserAvatar
                        name={`${rowData.merge_to_user_firstname} ${rowData.merge_to_user_lastname}`}
                        style={avatarSx}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {toName}
                        {collapsed && (
                            <>
                                <Typography variant="caption">{rowData.merge_to_user_email}</Typography>
                                <Typography variant="caption">{rowData.merge_to_user_phone}</Typography>
                            </>
                        )}
                    </Box>
                </Box>
            </TableCell>
        )
    }

    const renderTableDataCell = (fromValue, toValue) => {
        if(collapsed) return;
        return (
            <TableCell>
                {contentPlaceholder(fromValue)}
                <Divider sx={{ my: 1 }} />
                {contentPlaceholder(toValue)}
            </TableCell>
        )
    }

    const renderTableRow = (rowData) => {
        const onClickHandler = () => handleRowClick(rowData.user_merge_id);
        return (
            <TableRow hover sx={{ cursor: 'pointer' }} onClick={onClickHandler}>
                <TableCell width={42} sx={collapsed && { px: 0 }}>
                    <Checkbox
                        checked={selectedUserMerges.includes(rowData.user_merge_id)}
                        onClick={onClickHandler}
                    />
                </TableCell>
                {renderNameCell(rowData)}
                {renderTableDataCell(
                    rowData.merge_from_user_email,
                    rowData.merge_to_user_email
                )}
                {renderTableDataCell(
                    rowData.merge_from_user_phone,
                    rowData.merge_to_user_phone
                )}
                {renderTableDataCell(
                    "Business",
                    "Customer"
                )}
            </TableRow>
        )
    }

    const renderTable = () => {
        if(loading) {
            return (
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress color="secondary"/>
                </Box>
            )
        }
        return (
            <TableContainer>
                <Table stickyHeader>
                    {renderTableHeader()}
                    <TableBody>
                        {userMergeData?.map((rowData) => renderTableRow(rowData)) }
                        {loadMore && (
                            <TableRow>
                                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => loadUserMergeData(userMergePage + 1)}
                                    >
                                        Load more...
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const collapsedButtonSx = collapsed ? { flexDirection: 'column', gap: 2 } : {};
    return (
        <Modal
            id="customer-merger-dialog"
            open={true}
            fullWidth
            maxWidth={"lg"}
            onClose={props.onClose}
        >
            <Box display="flex" flexDirection="column">
                <Box>
                    <Typography textAlign="center" variant="h5">Merge Duplicate Customers</Typography>
                    <hr/>
                    {renderTable()}
                </Box>
                <Box sx={{ ...{ mt: 2, display: 'flex', justifyContent: 'space-between' }, ...collapsedButtonSx}}>
                    {userMergeData?.length > 1 ? (
                        <Button variant="outlined" color="error" disabled={loading} onClick={() => submitUserMerge(true)}>
                            Merge all
                        </Button>
                    ) : <div/>}
                    <Button variant="contained" disabled={selectedUserMerges?.length === 0 || loading} onClick={() => submitUserMerge(false)}>
                        Merge selected{selectedUserMerges?.length > 0 && ` (${selectedUserMerges.length})`}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}