import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    FormText,
    Spinner,
    Alert,
    InputGroup,
    InputGroupText
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ImageCropper from "components/ImageCropper/ImageCropper";

import Compressor from 'compressorjs';

import Api from '../../api/index';

import { FiImage, FiX, FiTrash } from 'react-icons/fi';

import Switch from "react-switch";

import CurrencyInput from "react-currency-input-field";

import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';
import { connect } from "react-redux";

import currency from '../../constants/currency';

import withRouter from "utilities/withRouter";
import { error } from "jquery";

class ProductEdit extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            error: null,
            sweetAlert: null,
            imageCropperVisible: false,
            imageFile: null,
            imageFileCropped: null,
            imageState: 0,
            productName: null,
            productVolume: null,
            productDescription: null,
            productPrice: null,
            productStockLevel: null,
            productVisible: true
        };
        this.toggleImageCropper = this.toggleImageCropper.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.submitCroppedImage = this.submitCroppedImage.bind(this);
        this.productImgClick = this.productImgClick.bind(this);
        this.handleRemoveProductImg = this.handleRemoveProductImg.bind(this);
    }

    componentDidMount() {
        try {
            const query = new URLSearchParams(window.location.search);
            let product_id = query.get("product_id");
            if(product_id) {
                product_id = parseInt(product_id);
                if(Number.isInteger(product_id)){
                    this.loadProduct(product_id);
                } else {
                    this.props.navigate('/admin/products');
                }
            } else {
                this.props.navigate('/admin/products');
            }
        } catch(err) {
            this.props.triggerNotification("An unexpected error occured whilst loading this product.", "danger", "bc", 4);
            this.props.navigate('/admin/products');
        }
    }

    loadProduct(product_id) {
        let productData = this.props.product;
        console.log(productData);
        let productObj = productData.find(x => x.id === product_id);
        if(productObj) {
            this.setState({
                imageFileCropped: 'https://cdn.whatstyle.com/' + productObj.product_img,
                productId: product_id,
                productName: productObj.product_name,
                productVolume: productObj.product_volume,
                productDescription: productObj.product_description,
                productPrice: Number(productObj.product_price / 100).toFixed(2),
                productStockLevel: productObj.product_stock_level,
                productVisible: productObj.product_retail_enabled === 1
            });
        } else {
            this.props.triggerNotification("Product not found.", "danger", "bc", 4);
        }
    }

    handleChange(e, name) {
        switch(name) {
            case "product_name":
                this.setState({ productName: e.target.value });
                break;
            case "product_volume":
                this.setState({ productVolume: e.target.value });
                break;
            case "product_description":
                this.setState({ productDescription: e.target.value });
                break;
            case "product_price":
                console.log(e);
                this.setState({ productPrice: e });
                break;
            case "product_stock_level":
                this.setState({ productStockLevel: e.target.value });
                break;
            case "product_visible":
                this.setState({ productVisible: e });
                break;
            default:
                return;
        }
    }

    toggleImageCropper() {
        this.setState({ imageCropperVisible: !this.state.imageCropperVisible });
    }

    async onFileChange(e) {
        if(e.target && e.target.files && e.target.files.length === 1) {
            const imgFile = e.target.files[0];
            let imageDataUrl = await this.readFile(imgFile);
            this.setState({ imageFile: imageDataUrl, imageCropperVisible: true });
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            this.getNormalizedFile(file).then(normalizedFile => reader.readAsDataURL(normalizedFile)).catch(error => reject(error));
        });
    }

    getNormalizedFile(file) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 1000,
                maxHeight: 1000,
                success(normalizedFile) {
                    resolve(normalizedFile);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    }

    submitCroppedImage(file) {
        let imageState = this.state.imageState;
        if(imageState === 0 || imageState === 3) {
            imageState = 2;
        }
        this.setState({ imageFileCropped: file, imageCropperVisible: false, imageState });
    }

    async submitImage() {
        if(this.state.loading) {
            return;
        }
        if(!this.state.productName || this.state.productName.length === 0) {
            this.setState({ loading: false, error: 'A product name is required.' });
            return;
        }
        if(!this.state.productVolume || this.state.productVolume.length === 0) {
            this.setState({ loading: false, error: 'A product volume/size is required.' });
            return;
        }
        this.setState({ loading: true, error: null });
        try {
            let data = {
                product_id: this.state.productId,
                product_img_state: this.state.imageState,
                product_img: this.state.imageFileCropped,
                product_name: this.state.productName,
                product_volume: this.state.productVolume,
                product_description: this.state.productDescription,
                product_price: this.state.productPrice ? Math.round(this.state.productPrice * 100) : 0,
                product_stock_level: this.state.productStockLevel ? this.state.productStockLevel : 0,
                product_visible: this.state.productVisible
            };
            await Api.updateProduct(data);
            this.props.triggerNotification("Product updated successfully.", "success", "bc", 4);
            this.props.navigate('/admin/products');
        } catch(err) {
            console.log(err);
            this.setState({ loading: false, error: 'An unexpected error occured. Please contact support if the problem persists.' });
        }
    }

    handleRemoveProductImg(e) {
        e.preventDefault();
        this.setState({ imageFileCropped: null, imageState: 3 });
    }

    productImgClick(e) {
        if(e.defaultPrevented) return
        this.productImgInput.click();
    }

    handleDeleteProduct() {
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    title="Are you sure?"
                    onConfirm={() => this.confirmDeleteProduct()}
                    onCancel={() => this.cancelDeleteProduct()}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Are you sure that you want to delete this product? You will need to contact your consultant to undo this action.
                </SweetAlert>
            ),
        });
    }

    async confirmDeleteProduct() {
        try {
            this.setState({ sweetAlert: null });
            this.props.actions.loadLoadingSpinner(true);
            await Api.deleteProduct({ product_id: this.state.productId });
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("Product deleted successfully.", "success", "bc", 4);
            this.props.navigate('/admin/products');
        } catch(e) {
            this.props.actions.loadLoadingSpinner(false);
            this.props.triggerNotification("An unexpected error occured while trying to delete the product. Please contact Support if the problem persists.", "danger", "bc", 8);
            return;
        }
    }

    cancelDeleteProduct() {
        this.setState({ sweetAlert: null });
    }

    render() {
        const currencyObj = currency.find(x => x.id === this.props.business.currency_id);
        const editPermission = this.props.userRole.product.update === true;
        if(!editPermission && !this.state.error) {
            this.setState({ error: "You don't have permission to edit this product." });
        }
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Edit Product</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                {this.state.sweetAlert}
                <Row>
                    <Col xs={12} sm={8} md={7} lg={6} xl={4} xxl={3} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <div style={{ marginBottom: 15 }}>
                                    <input type="file" ref={fileInput => this.productImgInput = fileInput} accept="image/png, image/gif, image/jpeg, .jpg, .jpeg, .png" onChange={this.onFileChange} style={{ display: 'none' }} />
                                    <div className="gallery-img-container" onClick={this.productImgClick}>
                                        {this.state.imageFileCropped ?
                                            <div>
                                                <img alt="ProductImg" src={this.state.imageFileCropped} style={{ width: '100%' }}/>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    className="btn-icon"
                                                    style={{ float: 'right', marginBottom: 0, marginTop: -30, marginRight: 0 }}
                                                    onClick={this.handleRemoveProductImg}
                                                >
                                                    <FiX size={14}/>
                                                </Button>
                                            </div>
                                        :
                                            <div className="gallery-img-icon-container">
                                                <FiImage size={30}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <Row>
                                    <Col md={12}>
                                        <label>Product Name</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                value={this.state.productName}
                                                onChange={(e) => this.handleChange(e, 'product_name')}
                                                disabled={!editPermission}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Product Volume/Size</label>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                placeholder="e.g. 200ml"
                                                value={this.state.productVolume}
                                                onChange={(e) => this.handleChange(e, 'product_volume')}
                                                disabled={!editPermission}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label>Product Description</label>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                style={{ padding: 10 }}
                                                value={this.state.productDescription}
                                                onChange={(e) => this.handleChange(e, 'product_description')}
                                                disabled={!editPermission}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <label>Product Price</label>
                                        <InputGroup>
                                            <InputGroupText className="currency-input-symbol">{currencyObj ? currencyObj.symbol : '£'}</InputGroupText>
                                            <CurrencyInput
                                                className="form-control currency-input-control"
                                                placeholder="0.00"
                                                decimalsLimit={2}
                                                decimalScale={2}
                                                allowNegativeValue={false}
                                                step={1}
                                                value={this.state.productPrice ? this.state.productPrice : 0}
                                                onValueChange={(value, name) => this.handleChange(value, 'product_price')}
                                                disabled={!editPermission}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <label>Product Stock Level</label>
                                        <FormGroup>
                                            <Input
                                                type="number"
                                                placeholder="0"
                                                min={0}
                                                step={1}
                                                style={{ padding: 10 }}
                                                value={this.state.productStockLevel}
                                                onChange={(e) => this.handleChange(e, 'product_stock_level')}
                                                disabled={!editPermission}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={12}>
                                        <h6>Visibility</h6>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} className="mt-2">
                                        <FormGroup>
                                            <p className="react-switch-label">App Products Screen</p>
                                            <Switch
                                                onChange={(e) => { this.handleChange(e, 'product_visible') }}
                                                checked={this.state.productVisible}
                                                onColor="#1ab394"
                                                offColor="#ed5565"
                                                handleDiameter={25}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                disabled={!editPermission}
                                            />
                                            <br/>
                                            <FormText color="muted" style={{ marginTop: '1rem' }}>
                                                Should this image be visible on your business app products screen?
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col md={12}>
                                {editPermission && (
                                    <Button color="primary" onClick={() => this.submitImage()}>
                                        {this.state.loading ? <Spinner color="success" size="sm" /> : 'Save Changes'}
                                    </Button>
                                )}
                                {this.props.userRole.product.delete === true && (
                                    <Button color="danger" className="pull-right" onClick={() => this.handleDeleteProduct()}>
                                        <FiTrash size={15}/>
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Alert color="danger" className="alert-fullwidth" isOpen={this.state.error} toggle={() => this.setState({ error: null })}>
                            <span>
                                {this.state.error}
                            </span>
                        </Alert>
                    </Col>
                </Row>
            </div>
            <ImageCropper
                visible={this.state.imageCropperVisible}
                toggleVisible={this.toggleImageCropper}
                imageFile={this.state.imageFile}
                submitCroppedImage={this.submitCroppedImage}
                xDim={600}
                yDim={600}
            />
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        product: state.product,
        userRole: state.userRole
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductEdit));