import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Collapse,
    FormText,
    Spinner,
    Button
} from "reactstrap";
import moment from 'moment-timezone';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BusinessScheduleAdd from "components/Business/BusinessScheduleAdd";
import BusinessScheduleEdit from "components/Business/BusinessScheduleEdit";

import Api from 'api/index';

import { FiArrowRight, FiPlus } from 'react-icons/fi';

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

class BusinessSchedules extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            error: null,
            openedCollapses: ["collapseTwo"],
            conflictingSchedules: [],
            upcomingSchedules: [{ schedule_id: 1, schedule_start: '2022-03-27', schedule_end: null }],
            previousSchedules: [],
            addScheduleModalVisible: false,
            editScheduleModalVisible: false,
            selectedScheduleData: null
        };
        this.toggleAddScheduleModal = this.toggleAddScheduleModal.bind(this);
        this.submitAddSchedule = this.submitAddSchedule.bind(this);
        this.toggleEditScheduleModal = this.toggleEditScheduleModal.bind(this);
        this.submitEditSchedule = this.submitEditSchedule.bind(this);
    }

    componentDidMount() {
        this.getBusinessSchedules();
    }

    async getBusinessSchedules() {
        this.setState({ loading: true });
        try {
            let response = await Api.getBusinessSchedules();
            let businessScheduleData = response.data.businessScheduleData;
            let conflictingSchedules = [], upcomingSchedules = [], previousSchedules = [];
            let now = moment().tz(this.props.business.timezone_name, false);
            businessScheduleData.forEach(scheduleObj => {
                // Detect schedule conflict
                let filteredSchedules = businessScheduleData.filter(x => x.business_schedule_id !== scheduleObj.business_schedule_id);
                let foundSchedule = null;
                if(!scheduleObj.business_schedule_end) {
                    foundSchedule = filteredSchedules.find(x =>
                        (moment(x.business_schedule_start, 'YYYY-MM-DD').isSameOrBefore(moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD'), 'day') && moment(x.business_schedule_end, 'YYYY-MM-DD').isSameOrAfter(moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD'), 'day')) ||
                        !x.business_schedule_end ||
                        moment(x.business_schedule_start, 'YYYY-MM-DD').isSameOrAfter(moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD'), 'day')
                    );
                } else {
                    foundSchedule = filteredSchedules.find(x =>
                        (moment(x.business_schedule_start, 'YYYY-MM-DD').isSameOrAfter(moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD'), 'day') && moment(x.business_schedule_end, 'YYYY-MM-DD').isSameOrBefore(moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD'), 'day')) ||
                        (moment(x.business_schedule_start, 'YYYY-MM-DD').isSameOrBefore(moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD'), 'day') && moment(x.business_schedule_end, 'YYYY-MM-DD').isSameOrAfter(moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD'), 'day')) ||
                        (moment(x.business_schedule_start, 'YYYY-MM-DD').isSameOrBefore(moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD'), 'day') && moment(x.business_schedule_end, 'YYYY-MM-DD').isSameOrAfter(moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD'), 'day')) ||
                        (moment(x.business_schedule_start, 'YYYY-MM-DD').isSameOrBefore(moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD'), 'day') && moment(x.business_schedule_end, 'YYYY-MM-DD').isSameOrAfter(moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD'), 'day')) ||
                        (moment(x.business_schedule_start, 'YYYY-MM-DD').isSameOrBefore(moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD'), 'day') && !x.business_schedule_end)
                    );
                }
                if(foundSchedule) {
                    conflictingSchedules.push(scheduleObj);
                } else {
                    // Classify schedule
                    if(scheduleObj.business_schedule_end) {
                        let scheduleStart = moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD');
                        let scheduleEnd = moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD');
                        if(scheduleStart.isAfter(now, 'day')) {
                            upcomingSchedules.push(scheduleObj);
                        } else if(scheduleStart.isBefore(now, 'day') && scheduleEnd.isAfter(now, 'day')) {
                            upcomingSchedules.push(scheduleObj);
                        } else {
                            previousSchedules.push(scheduleObj);
                        }
                    } else {
                        upcomingSchedules.push(scheduleObj);
                    }
                }
            });
            this.setState({ loading: false, conflictingSchedules, upcomingSchedules, previousSchedules });
        } catch(e) {
            this.setState({ loading: false });
            this.props.triggerNotification("An unexpected error occured whilst loading your business schedules.", "danger", "bc", 4);
        }
    }

    collapsesToggle = (collapse) => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
          this.setState({
            openedCollapses: [],
          });
        } else {
          this.setState({
            openedCollapses: [collapse],
          });
        }
    };

    toggleAddScheduleModal() {
        this.setState({ addScheduleModalVisible: !this.state.addScheduleModalVisible });
    }

    submitAddSchedule() {
        this.setState({ addScheduleModalVisible: false });
        this.getBusinessSchedules();
    }

    toggleEditScheduleModal() {
        this.setState({ editScheduleModalVisible: !this.state.editScheduleModalVisible });
    }

    submitEditSchedule() {
        this.setState({ editScheduleModalVisible: false });
        this.getBusinessSchedules();
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Business Schedules</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={8} md={7} lg={6} xl={4} className="ms-auto me-auto">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <FormText color="muted">
                                            Business schedules are a legacy feature carried over from Hub V1 for the benefit of Partners who have used them previously.
                                            New Partners should use the staff schedules instead. Please contact your Styler Consultant if you have any questions.
                                        </FormText>
                                        <hr/>
                                    </Col>
                                    <Col md={12}>
                                        {this.state.previousSchedules.length === 0 && this.state.upcomingSchedules.length === 0 && this.state.conflictingSchedules.length === 0 && !this.state.loading ?
                                            <div className="text-center" style={{ marginBottom: 25 }}>No schedules to display</div>
                                        : null}
                                        <Button color="primary" outline block style={{ marginTop: 5 }} onClick={() => this.toggleAddScheduleModal()}>
                                            <FiPlus size={16} color={'#1ab394'}/> Add new schedule
                                        </Button>
                                        <hr/>
                                    </Col>
                                    <Col md={12}>
                                        {this.state.loading ?
                                            <div className="text-center" style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Spinner size="lg" color="success"/>
                                            </div>
                                        : null}
                                        <div
                                            aria-multiselectable={true}
                                            className="card-collapse"
                                            id="accordion"
                                            role="tablist"
                                        >
                                            {this.state.conflictingSchedules && this.state.conflictingSchedules.length > 0 && !this.state.loading ?
                                                <Card className="card-plain" style={{ marginBottom: 10 }}>
                                                    <CardHeader role="tab" className="schedule-category-header schedule-category-header-conflict">
                                                        <a
                                                        aria-expanded={this.state.openedCollapses.includes(
                                                            "collapseOne"
                                                        )}
                                                        href="#pablo"
                                                        data-parent="#accordion"
                                                        data-toggle="collapse"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.collapsesToggle("collapseOne");
                                                        }}
                                                        >
                                                        Conflicting Schedules{" "}
                                                        <i className="now-ui-icons arrows-1_minimal-down" />
                                                        </a>
                                                    </CardHeader>
                                                    <Collapse
                                                        role="tabpanel"
                                                        isOpen={this.state.openedCollapses.includes(
                                                        "collapseOne"
                                                        )}
                                                    >
                                                        <CardBody className="schedule-category-body">
                                                            <div>
                                                                {this.state.conflictingSchedules.map(scheduleObj => {
                                                                    return (
                                                                        <div className="schedule-container" onClick={() => this.setState({ editScheduleModalVisible: true, selectedScheduleData: scheduleObj })}>
                                                                            <div>{moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD').format('ddd Do MMM YYYY')}<FiArrowRight style={{ marginBottom: 3, marginLeft: 10, marginRight: 10 }}/>{scheduleObj.business_schedule_end ? moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD').format('ddd Do MMM YYYY') : "Ongoing"}</div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                            : null}
                                            {this.state.upcomingSchedules && this.state.upcomingSchedules.length > 0 && !this.state.loading ?
                                                <Card className="card-plain" style={{ marginBottom: 10 }}>
                                                    <CardHeader role="tab" className="schedule-category-header schedule-category-header-primary">
                                                        <a
                                                        aria-expanded={this.state.openedCollapses.includes(
                                                            "collapseTwo"
                                                        )}
                                                        href="#pablo"
                                                        data-parent="#accordion"
                                                        data-toggle="collapse"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.collapsesToggle("collapseTwo");
                                                        }}
                                                        >
                                                        Upcoming Schedules{" "}
                                                        <i className="now-ui-icons arrows-1_minimal-down" />
                                                        </a>
                                                    </CardHeader>
                                                    <Collapse
                                                        role="tabpanel"
                                                        isOpen={this.state.openedCollapses.includes(
                                                        "collapseTwo"
                                                        )}
                                                    >
                                                        <CardBody className="schedule-category-body">
                                                            <div>
                                                                {this.state.upcomingSchedules.map(scheduleObj => {
                                                                    return (
                                                                        <div className="schedule-container" onClick={() => this.setState({ editScheduleModalVisible: true, selectedScheduleData: scheduleObj })}>
                                                                            <div>{moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD').format('ddd Do MMM YYYY')}<FiArrowRight style={{ marginBottom: 3, marginLeft: 10, marginRight: 10 }}/>{scheduleObj.business_schedule_end ? moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD').format('ddd Do MMM YYYY') : "Ongoing"}</div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                            : null}
                                            {this.state.previousSchedules && this.state.previousSchedules.length > 0 && !this.state.loading ?
                                                <Card className="card-plain" style={{ marginBottom: 10 }}>
                                                    <CardHeader role="tab" className="schedule-category-header schedule-category-header-primary">
                                                        <a
                                                        aria-expanded={this.state.openedCollapses.includes(
                                                            "collapseThree"
                                                        )}
                                                        href="#pablo"
                                                        data-parent="#accordion"
                                                        data-toggle="collapse"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.collapsesToggle("collapseThree");
                                                        }}
                                                        >
                                                        Previous Schedules{" "}
                                                        <i className="now-ui-icons arrows-1_minimal-down" />
                                                        </a>
                                                    </CardHeader>
                                                    <Collapse
                                                        role="tabpanel"
                                                        isOpen={this.state.openedCollapses.includes(
                                                        "collapseThree"
                                                        )}
                                                    >
                                                        <CardBody className="schedule-category-body">
                                                            <div>
                                                                {this.state.previousSchedules.map(scheduleObj => {
                                                                    return (
                                                                        <div className="schedule-container" onClick={() => this.setState({ editScheduleModalVisible: true, selectedScheduleData: scheduleObj })}>
                                                                            <div>{moment(scheduleObj.business_schedule_start, 'YYYY-MM-DD').format('ddd Do MMM YYYY')}<FiArrowRight style={{ marginBottom: 3, marginLeft: 10, marginRight: 10 }}/>{scheduleObj.business_schedule_end ? moment(scheduleObj.business_schedule_end, 'YYYY-MM-DD').format('ddd Do MMM YYYY') : "Ongoing"}</div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                            : null}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {this.state.addScheduleModalVisible ?
                <BusinessScheduleAdd
                    visible={this.state.addScheduleModalVisible}
                    toggleVisible={this.toggleAddScheduleModal}
                    onSubmit={this.submitAddSchedule}
                    triggerNotification={this.props.triggerNotification}
                />
            : null}
            {this.state.editScheduleModalVisible ?
                <BusinessScheduleEdit
                    visible={this.state.editScheduleModalVisible}
                    toggleVisible={this.toggleEditScheduleModal}
                    onSubmit={this.submitEditSchedule}
                    triggerNotification={this.props.triggerNotification}
                    data={this.state.selectedScheduleData}
                />
            : null}
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSchedules);